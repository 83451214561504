import { Injectable } from "@angular/core";
import { player } from "./app.component";

@Injectable({providedIn: 'root'})
export class PlayerService {

    getPlayerDatabase() : player[] {
        let allDatabasePlayers: player[] = [];
        allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Mebane`, Position : 'NT', Salary : 650000, SleeperID : 6 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Koch`, Position : 'P', Salary : 650000, SleeperID : 13 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Kalil`, Position : 'C', Salary : 650000, SleeperID : 12 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Prater`, Position : 'K', Salary : 650000, SleeperID : 17 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Flacco`, Position : 'QB', Salary : 650000, SleeperID : 19 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Witten`, Position : 'TE', Salary : 650000, SleeperID : 23 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Ryan`, Position : 'QB', Salary : 650000, SleeperID : 24 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robbie`, LastName: `Gould`, Position : 'K', Salary : 650000, SleeperID : 49 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Crosby`, Position : 'K', Salary : 650000, SleeperID : 59 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Staley`, Position : 'T', Salary : 650000, SleeperID : 70 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Adams`, Position : 'FS', Salary : 650000, SleeperID : 74 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zak`, LastName: `DeOssie`, Position : 'LS', Salary : 650000, SleeperID : 77 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antoine`, LastName: `Bethea`, Position : 'FS', Salary : 650000, SleeperID : 88 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Henne`, Position : 'QB', Salary : 650000, SleeperID : 89 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominique`, LastName: `Rodgers-Cromartie`, Position : 'CB', Salary : 650000, SleeperID : 95 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Rodgers`, Position : 'QB', Salary : 650000, SleeperID : 96 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Carr`, Position : 'CB', Salary : 650000, SleeperID : 105 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Gostkowski`, Position : 'K', Salary : 650000, SleeperID : 110 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcedes`, LastName: `Lewis`, Position : 'TE', Salary : 650000, SleeperID : 111 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andy`, LastName: `Lee`, Position : 'P', Salary : 650000, SleeperID : 112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Vinatieri`, Position : 'K', Salary : 650000, SleeperID : 120 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calais`, LastName: `Campbell`, Position : 'DE', Salary : 650000, SleeperID : 125 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Bryant`, Position : 'K', Salary : 650000, SleeperID : 127 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duane`, LastName: `Brown`, Position : 'T', Salary : 650000, SleeperID : 131 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Roethlisberger`, Position : 'QB', Salary : 650000, SleeperID : 138 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tramon`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeSean`, LastName: `Jackson`, Position : 'WR', Salary : 650000, SleeperID : 147 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wes`, LastName: `Horton`, Position : 'DE', Salary : 650000, SleeperID : 161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tom`, LastName: `Brady`, Position : 'QB', Salary : 650000, SleeperID : 167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnathan`, LastName: `Joseph`, Position : 'CB', Salary : 650000, SleeperID : 172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Schaub`, Position : 'QB', Salary : 650000, SleeperID : 178 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aqib`, LastName: `Talib`, Position : 'CB', Salary : 650000, SleeperID : 180 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Stanton`, Position : 'QB', Salary : 650000, SleeperID : 181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Peterson`, Position : 'RB', Salary : 650000, SleeperID : 184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Davis`, Position : 'LB', Salary : 650000, SleeperID : 187 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Condo`, Position : 'LS', Salary : 650000, SleeperID : 199 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vernon`, LastName: `Davis`, Position : 'TE', Salary : 650000, SleeperID : 200 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Whitworth`, Position : 'OL', Salary : 650000, SleeperID : 202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donald`, LastName: `Penn`, Position : 'OT', Salary : 650000, SleeperID : 207 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `McCown`, Position : 'QB', Salary : 650000, SleeperID : 208 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wesley`, LastName: `Woodyard`, Position : 'LB', Salary : 650000, SleeperID : 210 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Fitzgerald`, Position : 'WR', Salary : 650000, SleeperID : 223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `L.P.`, LastName: `LaDouceur`, Position : 'LS', Salary : 650000, SleeperID : 225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frank`, LastName: `Gore`, Position : 'RB', Salary : 650000, SleeperID : 232 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Moore`, Position : 'QB', Salary : 650000, SleeperID : 233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Domata`, LastName: `Peko`, Position : 'DT', Salary : 650000, SleeperID : 236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darren`, LastName: `Sproles`, Position : 'RB', Salary : 650000, SleeperID : 246 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Don`, LastName: `Muhlbach`, Position : 'LS', Salary : 650000, SleeperID : 252 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Johnson`, Position : 'QB', Salary : 650000, SleeperID : 260 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Orlando`, LastName: `Scandrick`, Position : 'CB', Salary : 650000, SleeperID : 266 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Smith`, Position : 'QB', Salary : 650000, SleeperID : 268 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Delanie`, LastName: `Walker`, Position : 'TE', Salary : 650000, SleeperID : 284 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Brees`, Position : 'QB', Salary : 650000, SleeperID : 289 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Weddle`, Position : 'FS', Salary : 650000, SleeperID : 290 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `Suggs`, Position : 'LB', Salary : 650000, SleeperID : 296 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marshal`, LastName: `Yanda`, Position : 'G', Salary : 650000, SleeperID : 301 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Slater`, Position : 'WR', Salary : 650000, SleeperID : 312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dustin`, LastName: `Colquitt`, Position : 'P', Salary : 650000, SleeperID : 330 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Philip`, LastName: `Rivers`, Position : 'QB', Salary : 650000, SleeperID : 331 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Fitzpatrick`, Position : 'QB', Salary : 650000, SleeperID : 333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Wake`, Position : 'DE', Salary : 650000, SleeperID : 342 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Chung`, Position : 'SS', Salary : 650000, SleeperID : 343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Edelman`, Position : 'WR', Salary : 650000, SleeperID : 344 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Hoyer`, Position : 'QB', Salary : 650000, SleeperID : 345 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Huber`, Position : 'P', Salary : 650000, SleeperID : 353 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Smith`, Position : 'T', Salary : 650000, SleeperID : 358 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ramon`, LastName: `Foster`, Position : 'G', Salary : 650000, SleeperID : 360 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Mack`, Position : 'C', Salary : 650000, SleeperID : 359 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Cook`, Position : 'TE', Salary : 650000, SleeperID : 367 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `McCourty`, Position : 'DB', Salary : 650000, SleeperID : 368 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Britton`, LastName: `Colquitt`, Position : 'P', Salary : 650000, SleeperID : 385 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Succop`, Position : 'K', Salary : 650000, SleeperID : 391 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Peters`, Position : 'OL', Salary : 650000, SleeperID : 412 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Stafford`, Position : 'QB', Salary : 650000, SleeperID : 421 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clay`, LastName: `Matthews`, Position : 'LB', Salary : 650000, SleeperID : 425 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JJ`, LastName: `Jansen`, Position : 'LS', Salary : 650000, SleeperID : 439 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Jenkins`, Position : 'SS', Salary : 650000, SleeperID : 444 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Morstead`, Position : 'P', Salary : 650000, SleeperID : 445 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demar`, LastName: `Dotson`, Position : 'OT', Salary : 650000, SleeperID : 447 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Crabtree`, Position : 'WR', Salary : 650000, SleeperID : 455 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Smith`, Position : 'CB', Salary : 650000, SleeperID : 467 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrison`, LastName: `Sanborn`, Position : 'LS', Salary : 650000, SleeperID : 471 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Daniel`, Position : 'QB', Salary : 650000, SleeperID : 490 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Amendola`, Position : 'WR', Salary : 650000, SleeperID : 491 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Bennett`, Position : 'DE', Salary : 650000, SleeperID : 495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brett`, LastName: `Kern`, Position : 'P', Salary : 650000, SleeperID : 497 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Graham`, LastName: `Gano`, Position : 'K', Salary : 650000, SleeperID : 503 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Jerry`, Position : 'G', Salary : 650000, SleeperID : 509 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rob`, LastName: `Gronkowski`, Position : 'TE', Salary : 650000, SleeperID : 515 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Love`, Position : 'NT', Salary : 650000, SleeperID : 517 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `McCourty`, Position : 'DB', Salary : 650000, SleeperID : 518 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Morgan`, LastName: `Cox`, Position : 'LS', Salary : 650000, SleeperID : 525 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Geno`, LastName: `Atkins`, Position : 'DT', Salary : 650000, SleeperID : 528 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlos`, LastName: `Dunlap`, Position : 'DL', Salary : 650000, SleeperID : 529 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Haden`, Position : 'CB', Salary : 650000, SleeperID : 531 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colt`, LastName: `McCoy`, Position : 'QB', Salary : 650000, SleeperID : 533 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 536 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maurkice`, LastName: `Pouncey`, Position : 'C', Salary : 650000, SleeperID : 537 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emmanuel`, LastName: `Sanders`, Position : 'WR', Salary : 650000, SleeperID : 538 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kareem`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 544 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sherrick`, LastName: `McManis`, Position : 'DB', Salary : 650000, SleeperID : 545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Earl`, LastName: `Mitchell`, Position : 'DT', Salary : 650000, SleeperID : 546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Weeks`, Position : 'LS', Salary : 650000, SleeperID : 548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerry`, LastName: `Hughes`, Position : 'DE', Salary : 650000, SleeperID : 550 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyson`, LastName: `Alualu`, Position : 'DE', Salary : 650000, SleeperID : 554 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Tebow`, Position : 'TE', Salary : 650000, SleeperID : 559 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Veldheer`, Position : 'OT', Salary : 650000, SleeperID : 569 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dez`, LastName: `Bryant`, Position : 'WR', Salary : 650000, SleeperID : 574 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Lee`, Position : 'LB', Salary : 650000, SleeperID : 576 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Young`, Position : 'OT', Salary : 650000, SleeperID : 578 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Linval`, LastName: `Joseph`, Position : 'DT', Salary : 650000, SleeperID : 579 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kurt`, LastName: `Coleman`, Position : 'FS', Salary : 650000, SleeperID : 581 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Graham`, Position : 'DE', Salary : 650000, SleeperID : 583 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Paulsen`, Position : 'TE', Salary : 650000, SleeperID : 587 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J'Marcus`, LastName: `Webb`, Position : 'OT', Salary : 650000, SleeperID : 589 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ndamukong`, LastName: `Suh`, Position : 'DT', Salary : 650000, SleeperID : 594 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Bulaga`, Position : 'T', Salary : 650000, SleeperID : 596 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Morgan`, LastName: `Burnett`, Position : 'SS', Salary : 650000, SleeperID : 597 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marshall`, LastName: `Newhouse`, Position : 'OL', Salary : 650000, SleeperID : 600 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Everson`, LastName: `Griffen`, Position : 'DE', Salary : 650000, SleeperID : 605 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Webb`, Position : 'QB', Salary : 650000, SleeperID : 606 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Peters`, Position : 'DT', Salary : 650000, SleeperID : 608 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Graham`, Position : 'TE', Salary : 650000, SleeperID : 616 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Robinson`, Position : 'CB', Salary : 650000, SleeperID : 617 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gerald`, LastName: `McCoy`, Position : 'DT', Salary : 650000, SleeperID : 619 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodger`, LastName: `Saffold`, Position : 'G', Salary : 650000, SleeperID : 623 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darian`, LastName: `Stewart`, Position : 'FS', Salary : 650000, SleeperID : 625 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Roberts`, Position : 'WR', Salary : 650000, SleeperID : 627 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Boone`, Position : 'OG', Salary : 650000, SleeperID : 631 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tramaine`, LastName: `Brock`, Position : 'CB', Salary : 650000, SleeperID : 633 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Iupati`, Position : 'G', Salary : 650000, SleeperID : 635 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Russell`, LastName: `Okung`, Position : 'OT', Salary : 650000, SleeperID : 641 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Golden`, LastName: `Tate`, Position : 'WR', Salary : 650000, SleeperID : 642 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Earl`, LastName: `Thomas`, Position : 'FS', Salary : 650000, SleeperID : 643 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richie`, LastName: `Incognito`, Position : 'G', Salary : 650000, SleeperID : 647 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Folk`, Position : 'K', Salary : 650000, SleeperID : 650 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Nugent`, Position : 'K', Salary : 650000, SleeperID : 658 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benjamin`, LastName: `Watson`, Position : 'TE', Salary : 650000, SleeperID : 663 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steve`, LastName: `McLendon`, Position : 'DT', Salary : 650000, SleeperID : 667 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LeSean`, LastName: `McCoy`, Position : 'RB', Salary : 650000, SleeperID : 676 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lorenzo`, LastName: `Alexander`, Position : 'LB', Salary : 650000, SleeperID : 678 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Sundberg`, Position : 'LS', Salary : 650000, SleeperID : 681 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dekoda`, LastName: `Watson`, Position : 'LB', Salary : 650000, SleeperID : 690 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ted`, LastName: `Ginn`, Position : 'WR', Salary : 650000, SleeperID : 695 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Clark`, Position : 'T', Salary : 650000, SleeperID : 707 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reshad`, LastName: `Jones`, Position : 'FS', Salary : 650000, SleeperID : 714 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Pierre-Paul`, Position : 'LB', Salary : 650000, SleeperID : 720 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ted`, LastName: `Larsen`, Position : 'OG', Salary : 650000, SleeperID : 731 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `Williams`, Position : 'T', Salary : 650000, SleeperID : 734 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clark`, LastName: `Harris`, Position : 'LS', Salary : 650000, SleeperID : 738 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marshawn`, LastName: `Lynch`, Position : 'RB', Salary : 650000, SleeperID : 745 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Al`, LastName: `Woods`, Position : 'DT', Salary : 650000, SleeperID : 754 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clinton`, LastName: `McDonald`, Position : 'DE', Salary : 650000, SleeperID : 757 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Sendejo`, Position : 'DB', Salary : 650000, SleeperID : 770 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Hauschka`, Position : 'K', Salary : 650000, SleeperID : 775 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Sherels`, Position : 'CB', Salary : 650000, SleeperID : 784 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Kelce`, Position : 'C', Salary : 650000, SleeperID : 787 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dion`, LastName: `Lewis`, Position : 'RB', Salary : 650000, SleeperID : 788 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcell`, LastName: `Dareus`, Position : 'DE', Salary : 650000, SleeperID : 795 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Clay`, Position : 'TE', Salary : 650000, SleeperID : 801 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Cannon`, Position : 'OL', Salary : 650000, SleeperID : 808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lee`, LastName: `Smith`, Position : 'TE', Salary : 650000, SleeperID : 812 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Solder`, Position : 'T', Salary : 650000, SleeperID : 813 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Bellore`, Position : 'LB', Salary : 650000, SleeperID : 815 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bilal`, LastName: `Powell`, Position : 'RB', Salary : 650000, SleeperID : 818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Bynes`, Position : 'LB', Salary : 650000, SleeperID : 821 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pernell`, LastName: `McPhee`, Position : 'LB', Salary : 650000, SleeperID : 823 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Smith`, Position : 'CB', Salary : 650000, SleeperID : 825 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrod`, LastName: `Taylor`, Position : 'QB', Salary : 650000, SleeperID : 827 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andy`, LastName: `Dalton`, Position : 'QB', Salary : 650000, SleeperID : 829 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Green`, Position : 'WR', Salary : 650000, SleeperID : 830 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jabaal`, LastName: `Sheard`, Position : 'DE', Salary : 650000, SleeperID : 836 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Buster`, LastName: `Skrine`, Position : 'DB', Salary : 650000, SleeperID : 837 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Gilbert`, Position : 'OT', Salary : 650000, SleeperID : 842 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Heyward`, Position : 'DT', Salary : 650000, SleeperID : 843 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jurrell`, LastName: `Casey`, Position : 'DT', Salary : 650000, SleeperID : 846 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brooks`, LastName: `Reed`, Position : 'LB', Salary : 650000, SleeperID : 855 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.J.`, LastName: `Watt`, Position : 'DE', Salary : 650000, SleeperID : 856 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Castonzo`, Position : 'T', Salary : 650000, SleeperID : 858 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blaine`, LastName: `Gabbert`, Position : 'QB', Salary : 650000, SleeperID : 862 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Virgil`, LastName: `Green`, Position : 'TE', Salary : 650000, SleeperID : 868 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Harris`, Position : 'CB', Salary : 650000, SleeperID : 869 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stefen`, LastName: `Wisniewski`, Position : 'OG', Salary : 650000, SleeperID : 890 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Houston`, Position : 'DL', Salary : 650000, SleeperID : 878 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Allen`, LastName: `Bailey`, Position : 'DE', Salary : 650000, SleeperID : 875 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Hudson`, Position : 'OL', Salary : 650000, SleeperID : 879 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taiwan`, LastName: `Jones`, Position : 'RB', Salary : 650000, SleeperID : 886 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Liuget`, Position : 'DT', Salary : 650000, SleeperID : 894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Gilchrist`, Position : 'DB', Salary : 650000, SleeperID : 893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyron`, LastName: `Smith`, Position : 'T', Salary : 650000, SleeperID : 905 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Bailey`, Position : 'K', Salary : 650000, SleeperID : 899 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Jones`, Position : 'P', Salary : 650000, SleeperID : 903 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dwayne`, LastName: `Harris`, Position : 'WR', Salary : 650000, SleeperID : 902 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Prince`, LastName: `Amukamara`, Position : 'DB', Salary : 650000, SleeperID : 908 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Kerrigan`, Position : 'DE', Salary : 650000, SleeperID : 917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mario`, LastName: `Addison`, Position : 'DE', Salary : 650000, SleeperID : 922 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Von`, LastName: `Miller`, Position : 'LB', Salary : 650000, SleeperID : 871 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randall`, LastName: `Cobb`, Position : 'WR', Salary : 650000, SleeperID : 928 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Rudolph`, Position : 'TE', Salary : 650000, SleeperID : 943 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Bosher`, Position : 'P', Salary : 650000, SleeperID : 944 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julio`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 947 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `McClain`, Position : 'DT', Salary : 650000, SleeperID : 953 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Newton`, Position : 'QB', Salary : 650000, SleeperID : 954 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Ingram`, Position : 'RB', Salary : 650000, SleeperID : 956 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Jordan`, Position : 'DE', Salary : 650000, SleeperID : 957 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Clayborn`, Position : 'DE', Salary : 650000, SleeperID : 961 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Stocker`, Position : 'TE', Salary : 650000, SleeperID : 963 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lance`, LastName: `Kendricks`, Position : 'TE', Salary : 650000, SleeperID : 964 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `McQuaide`, Position : 'LS', Salary : 650000, SleeperID : 965 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Quinn`, Position : 'LB', Salary : 650000, SleeperID : 967 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colin`, LastName: `Jones`, Position : 'FS', Salary : 650000, SleeperID : 979 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Acho`, Position : 'LB', Salary : 650000, SleeperID : 969 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Peterson`, Position : 'CB', Salary : 650000, SleeperID : 972 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Sherman`, Position : 'RB', Salary : 650000, SleeperID : 973 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Kilgore`, Position : 'C', Salary : 650000, SleeperID : 981 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bruce`, LastName: `Miller`, Position : 'FB', Salary : 650000, SleeperID : 982 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Person`, Position : 'G', Salary : 650000, SleeperID : 983 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richard`, LastName: `Sherman`, Position : 'CB', Salary : 650000, SleeperID : 996 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Carpenter`, Position : 'T', Salary : 650000, SleeperID : 989 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aldon`, LastName: `Smith`, Position : 'DE', Salary : 650000, SleeperID : 986 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 997 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K.J.`, LastName: `Wright`, Position : 'LB', Salary : 650000, SleeperID : 998 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Albert`, LastName: `McClellan`, Position : 'LB', Salary : 650000, SleeperID : 1000 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.Q.`, LastName: `Shipley`, Position : 'C', Salary : 650000, SleeperID : 1011 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fletcher`, LastName: `Cox`, Position : 'DT', Salary : 650000, SleeperID : 1027 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vinny`, LastName: `Curry`, Position : 'DE', Salary : 650000, SleeperID : 1028 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Pouncey`, Position : 'C', Salary : 650000, SleeperID : 1023 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Foles`, Position : 'QB', Salary : 650000, SleeperID : 1029 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mychal`, LastName: `Kendricks`, Position : 'LB', Salary : 650000, SleeperID : 1032 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Bolden`, Position : 'RB', Salary : 650000, SleeperID : 1034 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dennis`, LastName: `Kelly`, Position : 'T', Salary : 650000, SleeperID : 1031 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Ebner`, Position : 'DB', Salary : 650000, SleeperID : 1035 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dont'a`, LastName: `Hightower`, Position : 'LB', Salary : 650000, SleeperID : 1037 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chandler`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 1038 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tavon`, LastName: `Wilson`, Position : 'DB', Salary : 650000, SleeperID : 1039 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nigel`, LastName: `Bradham`, Position : 'LB', Salary : 650000, SleeperID : 1040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephon`, LastName: `Gilmore`, Position : 'CB', Salary : 650000, SleeperID : 1043 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cordy`, LastName: `Glenn`, Position : 'T', Salary : 650000, SleeperID : 1044 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lamar`, LastName: `Miller`, Position : 'RB', Salary : 650000, SleeperID : 1048 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Tannehill`, Position : 'QB', Salary : 650000, SleeperID : 1049 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demario`, LastName: `Davis`, Position : 'LB', Salary : 650000, SleeperID : 1052 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damon`, LastName: `Harrison`, Position : 'DT', Salary : 650000, SleeperID : 1053 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vontaze`, LastName: `Burfict`, Position : 'LB', Salary : 650000, SleeperID : 1064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Iloka`, Position : 'DB', Salary : 650000, SleeperID : 1066 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dre`, LastName: `Kirkpatrick`, Position : 'CB', Salary : 650000, SleeperID : 1068 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kelechi`, LastName: `Osemele`, Position : 'OG', Salary : 650000, SleeperID : 1058 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mohamed`, LastName: `Sanu`, Position : 'WR', Salary : 650000, SleeperID : 1071 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Zeitler`, Position : 'OG', Salary : 650000, SleeperID : 1074 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marvin`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 1067 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnson`, LastName: `Bademosi`, Position : 'CB', Salary : 650000, SleeperID : 1076 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Brown`, Position : 'LB', Salary : 650000, SleeperID : 1093 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Benjamin`, Position : 'WR', Salary : 650000, SleeperID : 1077 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `L.J.`, LastName: `Fort`, Position : 'LB', Salary : 650000, SleeperID : 1078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tashaun`, LastName: `Gipson`, Position : 'DB', Salary : 650000, SleeperID : 1079 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `Schwartz`, Position : 'T', Salary : 650000, SleeperID : 1083 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Billy`, LastName: `Winn`, Position : 'DT', Salary : 650000, SleeperID : 1086 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kelvin`, LastName: `Beachum`, Position : 'OL', Salary : 650000, SleeperID : 1088 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Coty`, LastName: `Sensabaugh`, Position : 'CB', Salary : 650000, SleeperID : 1095 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Brooks`, Position : 'G', Salary : 650000, SleeperID : 1098 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randy`, LastName: `Bullock`, Position : 'K', Salary : 650000, SleeperID : 1099 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Marshall`, Position : 'LB', Salary : 650000, SleeperID : 1118 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Jones`, Position : 'C', Salary : 650000, SleeperID : 1101 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Whitney`, LastName: `Mercilus`, Position : 'LB', Salary : 650000, SleeperID : 1103 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.Y.`, LastName: `Hilton`, Position : 'WR', Salary : 650000, SleeperID : 1110 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Overton`, Position : 'LS', Salary : 650000, SleeperID : 1112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Anger`, Position : 'P', Salary : 650000, SleeperID : 1113 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Stanford`, Position : 'LB', Salary : 650000, SleeperID : 1120 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Brewer`, Position : 'LS', Salary : 650000, SleeperID : 1122 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Jackson`, Position : 'DT', Salary : 650000, SleeperID : 1124 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Trevathan`, Position : 'LB', Salary : 650000, SleeperID : 1127 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Wolfe`, Position : 'DE', Salary : 650000, SleeperID : 1128 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Allen`, Position : 'G', Salary : 650000, SleeperID : 1129 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontari`, LastName: `Poe`, Position : 'DT', Salary : 650000, SleeperID : 1131 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Bergstrom`, Position : 'C', Salary : 650000, SleeperID : 1134 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Crawford`, Position : 'DT', Salary : 650000, SleeperID : 1136 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Melvin`, LastName: `Ingram`, Position : 'LB', Salary : 650000, SleeperID : 1141 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Beasley`, Position : 'WR', Salary : 650000, SleeperID : 1144 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Morris`, LastName: `Claiborne`, Position : 'CB', Salary : 650000, SleeperID : 1145 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrone`, LastName: `Crawford`, Position : 'DE', Salary : 650000, SleeperID : 1146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Wilber`, Position : 'LB', Salary : 650000, SleeperID : 1148 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alshon`, LastName: `Jeffery`, Position : 'WR', Salary : 650000, SleeperID : 1149 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Reiff`, Position : 'T', Salary : 650000, SleeperID : 1159 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tahir`, LastName: `Whitehead`, Position : 'LB', Salary : 650000, SleeperID : 1160 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kirk`, LastName: `Cousins`, Position : 'QB', Salary : 683367.5846, SleeperID : 1166 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Griffin`, Position : 'QB', Salary : 650000, SleeperID : 1169 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Daniels`, Position : 'DT', Salary : 650000, SleeperID : 1174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alfred`, LastName: `Morris`, Position : 'RB', Salary : 650000, SleeperID : 1170 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Casey`, LastName: `Hayward`, Position : 'CB', Salary : 650000, SleeperID : 1175 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Kuechly`, Position : 'LB', Salary : 650000, SleeperID : 1181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Norman`, Position : 'CB', Salary : 650000, SleeperID : 1182 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Akiem`, LastName: `Hicks`, Position : 'DT', Salary : 650000, SleeperID : 1186 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lavonte`, LastName: `David`, Position : 'LB', Salary : 650000, SleeperID : 1192 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Najee`, LastName: `Goode`, Position : 'LB', Salary : 650000, SleeperID : 1193 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Brockers`, Position : 'DE', Salary : 650000, SleeperID : 1197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Hekker`, Position : 'P', Salary : 650000, SleeperID : 1202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Janoris`, LastName: `Jenkins`, Position : 'CB', Salary : 650000, SleeperID : 1203 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trumaine`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 1204 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Looney`, Position : 'OG', Salary : 650000, SleeperID : 1227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Russell`, LastName: `Wilson`, Position : 'QB', Salary : 650000, SleeperID : 1234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `McLeod`, Position : 'DB', Salary : 650000, SleeperID : 1205 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rhett`, LastName: `Ellison`, Position : 'TE', Salary : 650000, SleeperID : 1211 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harrison`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 1213 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarius`, LastName: `Wright`, Position : 'WR', Salary : 650000, SleeperID : 1215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Bethel`, Position : 'DB', Salary : 650000, SleeperID : 1216 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Senio`, LastName: `Kelemete`, Position : 'OL', Salary : 650000, SleeperID : 1220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Celek`, Position : 'TE', Salary : 650000, SleeperID : 1223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Turbin`, Position : 'RB', Salary : 650000, SleeperID : 1232 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobby`, LastName: `Wagner`, Position : 'LB', Salary : 650000, SleeperID : 1233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Harris`, Position : 'LS', Salary : 650000, SleeperID : 1238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bruce`, LastName: `Irvin`, Position : 'LB', Salary : 650000, SleeperID : 1242 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.R.`, LastName: `Sweezy`, Position : 'OG', Salary : 650000, SleeperID : 1243 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Gordon`, Position : 'WR', Salary : 650000, SleeperID : 1244 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Beau`, LastName: `Brinkley`, Position : 'LS', Salary : 650000, SleeperID : 1245 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Robinson`, Position : 'CB', Salary : 650000, SleeperID : 1254 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Barron`, Position : 'LB', Salary : 650000, SleeperID : 1255 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Olivier`, LastName: `Vernon`, Position : 'DE', Salary : 650000, SleeperID : 1259 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Tanney`, Position : 'QB', Salary : 650000, SleeperID : 1262 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Tucker`, Position : 'K', Salary : 650000, SleeperID : 1264 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobby`, LastName: `Massie`, Position : 'T', Salary : 650000, SleeperID : 1222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Zuerlein`, Position : 'K', Salary : 650000, SleeperID : 1266 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Nsekhe`, Position : 'T', Salary : 650000, SleeperID : 1267 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `DeCastro`, Position : 'OG', Salary : 650000, SleeperID : 1261 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `DiMarco`, Position : 'FB', Salary : 650000, SleeperID : 1305 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradley`, LastName: `Sowell`, Position : 'TE', Salary : 650000, SleeperID : 1269 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Craig`, LastName: `Robertson`, Position : 'LB', Salary : 650000, SleeperID : 1272 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Francis`, LastName: `Owusu`, Position : 'WR', Salary : 650000, SleeperID : 1273 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Neiko`, LastName: `Thorpe`, Position : 'CB', Salary : 650000, SleeperID : 1283 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kai`, LastName: `Forbath`, Position : 'K', Salary : 650000, SleeperID : 1287 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lawrence`, LastName: `Guy`, Position : 'DT', Salary : 650000, SleeperID : 1288 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Kearse`, Position : 'WR', Salary : 650000, SleeperID : 1290 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Levine`, Position : 'DB', Salary : 650000, SleeperID : 1298 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Nelson`, Position : 'LS', Salary : 650000, SleeperID : 1301 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Develin`, Position : 'FB', Salary : 650000, SleeperID : 1303 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamize`, LastName: `Olawale`, Position : 'FB', Salary : 650000, SleeperID : 1308 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Ertz`, Position : 'TE', Salary : 650000, SleeperID : 1339 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tom`, LastName: `Compton`, Position : 'OL', Salary : 650000, SleeperID : 1310 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Bellamy`, Position : 'WR', Salary : 650000, SleeperID : 1312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronald`, LastName: `Leary`, Position : 'G', Salary : 650000, SleeperID : 1327 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Hogan`, Position : 'WR', Salary : 650000, SleeperID : 1329 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Russell`, LastName: `Shepard`, Position : 'WR', Salary : 650000, SleeperID : 1333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brad`, LastName: `Wing`, Position : 'P', Salary : 650000, SleeperID : 1337 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Barkley`, Position : 'QB', Salary : 650000, SleeperID : 1338 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lane`, LastName: `Johnson`, Position : 'T', Salary : 650000, SleeperID : 1340 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damion`, LastName: `Square`, Position : 'DT', Salary : 650000, SleeperID : 1334 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Poyer`, Position : 'DB', Salary : 650000, SleeperID : 1343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kiko`, LastName: `Alonso`, Position : 'LB', Salary : 650000, SleeperID : 1345 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquise`, LastName: `Goodwin`, Position : 'WR', Salary : 650000, SleeperID : 1346 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dustin`, LastName: `Hopkins`, Position : 'K', Salary : 650000, SleeperID : 1348 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Woods`, Position : 'WR', Salary : 650000, SleeperID : 1352 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dion`, LastName: `Jordan`, Position : 'DE', Salary : 650000, SleeperID : 1356 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamar`, LastName: `Taylor`, Position : 'CB', Salary : 650000, SleeperID : 1359 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamie`, LastName: `Collins`, Position : 'LB', Salary : 650000, SleeperID : 1364 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duron`, LastName: `Harmon`, Position : 'DB', Salary : 650000, SleeperID : 1366 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Ryan`, Position : 'DB', Salary : 650000, SleeperID : 1367 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Oday`, LastName: `Aboushi`, Position : 'G', Salary : 650000, SleeperID : 1368 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sheldon`, LastName: `Richardson`, Position : 'DT', Salary : 650000, SleeperID : 1372 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Geno`, LastName: `Smith`, Position : 'QB', Salary : 650000, SleeperID : 1373 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Winters`, Position : 'OL', Salary : 650000, SleeperID : 1374 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Jensen`, Position : 'C', Salary : 650000, SleeperID : 1378 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Juszczyk`, Position : 'FB', Salary : 650000, SleeperID : 1379 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Simon`, Position : 'LB', Salary : 650000, SleeperID : 1381 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rick`, LastName: `Wagner`, Position : 'G', Salary : 650000, SleeperID : 1382 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 1383 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Giovani`, LastName: `Bernard`, Position : 'RB', Salary : 650000, SleeperID : 1386 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rex`, LastName: `Burkhead`, Position : 'RB', Salary : 650000, SleeperID : 1387 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Eifert`, Position : 'TE', Salary : 650000, SleeperID : 1388 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Margus`, LastName: `Hunt`, Position : 'DE', Salary : 650000, SleeperID : 1392 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shawn`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 1395 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Barkevious`, LastName: `Mingo`, Position : 'LB', Salary : 650000, SleeperID : 1405 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Hubbard`, Position : 'T', Salary : 650000, SleeperID : 1407 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Le'Veon`, LastName: `Bell`, Position : 'RB', Salary : 650000, SleeperID : 1408 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Williams`, Position : 'DE', Salary : 650000, SleeperID : 1414 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vince`, LastName: `Williams`, Position : 'LB', Salary : 650000, SleeperID : 1415 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blidi`, LastName: `Wreh-Wilson`, Position : 'CB', Salary : 650000, SleeperID : 1423 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Griffin`, Position : 'TE', Salary : 650000, SleeperID : 1425 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAndre`, LastName: `Hopkins`, Position : 'WR', Salary : 1077882.179, SleeperID : 1426 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Quessenberry`, Position : 'OL', Salary : 650000, SleeperID : 1428 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Swearinger`, Position : 'DB', Salary : 650000, SleeperID : 1429 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `McManus`, Position : 'K', Salary : 650000, SleeperID : 1433 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Banjo`, Position : 'DB', Salary : 650000, SleeperID : 1442 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnathan`, LastName: `Cyprien`, Position : 'SS', Salary : 650000, SleeperID : 1443 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Anderson`, Position : 'RB', Salary : 650000, SleeperID : 1451 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lerentee`, LastName: `McCray`, Position : 'DE', Salary : 650000, SleeperID : 1453 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kayvon`, LastName: `Webster`, Position : 'CB', Salary : 650000, SleeperID : 1459 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sylvester`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 1460 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Fisher`, Position : 'OT', Salary : 650000, SleeperID : 1464 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Kelce`, Position : 'TE', Salary : 2967472.232, SleeperID : 1466 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Manti`, LastName: `Te'o`, Position : 'LB', Salary : 650000, SleeperID : 1482 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damontre`, LastName: `Moore`, Position : 'DE', Salary : 650000, SleeperID : 1494 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stacy`, LastName: `McGee`, Position : 'DT', Salary : 650000, SleeperID : 1474 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Latavius`, LastName: `Murray`, Position : 'RB', Salary : 650000, SleeperID : 1476 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keenan`, LastName: `Allen`, Position : 'WR', Salary : 1788458.956, SleeperID : 1479 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Fluker`, Position : 'T', Salary : 650000, SleeperID : 1480 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Frederick`, Position : 'C', Salary : 650000, SleeperID : 1486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.W.`, LastName: `Webb`, Position : 'CB', Salary : 650000, SleeperID : 1488 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnathan`, LastName: `Hankins`, Position : 'DT', Salary : 650000, SleeperID : 1492 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Pugh`, Position : 'OL', Salary : 650000, SleeperID : 1496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Reed`, Position : 'TE', Salary : 650000, SleeperID : 1500 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tress`, LastName: `Way`, Position : 'P', Salary : 650000, SleeperID : 1506 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Bostic`, Position : 'LB', Salary : 650000, SleeperID : 1507 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Thompson`, Position : 'RB', Salary : 650000, SleeperID : 1502 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Warford`, Position : 'G', Salary : 650000, SleeperID : 1519 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Long`, Position : 'OG', Salary : 650000, SleeperID : 1509 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Mills`, Position : 'T', Salary : 650000, SleeperID : 1510 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ezekiel`, LastName: `Ansah`, Position : 'DE', Salary : 650000, SleeperID : 1513 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Martin`, Position : 'P', Salary : 650000, SleeperID : 1516 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Theo`, LastName: `Riddick`, Position : 'RB', Salary : 650000, SleeperID : 1517 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Slay`, Position : 'CB', Salary : 650000, SleeperID : 1518 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Bakhtiari`, Position : 'OT', Salary : 650000, SleeperID : 1521 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Hyde`, Position : 'DB', Salary : 650000, SleeperID : 1525 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Datone`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 1526 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.C.`, LastName: `Tretter`, Position : 'C', Salary : 650000, SleeperID : 1529 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cordarrelle`, LastName: `Patterson`, Position : 'RB', Salary : 650000, SleeperID : 1535 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Rhodes`, Position : 'CB', Salary : 650000, SleeperID : 1536 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paul`, LastName: `Worrilow`, Position : 'LB', Salary : 650000, SleeperID : 1539 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Alford`, Position : 'CB', Salary : 650000, SleeperID : 1540 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kemal`, LastName: `Ishmael`, Position : 'SS', Salary : 650000, SleeperID : 1542 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Desmond`, LastName: `Trufant`, Position : 'CB', Salary : 650000, SleeperID : 1545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenjon`, LastName: `Barner`, Position : 'RB', Salary : 650000, SleeperID : 1546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Klein`, Position : 'LB', Salary : 650000, SleeperID : 1547 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Star`, LastName: `Lotulelei`, Position : 'DT', Salary : 650000, SleeperID : 1548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kawann`, LastName: `Short`, Position : 'DT', Salary : 650000, SleeperID : 1549 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terron`, LastName: `Armstead`, Position : 'T', Salary : 650000, SleeperID : 1552 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Griffin`, Position : 'QB', Salary : 650000, SleeperID : 1550 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Jenkins`, Position : 'DT', Salary : 650000, SleeperID : 1553 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Stills`, Position : 'WR', Salary : 650000, SleeperID : 1555 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Vaccaro`, Position : 'SS', Salary : 650000, SleeperID : 1556 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Gholston`, Position : 'DE', Salary : 650000, SleeperID : 1558 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Glennon`, Position : 'QB', Salary : 650000, SleeperID : 1559 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Means`, Position : 'LB', Salary : 650000, SleeperID : 1561 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Akeem`, LastName: `Spence`, Position : 'DT', Salary : 650000, SleeperID : 1562 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daren`, LastName: `Bates`, Position : 'LB', Salary : 650000, SleeperID : 1563 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Davis`, Position : 'DB', Salary : 650000, SleeperID : 1565 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tavon`, LastName: `Austin`, Position : 'WR', Salary : 650000, SleeperID : 1567 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alec`, LastName: `Ogletree`, Position : 'LB', Salary : 650000, SleeperID : 1572 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Cooper`, Position : 'G', Salary : 650000, SleeperID : 1574 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Kush`, Position : 'OL', Salary : 650000, SleeperID : 1467 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Wetzel`, Position : 'OG', Salary : 650000, SleeperID : 1469 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Hayden`, Position : 'CB', Salary : 650000, SleeperID : 1472 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrann`, LastName: `Mathieu`, Position : 'DB', Salary : 650000, SleeperID : 1576 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Minter`, Position : 'LB', Salary : 650000, SleeperID : 1577 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Okafor`, Position : 'DE', Salary : 650000, SleeperID : 1578 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Earl`, LastName: `Watford`, Position : 'OL', Salary : 650000, SleeperID : 1580 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cornellius`, LastName: `Carradine`, Position : 'DE', Salary : 650000, SleeperID : 1582 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vance`, LastName: `McDonald`, Position : 'TE', Salary : 650000, SleeperID : 1587 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Reid`, Position : 'SS', Salary : 650000, SleeperID : 1590 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darren`, LastName: `Fells`, Position : 'TE', Salary : 650000, SleeperID : 1592 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Ware`, Position : 'RB', Salary : 650000, SleeperID : 1601 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Willson`, Position : 'TE', Salary : 650000, SleeperID : 1603 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 1604 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rontez`, LastName: `Miles`, Position : 'FS', Salary : 650000, SleeperID : 1608 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Bray`, Position : 'QB', Salary : 650000, SleeperID : 1612 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richie`, LastName: `Leone`, Position : 'P', Salary : 650000, SleeperID : 1873 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Martin`, Position : 'LB', Salary : 650000, SleeperID : 1616 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaquelle`, LastName: `Evans`, Position : 'WR', Salary : 650000, SleeperID : 1855 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kerry`, LastName: `Hyder`, Position : 'DE', Salary : 650000, SleeperID : 1857 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brent`, LastName: `Qvale`, Position : 'OT', Salary : 650000, SleeperID : 1860 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Hurst`, Position : 'OL', Salary : 650000, SleeperID : 1870 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Timmy`, LastName: `Jernigan`, Position : 'DT', Salary : 650000, SleeperID : 1872 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Otha`, LastName: `Foster`, Position : 'SS', Salary : 650000, SleeperID : 1613 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamie`, LastName: `Meder`, Position : 'DT', Salary : 650000, SleeperID : 1874 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Mosley`, Position : 'LB', Salary : 650000, SleeperID : 1875 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brent`, LastName: `Urban`, Position : 'DE', Salary : 650000, SleeperID : 1881 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrence`, LastName: `Brooks`, Position : 'DB', Salary : 650000, SleeperID : 1864 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darqueze`, LastName: `Dennard`, Position : 'CB', Salary : 650000, SleeperID : 1887 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Hopkins`, Position : 'C', Salary : 650000, SleeperID : 1893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Omameh`, Position : 'OL', Salary : 650000, SleeperID : 1639 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Purcell`, Position : 'NT', Salary : 650000, SleeperID : 1640 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Levine`, LastName: `Toilolo`, Position : 'TE', Salary : 650000, SleeperID : 1642 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Dangerfield`, Position : 'SS', Salary : 650000, SleeperID : 1643 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `Sinkfield`, Position : 'WR', Salary : 650000, SleeperID : 1652 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Kline`, Position : 'G', Salary : 650000, SleeperID : 1653 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Copeland`, Position : 'LB', Salary : 650000, SleeperID : 1657 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Devey`, Position : 'OL', Salary : 650000, SleeperID : 1658 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Pericak`, Position : 'G', Salary : 650000, SleeperID : 1659 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brynden`, LastName: `Trawick`, Position : 'FS', Salary : 650000, SleeperID : 1660 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Abry`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 1663 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaRoy`, LastName: `Reynolds`, Position : 'LB', Salary : 650000, SleeperID : 1666 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carson`, LastName: `Tinker`, Position : 'LS', Salary : 650000, SleeperID : 1668 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lawrence`, LastName: `Okoye`, Position : 'DT', Salary : 650000, SleeperID : 1670 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nickell`, LastName: `Robey-Coleman`, Position : 'CB', Salary : 650000, SleeperID : 1672 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Bouye`, Position : 'CB', Salary : 650000, SleeperID : 1674 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Uzoma`, LastName: `Nwachukwu`, Position : 'WR', Salary : 650000, SleeperID : 1678 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaron`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 1684 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Jefferson`, Position : 'DB', Salary : 650000, SleeperID : 1686 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Thielen`, Position : 'WR', Salary : 650000, SleeperID : 1689 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Line`, Position : 'FB', Salary : 650000, SleeperID : 1693 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Allen`, Position : 'P', Salary : 650000, SleeperID : 1694 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 1696 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Heath`, Position : 'DB', Salary : 650000, SleeperID : 1700 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lane`, LastName: `Taylor`, Position : 'OL', Salary : 650000, SleeperID : 1702 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael J.`, LastName: `Thomas`, Position : 'DB', Salary : 650000, SleeperID : 1703 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Doyle`, Position : 'TE', Salary : 650000, SleeperID : 1706 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Carrier`, Position : 'TE', Salary : 650000, SleeperID : 1718 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `DePaola`, Position : 'LS', Salary : 650000, SleeperID : 1721 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Remmers`, Position : 'T', Salary : 650000, SleeperID : 1722 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Garland`, Position : 'OG', Salary : 650000, SleeperID : 1728 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Stupar`, Position : 'LB', Salary : 650000, SleeperID : 1729 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Wiggins`, Position : 'G', Salary : 650000, SleeperID : 1730 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Case`, LastName: `Keenum`, Position : 'QB', Salary : 650000, SleeperID : 1737 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Scales`, Position : 'LS', Salary : 650000, SleeperID : 1745 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benson`, LastName: `Mayowa`, Position : 'DE', Salary : 650000, SleeperID : 1764 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ray-Ray`, LastName: `Armstrong`, Position : 'LB', Salary : 650000, SleeperID : 1770 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deon`, LastName: `Lacey`, Position : 'LB', Salary : 650000, SleeperID : 1774 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Hill`, Position : 'TE', Salary : 650000, SleeperID : 1777 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Winchester`, Position : 'LS', Salary : 650000, SleeperID : 1785 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Beau`, LastName: `Allen`, Position : 'NT', Salary : 650000, SleeperID : 1788 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Andrews`, Position : 'C', Salary : 650000, SleeperID : 1789 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Karim`, LastName: `Barton`, Position : 'G', Salary : 650000, SleeperID : 1791 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Burton`, Position : 'TE', Salary : 650000, SleeperID : 1793 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Fluellen`, Position : 'RB', Salary : 650000, SleeperID : 1794 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `White`, Position : 'CB', Salary : 650000, SleeperID : 1779 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Matthews`, Position : 'TE', Salary : 650000, SleeperID : 1800 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alejandro`, LastName: `Villanueva`, Position : 'OT', Salary : 650000, SleeperID : 1805 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Watkins`, Position : 'SS', Salary : 650000, SleeperID : 1806 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Preston`, LastName: `Brown`, Position : 'LB', Salary : 650000, SleeperID : 1808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Cockrell`, Position : 'CB', Salary : 650000, SleeperID : 1809 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seantrel`, LastName: `Henderson`, Position : 'T', Salary : 650000, SleeperID : 1811 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Ladler`, Position : 'FS', Salary : 650000, SleeperID : 1815 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Walt`, LastName: `Aikens`, Position : 'SS', Salary : 650000, SleeperID : 1818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Wuan`, LastName: `James`, Position : 'OT', Salary : 650000, SleeperID : 1822 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarvis`, LastName: `Landry`, Position : 'WR', Salary : 650000, SleeperID : 1825 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Larsen`, Position : 'C', Salary : 650000, SleeperID : 1826 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Ola`, Position : 'T', Salary : 650000, SleeperID : 1829 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Billy`, LastName: `Turner`, Position : 'OL', Salary : 650000, SleeperID : 1832 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damien`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 1833 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Butler`, Position : 'CB', Salary : 650000, SleeperID : 1834 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sammy`, LastName: `Watkins`, Position : 'WR', Salary : 650000, SleeperID : 1817 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Fleming`, Position : 'T', Salary : 650000, SleeperID : 1836 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Garoppolo`, Position : 'QB', Salary : 650000, SleeperID : 1837 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Halapio`, Position : 'G', Salary : 650000, SleeperID : 1840 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Houston`, Position : 'RB', Salary : 650000, SleeperID : 1841 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Ott`, Position : 'LS', Salary : 650000, SleeperID : 1844 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `White`, Position : 'RB', Salary : 650000, SleeperID : 1848 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dakota`, LastName: `Dozier`, Position : 'G', Salary : 650000, SleeperID : 1852 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quincy`, LastName: `Enunwa`, Position : 'WR', Salary : 650000, SleeperID : 1854 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalil`, LastName: `Mack`, Position : 'LB', Salary : 650000, SleeperID : 2036 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seth`, LastName: `Roberts`, Position : 'WR', Salary : 650000, SleeperID : 2039 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scott`, LastName: `Simonson`, Position : 'TE', Salary : 650000, SleeperID : 2040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Attaochu`, Position : 'LB', Salary : 650000, SleeperID : 2042 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Verrett`, Position : 'CB', Salary : 650000, SleeperID : 2055 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Hitchens`, Position : 'LB', Salary : 650000, SleeperID : 2062 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Casey`, LastName: `Kreiter`, Position : 'LS', Salary : 650000, SleeperID : 2063 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeMarcus`, LastName: `Lawrence`, Position : 'DE', Salary : 650000, SleeperID : 2064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zack`, LastName: `Martin`, Position : 'G', Salary : 650000, SleeperID : 2066 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrance`, LastName: `Mitchell`, Position : 'DB', Salary : 650000, SleeperID : 2067 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronald`, LastName: `Patrick`, Position : 'C', Salary : 650000, SleeperID : 2069 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Smith`, Position : 'FB', Salary : 650000, SleeperID : 2073 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Odell`, LastName: `Beckham`, Position : 'WR', Salary : 650000, SleeperID : 2078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Grimble`, Position : 'TE', Salary : 650000, SleeperID : 2082 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bennett`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 2083 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Heuerman`, Position : 'TE', Salary : 650000, SleeperID : 2397 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Miller`, Position : 'G', Salary : 650000, SleeperID : 2386 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Hicks`, Position : 'LB', Salary : 650000, SleeperID : 2389 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Kroft`, Position : 'TE', Salary : 650000, SleeperID : 2390 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Johnson`, Position : 'RB', Salary : 650000, SleeperID : 2391 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danielle`, LastName: `Hunter`, Position : 'DE', Salary : 650000, SleeperID : 2393 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Mannion`, Position : 'QB', Salary : 650000, SleeperID : 2394 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carl`, LastName: `Davis`, Position : 'DT', Salary : 650000, SleeperID : 2395 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chaz`, LastName: `Green`, Position : 'T', Salary : 650000, SleeperID : 2396 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devon`, LastName: `Kennard`, Position : 'LB', Salary : 650000, SleeperID : 2084 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyeler`, LastName: `Davison`, Position : 'DT', Salary : 650000, SleeperID : 2457 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Uzomah`, Position : 'TE', Salary : 650000, SleeperID : 2460 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.J.`, LastName: `Nelson`, Position : 'WR', Salary : 650000, SleeperID : 2462 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jesse`, LastName: `James`, Position : 'TE', Salary : 650000, SleeperID : 2463 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Bell`, Position : 'WR', Salary : 650000, SleeperID : 2465 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradley`, LastName: `Pinion`, Position : 'P', Salary : 650000, SleeperID : 2468 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Cardona`, Position : 'LS', Salary : 650000, SleeperID : 2469 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Burton`, Position : 'FB', Salary : 650000, SleeperID : 2471 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Mayo`, Position : 'LB', Salary : 650000, SleeperID : 2472 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tye`, LastName: `Smith`, Position : 'CB', Salary : 650000, SleeperID : 2473 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Boyle`, Position : 'TE', Salary : 650000, SleeperID : 2474 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Alexander`, Position : 'LB', Salary : 650000, SleeperID : 2475 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kerry`, LastName: `Wynn`, Position : 'DE', Salary : 650000, SleeperID : 2089 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bashaud`, LastName: `Breeland`, Position : 'CB', Salary : 650000, SleeperID : 2091 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Grant`, Position : 'WR', Salary : 650000, SleeperID : 2093 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `AJ`, LastName: `McCarron`, Position : 'QB', Salary : 650000, SleeperID : 1895 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joel`, LastName: `Bitonio`, Position : 'OG', Salary : 650000, SleeperID : 1900 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Weston`, LastName: `Richburg`, Position : 'C', Salary : 650000, SleeperID : 2086 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pierre`, LastName: `Desir`, Position : 'CB', Salary : 650000, SleeperID : 1902 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Snead`, Position : 'WR', Salary : 650000, SleeperID : 1911 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrell`, LastName: `Adams`, Position : 'LB', Salary : 650000, SleeperID : 2613 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Martavis`, LastName: `Bryant`, Position : 'WR', Salary : 650000, SleeperID : 1916 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Jennings`, Position : 'WR', Salary : 650000, SleeperID : 2602 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.Y.`, LastName: `McGill`, Position : 'DT', Salary : 650000, SleeperID : 2614 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K'Waun`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 1913 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Singleton`, Position : 'LB', Salary : 650000, SleeperID : 2617 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Heaps`, Position : 'QB', Salary : 650000, SleeperID : 2620 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathon`, LastName: `Mincy`, Position : 'FS', Salary : 650000, SleeperID : 2625 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deshazor`, LastName: `Everett`, Position : 'DB', Salary : 650000, SleeperID : 2643 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinton`, LastName: `Dunbar`, Position : 'CB', Salary : 650000, SleeperID : 2645 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Long`, Position : 'P', Salary : 650000, SleeperID : 2650 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Dzubnar`, Position : 'LB', Salary : 650000, SleeperID : 2655 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 2668 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Lambo`, Position : 'K', Salary : 650000, SleeperID : 2671 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Gabriel`, Position : 'WR', Salary : 650000, SleeperID : 1903 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Kirksey`, Position : 'LB', Salary : 650000, SleeperID : 1905 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Bortles`, Position : 'QB', Salary : 650000, SleeperID : 1979 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Colvin`, Position : 'CB', Salary : 650000, SleeperID : 1982 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Allen`, LastName: `Hurns`, Position : 'WR', Salary : 650000, SleeperID : 1984 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marqise`, LastName: `Lee`, Position : 'WR', Salary : 650000, SleeperID : 1987 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Linder`, Position : 'OL', Salary : 650000, SleeperID : 1988 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashaad`, LastName: `Reynolds`, Position : 'CB', Salary : 650000, SleeperID : 1991 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Allen`, LastName: `Robinson`, Position : 'WR', Salary : 650000, SleeperID : 1992 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Shatley`, Position : 'OL', Salary : 650000, SleeperID : 1993 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Smith`, Position : 'DE', Salary : 650000, SleeperID : 1994 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Wells`, Position : 'T', Salary : 650000, SleeperID : 1997 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaquil`, LastName: `Barrett`, Position : 'LB', Salary : 650000, SleeperID : 1999 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bennie`, LastName: `Fowler`, Position : 'WR', Salary : 650000, SleeperID : 2003 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Latimer`, Position : 'WR', Salary : 650000, SleeperID : 2004 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Parkey`, Position : 'K', Salary : 650000, SleeperID : 1974 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Nelson`, Position : 'LB', Salary : 650000, SleeperID : 2005 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Paradis`, Position : 'C', Salary : 650000, SleeperID : 2006 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradley`, LastName: `Roby`, Position : 'CB', Salary : 650000, SleeperID : 2008 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Schofield`, Position : 'T', Salary : 650000, SleeperID : 2009 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Laurent`, LastName: `Duvernay-Tardif`, Position : 'OL', Salary : 650000, SleeperID : 2014 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dee`, LastName: `Ford`, Position : 'DE', Salary : 650000, SleeperID : 2015 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Fulton`, Position : 'OL', Salary : 650000, SleeperID : 2016 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phillip`, LastName: `Gaines`, Position : 'CB', Salary : 650000, SleeperID : 2017 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cairo`, LastName: `Santos`, Position : 'K', Salary : 650000, SleeperID : 2020 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Sorensen`, Position : 'DB', Salary : 650000, SleeperID : 2022 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Anthony`, LastName: `Thomas`, Position : 'WR', Salary : 650000, SleeperID : 2023 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denico`, LastName: `Autry`, Position : 'DE', Salary : 650000, SleeperID : 2027 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Carr`, Position : 'QB', Salary : 650000, SleeperID : 2028 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Carrie`, Position : 'CB', Salary : 650000, SleeperID : 2029 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Albert`, LastName: `Wilson`, Position : 'WR', Salary : 650000, SleeperID : 2025 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Ellis`, Position : 'DT', Salary : 650000, SleeperID : 2031 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shelby`, LastName: `Harris`, Position : 'DE', Salary : 650000, SleeperID : 2033 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabe`, LastName: `Jackson`, Position : 'G', Salary : 650000, SleeperID : 2034 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradley`, LastName: `McDougald`, Position : 'DB', Salary : 650000, SleeperID : 1617 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demetrius`, LastName: `Harris`, Position : 'TE', Salary : 650000, SleeperID : 1619 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jahleel`, LastName: `Addae`, Position : 'DB', Salary : 650000, SleeperID : 1620 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frank`, LastName: `Beltre`, Position : 'ILB', Salary : 650000, SleeperID : 1622 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaAdrian`, LastName: `Waddle`, Position : 'T', Salary : 650000, SleeperID : 1629 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashaan`, LastName: `Melvin`, Position : 'CB', Salary : 650000, SleeperID : 1633 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Long`, Position : 'C', Salary : 650000, SleeperID : 2096 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Morgan`, LastName: `Moses`, Position : 'OL', Salary : 650000, SleeperID : 2098 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `Murphy`, Position : 'DE', Salary : 650000, SleeperID : 2099 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Dunn`, Position : 'NT', Salary : 650000, SleeperID : 2105 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Fales`, Position : 'QB', Salary : 650000, SleeperID : 2106 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Fuller`, Position : 'CB', Salary : 650000, SleeperID : 2108 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Groy`, Position : 'G', Salary : 650000, SleeperID : 2109 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Fowler`, Position : 'LB', Salary : 650000, SleeperID : 2308 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Compton`, Position : 'LB', Salary : 650000, SleeperID : 1626 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amari`, LastName: `Cooper`, Position : 'WR', Salary : 2614627.004, SleeperID : 2309 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Scherff`, Position : 'G', Salary : 650000, SleeperID : 2310 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leonard`, LastName: `Williams`, Position : 'DE', Salary : 650000, SleeperID : 2311 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `White`, Position : 'WR', Salary : 650000, SleeperID : 2312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ereck`, LastName: `Flowers`, Position : 'T', Salary : 650000, SleeperID : 2314 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Todd`, LastName: `Gurley`, Position : 'RB', Salary : 650000, SleeperID : 2315 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nelson`, LastName: `Agholor`, Position : 'WR', Salary : 650000, SleeperID : 2325 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trae`, LastName: `Waynes`, Position : 'CB', Salary : 650000, SleeperID : 2316 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Shelton`, Position : 'DT', Salary : 650000, SleeperID : 2317 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrus`, LastName: `Peat`, Position : 'OL', Salary : 650000, SleeperID : 2318 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeVante`, LastName: `Parker`, Position : 'WR', Salary : 650000, SleeperID : 2319 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vic`, LastName: `Beasley`, Position : 'DE', Salary : 650000, SleeperID : 2313 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Laken`, LastName: `Tomlinson`, Position : 'OL', Salary : 650000, SleeperID : 2333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 2321 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arik`, LastName: `Armstead`, Position : 'DE', Salary : 650000, SleeperID : 2322 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Rogers`, Position : 'WR', Salary : 650000, SleeperID : 1701 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Peters`, Position : 'CB', Salary : 650000, SleeperID : 2323 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedric`, LastName: `Ogbuehi`, Position : 'T', Salary : 650000, SleeperID : 2326 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bud`, LastName: `Dupree`, Position : 'LB', Salary : 650000, SleeperID : 2327 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shane`, LastName: `Ray`, Position : 'LB', Salary : 650000, SleeperID : 2328 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Humphries`, Position : 'OL', Salary : 650000, SleeperID : 2329 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaq`, LastName: `Thompson`, Position : 'LB', Salary : 650000, SleeperID : 2330 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Breshad`, LastName: `Perriman`, Position : 'WR', Salary : 650000, SleeperID : 2331 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Byron`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 2332 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Melvin`, LastName: `Gordon`, Position : 'RB', Salary : 650000, SleeperID : 2320 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phillip`, LastName: `Dorsett`, Position : 'WR', Salary : 650000, SleeperID : 2334 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damarious`, LastName: `Randall`, Position : 'CB', Salary : 650000, SleeperID : 2335 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephone`, LastName: `Anthony`, Position : 'LB', Salary : 650000, SleeperID : 2336 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcom`, LastName: `Brown`, Position : 'DT', Salary : 650000, SleeperID : 2337 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Landon`, LastName: `Collins`, Position : 'DB', Salary : 650000, SleeperID : 2338 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Erving`, Position : 'T', Salary : 650000, SleeperID : 2324 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mario`, LastName: `Edwards`, Position : 'DE', Salary : 650000, SleeperID : 2340 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donovan`, LastName: `Smith`, Position : 'T', Salary : 650000, SleeperID : 2339 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Yeldon`, Position : 'RB', Salary : 650000, SleeperID : 2341 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Smith`, Position : 'WR', Salary : 650000, SleeperID : 2342 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Preston`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 2343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eddie`, LastName: `Goldman`, Position : 'DT', Salary : 650000, SleeperID : 2344 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Funchess`, Position : 'TE', Salary : 650000, SleeperID : 2346 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benardrick`, LastName: `McKinney`, Position : 'LB', Salary : 650000, SleeperID : 2348 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Kendricks`, Position : 'LB', Salary : 650000, SleeperID : 2350 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaquiski`, LastName: `Tartt`, Position : 'DB', Salary : 650000, SleeperID : 2351 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Rowe`, Position : 'DB', Salary : 650000, SleeperID : 2352 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denzel`, LastName: `Perryman`, Position : 'LB', Salary : 650000, SleeperID : 2353 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitch`, LastName: `Morse`, Position : 'C', Salary : 650000, SleeperID : 2354 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronald`, LastName: `Darby`, Position : 'CB', Salary : 650000, SleeperID : 2355 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Orchard`, Position : 'DE', Salary : 650000, SleeperID : 2356 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Phillips`, Position : 'DE', Salary : 650000, SleeperID : 2357 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ameer`, LastName: `Abdullah`, Position : 'RB', Salary : 650000, SleeperID : 2359 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maxx`, LastName: `Williams`, Position : 'TE', Salary : 650000, SleeperID : 2360 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Markus`, LastName: `Golden`, Position : 'LB', Salary : 650000, SleeperID : 2363 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Sambrailo`, Position : 'T', Salary : 650000, SleeperID : 2364 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randy`, LastName: `Gregory`, Position : 'DE', Salary : 650000, SleeperID : 2365 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ali`, LastName: `Marpet`, Position : 'G', Salary : 650000, SleeperID : 2366 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frank`, LastName: `Clark`, Position : 'DE', Salary : 650000, SleeperID : 2368 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Richards`, Position : 'DB', Salary : 650000, SleeperID : 2369 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Poutasi`, Position : 'OL', Salary : 650000, SleeperID : 2371 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Cann`, Position : 'OL', Salary : 650000, SleeperID : 2372 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clive`, LastName: `Walford`, Position : 'TE', Salary : 650000, SleeperID : 2373 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Lockett`, Position : 'WR', Salary : 650000, SleeperID : 2374 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hroniss`, LastName: `Grasu`, Position : 'OL', Salary : 650000, SleeperID : 2376 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rob`, LastName: `Havenstein`, Position : 'OL', Salary : 650000, SleeperID : 2362 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tevin`, LastName: `Coleman`, Position : 'RB', Salary : 650000, SleeperID : 2378 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Conley`, Position : 'WR', Salary : 650000, SleeperID : 2381 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duke`, LastName: `Johnson`, Position : 'RB', Salary : 650000, SleeperID : 2382 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamon`, LastName: `Brown`, Position : 'G', Salary : 650000, SleeperID : 2377 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `P.J.`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 2383 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Za'Darius`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 2427 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kwon`, LastName: `Alexander`, Position : 'LB', Salary : 650000, SleeperID : 2429 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javorius`, LastName: `Allen`, Position : 'RB', Salary : 650000, SleeperID : 2430 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Davis`, Position : 'RB', Salary : 650000, SleeperID : 2431 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damien`, LastName: `Wilson`, Position : 'LB', Salary : 650000, SleeperID : 2432 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Feliciano`, Position : 'OG', Salary : 650000, SleeperID : 2433 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Ryan`, Position : 'LB', Salary : 650000, SleeperID : 2434 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaq`, LastName: `Mason`, Position : 'OL', Salary : 650000, SleeperID : 2435 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Garcia`, Position : 'OL', Salary : 650000, SleeperID : 2437 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Glowinski`, Position : 'G', Salary : 650000, SleeperID : 2438 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Amos`, Position : 'DB', Salary : 650000, SleeperID : 2445 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `MyCole`, LastName: `Pruitt`, Position : 'TE', Salary : 650000, SleeperID : 2446 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobby`, LastName: `McCain`, Position : 'CB', Salary : 650000, SleeperID : 2448 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stefon`, LastName: `Diggs`, Position : 'WR', Salary : 3367934.101, SleeperID : 2449 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davis`, LastName: `Tull`, Position : 'OLB', Salary : 650000, SleeperID : 2451 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brett`, LastName: `Hundley`, Position : 'QB', Salary : 650000, SleeperID : 2450 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedric`, LastName: `Thompson`, Position : 'SS', Salary : 650000, SleeperID : 2453 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Emanuel`, Position : 'LB', Salary : 650000, SleeperID : 2456 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Siemian`, Position : 'QB', Salary : 650000, SleeperID : 2549 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Angelo`, LastName: `Blackson`, Position : 'DE', Salary : 650000, SleeperID : 2405 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jay`, LastName: `Ajayi`, Position : 'RB', Salary : 650000, SleeperID : 2452 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Flowers`, Position : 'LB', Salary : 650000, SleeperID : 2406 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daryl`, LastName: `Williams`, Position : 'OL', Salary : 650000, SleeperID : 2407 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamison`, LastName: `Crowder`, Position : 'WR', Salary : 650000, SleeperID : 2410 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Hardy`, Position : 'WR', Salary : 650000, SleeperID : 2412 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clayton`, LastName: `Geathers`, Position : 'DB', Salary : 650000, SleeperID : 2414 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabe`, LastName: `Wright`, Position : 'DT', Salary : 650000, SleeperID : 2418 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamil`, LastName: `Douglas`, Position : 'OL', Salary : 650000, SleeperID : 2419 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ibraheim`, LastName: `Campbell`, Position : 'DB', Salary : 650000, SleeperID : 2420 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Gunter`, Position : 'DE', Salary : 650000, SleeperID : 2421 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Bell`, Position : 'TE', Salary : 650000, SleeperID : 2422 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ramik`, LastName: `Wilson`, Position : 'LB', Salary : 650000, SleeperID : 2423 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Donnal`, Position : 'T', Salary : 650000, SleeperID : 2424 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rory`, LastName: `Anderson`, Position : 'TE', Salary : 650000, SleeperID : 2553 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denzelle`, LastName: `Good`, Position : 'G', Salary : 650000, SleeperID : 2554 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Zenner`, Position : 'RB', Salary : 650000, SleeperID : 2560 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adarius`, LastName: `Taylor`, Position : 'LB', Salary : 650000, SleeperID : 2190 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Lucas`, Position : 'WR', Salary : 650000, SleeperID : 2191 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Clemmings`, Position : 'T', Salary : 650000, SleeperID : 2415 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lamarcus`, LastName: `Joyner`, Position : 'DB', Salary : 650000, SleeperID : 2230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trai`, LastName: `Turner`, Position : 'G', Salary : 650000, SleeperID : 2194 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandin`, LastName: `Cooks`, Position : 'WR', Salary : 650000, SleeperID : 2197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kasim`, LastName: `Edebali`, Position : 'DE', Salary : 650000, SleeperID : 2199 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Brate`, Position : 'TE', Salary : 650000, SleeperID : 2214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Evans`, Position : 'WR', Salary : 3121601.296, SleeperID : 2216 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Pamphile`, Position : 'G', Salary : 650000, SleeperID : 2220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Donald`, Position : 'DT', Salary : 650000, SleeperID : 2227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Gilbert`, Position : 'QB', Salary : 650000, SleeperID : 2229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Robinson`, Position : 'T', Salary : 650000, SleeperID : 2234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ethan`, LastName: `Westbrooks`, Position : 'DT', Salary : 650000, SleeperID : 2237 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maurice`, LastName: `Alexander`, Position : 'LB', Salary : 650000, SleeperID : 2224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 2238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deone`, LastName: `Bucannon`, Position : 'LB', Salary : 650000, SleeperID : 2240 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kareem`, LastName: `Martin`, Position : 'LB', Salary : 650000, SleeperID : 2244 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Thomas`, Position : 'TE', Salary : 650000, SleeperID : 2251 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlos`, LastName: `Hyde`, Position : 'RB', Salary : 650000, SleeperID : 2257 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontae`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 2258 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Lynch`, Position : 'LB', Salary : 650000, SleeperID : 2259 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Martin`, Position : 'C', Salary : 650000, SleeperID : 2260 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charley`, LastName: `Hughlett`, Position : 'LS', Salary : 650000, SleeperID : 2305 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Millard`, Position : 'RB', Salary : 650000, SleeperID : 2262 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Thomas`, Position : 'G', Salary : 0, SleeperID : 2266 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmie`, LastName: `Ward`, Position : 'DB', Salary : 650000, SleeperID : 2267 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Britt`, Position : 'OL', Salary : 650000, SleeperID : 2270 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cassius`, LastName: `Marsh`, Position : 'LB', Salary : 650000, SleeperID : 2275 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Pierre-Louis`, Position : 'LB', Salary : 650000, SleeperID : 2277 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paul`, LastName: `Richardson`, Position : 'WR', Salary : 650000, SleeperID : 2279 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Phillips`, Position : 'DB', Salary : 650000, SleeperID : 2287 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontrelle`, LastName: `Inman`, Position : 'WR', Salary : 650000, SleeperID : 2291 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerome`, LastName: `Cunningham`, Position : 'TE', Salary : 650000, SleeperID : 2304 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jameis`, LastName: `Winston`, Position : 'QB', Salary : 650000, SleeperID : 2306 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `O'Shaughnessy`, Position : 'TE', Salary : 650000, SleeperID : 2476 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrus`, LastName: `Thompson`, Position : 'OT', Salary : 650000, SleeperID : 2486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Geremy`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 2487 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Philon`, Position : 'DT', Salary : 650000, SleeperID : 2493 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Mariota`, Position : 'QB', Salary : 650000, SleeperID : 2307 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quandre`, LastName: `Diggs`, Position : 'DB', Salary : 650000, SleeperID : 2501 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Ringo`, Position : 'DT', Salary : 650000, SleeperID : 2511 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leterrius`, LastName: `Walton`, Position : 'DE', Salary : 650000, SleeperID : 2500 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `O'Leary`, Position : 'TE', Salary : 650000, SleeperID : 2495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Hager`, Position : 'LB', Salary : 650000, SleeperID : 2523 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Kilgo`, Position : 'DT', Salary : 650000, SleeperID : 2504 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darren`, LastName: `Waller`, Position : 'TE', Salary : 650000, SleeperID : 2505 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Koyack`, Position : 'TE', Salary : 650000, SleeperID : 2528 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Edmond`, LastName: `Robinson`, Position : 'LB', Salary : 650000, SleeperID : 2531 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Covington`, Position : 'DT', Salary : 650000, SleeperID : 2517 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Laurence`, LastName: `Gibson`, Position : 'OT', Salary : 650000, SleeperID : 2542 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Rodgers`, Position : 'T', Salary : 650000, SleeperID : 2524 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Martin`, LastName: `Ifedi`, Position : 'DE', Salary : 650000, SleeperID : 2526 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Geoff`, LastName: `Swaim`, Position : 'TE', Salary : 650000, SleeperID : 2545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darryl`, LastName: `Roberts`, Position : 'CB', Salary : 650000, SleeperID : 2546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henry`, LastName: `Anderson`, Position : 'DE', Salary : 650000, SleeperID : 2398 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Montgomery`, Position : 'RB', Salary : 650000, SleeperID : 2399 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Nelson`, Position : 'CB', Salary : 650000, SleeperID : 2403 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Washington`, Position : 'OLB', Salary : 650000, SleeperID : 2563 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Titus`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 2580 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrell`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 2583 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dres`, LastName: `Anderson`, Position : 'WR', Salary : 650000, SleeperID : 2587 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Brown`, Position : 'RB', Salary : 650000, SleeperID : 2588 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `La'el`, LastName: `Collins`, Position : 'T', Salary : 650000, SleeperID : 2591 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grady`, LastName: `Jarrett`, Position : 'DT', Salary : 650000, SleeperID : 2592 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Coleman`, Position : 'CB', Salary : 650000, SleeperID : 2572 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Johnson`, Position : 'FB', Salary : 650000, SleeperID : 2496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reshard`, LastName: `Cliett`, Position : 'OLB', Salary : 650000, SleeperID : 2512 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Chickillo`, Position : 'LB', Salary : 650000, SleeperID : 2513 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Nzeocha`, Position : 'LB', Salary : 650000, SleeperID : 2535 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `Brown`, Position : 'OL', Salary : 650000, SleeperID : 2543 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Reiter`, Position : 'C', Salary : 650000, SleeperID : 2521 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobby`, LastName: `Hart`, Position : 'T', Salary : 650000, SleeperID : 2525 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Da'Ron`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 2532 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Akeem`, LastName: `King`, Position : 'CB', Salary : 650000, SleeperID : 2548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antwan`, LastName: `Goodley`, Position : 'WR', Salary : 650000, SleeperID : 2593 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Semisch`, Position : 'TE', Salary : 650000, SleeperID : 2595 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Easton`, Position : 'G', Salary : 650000, SleeperID : 2966 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Berry`, Position : 'P', Salary : 650000, SleeperID : 2975 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinton`, LastName: `Spain`, Position : 'G', Salary : 650000, SleeperID : 2982 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Riley`, Position : 'DB', Salary : 650000, SleeperID : 2983 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Lamm`, Position : 'OT', Salary : 650000, SleeperID : 2985 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Mancz`, Position : 'OL', Salary : 650000, SleeperID : 2986 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tevin`, LastName: `Mitchel`, Position : 'CB', Salary : 650000, SleeperID : 2988 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kitt`, LastName: `O'Brien`, Position : 'G', Salary : 650000, SleeperID : 2989 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kasey`, LastName: `Redfern`, Position : 'P', Salary : 650000, SleeperID : 2990 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rakeem`, LastName: `Nunez-Roches`, Position : 'DT', Salary : 650000, SleeperID : 2993 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Jefferson`, Position : 'G', Salary : 650000, SleeperID : 2998 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shamar`, LastName: `Stephen`, Position : 'DT', Salary : 650000, SleeperID : 2163 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricardo`, LastName: `Allen`, Position : 'DB', Salary : 650000, SleeperID : 2167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devonta`, LastName: `Freeman`, Position : 'RB', Salary : 650000, SleeperID : 2168 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devonta`, LastName: `Glover-Wright`, Position : 'CB', Salary : 650000, SleeperID : 2169 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Matthews`, Position : 'T', Salary : 650000, SleeperID : 2172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Munyer`, Position : 'C', Salary : 650000, SleeperID : 2992 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roosevelt`, LastName: `Nix`, Position : 'FB', Salary : 650000, SleeperID : 2174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Boston`, Position : 'DB', Salary : 650000, SleeperID : 2184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Norwell`, Position : 'OL', Salary : 650000, SleeperID : 2192 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rick`, LastName: `Lovato`, Position : 'LS', Salary : 650000, SleeperID : 2999 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Olsen`, LastName: `Pierre`, Position : 'DT', Salary : 650000, SleeperID : 3000 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damiere`, LastName: `Byrd`, Position : 'WR', Salary : 650000, SleeperID : 2673 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Neville`, LastName: `Hewitt`, Position : 'LB', Salary : 650000, SleeperID : 2677 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dean`, LastName: `Marlowe`, Position : 'DB', Salary : 650000, SleeperID : 2679 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ashaad`, LastName: `Mabry`, Position : 'DT', Salary : 650000, SleeperID : 2728 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arthur`, LastName: `Miley`, Position : 'DE', Salary : 650000, SleeperID : 2680 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Slade`, Position : 'OG', Salary : 650000, SleeperID : 2688 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raheem`, LastName: `Mostert`, Position : 'RB', Salary : 949717.346, SleeperID : 2749 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Mason`, Position : 'OLB', Salary : 650000, SleeperID : 2693 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Junior`, LastName: `Sylvestre`, Position : 'ILB', Salary : 650000, SleeperID : 2708 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Heinicke`, Position : 'QB', Salary : 650000, SleeperID : 2711 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Harris`, Position : 'DB', Salary : 650000, SleeperID : 2719 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `King`, Position : 'LB', Salary : 650000, SleeperID : 2724 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Crume`, Position : 'DT', Salary : 650000, SleeperID : 2737 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Myers`, Position : 'K', Salary : 650000, SleeperID : 2747 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Todd`, LastName: `Davis`, Position : 'LB', Salary : 650000, SleeperID : 2689 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAndre`, LastName: `Carter`, Position : 'WR', Salary : 650000, SleeperID : 2750 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Brown`, Position : 'TE', Salary : 650000, SleeperID : 2751 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Tomlinson`, Position : 'TE', Salary : 650000, SleeperID : 2755 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Raciti`, Position : 'NT', Salary : 650000, SleeperID : 2760 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Vaughters`, Position : 'LB', Salary : 650000, SleeperID : 2778 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gus`, LastName: `Johnson`, Position : 'RB', Salary : 650000, SleeperID : 2785 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Efe`, LastName: `Obada`, Position : 'DE', Salary : 650000, SleeperID : 2796 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAndrew`, LastName: `White`, Position : 'WR', Salary : 650000, SleeperID : 2801 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jay`, LastName: `Hughes`, Position : 'CB', Salary : 650000, SleeperID : 2814 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Hagen`, Position : 'FS', Salary : 650000, SleeperID : 2808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rasheed`, LastName: `Bailey`, Position : 'WR', Salary : 650000, SleeperID : 2753 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Palardy`, Position : 'P', Salary : 650000, SleeperID : 2818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Meyer`, Position : 'K', Salary : 650000, SleeperID : 2819 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Kumerow`, Position : 'WR', Salary : 650000, SleeperID : 2821 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Humphries`, Position : 'WR', Salary : 650000, SleeperID : 2822 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Hill`, Position : 'CB', Salary : 650000, SleeperID : 2826 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeShawn`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 2827 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Cook`, Position : 'WR', Salary : 650000, SleeperID : 2836 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `LaCosse`, Position : 'TE', Salary : 650000, SleeperID : 2944 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `March-Lillard`, Position : 'LB', Salary : 650000, SleeperID : 2839 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Hamilton`, Position : 'DT', Salary : 650000, SleeperID : 2859 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Callahan`, Position : 'CB', Salary : 650000, SleeperID : 2860 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Goodwin`, Position : 'CB', Salary : 650000, SleeperID : 2869 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Laskey`, Position : 'RB', Salary : 650000, SleeperID : 2880 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Smith`, Position : 'RB', Salary : 650000, SleeperID : 2892 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Watson`, Position : 'LB', Salary : 650000, SleeperID : 2894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Vogler`, Position : 'TE', Salary : 650000, SleeperID : 2917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Des`, LastName: `Lawrence`, Position : 'WR', Salary : 650000, SleeperID : 2940 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brett`, LastName: `Jones`, Position : 'C', Salary : 650000, SleeperID : 2956 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Andrews`, Position : 'C', Salary : 650000, SleeperID : 2961 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Mauro`, Position : 'DE', Salary : 650000, SleeperID : 1920 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `McCullers`, Position : 'DT', Salary : 650000, SleeperID : 1921 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roy`, LastName: `Philon`, Position : 'NT', Salary : 650000, SleeperID : 1922 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaquille`, LastName: `Richardson`, Position : 'CB', Salary : 650000, SleeperID : 1923 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephon`, LastName: `Tuitt`, Position : 'DE', Salary : 650000, SleeperID : 1925 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Lewan`, Position : 'OT', Salary : 650000, SleeperID : 1933 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `McCray`, Position : 'OL', Salary : 650000, SleeperID : 1934 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derel`, LastName: `Walker`, Position : 'WR', Salary : 650000, SleeperID : 1939 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaQuan`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 1932 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deshon`, LastName: `Foxx`, Position : 'WR', Salary : 650000, SleeperID : 2931 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Cottom`, Position : 'RB', Salary : 650000, SleeperID : 2948 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davante`, LastName: `Adams`, Position : 'WR', Salary : 4020974.097, SleeperID : 2133 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rajion`, LastName: `Neal`, Position : 'RB', Salary : 650000, SleeperID : 2142 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Teddy`, LastName: `Bridgewater`, Position : 'QB', Salary : 650000, SleeperID : 2152 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 2121 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cornelius`, LastName: `Lucas`, Position : 'T', Salary : 650000, SleeperID : 2123 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nevin`, LastName: `Lawson`, Position : 'CB', Salary : 650000, SleeperID : 2122 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ha Ha`, LastName: `Clinton-Dix`, Position : 'DB', Salary : 650000, SleeperID : 2135 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Pennel`, Position : 'DT', Salary : 650000, SleeperID : 2143 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Thomas`, Position : 'LB', Salary : 650000, SleeperID : 2147 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richard`, LastName: `Rodgers`, Position : 'TE', Salary : 650000, SleeperID : 2146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Vujnovich`, Position : 'G', Salary : 650000, SleeperID : 2149 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Barr`, Position : 'LB', Salary : 650000, SleeperID : 2151 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isame`, LastName: `Faciane`, Position : 'G', Salary : 650000, SleeperID : 2157 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerick`, LastName: `McKinnon`, Position : 'RB', Salary : 650000, SleeperID : 2161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Boswell`, Position : 'K', Salary : 650000, SleeperID : 1945 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caraun`, LastName: `Reid`, Position : 'DT', Salary : 650000, SleeperID : 2126 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Van Noy`, Position : 'LB', Salary : 650000, SleeperID : 2130 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Linsley`, Position : 'C', Salary : 650000, SleeperID : 2141 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antone`, LastName: `Exum`, Position : 'SS', Salary : 650000, SleeperID : 2156 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avery`, LastName: `Williamson`, Position : 'LB', Salary : 650000, SleeperID : 1940 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 2110 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jadeveon`, LastName: `Clowney`, Position : 'DE', Salary : 650000, SleeperID : 1947 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Feiler`, Position : 'G', Salary : 650000, SleeperID : 1949 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Ferentz`, Position : 'OL', Salary : 650000, SleeperID : 1950 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Witzmann`, Position : 'G', Salary : 650000, SleeperID : 1961 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Su'a-Filo`, Position : 'G', Salary : 650000, SleeperID : 1958 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonotthan`, LastName: `Harrison`, Position : 'OL', Salary : 650000, SleeperID : 1963 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Kerr`, Position : 'DE', Salary : 650000, SleeperID : 1965 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donte`, LastName: `Moncrief`, Position : 'WR', Salary : 650000, SleeperID : 1971 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Leno`, Position : 'T', Salary : 650000, SleeperID : 2112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pat`, LastName: `O'Donnell`, Position : 'P', Salary : 650000, SleeperID : 2113 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Senorise`, LastName: `Perry`, Position : 'RB', Salary : 650000, SleeperID : 2114 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Ebron`, Position : 'TE', Salary : 650000, SleeperID : 2118 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lavon`, LastName: `Hooks`, Position : 'DT', Salary : 650000, SleeperID : 3005 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Whitehead`, Position : 'FS', Salary : 650000, SleeperID : 3016 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Wile`, Position : 'P', Salary : 650000, SleeperID : 3008 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jesse`, LastName: `Davis`, Position : 'OL', Salary : 650000, SleeperID : 3017 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Vante`, LastName: `Bausby`, Position : 'CB', Salary : 650000, SleeperID : 3022 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damian`, LastName: `Parms`, Position : 'SS', Salary : 650000, SleeperID : 3023 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Lengel`, Position : 'TE', Salary : 650000, SleeperID : 3036 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josue`, LastName: `Matias`, Position : 'G', Salary : 650000, SleeperID : 3037 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Reed`, Position : 'G', Salary : 650000, SleeperID : 3038 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Irving`, Position : 'DE', Salary : 650000, SleeperID : 3040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Travis`, Position : 'TE', Salary : 650000, SleeperID : 3041 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Bunche`, Position : 'G', Salary : 650000, SleeperID : 3044 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gannon`, LastName: `Sinclair`, Position : 'TE', Salary : 650000, SleeperID : 3045 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Rotheram`, Position : 'G', Salary : 650000, SleeperID : 3046 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Manhertz`, Position : 'TE', Salary : 650000, SleeperID : 3048 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terry`, LastName: `Poole`, Position : 'OT', Salary : 650000, SleeperID : 3049 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.J.`, LastName: `Finney`, Position : 'OL', Salary : 650000, SleeperID : 3057 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Lott`, Position : 'DT', Salary : 650000, SleeperID : 3064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamel`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 3078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erik`, LastName: `Harris`, Position : 'DB', Salary : 650000, SleeperID : 3144 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Dable`, Position : 'WR', Salary : 650000, SleeperID : 3148 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Rush`, Position : 'WR', Salary : 650000, SleeperID : 3150 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Laquon`, LastName: `Treadwell`, Position : 'WR', Salary : 650000, SleeperID : 3155 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joey`, LastName: `Bosa`, Position : 'LB', Salary : 650000, SleeperID : 3156 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Fuller`, Position : 'WR', Salary : 650000, SleeperID : 3157 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Laremy`, LastName: `Tunsil`, Position : 'OL', Salary : 650000, SleeperID : 3159 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Ramsey`, Position : 'CB', Salary : 650000, SleeperID : 3160 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carson`, LastName: `Wentz`, Position : 'QB', Salary : 650000, SleeperID : 3161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronnie`, LastName: `Stanley`, Position : 'T', Salary : 650000, SleeperID : 3162 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Goff`, Position : 'QB', Salary : 1105520.184, SleeperID : 3163 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ezekiel`, LastName: `Elliott`, Position : 'RB', Salary : 650000, SleeperID : 3164 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Jack`, Position : 'LB', Salary : 650000, SleeperID : 3166 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 3167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paxton`, LastName: `Lynch`, Position : 'QB', Salary : 650000, SleeperID : 3170 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeForest`, LastName: `Buckner`, Position : 'DT', Salary : 650000, SleeperID : 3172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leonard`, LastName: `Floyd`, Position : 'LB', Salary : 650000, SleeperID : 3174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Conklin`, Position : 'OT', Salary : 650000, SleeperID : 3175 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Ragland`, Position : 'LB', Salary : 650000, SleeperID : 3176 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Coleman`, Position : 'WR', Salary : 650000, SleeperID : 3177 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Doctson`, Position : 'WR', Salary : 650000, SleeperID : 3178 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Garnett`, Position : 'G', Salary : 650000, SleeperID : 3179 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Nkemdiche`, Position : 'DT', Salary : 650000, SleeperID : 3180 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keanu`, LastName: `Neal`, Position : 'LB', Salary : 650000, SleeperID : 3181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaq`, LastName: `Lawson`, Position : 'DE', Salary : 650000, SleeperID : 3182 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Clark`, Position : 'DE', Salary : 650000, SleeperID : 3186 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vernon`, LastName: `Butler`, Position : 'DT', Salary : 650000, SleeperID : 3183 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Decker`, Position : 'T', Salary : 650000, SleeperID : 3185 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Jackson`, Position : 'CB', Salary : 650000, SleeperID : 3184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Kelly`, Position : 'C', Salary : 650000, SleeperID : 3187 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sheldon`, LastName: `Rankins`, Position : 'DT', Salary : 650000, SleeperID : 3188 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eli`, LastName: `Apple`, Position : 'CB', Salary : 650000, SleeperID : 3189 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Karl`, LastName: `Joseph`, Position : 'DB', Salary : 650000, SleeperID : 3191 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Artie`, LastName: `Burns`, Position : 'DB', Salary : 650000, SleeperID : 3192 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Germain`, LastName: `Ifedi`, Position : 'OL', Salary : 650000, SleeperID : 3193 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vernon`, LastName: `Hargreaves`, Position : 'DB', Salary : 650000, SleeperID : 3194 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Spence`, Position : 'DE', Salary : 650000, SleeperID : 3195 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emmanuel`, LastName: `Ogbah`, Position : 'DE', Salary : 650000, SleeperID : 3197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Henry`, Position : 'RB', Salary : 3283735.749, SleeperID : 3198 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darron`, LastName: `Lee`, Position : 'LB', Salary : 650000, SleeperID : 3190 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Thomas`, Position : 'WR', Salary : 650000, SleeperID : 3199 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sterling`, LastName: `Shepard`, Position : 'WR', Salary : 650000, SleeperID : 3200 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Hooper`, Position : 'TE', Salary : 650000, SleeperID : 3202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Prosise`, Position : 'RB', Salary : 650000, SleeperID : 3204 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Kessler`, Position : 'QB', Salary : 650000, SleeperID : 3205 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Schobert`, Position : 'LB', Salary : 650000, SleeperID : 3206 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Billings`, Position : 'DT', Salary : 650000, SleeperID : 3207 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenneth`, LastName: `Dixon`, Position : 'RB', Salary : 650000, SleeperID : 3208 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devontae`, LastName: `Booker`, Position : 'RB', Salary : 650000, SleeperID : 3209 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paul`, LastName: `Perkins`, Position : 'RB', Salary : 650000, SleeperID : 3211 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Henry`, Position : 'TE', Salary : 650000, SleeperID : 3214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavien`, LastName: `Howard`, Position : 'DB', Salary : 650000, SleeperID : 3215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kamalei`, LastName: `Correa`, Position : 'LB', Salary : 650000, SleeperID : 3216 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jihad`, LastName: `Ward`, Position : 'DE', Salary : 650000, SleeperID : 3217 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A'Shawn`, LastName: `Robinson`, Position : 'DT', Salary : 650000, SleeperID : 3218 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Spriggs`, Position : 'OL', Salary : 650000, SleeperID : 3219 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarran`, LastName: `Reed`, Position : 'DT', Salary : 650000, SleeperID : 3220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Martin`, Position : 'C', Salary : 650000, SleeperID : 3221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deion`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 3222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mackensie`, LastName: `Alexander`, Position : 'CB', Salary : 650000, SleeperID : 3224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Boyd`, Position : 'WR', Salary : 650000, SleeperID : 3225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Whitehair`, Position : 'OL', Salary : 650000, SleeperID : 3226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Green`, Position : 'DB', Salary : 650000, SleeperID : 3227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Davis`, Position : 'DB', Salary : 650000, SleeperID : 3228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cyrus`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 3229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vonn`, LastName: `Bell`, Position : 'DB', Salary : 650000, SleeperID : 3230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Bradberry`, Position : 'CB', Salary : 650000, SleeperID : 3231 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Gotsis`, Position : 'DE', Salary : 650000, SleeperID : 3232 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Byard`, Position : 'DB', Salary : 650000, SleeperID : 3233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carl`, LastName: `Nassib`, Position : 'DE', Salary : 650000, SleeperID : 3234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maliek`, LastName: `Collins`, Position : 'DT', Salary : 650000, SleeperID : 3236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Redmond`, Position : 'DB', Salary : 650000, SleeperID : 3237 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yannick`, LastName: `Ngakoue`, Position : 'DE', Salary : 650000, SleeperID : 3238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bronson`, LastName: `Kaufusi`, Position : 'TE', Salary : 650000, SleeperID : 3239 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darian`, LastName: `Thompson`, Position : 'DB', Salary : 650000, SleeperID : 3240 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Bullard`, Position : 'DE', Salary : 650000, SleeperID : 3241 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenyan`, LastName: `Drake`, Position : 'RB', Salary : 650000, SleeperID : 3242 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KeiVarae`, LastName: `Russell`, Position : 'DB', Salary : 650000, SleeperID : 3243 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shilique`, LastName: `Calhoun`, Position : 'LB', Salary : 650000, SleeperID : 3244 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shon`, LastName: `Coleman`, Position : 'OT', Salary : 650000, SleeperID : 3245 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daryl`, LastName: `Worley`, Position : 'CB', Salary : 650000, SleeperID : 3246 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Thuney`, Position : 'OL', Salary : 650000, SleeperID : 3247 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Seumalo`, Position : 'G', Salary : 650000, SleeperID : 3248 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Le'Raven`, LastName: `Clark`, Position : 'T', Salary : 650000, SleeperID : 3250 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Jenkins`, Position : 'DE', Salary : 650000, SleeperID : 3251 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Fuller`, Position : 'CB', Salary : 650000, SleeperID : 3252 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Vigil`, Position : 'LB', Salary : 650000, SleeperID : 3254 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyler`, LastName: `Fackrell`, Position : 'LB', Salary : 650000, SleeperID : 3255 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Hargrave`, Position : 'DT', Salary : 650000, SleeperID : 3256 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacoby`, LastName: `Brissett`, Position : 'QB', Salary : 650000, SleeperID : 3257 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Vannett`, Position : 'TE', Salary : 650000, SleeperID : 3258 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Graham`, LastName: `Glasgow`, Position : 'G', Salary : 650000, SleeperID : 3259 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Simmons`, Position : 'DB', Salary : 650000, SleeperID : 3262 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sheldon`, LastName: `Day`, Position : 'DT', Salary : 650000, SleeperID : 3265 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tavon`, LastName: `Young`, Position : 'CB', Salary : 650000, SleeperID : 3266 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `Ehinger`, Position : 'G', Salary : 650000, SleeperID : 3267 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Murray`, Position : 'DB', Salary : 650000, SleeperID : 3268 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Moore`, Position : 'WR', Salary : 650000, SleeperID : 3269 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.J.`, LastName: `Goodson`, Position : 'LB', Salary : 650000, SleeperID : 3270 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Higbee`, Position : 'TE', Salary : 650000, SleeperID : 3271 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Miles`, LastName: `Killebrew`, Position : 'DB', Salary : 650000, SleeperID : 3272 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Kwiatkoski`, Position : 'LB', Salary : 650000, SleeperID : 3274 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Vondre`, LastName: `Campbell`, Position : 'LB', Salary : 650000, SleeperID : 3276 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hassan`, LastName: `Ridgeway`, Position : 'DT', Salary : 650000, SleeperID : 3277 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pharoh`, LastName: `Cooper`, Position : 'WR', Salary : 650000, SleeperID : 3278 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juston`, LastName: `Burris`, Position : 'DB', Salary : 650000, SleeperID : 3279 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Ervin`, Position : 'RB', Salary : 650000, SleeperID : 3280 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Onyemata`, Position : 'DT', Salary : 650000, SleeperID : 3281 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Beavers`, Position : 'OL', Salary : 650000, SleeperID : 3282 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerald`, LastName: `Hawkins`, Position : 'OT', Salary : 650000, SleeperID : 3283 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deon`, LastName: `Bush`, Position : 'DB', Salary : 650000, SleeperID : 3284 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demarcus`, LastName: `Robinson`, Position : 'WR', Salary : 650000, SleeperID : 3286 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Boehm`, Position : 'C', Salary : 650000, SleeperID : 3288 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Lewis`, Position : 'OL', Salary : 650000, SleeperID : 3290 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Martinez`, Position : 'LB', Salary : 650000, SleeperID : 3291 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Henry`, Position : 'DT', Salary : 650000, SleeperID : 3292 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashard`, LastName: `Robinson`, Position : 'CB', Salary : 650000, SleeperID : 3293 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dak`, LastName: `Prescott`, Position : 'QB', Salary : 1657649.95, SleeperID : 3294 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dean`, LastName: `Lowry`, Position : 'DE', Salary : 650000, SleeperID : 3295 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seth`, LastName: `DeValve`, Position : 'TE', Salary : 650000, SleeperID : 3296 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tajae`, LastName: `Sharpe`, Position : 'WR', Salary : 650000, SleeperID : 3297 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronald`, LastName: `Blair`, Position : 'DE', Salary : 650000, SleeperID : 3299 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAndre`, LastName: `Washington`, Position : 'RB', Salary : 650000, SleeperID : 3300 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `McGovern`, Position : 'OL', Salary : 650000, SleeperID : 3301 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Judon`, Position : 'LB', Salary : 650000, SleeperID : 3303 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinton`, LastName: `Jefferson`, Position : 'DT', Salary : 650000, SleeperID : 3304 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Benenoch`, Position : 'OL', Salary : 650000, SleeperID : 3305 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Howard`, Position : 'RB', Salary : 650000, SleeperID : 3306 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Dahl`, Position : 'G', Salary : 650000, SleeperID : 3307 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Ioannidis`, Position : 'DT', Salary : 650000, SleeperID : 3308 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wendell`, LastName: `Smallwood`, Position : 'RB', Salary : 650000, SleeperID : 3309 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Haeg`, Position : 'T', Salary : 650000, SleeperID : 3311 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 3312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LeShaun`, LastName: `Sims`, Position : 'CB', Salary : 650000, SleeperID : 3313 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Shell`, Position : 'T', Salary : 650000, SleeperID : 3314 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kentrell`, LastName: `Brothers`, Position : 'ILB', Salary : 650000, SleeperID : 3316 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Hogan`, Position : 'QB', Salary : 650000, SleeperID : 3318 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 3319 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Halapoulivaati`, LastName: `Vaitai`, Position : 'G', Salary : 650000, SleeperID : 3320 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyreek`, LastName: `Hill`, Position : 'WR', Salary : 11069975.19, SleeperID : 3321 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Reader`, Position : 'DT', Salary : 650000, SleeperID : 3322 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marqui`, LastName: `Christian`, Position : 'DB', Salary : 650000, SleeperID : 3323 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Drango`, Position : 'G', Salary : 650000, SleeperID : 3324 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Toner`, Position : 'OL', Salary : 650000, SleeperID : 3326 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Collins`, Position : 'RB', Salary : 650000, SleeperID : 3327 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashard`, LastName: `Higgins`, Position : 'WR', Salary : 650000, SleeperID : 3328 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fahn`, LastName: `Cooper`, Position : 'OT', Salary : 650000, SleeperID : 3330 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jatavis`, LastName: `Brown`, Position : 'LB', Salary : 650000, SleeperID : 3331 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andy`, LastName: `Janovich`, Position : 'FB', Salary : 650000, SleeperID : 3332 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Temarrick`, LastName: `Hemingway`, Position : 'TE', Salary : 650000, SleeperID : 3333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `White`, Position : 'CB', Salary : 650000, SleeperID : 3334 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Kaser`, Position : 'P', Salary : 650000, SleeperID : 3335 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Moritz`, LastName: `Bohringer`, Position : 'TE', Salary : 650000, SleeperID : 3336 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devante`, LastName: `Bond`, Position : 'LB', Salary : 650000, SleeperID : 3339 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerell`, LastName: `Adams`, Position : 'TE', Salary : 650000, SleeperID : 3340 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAndre`, LastName: `Houston-Carson`, Position : 'DB', Salary : 650000, SleeperID : 3341 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jakeem`, LastName: `Grant`, Position : 'WR', Salary : 650000, SleeperID : 3342 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Sudfeld`, Position : 'QB', Salary : 650000, SleeperID : 3343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Brown`, Position : 'CB', Salary : 650000, SleeperID : 3345 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Rudock`, Position : 'QB', Salary : 650000, SleeperID : 3347 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kolby`, LastName: `Listenbee`, Position : 'WR', Salary : 650000, SleeperID : 3348 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wes`, LastName: `Schweitzer`, Position : 'G', Salary : 650000, SleeperID : 3351 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Countess`, Position : 'DB', Salary : 650000, SleeperID : 3352 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Vitale`, Position : 'FB', Salary : 650000, SleeperID : 3353 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Watt`, Position : 'FB', Salary : 650000, SleeperID : 3354 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Core`, Position : 'WR', Salary : 650000, SleeperID : 3355 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Murphy`, Position : 'OT', Salary : 650000, SleeperID : 3356 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Allen`, Position : 'QB', Salary : 650000, SleeperID : 3357 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Zettel`, Position : 'DE', Salary : 650000, SleeperID : 3358 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Lucas`, Position : 'DB', Salary : 650000, SleeperID : 3360 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Driskel`, Position : 'QB', Salary : 650000, SleeperID : 3362 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kamu`, LastName: `Grugier-Hill`, Position : 'LB', Salary : 650000, SleeperID : 3363 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maurice`, LastName: `Canady`, Position : 'CB', Salary : 650000, SleeperID : 3364 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Landes`, Position : 'LS', Salary : 650000, SleeperID : 3365 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kavon`, LastName: `Frazier`, Position : 'DB', Salary : 650000, SleeperID : 3367 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elandon`, LastName: `Roberts`, Position : 'LB', Salary : 650000, SleeperID : 3369 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Jackson`, Position : 'RB', Salary : 650000, SleeperID : 3371 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joey`, LastName: `Hunt`, Position : 'C', Salary : 650000, SleeperID : 3370 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Parks`, Position : 'DB', Salary : 650000, SleeperID : 3373 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevon`, LastName: `Seymour`, Position : 'CB', Salary : 650000, SleeperID : 3374 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donavon`, LastName: `Clark`, Position : 'G', Salary : 650000, SleeperID : 3379 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ted`, LastName: `Karras`, Position : 'OL', Salary : 650000, SleeperID : 3376 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Woodard`, Position : 'DE', Salary : 650000, SleeperID : 3381 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Weatherly`, Position : 'LB', Salary : 650000, SleeperID : 3382 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Lucien`, Position : 'WR', Salary : 650000, SleeperID : 3380 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Dixon`, Position : 'P', Salary : 650000, SleeperID : 3383 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Daniels`, Position : 'OLB', Salary : 650000, SleeperID : 3387 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Mills`, Position : 'CB', Salary : 650000, SleeperID : 3388 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lachlan`, LastName: `Edwards`, Position : 'P', Salary : 650000, SleeperID : 3390 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayron`, LastName: `Kearse`, Position : 'DB', Salary : 650000, SleeperID : 3399 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dwayne`, LastName: `Washington`, Position : 'RB', Salary : 650000, SleeperID : 3391 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Fuller`, Position : 'WR', Salary : 650000, SleeperID : 3393 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `McCalister`, Position : 'DE', Salary : 650000, SleeperID : 3395 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Marshall`, Position : 'RB', Salary : 650000, SleeperID : 3397 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Lawler`, Position : 'WR', Salary : 650000, SleeperID : 3398 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clayton`, LastName: `Fejedelem`, Position : 'DB', Salary : 650000, SleeperID : 3400 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Matakevich`, Position : 'LB', Salary : 650000, SleeperID : 3401 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Blythe`, Position : 'OL', Salary : 650000, SleeperID : 3403 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Walker`, Position : 'LB', Salary : 650000, SleeperID : 3406 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sterling`, LastName: `Bailey`, Position : 'DE', Salary : 650000, SleeperID : 3421 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Beau`, LastName: `Sandland`, Position : 'TE', Salary : 650000, SleeperID : 3407 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devon`, LastName: `Johnson`, Position : 'RB', Salary : 650000, SleeperID : 3412 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Jones`, Position : 'DB', Salary : 650000, SleeperID : 3415 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cre'Von`, LastName: `LeBlanc`, Position : 'DB', Salary : 650000, SleeperID : 3416 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mekale`, LastName: `McKay`, Position : 'WR', Salary : 650000, SleeperID : 3418 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Friend`, Position : 'C', Salary : 650000, SleeperID : 3422 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Oni`, LastName: `Omoile`, Position : 'G', Salary : 650000, SleeperID : 3408 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 3438 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Pettway`, Position : 'DE', Salary : 650000, SleeperID : 3425 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ken`, LastName: `Crawley`, Position : 'CB', Salary : 650000, SleeperID : 3426 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrance`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 3417 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Dean`, Position : 'DT', Salary : 650000, SleeperID : 3430 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joseph`, LastName: `Cheek`, Position : 'G', Salary : 650000, SleeperID : 3432 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Erickson`, Position : 'WR', Salary : 650000, SleeperID : 3433 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Farris`, Position : 'G', Salary : 650000, SleeperID : 3435 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robbie`, LastName: `Chosen`, Position : 'WR', Salary : 650000, SleeperID : 3423 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Brendel`, Position : 'OL', Salary : 650000, SleeperID : 3439 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Woodrum`, Position : 'QB', Salary : 650000, SleeperID : 3440 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Skura`, Position : 'C', Salary : 650000, SleeperID : 3441 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Swain`, Position : 'FB', Salary : 650000, SleeperID : 3443 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Burgess`, Position : 'LB', Salary : 650000, SleeperID : 3444 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 3445 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joel`, LastName: `Heath`, Position : 'DE', Salary : 650000, SleeperID : 3446 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Giorgio`, LastName: `Newberry`, Position : 'DE', Salary : 650000, SleeperID : 3448 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avery`, LastName: `Young`, Position : 'G', Salary : 650000, SleeperID : 3458 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Monday`, Position : 'P', Salary : 650000, SleeperID : 3449 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ka'imi`, LastName: `Fairbairn`, Position : 'K', Salary : 650000, SleeperID : 3451 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Ferguson`, Position : 'RB', Salary : 650000, SleeperID : 3453 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joel`, LastName: `Stave`, Position : 'QB', Salary : 650000, SleeperID : 3456 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cayleb`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 3447 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Martin`, Position : 'K', Salary : 650000, SleeperID : 3460 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Braunecker`, Position : 'TE', Salary : 650000, SleeperID : 3465 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquez`, LastName: `North`, Position : 'WR', Salary : 650000, SleeperID : 3464 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Williamson`, Position : 'SS', Salary : 650000, SleeperID : 3466 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Bertolet`, Position : 'K', Salary : 650000, SleeperID : 3467 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cassanova`, LastName: `McKinzy`, Position : 'OLB', Salary : 650000, SleeperID : 3469 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Zimmer`, Position : 'DT', Salary : 650000, SleeperID : 3473 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Briean`, LastName: `Boddy-Calhoun`, Position : 'CB', Salary : 650000, SleeperID : 3474 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keyarris`, LastName: `Garrett`, Position : 'WR', Salary : 650000, SleeperID : 3475 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antwaun`, LastName: `Woods`, Position : 'DT', Salary : 650000, SleeperID : 3479 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Holton`, Position : 'WR', Salary : 650000, SleeperID : 3476 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 3486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Foster`, Position : 'RB', Salary : 650000, SleeperID : 3488 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Bercovici`, Position : 'QB', Salary : 650000, SleeperID : 3495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Anderson`, Position : 'TE', Salary : 650000, SleeperID : 3496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darion`, LastName: `Griswold`, Position : 'TE', Salary : 650000, SleeperID : 3497 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Milhouse`, Position : 'DT', Salary : 650000, SleeperID : 3498 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devon`, LastName: `Cajuste`, Position : 'WR', Salary : 650000, SleeperID : 3500 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Geronimo`, LastName: `Allison`, Position : 'WR', Salary : 650000, SleeperID : 3503 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Romeo`, LastName: `Okwara`, Position : 'LB', Salary : 650000, SleeperID : 3502 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Scheu`, Position : 'TE', Salary : 650000, SleeperID : 3504 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Henry`, Position : 'OL', Salary : 650000, SleeperID : 3508 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dom`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 3505 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ron`, LastName: `Thompson`, Position : 'DE', Salary : 650000, SleeperID : 3510 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clay`, LastName: `DeBord`, Position : 'OT', Salary : 650000, SleeperID : 3522 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aziz`, LastName: `Shittu`, Position : 'DT', Salary : 650000, SleeperID : 3511 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Norris`, Position : 'LB', Salary : 650000, SleeperID : 3513 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `McGee`, Position : 'TE', Salary : 650000, SleeperID : 3514 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K.J.`, LastName: `Maye`, Position : 'WR', Salary : 650000, SleeperID : 3515 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitch`, LastName: `Mathews`, Position : 'WR', Salary : 650000, SleeperID : 3516 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Wujciak`, Position : 'DT', Salary : 650000, SleeperID : 3518 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Fabiano`, Position : 'C', Salary : 650000, SleeperID : 3523 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephane`, LastName: `Nembot`, Position : 'OT', Salary : 650000, SleeperID : 3525 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roy`, LastName: `Robertson-Harris`, Position : 'DE', Salary : 650000, SleeperID : 3526 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `McDonald`, Position : 'SS', Salary : 650000, SleeperID : 3534 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Peterson`, Position : 'CB', Salary : 650000, SleeperID : 3527 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Hilton`, Position : 'CB', Salary : 650000, SleeperID : 3546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nile`, LastName: `Lawrence-Stample`, Position : 'NT', Salary : 650000, SleeperID : 3529 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Scott`, Position : 'CB', Salary : 650000, SleeperID : 3538 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Pulley`, Position : 'C', Salary : 650000, SleeperID : 3543 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Matthews`, Position : 'C', Salary : 650000, SleeperID : 3544 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashod`, LastName: `Hill`, Position : 'T', Salary : 650000, SleeperID : 3545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Robinson`, Position : 'WR', Salary : 650000, SleeperID : 3548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Canaan`, LastName: `Severin`, Position : 'WR', Salary : 650000, SleeperID : 3555 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 3558 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamaal`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 3540 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chester`, LastName: `Rogers`, Position : 'WR', Salary : 650000, SleeperID : 3582 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Smith`, Position : 'CB', Salary : 650000, SleeperID : 3559 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrod`, LastName: `Wilson`, Position : 'DB', Salary : 650000, SleeperID : 3550 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyvis`, LastName: `Powell`, Position : 'SS', Salary : 650000, SleeperID : 3562 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Williams`, Position : 'TE', Salary : 650000, SleeperID : 3561 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Johnson`, Position : 'DT', Salary : 650000, SleeperID : 3560 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Baxter`, Position : 'CB', Salary : 650000, SleeperID : 3565 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marken`, LastName: `Michel`, Position : 'WR', Salary : 650000, SleeperID : 3568 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Bonnet`, Position : 'FB', Salary : 650000, SleeperID : 3572 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reid`, LastName: `Ferguson`, Position : 'LS', Salary : 650000, SleeperID : 3576 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquis`, LastName: `Lucas`, Position : 'OT', Salary : 650000, SleeperID : 3581 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Redmond`, Position : 'C', Salary : 650000, SleeperID : 3590 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steve`, LastName: `Longa`, Position : 'OLB', Salary : 650000, SleeperID : 3563 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Leak`, Position : 'WR', Salary : 650000, SleeperID : 3584 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kivon`, LastName: `Cartwright`, Position : 'TE', Salary : 650000, SleeperID : 3596 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Milton`, Position : 'DB', Salary : 650000, SleeperID : 3589 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Peyton`, LastName: `Barber`, Position : 'RB', Salary : 650000, SleeperID : 3594 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Rhodes`, Position : 'LS', Salary : 650000, SleeperID : 3598 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kameron`, LastName: `Canaday`, Position : 'LS', Salary : 650000, SleeperID : 3603 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elie`, LastName: `Bouka`, Position : 'CB', Salary : 650000, SleeperID : 3604 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthias`, LastName: `Farley`, Position : 'DB', Salary : 650000, SleeperID : 3605 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronald`, LastName: `Zamort`, Position : 'CB', Salary : 650000, SleeperID : 3613 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Moala`, Position : 'DT', Salary : 650000, SleeperID : 3628 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommylee`, LastName: `Lewis`, Position : 'WR', Salary : 650000, SleeperID : 3614 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `Loewen`, Position : 'DE', Salary : 650000, SleeperID : 3616 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Williams-Lambert`, Position : 'WR', Salary : 650000, SleeperID : 3621 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dez`, LastName: `Stewart`, Position : 'WR', Salary : 650000, SleeperID : 3599 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vontarrius`, LastName: `Dora`, Position : 'OLB', Salary : 650000, SleeperID : 3623 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaneil`, LastName: `Jenkins`, Position : 'DE', Salary : 650000, SleeperID : 3627 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Murray`, Position : 'OL', Salary : 650000, SleeperID : 3629 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Peko`, Position : 'DT', Salary : 650000, SleeperID : 3633 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalif`, LastName: `Raymond`, Position : 'WR', Salary : 650000, SleeperID : 3634 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eddie`, LastName: `Yarbrough`, Position : 'DE', Salary : 650000, SleeperID : 3637 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryker`, LastName: `Mathews`, Position : 'OT', Salary : 650000, SleeperID : 3619 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Heurtelou`, Position : 'NT', Salary : 650000, SleeperID : 3625 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Neary`, Position : 'C', Salary : 650000, SleeperID : 3631 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Soma`, LastName: `Vainuku`, Position : 'RB', Salary : 650000, SleeperID : 3640 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ufomba`, LastName: `Kamalu`, Position : 'OLB', Salary : 650000, SleeperID : 3642 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Doug`, LastName: `Middleton`, Position : 'DB', Salary : 650000, SleeperID : 3643 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quayvon`, LastName: `Hicks`, Position : 'RB', Salary : 650000, SleeperID : 3646 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Weiser`, Position : 'TE', Salary : 650000, SleeperID : 3648 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Thomas`, Position : 'WR', Salary : 650000, SleeperID : 3652 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Bowen`, Position : 'OT', Salary : 650000, SleeperID : 3654 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Diggs`, Position : 'WR', Salary : 650000, SleeperID : 3655 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Valdez`, LastName: `Showers`, Position : 'WR', Salary : 650000, SleeperID : 3660 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cory`, LastName: `Johnson`, Position : 'NT', Salary : 650000, SleeperID : 3661 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devonte`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 3662 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Mayes`, Position : 'OT', Salary : 650000, SleeperID : 3663 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.D.`, LastName: `McKissic`, Position : 'RB', Salary : 650000, SleeperID : 3664 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sharrod`, LastName: `Neasman`, Position : 'DB', Salary : 650000, SleeperID : 3667 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Perkins`, Position : 'TE', Salary : 650000, SleeperID : 3668 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Don`, LastName: `Cherry`, Position : 'ILB', Salary : 650000, SleeperID : 3683 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Poole`, Position : 'CB', Salary : 650000, SleeperID : 3669 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Ratelle`, Position : 'FB', Salary : 650000, SleeperID : 3670 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarell`, LastName: `Broxton`, Position : 'G', Salary : 650000, SleeperID : 3675 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Coley`, Position : 'DT', Salary : 650000, SleeperID : 3676 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wil`, LastName: `Lutz`, Position : 'K', Salary : 650000, SleeperID : 3678 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Onwuasor`, Position : 'LB', Salary : 650000, SleeperID : 3680 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Brown-Dukes`, Position : 'RB', Salary : 650000, SleeperID : 3816 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Pierce`, Position : 'DT', Salary : 650000, SleeperID : 3681 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Kling`, Position : 'OT', Salary : 650000, SleeperID : 3685 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Brown`, Position : 'DE', Salary : 650000, SleeperID : 3687 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tra`, LastName: `Carson`, Position : 'RB', Salary : 650000, SleeperID : 3688 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Redmond`, Position : 'OG', Salary : 650000, SleeperID : 3692 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.P.`, LastName: `Holtz`, Position : 'TE', Salary : 650000, SleeperID : 3695 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Washington`, Position : 'DB', Salary : 650000, SleeperID : 3714 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Flores`, Position : 'C', Salary : 650000, SleeperID : 3719 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Gilbert`, Position : 'LB', Salary : 650000, SleeperID : 3720 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Brown`, Position : 'K', Salary : 650000, SleeperID : 3805 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rolan`, LastName: `Milligan`, Position : 'DB', Salary : 650000, SleeperID : 3808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Roberson`, Position : 'CB', Salary : 650000, SleeperID : 3813 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pete`, LastName: `Robertson`, Position : 'OLB', Salary : 650000, SleeperID : 3818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Awe`, Position : 'ILB', Salary : 650000, SleeperID : 3820 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Ellis`, Position : 'TE', Salary : 650000, SleeperID : 3824 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Langford`, Position : 'ILB', Salary : 650000, SleeperID : 3829 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aldrick`, LastName: `Rosas`, Position : 'K', Salary : 650000, SleeperID : 3827 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Ham`, Position : 'FB', Salary : 650000, SleeperID : 3832 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Tucker`, Position : 'WR', Salary : 650000, SleeperID : 3817 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Iosia`, LastName: `Iosia`, Position : 'DE', Salary : 650000, SleeperID : 3825 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Burbank`, Position : 'C', Salary : 650000, SleeperID : 3835 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Hamilton`, Position : 'CB', Salary : 650000, SleeperID : 3838 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Iddings`, Position : 'DE', Salary : 650000, SleeperID : 3839 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `McRae`, Position : 'CB', Salary : 650000, SleeperID : 3841 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duke`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 3846 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wynton`, LastName: `McManis`, Position : 'LB', Salary : 650000, SleeperID : 3849 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaydon`, LastName: `Mickens`, Position : 'WR', Salary : 650000, SleeperID : 3852 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terran`, LastName: `Vaughn`, Position : 'G', Salary : 650000, SleeperID : 3853 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Brown`, Position : 'CB', Salary : 650000, SleeperID : 3854 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Simmons`, Position : 'RB', Salary : 650000, SleeperID : 3859 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Edwards`, Position : 'DB', Salary : 650000, SleeperID : 3865 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Branden`, LastName: `Jackson`, Position : 'DE', Salary : 650000, SleeperID : 3867 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Richard`, Position : 'RB', Salary : 650000, SleeperID : 3868 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `M.J.`, LastName: `McFarland`, Position : 'TE', Salary : 650000, SleeperID : 3880 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nila`, LastName: `Kasitati`, Position : 'G', Salary : 650000, SleeperID : 3875 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mariel`, LastName: `Cooper`, Position : 'CB', Salary : 650000, SleeperID : 3881 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Wood`, Position : 'LS', Salary : 650000, SleeperID : 3882 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Romar`, LastName: `Morris`, Position : 'RB', Salary : 650000, SleeperID : 3883 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javien`, LastName: `Elliott`, Position : 'CB', Salary : 650000, SleeperID : 3886 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lucas`, LastName: `Patrick`, Position : 'G', Salary : 650000, SleeperID : 3888 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Coleman`, Position : 'FB', Salary : 650000, SleeperID : 3891 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reece`, LastName: `Horn`, Position : 'WR', Salary : 650000, SleeperID : 3893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Griffin`, Position : 'TE', Salary : 650000, SleeperID : 3894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marvin`, LastName: `Hall`, Position : 'WR', Salary : 650000, SleeperID : 3895 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Wallace`, Position : 'TE', Salary : 650000, SleeperID : 3908 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `James`, Position : 'G', Salary : 650000, SleeperID : 3723 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Price`, Position : 'DT', Salary : 650000, SleeperID : 3728 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tevin`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 3730 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richard`, LastName: `Mullaney`, Position : 'WR', Salary : 650000, SleeperID : 3732 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shakeel`, LastName: `Rashad`, Position : 'ILB', Salary : 650000, SleeperID : 3733 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brennan`, LastName: `Scarlett`, Position : 'LB', Salary : 650000, SleeperID : 3734 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duke`, LastName: `Thomas`, Position : 'CB', Salary : 650000, SleeperID : 3735 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arturo`, LastName: `Uzdavinis`, Position : 'OT', Salary : 650000, SleeperID : 3736 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vernon`, LastName: `Harris`, Position : 'CB', Salary : 650000, SleeperID : 3739 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Chubb`, Position : 'OLB', Salary : 650000, SleeperID : 3742 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Morgan`, LastName: `Fox`, Position : 'DE', Salary : 650000, SleeperID : 3743 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cory`, LastName: `Littleton`, Position : 'LB', Salary : 650000, SleeperID : 3747 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Winston`, LastName: `Rose`, Position : 'CB', Salary : 650000, SleeperID : 3751 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ian`, LastName: `Seau`, Position : 'DE', Salary : 650000, SleeperID : 3752 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ruben`, LastName: `Carter`, Position : 'G', Salary : 650000, SleeperID : 3755 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `DiSalvo`, Position : 'LS', Salary : 650000, SleeperID : 3756 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Farrington`, LastName: `Huguenin`, Position : 'OLB', Salary : 650000, SleeperID : 3760 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lafayette`, LastName: `Pitts`, Position : 'DB', Salary : 650000, SleeperID : 3761 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Shippen`, Position : 'WR', Salary : 650000, SleeperID : 3762 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Woodrow`, LastName: `Hamilton`, Position : 'DT', Salary : 650000, SleeperID : 3763 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Adams`, Position : 'DB', Salary : 650000, SleeperID : 3764 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donte`, LastName: `Deayon`, Position : 'CB', Salary : 650000, SleeperID : 3765 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedrick`, LastName: `Lang`, Position : 'G', Salary : 650000, SleeperID : 3767 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julien`, LastName: `Obioha`, Position : 'DE', Salary : 650000, SleeperID : 3772 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Vander Laan`, Position : 'TE', Salary : 650000, SleeperID : 3774 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dillon`, LastName: `Gordon`, Position : 'OT', Salary : 650000, SleeperID : 3777 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Greene`, Position : 'G', Salary : 650000, SleeperID : 3778 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedric`, LastName: `O'Neal`, Position : 'RB', Salary : 650000, SleeperID : 3780 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandin`, LastName: `Bryant`, Position : 'DT', Salary : 650000, SleeperID : 3785 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Anderson`, Position : 'OLB', Salary : 650000, SleeperID : 3790 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Fant`, Position : 'OT', Salary : 650000, SleeperID : 3786 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demetrius`, LastName: `Cherry`, Position : 'DE', Salary : 650000, SleeperID : 3792 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `Trubisky`, Position : 'QB', Salary : 650000, SleeperID : 3976 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Huesman`, Position : 'QB', Salary : 650000, SleeperID : 3978 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Bazzie`, Position : 'OLB', Salary : 650000, SleeperID : 3991 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Briggs`, Position : 'TE', Salary : 650000, SleeperID : 4004 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Garrett`, Position : 'DE', Salary : 650000, SleeperID : 3973 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cory`, LastName: `Carter`, Position : 'P', Salary : 650000, SleeperID : 3980 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Symmank`, Position : 'P', Salary : 650000, SleeperID : 3984 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Knox`, Position : 'OLB', Salary : 650000, SleeperID : 4001 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Boseko`, LastName: `Lokombo`, Position : 'OLB', Salary : 650000, SleeperID : 4015 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deshaun`, LastName: `Watson`, Position : 'QB', Salary : 775588.3836, SleeperID : 4017 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Adams`, Position : 'SS', Salary : 650000, SleeperID : 4077 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adoree'`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 4078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `King`, Position : 'CB', Salary : 650000, SleeperID : 4079 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zay`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 4080 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Budda`, LastName: `Baker`, Position : 'DB', Salary : 650000, SleeperID : 4081 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Samuel`, Position : 'WR', Salary : 650000, SleeperID : 4082 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sidney`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 4083 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyus`, LastName: `Bowser`, Position : 'LB', Salary : 650000, SleeperID : 4084 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Shaheen`, Position : 'TE', Salary : 650000, SleeperID : 4085 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeMarcus`, LastName: `Walker`, Position : 'DE', Salary : 650000, SleeperID : 4086 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Teez`, LastName: `Tabor`, Position : 'DB', Salary : 650000, SleeperID : 4087 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quincy`, LastName: `Wilson`, Position : 'CB', Salary : 650000, SleeperID : 4088 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gerald`, LastName: `Everett`, Position : 'TE', Salary : 650000, SleeperID : 4089 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raekwon`, LastName: `McMillan`, Position : 'LB', Salary : 650000, SleeperID : 4090 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalvin`, LastName: `Tomlinson`, Position : 'DT', Salary : 650000, SleeperID : 4092 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Maye`, Position : 'DB', Salary : 650000, SleeperID : 4093 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Forrest`, LastName: `Lamp`, Position : 'OL', Salary : 650000, SleeperID : 4094 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `McDowell`, Position : 'DT', Salary : 650000, SleeperID : 4095 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 4091 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Evans`, Position : 'SS', Salary : 650000, SleeperID : 4096 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Anderson`, Position : 'LB', Salary : 650000, SleeperID : 4097 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duke`, LastName: `Riley`, Position : 'LB', Salary : 650000, SleeperID : 4099 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Williams`, Position : 'LB', Salary : 650000, SleeperID : 4100 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Wormley`, Position : 'DT', Salary : 650000, SleeperID : 4101 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dion`, LastName: `Dawkins`, Position : 'OT', Salary : 650000, SleeperID : 4102 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daeshon`, LastName: `Hall`, Position : 'DE', Salary : 650000, SleeperID : 4103 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Moton`, Position : 'T', Salary : 650000, SleeperID : 4104 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Willis`, Position : 'DE', Salary : 650000, SleeperID : 4105 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Ogunjobi`, Position : 'DT', Salary : 650000, SleeperID : 4106 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chidobe`, LastName: `Awuzie`, Position : 'CB', Salary : 650000, SleeperID : 4107 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Jones`, Position : 'DB', Salary : 650000, SleeperID : 4109 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kareem`, LastName: `Hunt`, Position : 'RB', Salary : 650000, SleeperID : 4098 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Cunningham`, Position : 'LB', Salary : 650000, SleeperID : 4110 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tarell`, LastName: `Basham`, Position : 'DE', Salary : 650000, SleeperID : 4112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dawuane`, LastName: `Smoot`, Position : 'DE', Salary : 650000, SleeperID : 4113 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanoh`, LastName: `Kpassagnon`, Position : 'DE', Salary : 650000, SleeperID : 4114 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pat`, LastName: `Elflein`, Position : 'G', Salary : 650000, SleeperID : 4115 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlos`, LastName: `Henderson`, Position : 'WR', Salary : 650000, SleeperID : 4108 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Onta`, LastName: `Foreman`, Position : 'RB', Salary : 650000, SleeperID : 4111 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Rivers`, Position : 'DE', Salary : 650000, SleeperID : 4117 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Anzalone`, Position : 'LB', Salary : 650000, SleeperID : 4118 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Obi`, LastName: `Melifonwu`, Position : 'DB', Salary : 650000, SleeperID : 4120 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eddie`, LastName: `Vanderdoes`, Position : 'DT', Salary : 650000, SleeperID : 4121 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Feeney`, Position : 'OL', Salary : 650000, SleeperID : 4122 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ethan`, LastName: `Pocic`, Position : 'OL', Salary : 650000, SleeperID : 4123 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ahkello`, LastName: `Witherspoon`, Position : 'CB', Salary : 650000, SleeperID : 4124 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taywan`, LastName: `Taylor`, Position : 'WR', Salary : 650000, SleeperID : 4125 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fabian`, LastName: `Moreau`, Position : 'CB', Salary : 650000, SleeperID : 4126 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Beathard`, Position : 'QB', Salary : 650000, SleeperID : 4127 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 4128 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jourdan`, LastName: `Lewis`, Position : 'CB', Salary : 650000, SleeperID : 4129 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Golladay`, Position : 'WR', Salary : 650000, SleeperID : 4131 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Montravius`, LastName: `Adams`, Position : 'DT', Salary : 650000, SleeperID : 4132 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 4133 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cordrea`, LastName: `Tankersley`, Position : 'CB', Salary : 650000, SleeperID : 4134 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Hendrickson`, Position : 'DE', Salary : 650000, SleeperID : 4135 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Conner`, Position : 'RB', Salary : 1424034.526, SleeperID : 4137 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rasul`, LastName: `Douglas`, Position : 'CB', Salary : 650000, SleeperID : 4136 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Sutton`, Position : 'CB', Salary : 650000, SleeperID : 4138 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amara`, LastName: `Darboh`, Position : 'WR', Salary : 650000, SleeperID : 4139 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaq`, LastName: `Griffin`, Position : 'CB', Salary : 650000, SleeperID : 4140 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Delano`, LastName: `Hill`, Position : 'DB', Salary : 650000, SleeperID : 4141 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lenny`, LastName: `Jones`, Position : 'DE', Salary : 650000, SleeperID : 3793 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Muir`, Position : 'G', Salary : 650000, SleeperID : 3795 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Norman`, LastName: `Price`, Position : 'OT', Salary : 650000, SleeperID : 3796 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijhaa`, LastName: `Penny`, Position : 'RB', Salary : 650000, SleeperID : 3803 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Ficken`, Position : 'K', Salary : 650000, SleeperID : 3909 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Watford`, Position : 'WR', Salary : 650000, SleeperID : 3916 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendal`, LastName: `Thompson`, Position : 'WR', Salary : 650000, SleeperID : 3917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harvey`, LastName: `Binford`, Position : 'WR', Salary : 650000, SleeperID : 3920 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Truesdell`, Position : 'TE', Salary : 650000, SleeperID : 3921 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lee`, LastName: `Hightower`, Position : 'FS', Salary : 650000, SleeperID : 3925 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Williams`, Position : 'OL', Salary : 0, SleeperID : 3931 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troymaine`, LastName: `Pope`, Position : 'RB', Salary : 650000, SleeperID : 3934 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jesse`, LastName: `Schmitt`, Position : 'LS', Salary : 650000, SleeperID : 3937 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tylor`, LastName: `Harris`, Position : 'DT', Salary : 650000, SleeperID : 3941 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Ceresna`, Position : 'DE', Salary : 650000, SleeperID : 3946 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Powell`, Position : 'SS', Salary : 650000, SleeperID : 3950 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Metz`, Position : 'DE', Salary : 650000, SleeperID : 3954 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zamir`, LastName: `Carlis`, Position : 'DT', Salary : 650000, SleeperID : 3938 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Sims`, Position : 'QB', Salary : 650000, SleeperID : 3957 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Bean`, Position : 'DE', Salary : 650000, SleeperID : 3958 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leonard`, LastName: `Fournette`, Position : 'RB', Salary : 650000, SleeperID : 3969 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonnu`, LastName: `Smith`, Position : 'TE', Salary : 650000, SleeperID : 4144 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vince`, LastName: `Biegel`, Position : 'LB', Salary : 650000, SleeperID : 4145 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Tevi`, Position : 'OT', Salary : 650000, SleeperID : 4272 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dede`, LastName: `Westbrook`, Position : 'WR', Salary : 650000, SleeperID : 4146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Samaje`, LastName: `Perine`, Position : 'RB', Salary : 650000, SleeperID : 4147 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carl`, LastName: `Lawson`, Position : 'DE', Salary : 650000, SleeperID : 4148 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamaal`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 4149 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wayne`, LastName: `Gallman`, Position : 'RB', Salary : 650000, SleeperID : 4150 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Treyvon`, LastName: `Hester`, Position : 'DT', Salary : 650000, SleeperID : 4264 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Qualls`, Position : 'DT', Salary : 650000, SleeperID : 4268 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keion`, LastName: `Adams`, Position : 'OLB', Salary : 650000, SleeperID : 4269 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colin`, LastName: `Holba`, Position : 'LS', Salary : 650000, SleeperID : 4270 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Rochell`, Position : 'DE', Salary : 650000, SleeperID : 4271 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Carson`, Position : 'RB', Salary : 650000, SleeperID : 4273 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Moore`, Position : 'WR', Salary : 650000, SleeperID : 4274 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Senior`, Position : 'OT', Salary : 650000, SleeperID : 4275 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Colbert`, Position : 'DB', Salary : 650000, SleeperID : 4277 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shalom`, LastName: `Luani`, Position : 'SS', Salary : 650000, SleeperID : 4266 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 4278 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pita`, LastName: `Taumoepenu`, Position : 'LB', Salary : 650000, SleeperID : 4279 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stevie`, LastName: `Tu'ikolovatu`, Position : 'NT', Salary : 650000, SleeperID : 4280 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Levin`, Position : 'G', Salary : 650000, SleeperID : 4282 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalfani`, LastName: `Muhammad`, Position : 'RB', Salary : 650000, SleeperID : 4283 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brad`, LastName: `Seaton`, Position : 'OT', Salary : 650000, SleeperID : 4284 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 4285 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Harvey-Clemons`, Position : 'LB', Salary : 650000, SleeperID : 4286 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarnor`, LastName: `Jones`, Position : 'ILB', Salary : 650000, SleeperID : 4297 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Roullier`, Position : 'C', Salary : 650000, SleeperID : 4288 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ishmael`, LastName: `Zamora`, Position : 'WR', Salary : 650000, SleeperID : 4289 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Averill`, Position : 'G', Salary : 650000, SleeperID : 4291 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcelis`, LastName: `Branch`, Position : 'SS', Salary : 650000, SleeperID : 4292 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Brunskill`, Position : 'OL', Salary : 650000, SleeperID : 4293 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deante`, LastName: `Burton`, Position : 'CB', Salary : 650000, SleeperID : 4294 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wil`, LastName: `Freeman`, Position : 'OT', Salary : 650000, SleeperID : 4295 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Keizur`, Position : 'C', Salary : 650000, SleeperID : 4298 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andreas`, LastName: `Knappe`, Position : 'OT', Salary : 650000, SleeperID : 4299 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Leff`, Position : 'OT', Salary : 650000, SleeperID : 4300 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Magee`, Position : 'WR', Salary : 650000, SleeperID : 4301 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quincy`, LastName: `Mauger`, Position : 'SS', Salary : 650000, SleeperID : 4302 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Moore`, Position : 'FS', Salary : 650000, SleeperID : 4303 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Odom`, Position : 'OLB', Salary : 650000, SleeperID : 4304 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Renew`, Position : 'RB', Salary : 650000, SleeperID : 4305 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Reynolds`, Position : 'CB', Salary : 650000, SleeperID : 4306 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deron`, LastName: `Washington`, Position : 'FS', Salary : 650000, SleeperID : 4308 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scott`, LastName: `Orndoff`, Position : 'TE', Salary : 650000, SleeperID : 4309 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrish`, LastName: `Webb`, Position : 'FS', Salary : 650000, SleeperID : 4310 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Grace`, Position : 'LB', Salary : 650000, SleeperID : 4311 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Eldrenkamp`, Position : 'OL', Salary : 650000, SleeperID : 4313 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Mundt`, Position : 'TE', Salary : 650000, SleeperID : 4314 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Casey`, LastName: `Sayles`, Position : 'DE', Salary : 650000, SleeperID : 4315 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sae`, LastName: `Tautu`, Position : 'OLB', Salary : 650000, SleeperID : 4316 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Pascal`, Position : 'WR', Salary : 650000, SleeperID : 4319 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Quick`, Position : 'WR', Salary : 650000, SleeperID : 4320 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalton`, LastName: `Crossan`, Position : 'RB', Salary : 650000, SleeperID : 4322 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Daniels`, Position : 'TE', Salary : 650000, SleeperID : 4323 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jehu`, LastName: `Chesson`, Position : 'WR', Salary : 650000, SleeperID : 4169 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Samson`, LastName: `Ebukam`, Position : 'DE', Salary : 650000, SleeperID : 4170 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Reynolds`, Position : 'WR', Salary : 650000, SleeperID : 4171 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Gedeon`, Position : 'LB', Salary : 650000, SleeperID : 4172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Davis`, Position : 'ILB', Salary : 650000, SleeperID : 4312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaleel`, LastName: `Johnson`, Position : 'DT', Salary : 650000, SleeperID : 4173 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deatrich`, LastName: `Wise`, Position : 'DE', Salary : 650000, SleeperID : 4174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Hansen`, Position : 'WR', Salary : 650000, SleeperID : 4175 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Sharpe`, Position : 'T', Salary : 650000, SleeperID : 4176 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mack`, LastName: `Hollins`, Position : 'WR', Salary : 650000, SleeperID : 4177 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rayshawn`, LastName: `Jenkins`, Position : 'DB', Salary : 650000, SleeperID : 4180 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tedric`, LastName: `Thompson`, Position : 'DB', Salary : 650000, SleeperID : 4181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Montae`, LastName: `Nicholson`, Position : 'SS', Salary : 650000, SleeperID : 4182 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Peterman`, Position : 'QB', Salary : 650000, SleeperID : 4183 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bucky`, LastName: `Hodges`, Position : 'TE', Salary : 650000, SleeperID : 4184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Holden`, Position : 'T', Salary : 650000, SleeperID : 4185 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Logan`, Position : 'RB', Salary : 650000, SleeperID : 4186 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Hill`, Position : 'RB', Salary : 650000, SleeperID : 4187 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Muller`, Position : 'G', Salary : 650000, SleeperID : 4329 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Dobbs`, Position : 'QB', Salary : 650000, SleeperID : 4179 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damontae`, LastName: `Kazee`, Position : 'DB', Salary : 650000, SleeperID : 4188 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Freddie`, LastName: `Stevenson`, Position : 'FB', Salary : 650000, SleeperID : 4557 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.J.`, LastName: `Bello`, Position : 'LB', Salary : 650000, SleeperID : 4558 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ladell`, LastName: `Fleming`, Position : 'LB', Salary : 650000, SleeperID : 4559 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.D.`, LastName: `Harmon`, Position : 'CB', Salary : 650000, SleeperID : 4560 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Griffey`, Position : 'WR', Salary : 650000, SleeperID : 4324 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Hennessy`, Position : 'LS', Salary : 650000, SleeperID : 4325 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bug`, LastName: `Howard`, Position : 'TE', Salary : 650000, SleeperID : 4326 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colin`, LastName: `Jeter`, Position : 'TE', Salary : 650000, SleeperID : 4327 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerome`, LastName: `Lane`, Position : 'WR', Salary : 650000, SleeperID : 4328 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JoJo`, LastName: `Natson`, Position : 'WR', Salary : 650000, SleeperID : 4330 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rigoberto`, LastName: `Sanchez`, Position : 'P', Salary : 650000, SleeperID : 4331 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jhaustin`, LastName: `Thomas`, Position : 'DT', Salary : 650000, SleeperID : 4333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerry`, LastName: `Ugokwe`, Position : 'OT', Salary : 650000, SleeperID : 4334 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `P.J.`, LastName: `Walker`, Position : 'QB', Salary : 650000, SleeperID : 4335 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Brown`, Position : 'DE', Salary : 650000, SleeperID : 4336 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alvin`, LastName: `Hill`, Position : 'CB', Salary : 650000, SleeperID : 4561 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Theaker`, Position : 'G', Salary : 650000, SleeperID : 4575 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lucas`, LastName: `Wacha`, Position : 'OLB', Salary : 650000, SleeperID : 4577 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erik`, LastName: `Austell`, Position : 'G', Salary : 650000, SleeperID : 4578 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deon`, LastName: `Hollins`, Position : 'OLB', Salary : 650000, SleeperID : 4582 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Hunt`, Position : 'G', Salary : 650000, SleeperID : 4583 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Nash`, Position : 'WR', Salary : 650000, SleeperID : 4585 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontrell`, LastName: `Nelson`, Position : 'CB', Salary : 650000, SleeperID : 4586 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Sloter`, Position : 'QB', Salary : 650000, SleeperID : 4588 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shakir`, LastName: `Soto`, Position : 'DE', Salary : 650000, SleeperID : 4589 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Orion`, LastName: `Stewart`, Position : 'SS', Salary : 650000, SleeperID : 4590 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Wilkinson`, Position : 'OL', Salary : 650000, SleeperID : 4592 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Barnes`, Position : 'TE', Salary : 650000, SleeperID : 4593 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Barrett`, Position : 'DE', Salary : 650000, SleeperID : 4594 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontez`, LastName: `Ford`, Position : 'WR', Salary : 650000, SleeperID : 4595 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leo`, LastName: `Koloamatangi`, Position : 'OL', Salary : 650000, SleeperID : 4597 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Storm`, LastName: `Norton`, Position : 'T', Salary : 650000, SleeperID : 4598 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Barnett`, Position : 'FS', Salary : 650000, SleeperID : 4580 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Rector`, Position : 'WR', Salary : 650000, SleeperID : 4599 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noel`, LastName: `Thomas`, Position : 'WR', Salary : 650000, SleeperID : 4600 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Thornton`, Position : 'CB', Salary : 650000, SleeperID : 4601 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Tonyan`, Position : 'TE', Salary : 650000, SleeperID : 4602 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Valoaga`, Position : 'DE', Salary : 650000, SleeperID : 4603 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eli`, LastName: `Ankou`, Position : 'DT', Salary : 650000, SleeperID : 4604 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Baylis`, Position : 'TE', Salary : 650000, SleeperID : 4605 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Cole`, Position : 'LB', Salary : 650000, SleeperID : 4606 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Conque`, Position : 'QB', Salary : 650000, SleeperID : 4607 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Daniel`, Position : 'DE', Salary : 650000, SleeperID : 4608 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Godin`, Position : 'DE', Salary : 650000, SleeperID : 4609 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deante'`, LastName: `Gray`, Position : 'WR', Salary : 650000, SleeperID : 4610 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Karter`, LastName: `Schult`, Position : 'DE', Salary : 650000, SleeperID : 4566 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Channing`, LastName: `Stribling`, Position : 'CB', Salary : 650000, SleeperID : 4567 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Lynn`, Position : 'OLB', Salary : 650000, SleeperID : 4739 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 4569 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kennan`, LastName: `Gilchrist`, Position : 'OLB', Salary : 650000, SleeperID : 4570 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Jarwin`, Position : 'TE', Salary : 650000, SleeperID : 4571 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cooper`, LastName: `Rush`, Position : 'QB', Salary : 650000, SleeperID : 4574 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ezekiel`, LastName: `Bigger`, Position : 'OLB', Salary : 650000, SleeperID : 4734 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Jenkins`, Position : 'DE', Salary : 650000, SleeperID : 4735 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Nzeocha`, Position : 'OLB', Salary : 650000, SleeperID : 4736 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Woody`, LastName: `Baron`, Position : 'DT', Salary : 650000, SleeperID : 4568 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Simone`, Position : 'SS', Salary : 650000, SleeperID : 4737 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colby`, LastName: `Pearson`, Position : 'WR', Salary : 650000, SleeperID : 4738 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Likely`, Position : 'CB', Salary : 650000, SleeperID : 4740 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Arnold`, Position : 'TE', Salary : 650000, SleeperID : 4741 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Spelman`, Position : 'G', Salary : 650000, SleeperID : 4742 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Gray`, Position : 'CB', Salary : 650000, SleeperID : 4743 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 4572 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Brantley`, Position : 'DE', Salary : 650000, SleeperID : 4231 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zane`, LastName: `Gonzalez`, Position : 'K', Salary : 650000, SleeperID : 4233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Carrell`, Position : 'DT', Salary : 650000, SleeperID : 4235 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joey`, LastName: `Ivie`, Position : 'DT', Salary : 650000, SleeperID : 4236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquez`, LastName: `White`, Position : 'CB', Salary : 650000, SleeperID : 4237 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Woods`, Position : 'DB', Salary : 650000, SleeperID : 4238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `O'Connor`, Position : 'DE', Salary : 650000, SleeperID : 4241 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Ledbetter`, Position : 'DE', Salary : 650000, SleeperID : 4240 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kofi`, LastName: `Amichia`, Position : 'G', Salary : 650000, SleeperID : 4242 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Fuller`, Position : 'OL', Salary : 650000, SleeperID : 4245 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanzel`, LastName: `Smart`, Position : 'DT', Salary : 650000, SleeperID : 4251 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Ford`, Position : 'WR', Salary : 650000, SleeperID : 4252 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vincent`, LastName: `Taylor`, Position : 'DT', Salary : 650000, SleeperID : 4253 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Lee`, Position : 'LB', Salary : 650000, SleeperID : 4255 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ifeadi`, LastName: `Odenigbo`, Position : 'DE', Salary : 650000, SleeperID : 4256 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Tocho`, Position : 'FS', Salary : 650000, SleeperID : 4257 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Conor`, LastName: `McDermott`, Position : 'OL', Salary : 650000, SleeperID : 4258 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Al-Quadin`, LastName: `Muhammad`, Position : 'DE', Salary : 650000, SleeperID : 4259 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `McGuire`, Position : 'RB', Salary : 650000, SleeperID : 4263 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Mabin`, Position : 'CB', Salary : 650000, SleeperID : 4476 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paul`, LastName: `Magloire`, Position : 'ILB', Salary : 650000, SleeperID : 4477 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Moxey`, Position : 'CB', Salary : 650000, SleeperID : 4478 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Sperbeck`, Position : 'WR', Salary : 650000, SleeperID : 4479 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobo`, LastName: `Wilson`, Position : 'WR', Salary : 650000, SleeperID : 4480 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabriel`, LastName: `Mass`, Position : 'DT', Salary : 650000, SleeperID : 4482 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Letuligasenoa`, Position : 'LB', Salary : 650000, SleeperID : 4483 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raysean`, LastName: `Pringle`, Position : 'SS', Salary : 650000, SleeperID : 4484 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Stanback`, Position : 'RB', Salary : 650000, SleeperID : 4485 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Talley`, Position : 'OLB', Salary : 650000, SleeperID : 4486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Taylor`, Position : 'S', Salary : 650000, SleeperID : 4487 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gehrig`, LastName: `Dieter`, Position : 'WR', Salary : 650000, SleeperID : 4489 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wyatt`, LastName: `Houston`, Position : 'TE', Salary : 650000, SleeperID : 4490 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Kemp`, Position : 'WR', Salary : 650000, SleeperID : 4491 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ashton`, LastName: `Lampkin`, Position : 'CB', Salary : 650000, SleeperID : 4492 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Rogers`, Position : 'FB', Salary : 650000, SleeperID : 4250 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Lauderdale`, Position : 'OT', Salary : 650000, SleeperID : 4500 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Chappell`, Position : 'FS', Salary : 650000, SleeperID : 4488 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alonzo`, LastName: `Moore`, Position : 'WR', Salary : 650000, SleeperID : 4494 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Sterns`, Position : 'FS', Salary : 650000, SleeperID : 4496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Stevens`, Position : 'WR', Salary : 650000, SleeperID : 4497 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aarion`, LastName: `Penton`, Position : 'CB', Salary : 650000, SleeperID : 4498 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Dominguez`, Position : 'LS', Salary : 650000, SleeperID : 4499 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabe`, LastName: `Marks`, Position : 'WR', Salary : 650000, SleeperID : 4508 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devaroe`, LastName: `Lawrence`, Position : 'DT', Salary : 650000, SleeperID : 4501 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arthur`, LastName: `Maulet`, Position : 'CB', Salary : 650000, SleeperID : 4503 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.R.`, LastName: `Nelson`, Position : 'CB', Salary : 650000, SleeperID : 4495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Bordelon`, Position : 'G', Salary : 650000, SleeperID : 4506 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Tom`, Position : 'C', Salary : 650000, SleeperID : 4505 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Braden`, Position : 'G', Salary : 650000, SleeperID : 4507 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Robinson-Woodgett`, Position : 'RB', Salary : 650000, SleeperID : 4504 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Casher`, Position : 'OLB', Salary : 650000, SleeperID : 4509 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joby`, LastName: `Saint Fleur`, Position : 'DE', Salary : 650000, SleeperID : 4397 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Smith`, Position : 'T', Salary : 650000, SleeperID : 4399 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maurice`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 4400 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damore'ea`, LastName: `Stringfellow`, Position : 'WR', Salary : 650000, SleeperID : 4401 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tashawn`, LastName: `Bower`, Position : 'DE', Salary : 650000, SleeperID : 4402 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Bradley`, Position : 'NT', Salary : 650000, SleeperID : 4403 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aviante`, LastName: `Collins`, Position : 'T', Salary : 650000, SleeperID : 4404 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Kidder`, Position : 'DE', Salary : 650000, SleeperID : 4405 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wes`, LastName: `Lunt`, Position : 'QB', Salary : 650000, SleeperID : 4406 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `McCaskill`, Position : 'DE', Salary : 650000, SleeperID : 4407 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `Newby`, Position : 'RB', Salary : 650000, SleeperID : 4408 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josiah`, LastName: `Price`, Position : 'TE', Salary : 650000, SleeperID : 4409 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Horace`, LastName: `Richardson`, Position : 'CB', Salary : 650000, SleeperID : 4410 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `R.J.`, LastName: `Shelton`, Position : 'WR', Salary : 650000, SleeperID : 4411 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaan`, LastName: `Washington`, Position : 'ILB', Salary : 650000, SleeperID : 4412 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Wilson`, Position : 'LB', Salary : 650000, SleeperID : 4413 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Augusta`, Position : 'NT', Salary : 650000, SleeperID : 4414 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Butler`, Position : 'DT', Salary : 650000, SleeperID : 4415 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Carr`, Position : 'WR', Salary : 650000, SleeperID : 4416 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brooks`, LastName: `Ellis`, Position : 'OLB', Salary : 650000, SleeperID : 4419 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Hollister`, Position : 'WR', Salary : 650000, SleeperID : 4420 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Hollister`, Position : 'TE', Salary : 650000, SleeperID : 4421 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Jelks`, Position : 'OT', Salary : 650000, SleeperID : 4422 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Jones`, Position : 'FS', Salary : 650000, SleeperID : 4423 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `King`, Position : 'G', Salary : 650000, SleeperID : 4425 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harvey`, LastName: `Langi`, Position : 'LB', Salary : 650000, SleeperID : 4426 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Moore`, Position : 'CB', Salary : 650000, SleeperID : 4427 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Rich`, Position : 'OT', Salary : 650000, SleeperID : 4428 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Thompson`, Position : 'SS', Salary : 650000, SleeperID : 4430 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damarius`, LastName: `Travis`, Position : 'SS', Salary : 650000, SleeperID : 4431 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Vereen`, Position : 'DE', Salary : 650000, SleeperID : 4432 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brisly`, LastName: `Estime`, Position : 'WR', Salary : 650000, SleeperID : 4434 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Firkser`, Position : 'TE', Salary : 650000, SleeperID : 4435 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Gamble`, Position : 'DE', Salary : 650000, SleeperID : 4436 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Harris`, Position : 'FB', Salary : 650000, SleeperID : 4437 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dwayne`, LastName: `Thomas`, Position : 'CB', Salary : 650000, SleeperID : 4429 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chauncey`, LastName: `Briggs`, Position : 'OT', Salary : 650000, SleeperID : 4441 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javarius`, LastName: `Leamon`, Position : 'OT', Salary : 650000, SleeperID : 4438 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paul`, LastName: `Boyette`, Position : 'DE', Salary : 650000, SleeperID : 4440 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rickey`, LastName: `Jefferson`, Position : 'SS', Salary : 650000, SleeperID : 4447 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pharaoh`, LastName: `Brown`, Position : 'TE', Salary : 650000, SleeperID : 4443 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Breon`, LastName: `Borders`, Position : 'CB', Salary : 650000, SleeperID : 4439 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Cioffi`, Position : 'CB', Salary : 650000, SleeperID : 4444 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keon`, LastName: `Hatcher`, Position : 'WR', Salary : 650000, SleeperID : 4445 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Humes`, Position : 'SS', Salary : 650000, SleeperID : 4446 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fadol`, LastName: `Brown`, Position : 'DE', Salary : 650000, SleeperID : 4442 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K.D.`, LastName: `Cannon`, Position : 'WR', Salary : 650000, SleeperID : 4456 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.P.`, LastName: `Flynn`, Position : 'G', Salary : 650000, SleeperID : 4457 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Franklin`, Position : 'CB', Salary : 650000, SleeperID : 4458 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Mullens`, Position : 'QB', Salary : 650000, SleeperID : 4464 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donavin`, LastName: `Newsom`, Position : 'ILB', Salary : 650000, SleeperID : 4465 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noble`, LastName: `Nwachukwu`, Position : 'DT', Salary : 650000, SleeperID : 4466 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antony`, LastName: `Auclair`, Position : 'TE', Salary : 650000, SleeperID : 4468 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richie`, LastName: `Brown`, Position : 'OLB', Salary : 650000, SleeperID : 4469 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reese`, LastName: `Fleming`, Position : 'CB', Salary : 650000, SleeperID : 4471 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Gardner`, Position : 'OT', Salary : 650000, SleeperID : 4472 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Gray`, Position : 'FS', Salary : 650000, SleeperID : 4473 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Korren`, LastName: `Kirven`, Position : 'OT', Salary : 650000, SleeperID : 4474 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sefo`, LastName: `Liufau`, Position : 'QB', Salary : 650000, SleeperID : 4475 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Butt`, Position : 'TE', Salary : 650000, SleeperID : 4151 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marlon`, LastName: `Mack`, Position : 'RB', Salary : 650000, SleeperID : 4152 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Leggett`, Position : 'TE', Salary : 650000, SleeperID : 4153 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Harlow`, Position : 'OL', Salary : 650000, SleeperID : 4155 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tarik`, LastName: `Cohen`, Position : 'RB', Salary : 650000, SleeperID : 4157 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eddie`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 4158 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Glasgow`, Position : 'DT', Salary : 650000, SleeperID : 4159 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Malone`, Position : 'WR', Salary : 650000, SleeperID : 4160 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Howard`, LastName: `Wilson`, Position : 'CB', Salary : 650000, SleeperID : 4161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Switzer`, Position : 'WR', Salary : 650000, SleeperID : 4162 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Reeves-Maybin`, Position : 'LB', Salary : 650000, SleeperID : 4163 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julien`, LastName: `Davenport`, Position : 'T', Salary : 650000, SleeperID : 4165 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Banner`, Position : 'T', Salary : 650000, SleeperID : 4167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlos`, LastName: `Watkins`, Position : 'DE', Salary : 650000, SleeperID : 4166 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kareem`, LastName: `Are`, Position : 'G', Salary : 650000, SleeperID : 4760 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Marcus`, Position : 'LB', Salary : 650000, SleeperID : 4562 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `McNamara`, Position : 'TE', Salary : 650000, SleeperID : 4563 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kai`, LastName: `Nacua`, Position : 'DB', Salary : 650000, SleeperID : 4564 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenneth`, LastName: `Olugbode`, Position : 'ILB', Salary : 650000, SleeperID : 4565 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Manny`, LastName: `Abad`, Position : 'CB', Salary : 650000, SleeperID : 4751 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fred`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 4752 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charmeachealle`, LastName: `Moore`, Position : 'LB', Salary : 650000, SleeperID : 4755 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grover`, LastName: `Stewart`, Position : 'DT', Salary : 650000, SleeperID : 4168 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brady`, LastName: `Sheldon`, Position : 'LB', Salary : 650000, SleeperID : 4761 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Zaruba`, Position : 'TE', Salary : 650000, SleeperID : 4768 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Van Roten`, Position : 'OG', Salary : 650000, SleeperID : 4771 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Dunn`, Position : 'OG', Salary : 650000, SleeperID : 4753 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dejaun`, LastName: `Butler`, Position : 'CB', Salary : 650000, SleeperID : 4772 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `Collins`, Position : 'C', Salary : 650000, SleeperID : 4773 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Mard`, LastName: `Llorens`, Position : 'RB', Salary : 650000, SleeperID : 4774 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Olson`, Position : 'QB', Salary : 650000, SleeperID : 4775 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Chavon`, LastName: `Hayes`, Position : 'RB', Salary : 650000, SleeperID : 4777 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bra'Lon`, LastName: `Cherry`, Position : 'WR', Salary : 650000, SleeperID : 4779 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Folarin`, LastName: `Orimolade`, Position : 'OLB', Salary : 650000, SleeperID : 4780 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Golden`, Position : 'SS', Salary : 650000, SleeperID : 4781 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Simonich`, Position : 'C', Salary : 650000, SleeperID : 4782 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noor`, LastName: `Davis`, Position : 'OLB', Salary : 650000, SleeperID : 4783 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kermit`, LastName: `Whitfield`, Position : 'WR', Salary : 650000, SleeperID : 4785 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jhajuan`, LastName: `Seales`, Position : 'WR', Salary : 650000, SleeperID : 4786 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Westerkamp`, Position : 'WR', Salary : 650000, SleeperID : 4787 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Barrett`, LastName: `Burns`, Position : 'TE', Salary : 650000, SleeperID : 4744 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Reid`, Position : 'CB', Salary : 650000, SleeperID : 4746 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stevie`, LastName: `Donatell`, Position : 'TE', Salary : 650000, SleeperID : 4747 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Posey`, Position : 'WR', Salary : 650000, SleeperID : 4749 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lance`, LastName: `Lenoir`, Position : 'WR', Salary : 650000, SleeperID : 4750 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daquan`, LastName: `Holmes`, Position : 'FS', Salary : 650000, SleeperID : 4745 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Hardee`, Position : 'CB', Salary : 650000, SleeperID : 4611 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaq`, LastName: `Hill`, Position : 'WR', Salary : 650000, SleeperID : 4613 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dayon`, LastName: `Pratt`, Position : 'OLB', Salary : 650000, SleeperID : 4615 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kacy`, LastName: `Rodgers`, Position : 'SS', Salary : 650000, SleeperID : 4906 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steve`, LastName: `Keim`, Position : '0', Salary : 650000, SleeperID : 4908 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quincy`, LastName: `McDuffie`, Position : 'WR', Salary : 650000, SleeperID : 4910 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinton`, LastName: `Schooley`, Position : 'C', Salary : 650000, SleeperID : 4911 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lake`, LastName: `Dawson`, Position : '0', Salary : 650000, SleeperID : 4905 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Hughes`, Position : 'P', Salary : 650000, SleeperID : 4912 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Scantling`, Position : 'WR', Salary : 650000, SleeperID : 4922 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aiulua`, LastName: `Fanene`, Position : 'DT', Salary : 650000, SleeperID : 4913 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Coe`, Position : 'CB', Salary : 650000, SleeperID : 4915 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Royce`, LastName: `LaFrance`, Position : 'DE', Salary : 650000, SleeperID : 4916 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daryl`, LastName: `Virgies`, Position : 'RB', Salary : 650000, SleeperID : 4917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brock`, LastName: `Miller`, Position : 'P', Salary : 650000, SleeperID : 4918 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Latham`, Position : 'LS', Salary : 650000, SleeperID : 4919 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dax`, LastName: `Dellenbach`, Position : 'LS', Salary : 650000, SleeperID : 4920 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Fortunato`, Position : 'LS', Salary : 650000, SleeperID : 4921 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Maurice`, Position : 'DT', Salary : 650000, SleeperID : 4695 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrod`, LastName: `Harper`, Position : 'SS', Salary : 650000, SleeperID : 4694 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalid`, LastName: `Abdullah`, Position : 'RB', Salary : 650000, SleeperID : 4697 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Edmunds`, Position : 'RB', Salary : 650000, SleeperID : 4696 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Robinson`, Position : 'FS', Salary : 650000, SleeperID : 4698 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Snead`, Position : 'WR', Salary : 650000, SleeperID : 4699 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `McWilson`, Position : 'FS', Salary : 650000, SleeperID : 4700 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAngelo`, LastName: `Brown`, Position : 'NT', Salary : 650000, SleeperID : 4703 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Victor`, LastName: `Salako`, Position : 'T', Salary : 650000, SleeperID : 4701 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Green`, Position : 'CB', Salary : 650000, SleeperID : 4705 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Ferguson`, Position : 'QB', Salary : 650000, SleeperID : 4704 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denzel`, LastName: `Johnson`, Position : 'SS', Salary : 650000, SleeperID : 4706 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KeVonn`, LastName: `Mabon`, Position : 'WR', Salary : 650000, SleeperID : 4708 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Moore`, Position : 'OT', Salary : 650000, SleeperID : 4709 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gio`, LastName: `Pascascio`, Position : 'WR', Salary : 650000, SleeperID : 4710 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonah`, LastName: `Pirsig`, Position : 'OT', Salary : 650000, SleeperID : 4711 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Ragin`, Position : 'ILB', Salary : 650000, SleeperID : 4712 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Robbins`, Position : 'DE', Salary : 650000, SleeperID : 4713 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tevin`, LastName: `Homer`, Position : 'CB', Salary : 650000, SleeperID : 4714 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darnell`, LastName: `Leslie`, Position : 'DE', Salary : 650000, SleeperID : 4715 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Boykins`, Position : 'CB', Salary : 650000, SleeperID : 4702 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Skipper`, Position : 'T', Salary : 650000, SleeperID : 4716 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 4717 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terry`, LastName: `Swanson`, Position : 'RB', Salary : 650000, SleeperID : 5201 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaryd`, LastName: `Jones-Smith`, Position : 'T', Salary : 650000, SleeperID : 5202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K.J.`, LastName: `Malone`, Position : 'T', Salary : 650000, SleeperID : 5203 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Coyle`, Position : 'T', Salary : 650000, SleeperID : 5204 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kingsley`, LastName: `Opara`, Position : 'DL', Salary : 650000, SleeperID : 5205 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Daniel`, Position : 'P', Salary : 650000, SleeperID : 5206 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marchie`, LastName: `Murdock`, Position : 'WR', Salary : 650000, SleeperID : 5207 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Boone`, Position : 'RB', Salary : 650000, SleeperID : 5209 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Korey`, LastName: `Robertson`, Position : 'WR', Salary : 650000, SleeperID : 5210 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Wieneke`, Position : 'WR', Salary : 650000, SleeperID : 5211 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hercules`, LastName: `Mata'afa`, Position : 'DE', Salary : 650000, SleeperID : 5212 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garret`, LastName: `Dooley`, Position : 'OLB', Salary : 650000, SleeperID : 5213 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `McCray`, Position : 'OLB', Salary : 650000, SleeperID : 5221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Holton`, LastName: `Hill`, Position : 'CB', Salary : 650000, SleeperID : 5214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tray`, LastName: `Matthews`, Position : 'FS', Salary : 650000, SleeperID : 5215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Reese`, Position : 'TE', Salary : 650000, SleeperID : 5217 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demone`, LastName: `Harris`, Position : 'DE', Salary : 650000, SleeperID : 5218 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Simmons`, Position : 'DL', Salary : 650000, SleeperID : 5219 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Godwin`, LastName: `Igwebuike`, Position : 'RB', Salary : 650000, SleeperID : 5220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 5222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Scott`, Position : 'WR', Salary : 650000, SleeperID : 5223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taj`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 5224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcell`, LastName: `Frazier`, Position : 'DE', Salary : 650000, SleeperID : 5225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Carew`, Position : 'LS', Salary : 650000, SleeperID : 5227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.T.`, LastName: `Barrett`, Position : 'QB', Salary : 650000, SleeperID : 5228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henre'`, LastName: `Toliver`, Position : 'DB', Salary : 650000, SleeperID : 5229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Badgley`, Position : 'K', Salary : 650000, SleeperID : 5230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Hunt`, Position : 'TE', Salary : 650000, SleeperID : 5231 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Baugh`, Position : 'TE', Salary : 650000, SleeperID : 5233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Officer`, Position : 'C', Salary : 650000, SleeperID : 5234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dorren`, LastName: `Miller`, Position : 'WR', Salary : 650000, SleeperID : 5359 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Allen`, Position : 'LB', Salary : 650000, SleeperID : 5360 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avonte`, LastName: `Maddox`, Position : 'CB', Salary : 650000, SleeperID : 5361 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dare`, LastName: `Ogunbowale`, Position : 'RB', Salary : 650000, SleeperID : 4718 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shakeir`, LastName: `Ryan`, Position : 'WR', Salary : 650000, SleeperID : 4721 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Freddie`, LastName: `Tagaloa`, Position : 'OT', Salary : 650000, SleeperID : 4722 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Poona`, LastName: `Ford`, Position : 'DT', Salary : 650000, SleeperID : 5226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Galambos`, Position : 'ILB', Salary : 650000, SleeperID : 4724 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phazahn`, LastName: `Odom`, Position : 'TE', Salary : 650000, SleeperID : 4725 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nico`, LastName: `Marley`, Position : 'LB', Salary : 650000, SleeperID : 4726 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Izaah`, LastName: `Lunsford`, Position : 'DE', Salary : 650000, SleeperID : 4382 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Pankey`, Position : 'T', Salary : 650000, SleeperID : 4383 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Peck`, Position : 'TE', Salary : 650000, SleeperID : 4384 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalif`, LastName: `Phillips`, Position : 'RB', Salary : 650000, SleeperID : 4385 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Kozan`, Position : 'G', Salary : 650000, SleeperID : 4388 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Haack`, Position : 'P', Salary : 650000, SleeperID : 4390 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Hope`, Position : 'CB', Salary : 650000, SleeperID : 4391 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Lewis`, Position : 'WR', Salary : 650000, SleeperID : 4392 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Malveaux`, Position : 'DE', Salary : 650000, SleeperID : 4393 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Praise`, LastName: `Martin-Oguike`, Position : 'OLB', Salary : 650000, SleeperID : 4394 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Torry`, LastName: `McTyer`, Position : 'CB', Salary : 650000, SleeperID : 4395 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Morgan`, Position : 'WR', Salary : 650000, SleeperID : 4396 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Gesicki`, Position : 'TE', Salary : 650000, SleeperID : 4993 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalen`, LastName: `Ballage`, Position : 'RB', Salary : 650000, SleeperID : 4994 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ian`, LastName: `Thomas`, Position : 'TE', Salary : 650000, SleeperID : 4995 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Lauletta`, Position : 'QB', Salary : 650000, SleeperID : 4996 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Walton`, Position : 'RB', Salary : 650000, SleeperID : 4997 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaeSean`, LastName: `Hamilton`, Position : 'WR', Salary : 650000, SleeperID : 4998 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Sweat`, Position : 'DE', Salary : 650000, SleeperID : 4999 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalton`, LastName: `Schultz`, Position : 'TE', Salary : 974069.0728, SleeperID : 5001 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maurice`, LastName: `Hurst`, Position : 'DT', Salary : 650000, SleeperID : 5002 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaquem`, LastName: `Griffin`, Position : 'LB', Salary : 650000, SleeperID : 5003 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Sims`, Position : 'WR', Salary : 650000, SleeperID : 5432 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ranthony`, LastName: `Texada`, Position : 'CB', Salary : 650000, SleeperID : 5433 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quin`, LastName: `Blanding`, Position : 'FS', Salary : 650000, SleeperID : 5434 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tarvarus`, LastName: `McFadden`, Position : 'CB', Salary : 650000, SleeperID : 5435 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emmanuel`, LastName: `Moseley`, Position : 'CB', Salary : 650000, SleeperID : 5436 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Najee`, LastName: `Toran`, Position : 'G', Salary : 650000, SleeperID : 5438 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Wells`, Position : 'TE', Salary : 650000, SleeperID : 5235 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Lewis`, Position : 'WR', Salary : 650000, SleeperID : 5236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Ramesh`, Position : 'FB', Salary : 650000, SleeperID : 5238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Edmonds`, Position : 'RB', Salary : 650000, SleeperID : 5000 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Griffin`, Position : 'S', Salary : 650000, SleeperID : 5437 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jessamen`, LastName: `Dunker`, Position : 'G', Salary : 650000, SleeperID : 4639 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jadar`, LastName: `Johnson`, Position : 'FS', Salary : 650000, SleeperID : 4640 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keeon`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 4641 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarron`, LastName: `Jones`, Position : 'OL', Salary : 650000, SleeperID : 4642 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Clement`, Position : 'RB', Salary : 650000, SleeperID : 4651 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Winston`, LastName: `Craig`, Position : 'DE', Salary : 650000, SleeperID : 4652 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eli`, LastName: `Jenkins`, Position : 'QB', Salary : 650000, SleeperID : 4665 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randall`, LastName: `Goforth`, Position : 'FS', Salary : 650000, SleeperID : 4653 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Johnston`, Position : 'P', Salary : 650000, SleeperID : 4654 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Ekeler`, Position : 'RB', Salary : 1497998.187, SleeperID : 4663 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nigel`, LastName: `Harris`, Position : 'ILB', Salary : 650000, SleeperID : 4664 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Algernon`, LastName: `Brown`, Position : 'FB', Salary : 650000, SleeperID : 4673 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Liggins`, Position : 'DT', Salary : 650000, SleeperID : 4674 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Younghoe`, LastName: `Koo`, Position : 'K', Salary : 650000, SleeperID : 4666 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charmeachealle`, LastName: `Moore`, Position : 'OLB', Salary : 650000, SleeperID : 4667 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Onwualu`, Position : 'LB', Salary : 650000, SleeperID : 4668 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Patton`, Position : 'WR', Salary : 650000, SleeperID : 4669 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Artavis`, LastName: `Scott`, Position : 'WR', Salary : 650000, SleeperID : 4670 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brad`, LastName: `Watson`, Position : 'CB', Salary : 650000, SleeperID : 4672 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darreus`, LastName: `Rogers`, Position : 'WR', Salary : 650000, SleeperID : 4676 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Roos`, Position : 'G', Salary : 650000, SleeperID : 4677 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrone`, LastName: `Swoopes`, Position : 'TE', Salary : 650000, SleeperID : 4678 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Usher`, Position : 'OLB', Salary : 650000, SleeperID : 4679 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Eluemunor`, Position : 'OL', Salary : 650000, SleeperID : 4190 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Clark`, Position : 'WR', Salary : 650000, SleeperID : 4680 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Stewart`, Position : 'CB', Salary : 650000, SleeperID : 4671 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corn`, LastName: `Elder`, Position : 'CB', Salary : 650000, SleeperID : 4192 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randy`, LastName: `Allen`, Position : 'OLB', Salary : 650000, SleeperID : 4682 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roubbens`, LastName: `Joseph`, Position : 'OT', Salary : 650000, SleeperID : 4685 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Harrell`, Position : 'ILB', Salary : 650000, SleeperID : 4686 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Abner`, LastName: `Logan`, Position : 'LB', Salary : 650000, SleeperID : 4687 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Najee`, LastName: `Murray`, Position : 'CB', Salary : 650000, SleeperID : 4688 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Saubert`, Position : 'TE', Salary : 650000, SleeperID : 4189 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Milano`, Position : 'LB', Salary : 650000, SleeperID : 4191 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Morgan`, Position : 'G', Salary : 650000, SleeperID : 4193 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Bailey`, Position : 'QB', Salary : 650000, SleeperID : 4683 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Elliott`, Position : 'K', Salary : 650000, SleeperID : 4195 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roderick`, LastName: `Johnson`, Position : 'T', Salary : 650000, SleeperID : 4196 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `McKenzie`, Position : 'WR', Salary : 650000, SleeperID : 4197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Agnew`, Position : 'WR', Salary : 650000, SleeperID : 4198 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Jones`, Position : 'RB', Salary : 2303736.593, SleeperID : 4199 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Mixon`, Position : 'RB', Salary : 3454291.386, SleeperID : 4018 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeShone`, LastName: `Kizer`, Position : 'QB', Salary : 650000, SleeperID : 4026 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Winston`, LastName: `Chapman`, Position : 'LS', Salary : 650000, SleeperID : 4028 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalvin`, LastName: `Cook`, Position : 'RB', Salary : 650000, SleeperID : 4029 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reuben`, LastName: `Foster`, Position : 'LB', Salary : 650000, SleeperID : 4030 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Wilson`, Position : 'TE', Salary : 650000, SleeperID : 4031 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Allen`, Position : 'DT', Salary : 650000, SleeperID : 4032 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Njoku`, Position : 'TE', Salary : 1929590.404, SleeperID : 4033 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `McCaffrey`, Position : 'RB', Salary : 12249777.05, SleeperID : 4034 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alvin`, LastName: `Kamara`, Position : 'RB', Salary : 3043561.264, SleeperID : 4035 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Godwin`, Position : 'WR', Salary : 2081858.862, SleeperID : 4037 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Ross`, Position : 'WR', Salary : 650000, SleeperID : 4038 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JuJu`, LastName: `Smith-Schuster`, Position : 'WR', Salary : 650000, SleeperID : 4040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Lindsey`, Position : 'OLB', Salary : 650000, SleeperID : 4047 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Mudge`, Position : 'C', Salary : 650000, SleeperID : 4048 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradley`, LastName: `Sylve`, Position : 'CB', Salary : 650000, SleeperID : 4049 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 4036 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cooper`, LastName: `Kupp`, Position : 'WR', Salary : 2893285.426, SleeperID : 4039 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 4042 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Mahomes`, Position : 'QB', Salary : 7766248.222, SleeperID : 4046 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Kelly`, Position : 'QB', Salary : 650000, SleeperID : 4051 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cyril`, LastName: `Grayson`, Position : 'WR', Salary : 650000, SleeperID : 4050 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Chapman`, Position : 'WR', Salary : 650000, SleeperID : 5776 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garett`, LastName: `Bolles`, Position : 'OT', Salary : 650000, SleeperID : 4053 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mo`, LastName: `Alie-Cox`, Position : 'TE', Salary : 650000, SleeperID : 4054 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `O.J.`, LastName: `Howard`, Position : 'TE', Salary : 650000, SleeperID : 4055 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jabrill`, LastName: `Peppers`, Position : 'DB', Salary : 650000, SleeperID : 4056 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gareon`, LastName: `Conley`, Position : 'CB', Salary : 650000, SleeperID : 4057 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Solomon`, LastName: `Thomas`, Position : 'DT', Salary : 650000, SleeperID : 4058 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marshon`, LastName: `Lattimore`, Position : 'CB', Salary : 650000, SleeperID : 4059 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davis`, LastName: `Webb`, Position : 'QB', Salary : 650000, SleeperID : 4061 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Irwin-Hill`, Position : 'P', Salary : 650000, SleeperID : 4062 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Haason`, LastName: `Reddick`, Position : 'LB', Salary : 650000, SleeperID : 4063 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Robinson`, Position : 'OL', Salary : 650000, SleeperID : 4064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Barnett`, Position : 'DE', Salary : 650000, SleeperID : 4065 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Engram`, Position : 'TE', Salary : 1881350.644, SleeperID : 4066 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrad`, LastName: `Davis`, Position : 'LB', Salary : 650000, SleeperID : 4067 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 4068 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Takk`, LastName: `McKinley`, Position : 'DE', Salary : 650000, SleeperID : 4069 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Dooley`, Position : 'LS', Salary : 650000, SleeperID : 4956 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Moubarak`, LastName: `Djeri`, Position : 'DE', Salary : 650000, SleeperID : 4957 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brett`, LastName: `Maher`, Position : 'K', Salary : 650000, SleeperID : 4958 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scott`, LastName: `Daly`, Position : 'LS', Salary : 650000, SleeperID : 4959 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roquan`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 4960 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sony`, LastName: `Michel`, Position : 'RB', Salary : 650000, SleeperID : 4962 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denzel`, LastName: `Ward`, Position : 'CB', Salary : 650000, SleeperID : 4964 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Davenport`, Position : 'DE', Salary : 650000, SleeperID : 4965 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradley`, LastName: `Chubb`, Position : 'LB', Salary : 650000, SleeperID : 4967 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tremaine`, LastName: `Edmunds`, Position : 'LB', Salary : 650000, SleeperID : 4968 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vita`, LastName: `Vea`, Position : 'DT', Salary : 650000, SleeperID : 4969 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Minkah`, LastName: `Fitzpatrick`, Position : 'DB', Salary : 650000, SleeperID : 4963 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `McGlinchey`, Position : 'T', Salary : 650000, SleeperID : 4966 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quenton`, LastName: `Nelson`, Position : 'OL', Salary : 650000, SleeperID : 4970 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derwin`, LastName: `James`, Position : 'DB', Salary : 650000, SleeperID : 4971 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Rudolph`, Position : 'QB', Salary : 650000, SleeperID : 4972 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.T.`, LastName: `Gray`, Position : 'DB', Salary : 650000, SleeperID : 5452 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colton`, LastName: `Jumper`, Position : 'OLB', Salary : 650000, SleeperID : 5453 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Coleman`, LastName: `Shelton`, Position : 'OL', Salary : 650000, SleeperID : 5454 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Thompson`, Position : 'C', Salary : 650000, SleeperID : 5455 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randin`, LastName: `Crecelius`, Position : 'OT', Salary : 650000, SleeperID : 5456 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `LaCouture`, Position : 'NT', Salary : 650000, SleeperID : 5457 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Du'Vonta`, LastName: `Lampkin`, Position : 'DT', Salary : 650000, SleeperID : 5458 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `McKenrick`, Position : 'LB', Salary : 650000, SleeperID : 5459 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaare`, LastName: `Vedvik`, Position : 'K', Salary : 650000, SleeperID : 5460 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `Sieg`, Position : 'LS', Salary : 650000, SleeperID : 5461 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christopher`, LastName: `Ezeala`, Position : 'RB', Salary : 650000, SleeperID : 5462 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Thompson`, Position : 'RB', Salary : 650000, SleeperID : 5463 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Lance`, LastName: `Turner`, Position : 'RB', Salary : 650000, SleeperID : 5464 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Keizer`, Position : 'TE', Salary : 650000, SleeperID : 5465 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Franklin-Myers`, Position : 'DT', Salary : 650000, SleeperID : 5466 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tigie`, LastName: `Sankoh`, Position : 'SS', Salary : 650000, SleeperID : 5467 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bart`, LastName: `Houston`, Position : 'QB', Salary : 650000, SleeperID : 4728 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Toby`, LastName: `Baker`, Position : 'P', Salary : 650000, SleeperID : 4729 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Cotton`, Position : 'TE', Salary : 650000, SleeperID : 4730 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Bacci`, Position : 'FB', Salary : 650000, SleeperID : 4731 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricky`, LastName: `Ortiz`, Position : 'FB', Salary : 650000, SleeperID : 4350 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `Paige`, Position : 'WR', Salary : 650000, SleeperID : 4727 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Gray`, Position : 'TE', Salary : 650000, SleeperID : 4733 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ethan`, LastName: `Cooper`, Position : 'C', Salary : 650000, SleeperID : 4337 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Levern`, LastName: `Jacobs`, Position : 'WR', Salary : 650000, SleeperID : 4338 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ondre`, LastName: `Pipkins`, Position : 'DE', Salary : 650000, SleeperID : 4340 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Allen`, Position : 'P', Salary : 650000, SleeperID : 4343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Board`, Position : 'WR', Salary : 650000, SleeperID : 4344 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Omarius`, LastName: `Bryant`, Position : 'NT', Salary : 650000, SleeperID : 4346 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Kublanow`, Position : 'C', Salary : 650000, SleeperID : 4348 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taquan`, LastName: `Mizzell`, Position : 'RB', Salary : 650000, SleeperID : 4349 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Jefferson`, Position : 'DE', Salary : 650000, SleeperID : 4732 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Duke`, Position : 'WR', Salary : 650000, SleeperID : 4358 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Patrick`, Position : 'WR', Salary : 650000, SleeperID : 4351 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donald`, LastName: `Payne`, Position : 'LB', Salary : 650000, SleeperID : 4352 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Ricard`, Position : 'FB', Salary : 650000, SleeperID : 4353 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Boulware`, Position : 'ILB', Salary : 650000, SleeperID : 4356 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Cox`, Position : 'DE', Salary : 650000, SleeperID : 4357 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Luke`, Position : 'CB', Salary : 650000, SleeperID : 4359 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fred`, LastName: `Ross`, Position : 'WR', Salary : 650000, SleeperID : 4360 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cethan`, LastName: `Carter`, Position : 'TE', Salary : 650000, SleeperID : 4362 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Karel`, LastName: `Hamilton`, Position : 'WR', Salary : 650000, SleeperID : 4364 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrin`, LastName: `Laufasa`, Position : 'FB', Salary : 650000, SleeperID : 4365 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Landon`, LastName: `Lechler`, Position : 'OT', Salary : 650000, SleeperID : 4366 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Monty`, LastName: `Madaris`, Position : 'WR', Salary : 650000, SleeperID : 4367 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hardy`, LastName: `Nickerson`, Position : 'LB', Salary : 650000, SleeperID : 4368 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dustin`, LastName: `Stanton`, Position : 'G', Salary : 650000, SleeperID : 4370 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Tupou`, Position : 'DT', Salary : 650000, SleeperID : 4371 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stanley`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 4373 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Evans`, Position : 'G', Salary : 650000, SleeperID : 4378 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Heiman`, Position : 'ILB', Salary : 650000, SleeperID : 4380 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taysom`, LastName: `Hill`, Position : 'TE', Salary : 650000, SleeperID : 4381 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Scotland-Williamson`, Position : 'TE', Salary : 650000, SleeperID : 5468 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Schleuger`, Position : 'G', Salary : 650000, SleeperID : 5469 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendal`, LastName: `Vickers`, Position : 'DE', Salary : 650000, SleeperID : 5470 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 5471 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Adams`, Position : 'C', Salary : 650000, SleeperID : 5472 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Wozniak`, Position : 'T', Salary : 650000, SleeperID : 5475 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KC`, LastName: `McDermott`, Position : 'OL', Salary : 650000, SleeperID : 5476 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Hunter`, Position : 'LB', Salary : 650000, SleeperID : 5477 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Motuapuaka`, Position : 'ILB', Salary : 650000, SleeperID : 5478 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Herndon`, Position : 'CB', Salary : 650000, SleeperID : 5479 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Welsh`, Position : 'G', Salary : 650000, SleeperID : 5481 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Davis`, Position : 'C', Salary : 650000, SleeperID : 5482 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Linden`, LastName: `Stephens`, Position : 'DB', Salary : 650000, SleeperID : 5474 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kurt`, LastName: `Benkert`, Position : 'QB', Salary : 650000, SleeperID : 5142 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demario`, LastName: `Richard`, Position : 'RB', Salary : 650000, SleeperID : 5143 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dewey`, LastName: `Jarvis`, Position : 'ILB', Salary : 650000, SleeperID : 5148 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emmanuel`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 5149 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gerhard`, LastName: `de Beer`, Position : 'OT', Salary : 650000, SleeperID : 5151 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mat`, LastName: `Boesen`, Position : 'DE', Salary : 650000, SleeperID : 5152 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Levi`, LastName: `Wallace`, Position : 'CB', Salary : 650000, SleeperID : 5153 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 5144 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `Sherfield`, Position : 'WR', Salary : 650000, SleeperID : 5154 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Willis`, Position : 'WR', Salary : 650000, SleeperID : 5155 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Vollert`, Position : 'TE', Salary : 650000, SleeperID : 5156 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alec`, LastName: `James`, Position : 'DE', Salary : 650000, SleeperID : 5157 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dennis`, LastName: `Gardeck`, Position : 'LB', Salary : 650000, SleeperID : 5158 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Howard`, Position : 'SS', Salary : 650000, SleeperID : 5160 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ezekiel`, LastName: `Turner`, Position : 'LB', Salary : 650000, SleeperID : 5161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Bonnafon`, Position : 'RB', Salary : 650000, SleeperID : 5162 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Nall`, Position : 'RB', Salary : 650000, SleeperID : 5163 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Da'Mari`, LastName: `Scott`, Position : 'WR', Salary : 650000, SleeperID : 5166 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalton`, LastName: `Sturm`, Position : 'QB', Salary : 650000, SleeperID : 5167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Chunn`, Position : 'RB', Salary : 650000, SleeperID : 5168 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Earl`, Position : 'WR', Salary : 650000, SleeperID : 5169 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phillip`, LastName: `Lindsay`, Position : 'RB', Salary : 650000, SleeperID : 5170 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Rader`, Position : 'TE', Salary : 650000, SleeperID : 5171 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chuck`, LastName: `Clark`, Position : 'DB', Salary : 650000, SleeperID : 4224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Armah`, Position : 'FB', Salary : 650000, SleeperID : 4226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tegray`, LastName: `Scales`, Position : 'LB', Salary : 650000, SleeperID : 5174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davon`, LastName: `Grayson`, Position : 'WR', Salary : 650000, SleeperID : 5176 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Davis`, Position : 'SS', Salary : 650000, SleeperID : 5177 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Chandler`, Position : 'DB', Salary : 650000, SleeperID : 5178 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Ostman`, Position : 'DE', Salary : 650000, SleeperID : 5179 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Ezechukwu`, Position : 'OLB', Salary : 650000, SleeperID : 5180 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chandon`, LastName: `Sullivan`, Position : 'CB', Salary : 650000, SleeperID : 5181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Morris`, Position : 'C', Salary : 650000, SleeperID : 5182 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Isidora`, Position : 'OL', Salary : 650000, SleeperID : 4209 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avery`, LastName: `Moss`, Position : 'DE', Salary : 650000, SleeperID : 4210 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Gerry`, Position : 'LB', Salary : 650000, SleeperID : 4213 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shelton`, LastName: `Gibson`, Position : 'WR', Salary : 650000, SleeperID : 4214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Desmond`, LastName: `King`, Position : 'DB', Salary : 650000, SleeperID : 4216 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Kittle`, Position : 'TE', Salary : 2423391.551, SleeperID : 4217 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `McNichols`, Position : 'RB', Salary : 650000, SleeperID : 4219 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayon`, LastName: `Brown`, Position : 'LB', Salary : 650000, SleeperID : 4220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Sprinkle`, Position : 'TE', Salary : 650000, SleeperID : 4221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rudy`, LastName: `Ford`, Position : 'DB', Salary : 650000, SleeperID : 4223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Vallejo`, Position : 'LB', Salary : 650000, SleeperID : 4225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alvin`, LastName: `Jones`, Position : 'ILB', Salary : 650000, SleeperID : 5172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harrison`, LastName: `Butker`, Position : 'K', Salary : 650000, SleeperID : 4227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquel`, LastName: `Lee`, Position : 'LB', Salary : 650000, SleeperID : 4212 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Allen`, Position : 'CB', Salary : 650000, SleeperID : 4215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Evans`, Position : 'LB', Salary : 650000, SleeperID : 4228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `Taylor`, Position : 'WR', Salary : 650000, SleeperID : 4218 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Schreck`, Position : 'TE', Salary : 650000, SleeperID : 4229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Wilson`, Position : 'DB', Salary : 650000, SleeperID : 4230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarell`, LastName: `Carter`, Position : 'CB', Salary : 650000, SleeperID : 4789 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Wheelwright`, Position : 'WR', Salary : 650000, SleeperID : 4790 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harold`, LastName: `Landry`, Position : 'LB', Salary : 650000, SleeperID : 5030 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Walton`, Position : 'OLB', Salary : 650000, SleeperID : 4792 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Armstrong Jr.`, Position : 'QB', Salary : 650000, SleeperID : 4794 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Rounds`, Position : 'RB', Salary : 650000, SleeperID : 4797 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Germany`, Position : 'WR', Salary : 650000, SleeperID : 4798 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Faulk`, Position : 'DT', Salary : 650000, SleeperID : 4799 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 5029 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Victor`, Position : 'RB', Salary : 650000, SleeperID : 4791 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Peli`, LastName: `Anau`, Position : 'DE', Salary : 650000, SleeperID : 4793 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Oren`, LastName: `Burks`, Position : 'LB', Salary : 650000, SleeperID : 5031 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Nnadi`, Position : 'DT', Salary : 650000, SleeperID : 5035 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Reid`, Position : 'DB', Salary : 650000, SleeperID : 5036 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Breeland`, LastName: `Speaks`, Position : 'DE', Salary : 650000, SleeperID : 5037 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Gallup`, Position : 'WR', Salary : 650000, SleeperID : 5038 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Williams`, Position : 'G', Salary : 650000, SleeperID : 5039 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tarvarius`, LastName: `Moore`, Position : 'DB', Salary : 650000, SleeperID : 5040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Kukwa`, Position : 'LS', Salary : 650000, SleeperID : 4448 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nicholas`, LastName: `Morrow`, Position : 'LB', Salary : 650000, SleeperID : 4449 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Simmons`, Position : 'G', Salary : 650000, SleeperID : 4450 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Victor`, LastName: `Bolden`, Position : 'WR', Salary : 650000, SleeperID : 4453 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendrick`, LastName: `Bourne`, Position : 'WR', Salary : 650000, SleeperID : 4454 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Breida`, Position : 'RB', Salary : 650000, SleeperID : 4455 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fred`, LastName: `Warner`, Position : 'LB', Salary : 650000, SleeperID : 5041 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deadrin`, LastName: `Senat`, Position : 'DT', Salary : 650000, SleeperID : 5042 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Shepherd`, Position : 'DT', Salary : 650000, SleeperID : 5043 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Oliver`, Position : 'CB', Salary : 650000, SleeperID : 5044 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Courtland`, LastName: `Sutton`, Position : 'WR', Salary : 1192759.348, SleeperID : 5045 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Royce`, LastName: `Freeman`, Position : 'RB', Salary : 650000, SleeperID : 5046 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `O'Neill`, Position : 'T', Salary : 650000, SleeperID : 5047 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Yiadom`, Position : 'CB', Salary : 650000, SleeperID : 5048 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harrison`, LastName: `Phillips`, Position : 'DT', Salary : 650000, SleeperID : 5049 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashaan`, LastName: `Gaulden`, Position : 'DB', Salary : 650000, SleeperID : 5050 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donte`, LastName: `Jackson`, Position : 'CB', Salary : 650000, SleeperID : 5051 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronald`, LastName: `Jones`, Position : 'RB', Salary : 650000, SleeperID : 5052 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Cappa`, Position : 'G', Salary : 650000, SleeperID : 5053 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlton`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 5054 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerome`, LastName: `Baker`, Position : 'LB', Salary : 650000, SleeperID : 5056 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyquan`, LastName: `Lewis`, Position : 'DE', Salary : 650000, SleeperID : 5057 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kemoko`, LastName: `Turay`, Position : 'DE', Salary : 650000, SleeperID : 5058 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rasheem`, LastName: `Green`, Position : 'DE', Salary : 650000, SleeperID : 5059 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braden`, LastName: `Smith`, Position : 'OL', Salary : 650000, SleeperID : 5060 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Uchenna`, LastName: `Nwosu`, Position : 'LB', Salary : 650000, SleeperID : 5061 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Cole`, Position : 'OL', Salary : 650000, SleeperID : 5062 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `M.J.`, LastName: `Stewart`, Position : 'DB', Salary : 650000, SleeperID : 5055 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tracy`, LastName: `Walker`, Position : 'DB', Salary : 650000, SleeperID : 5064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jevoni`, LastName: `Robinson`, Position : 'TE', Salary : 650000, SleeperID : 4864 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Saquon`, LastName: `Barkley`, Position : 'RB', Salary : 10003802.53, SleeperID : 4866 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Munchak`, Position : '0', Salary : 650000, SleeperID : 4871 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Bazile`, Position : 'TE', Salary : 650000, SleeperID : 4874 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradley`, LastName: `Northnagel`, Position : 'LS', Salary : 650000, SleeperID : 4876 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Williams`, Position : 'LS', Salary : 650000, SleeperID : 4877 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Zylstra`, Position : 'WR', Salary : 650000, SleeperID : 4878 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lamar`, LastName: `Jackson`, Position : 'QB', Salary : 5448486.598, SleeperID : 4881 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Collin`, LastName: `Bevins`, Position : 'DE', Salary : 650000, SleeperID : 4886 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randy`, LastName: `Fichtner`, Position : '0', Salary : 650000, SleeperID : 4889 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Turk`, Position : 'P', Salary : 650000, SleeperID : 4890 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Triner`, Position : 'LS', Salary : 650000, SleeperID : 4865 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Bates`, Position : '0', Salary : 650000, SleeperID : 4891 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Baker`, LastName: `Mayfield`, Position : 'QB', Salary : 650000, SleeperID : 4892 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Day`, Position : '0', Salary : 650000, SleeperID : 4893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Washington`, Position : '0', Salary : 650000, SleeperID : 4894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lamar`, LastName: `Atkins`, Position : 'WR', Salary : 650000, SleeperID : 4895 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Callahan`, Position : '0', Salary : 650000, SleeperID : 4896 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Schneider`, Position : 'OT', Salary : 650000, SleeperID : 4898 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 5063 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Ferris`, Position : 'LS', Salary : 650000, SleeperID : 4899 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ozzie`, LastName: `Newsome`, Position : '0', Salary : 650000, SleeperID : 4900 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `O'Shea`, Position : '0', Salary : 650000, SleeperID : 4902 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayrone`, LastName: `Elliott`, Position : 'LB', Salary : 650000, SleeperID : 4903 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Buddy`, LastName: `Howell`, Position : 'RB', Salary : 650000, SleeperID : 5271 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lukas`, LastName: `Denis`, Position : 'FS', Salary : 650000, SleeperID : 5897 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Deiter`, Position : 'OL', Salary : 650000, SleeperID : 5898 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Peters`, Position : 'CB', Salary : 650000, SleeperID : 5899 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shareef`, LastName: `Miller`, Position : 'LB', Salary : 650000, SleeperID : 5900 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tytus`, LastName: `Howard`, Position : 'OL', Salary : 650000, SleeperID : 5901 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Penny`, LastName: `Hart`, Position : 'WR', Salary : 650000, SleeperID : 5902 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Grier`, Position : 'QB', Salary : 650000, SleeperID : 5903 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Edwards`, Position : 'OL', Salary : 650000, SleeperID : 5904 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Layne`, Position : 'CB', Salary : 650000, SleeperID : 5905 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dawson`, LastName: `Knox`, Position : 'TE', Salary : 650000, SleeperID : 5906 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vosean`, LastName: `Joseph`, Position : 'OLB', Salary : 650000, SleeperID : 5907 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amani`, LastName: `Hooker`, Position : 'DB', Salary : 650000, SleeperID : 5908 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Lancaster`, Position : 'DT', Salary : 650000, SleeperID : 5483 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Filipo`, LastName: `Mokofisi`, Position : 'DT', Salary : 650000, SleeperID : 5484 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Conor`, LastName: `Sheehy`, Position : 'NT', Salary : 650000, SleeperID : 5485 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Johnson`, Position : 'OT', Salary : 650000, SleeperID : 5486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Campos`, Position : 'OT', Salary : 650000, SleeperID : 5487 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joel`, LastName: `Lanning`, Position : 'OLB', Salary : 650000, SleeperID : 5488 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kameron`, LastName: `Kelly`, Position : 'FS', Salary : 650000, SleeperID : 5489 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jashon`, LastName: `Robertson`, Position : 'OL', Salary : 650000, SleeperID : 5490 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeQuinton`, LastName: `Osborne`, Position : 'DT', Salary : 650000, SleeperID : 5491 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donovan`, LastName: `Olumba`, Position : 'CB', Salary : 650000, SleeperID : 5492 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charvarius`, LastName: `Ward`, Position : 'CB', Salary : 650000, SleeperID : 5493 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Larkin`, Position : 'DE', Salary : 650000, SleeperID : 5495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Hearns`, Position : 'LB', Salary : 650000, SleeperID : 5496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Cunningham`, Position : 'DT', Salary : 650000, SleeperID : 5497 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joseph`, LastName: `Putu`, Position : 'DB', Salary : 650000, SleeperID : 5498 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaleel`, LastName: `Scott`, Position : 'WR', Salary : 650000, SleeperID : 5005 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keke`, LastName: `Coutee`, Position : 'WR', Salary : 650000, SleeperID : 5007 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Durham`, LastName: `Smythe`, Position : 'TE', Salary : 650000, SleeperID : 5008 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Herndon`, Position : 'TE', Salary : 650000, SleeperID : 5009 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Dissly`, Position : 'TE', Salary : 650000, SleeperID : 5010 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Dickson`, Position : 'P', Salary : 650000, SleeperID : 5011 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Andrews`, Position : 'TE', Salary : 5049980.883, SleeperID : 5012 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Miller`, Position : 'WR', Salary : 650000, SleeperID : 5013 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ito`, LastName: `Smith`, Position : 'RB', Salary : 650000, SleeperID : 5004 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Whitney`, LastName: `Richardson`, Position : 'DE', Salary : 650000, SleeperID : 4841 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyquwan`, LastName: `Glass`, Position : 'CB', Salary : 650000, SleeperID : 4842 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Crawford`, Position : 'CB', Salary : 650000, SleeperID : 4845 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Mitchell`, Position : 'CB', Salary : 650000, SleeperID : 4844 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mikey`, LastName: `Bart`, Position : 'DE', Salary : 650000, SleeperID : 4847 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Kuntz`, Position : 'LS', Salary : 650000, SleeperID : 4848 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Armagedon`, LastName: `Draughn`, Position : 'FS', Salary : 650000, SleeperID : 4849 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Tago`, Position : 'OLB', Salary : 650000, SleeperID : 4850 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bill`, LastName: `Lazor`, Position : '0', Salary : 650000, SleeperID : 4852 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Terrell`, Position : 'QB', Salary : 650000, SleeperID : 4924 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerod`, LastName: `Evans`, Position : 'QB', Salary : 650000, SleeperID : 4853 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `River`, LastName: `Cracraft`, Position : 'WR', Salary : 650000, SleeperID : 4854 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bob`, LastName: `McNair`, Position : '0', Salary : 650000, SleeperID : 4856 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Allen`, Position : 'OL', Salary : 650000, SleeperID : 5304 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rushel`, LastName: `Shell`, Position : 'RB', Salary : 650000, SleeperID : 4923 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Wade`, Position : 'G', Salary : 650000, SleeperID : 4925 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Schuessler`, Position : 'QB', Salary : 650000, SleeperID : 4926 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ahmad`, LastName: `Fulwood`, Position : 'WR', Salary : 650000, SleeperID : 4929 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Franko`, LastName: `House`, Position : 'TE', Salary : 650000, SleeperID : 4930 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Appleby`, Position : 'QB', Salary : 650000, SleeperID : 4931 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deondre`, LastName: `Barnett`, Position : 'DE', Salary : 650000, SleeperID : 4927 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `James`, Position : 'DT', Salary : 650000, SleeperID : 4932 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `McMeans`, Position : 'G', Salary : 650000, SleeperID : 4933 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 4934 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Walker`, Position : 'DT', Salary : 650000, SleeperID : 4935 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Skyler`, LastName: `Howard`, Position : 'QB', Salary : 650000, SleeperID : 4936 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcell`, LastName: `Ateman`, Position : 'WR', Salary : 650000, SleeperID : 4942 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Speedy`, LastName: `Noil`, Position : 'WR', Salary : 650000, SleeperID : 4938 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roderick`, LastName: `Henderson`, Position : 'DT', Salary : 650000, SleeperID : 4939 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeSean`, LastName: `Smith`, Position : 'TE', Salary : 650000, SleeperID : 4940 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Al`, LastName: `Riles`, Position : 'WR', Salary : 650000, SleeperID : 4937 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Darnold`, Position : 'QB', Salary : 650000, SleeperID : 4943 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zaycoven`, LastName: `Henderson`, Position : 'DT', Salary : 650000, SleeperID : 5778 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Orlando`, LastName: `Brown`, Position : 'T', Salary : 650000, SleeperID : 4948 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrius`, LastName: `Guice`, Position : 'RB', Salary : 650000, SleeperID : 4949 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Kirk`, Position : 'WR', Salary : 2362806.762, SleeperID : 4950 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Chark`, Position : 'WR', Salary : 650000, SleeperID : 4951 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erick`, LastName: `Wren`, Position : 'G', Salary : 650000, SleeperID : 5782 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tom`, LastName: `Benson`, Position : '0', Salary : 650000, SleeperID : 4952 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colby`, LastName: `Wadman`, Position : 'P', Salary : 650000, SleeperID : 4954 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Billy`, LastName: `Price`, Position : 'OL', Salary : 650000, SleeperID : 4947 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malachi`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 5777 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Turner`, Position : 'WR', Salary : 650000, SleeperID : 5781 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Basile`, Position : 'S', Salary : 650000, SleeperID : 5790 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gerald`, LastName: `Holmes`, Position : 'RB', Salary : 650000, SleeperID : 5791 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Boutte`, Position : 'CB', Salary : 650000, SleeperID : 5783 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Butler`, Position : 'RB', Salary : 650000, SleeperID : 5785 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Peterson`, Position : 'WR', Salary : 650000, SleeperID : 5786 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Bobo`, Position : 'WR', Salary : 650000, SleeperID : 5787 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Sawyer`, Position : 'DE', Salary : 650000, SleeperID : 5788 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Reth`, Position : 'DE', Salary : 650000, SleeperID : 5780 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Bazata`, Position : 'DT', Salary : 650000, SleeperID : 5789 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Reaves`, Position : 'DB', Salary : 650000, SleeperID : 5792 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Celestin`, Position : 'LB', Salary : 650000, SleeperID : 5784 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Wolf`, Position : 'WR', Salary : 650000, SleeperID : 5796 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Prince`, Position : 'WR', Salary : 650000, SleeperID : 5799 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davond`, LastName: `Dade`, Position : 'DE', Salary : 650000, SleeperID : 5793 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Simeyon`, LastName: `Robinson`, Position : 'DE', Salary : 650000, SleeperID : 5794 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Canady`, Position : 'CB', Salary : 650000, SleeperID : 5795 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darren`, LastName: `Carrington`, Position : 'WR', Salary : 650000, SleeperID : 5798 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kobe`, LastName: `McCrary`, Position : 'RB', Salary : 650000, SleeperID : 5801 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Judd`, Position : 'C', Salary : 650000, SleeperID : 5803 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 5804 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Ohnesorge`, Position : 'C', Salary : 650000, SleeperID : 5805 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Wolford`, Position : 'QB', Salary : 650000, SleeperID : 5806 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 5815 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Stockton`, Position : 'RB', Salary : 650000, SleeperID : 5800 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Quan`, LastName: `Gardner`, Position : 'RB', Salary : 650000, SleeperID : 5809 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaboree`, LastName: `Williams`, Position : 'OLB', Salary : 650000, SleeperID : 5807 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Bosa`, Position : 'DE', Salary : 650000, SleeperID : 5816 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Freddie`, LastName: `Kitchens`, Position : '0', Salary : 650000, SleeperID : 5818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Quinn`, Position : 'C', Salary : 650000, SleeperID : 5821 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alexander`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 5822 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hayden`, LastName: `Hunt`, Position : 'P', Salary : 650000, SleeperID : 5824 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Jessop`, Position : 'QB', Salary : 650000, SleeperID : 5808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Cotton`, Position : 'DE', Salary : 650000, SleeperID : 5825 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jameer`, LastName: `Thurman`, Position : 'ILB', Salary : 650000, SleeperID : 5826 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tevaughn`, LastName: `Campbell`, Position : 'CB', Salary : 650000, SleeperID : 5827 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tristan`, LastName: `Vizcaino`, Position : 'K', Salary : 650000, SleeperID : 5823 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Carlson`, Position : 'K', Salary : 650000, SleeperID : 5095 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ray-Ray`, LastName: `McCloud`, Position : 'WR', Salary : 650000, SleeperID : 5096 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wyatt`, LastName: `Teller`, Position : 'G', Salary : 650000, SleeperID : 5097 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Siran`, LastName: `Neal`, Position : 'CB', Salary : 650000, SleeperID : 5098 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Carter`, Position : 'LB', Salary : 650000, SleeperID : 5099 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Wilkins`, Position : 'RB', Salary : 650000, SleeperID : 5100 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daurice`, LastName: `Fountain`, Position : 'WR', Salary : 650000, SleeperID : 5102 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Cantrell`, Position : 'TE', Salary : 650000, SleeperID : 5103 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scott`, LastName: `Quessenberry`, Position : 'OL', Salary : 650000, SleeperID : 5104 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrell`, LastName: `Crosby`, Position : 'OL', Salary : 650000, SleeperID : 5106 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Samuels`, Position : 'RB', Salary : 650000, SleeperID : 5107 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Falk`, Position : 'QB', Salary : 650000, SleeperID : 5108 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Russell`, LastName: `Gage`, Position : 'WR', Salary : 650000, SleeperID : 5110 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Wims`, Position : 'WR', Salary : 650000, SleeperID : 5111 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deon`, LastName: `Cain`, Position : 'WR', Salary : 650000, SleeperID : 5101 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bo`, LastName: `Scarbrough`, Position : 'RB', Salary : 650000, SleeperID : 5112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedrick`, LastName: `Wilson`, Position : 'WR', Salary : 650000, SleeperID : 5113 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damon`, LastName: `Webb`, Position : 'SS', Salary : 650000, SleeperID : 5198 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Byron`, LastName: `Pringle`, Position : 'WR', Salary : 650000, SleeperID : 5199 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Heneghan`, Position : 'QB', Salary : 650000, SleeperID : 5392 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Mailata`, Position : 'T', Salary : 650000, SleeperID : 5362 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Frazier`, Position : 'NT', Salary : 650000, SleeperID : 5364 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Azeem`, LastName: `Victor`, Position : 'OLB', Salary : 650000, SleeperID : 5380 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Nelson`, Position : 'CB', Salary : 650000, SleeperID : 5382 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonah`, LastName: `Trinnaman`, Position : 'WR', Salary : 650000, SleeperID : 5384 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `McCrane`, Position : 'K', Salary : 650000, SleeperID : 5386 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerod`, LastName: `Fernandez`, Position : 'OLB', Salary : 650000, SleeperID : 5387 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alec`, LastName: `Bloom`, Position : 'TE', Salary : 650000, SleeperID : 5385 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 5388 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alan`, LastName: `Knott`, Position : 'C', Salary : 650000, SleeperID : 5389 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamar`, LastName: `McGloster`, Position : 'OT', Salary : 650000, SleeperID : 5390 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Niles`, LastName: `Scott`, Position : 'DT', Salary : 650000, SleeperID : 5393 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaun`, LastName: `Wilson`, Position : 'RB', Salary : 650000, SleeperID : 5395 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Crawford`, Position : 'RB', Salary : 650000, SleeperID : 5398 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Moore`, Position : 'K', Salary : 650000, SleeperID : 5397 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `Williams`, Position : 'FS', Salary : 650000, SleeperID : 5394 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Armanti`, LastName: `Foreman`, Position : 'WR', Salary : 650000, SleeperID : 5401 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Cothran`, Position : 'NT', Salary : 650000, SleeperID : 5399 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Wynn`, Position : 'DE', Salary : 650000, SleeperID : 5402 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Boozer`, Position : 'OT', Salary : 650000, SleeperID : 5405 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Mathis`, Position : 'CB', Salary : 650000, SleeperID : 5400 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Peter`, LastName: `Pujals`, Position : 'QB', Salary : 650000, SleeperID : 5403 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Gonzalez`, Position : 'G', Salary : 650000, SleeperID : 5404 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Liddell`, Position : 'S', Salary : 650000, SleeperID : 5406 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ervin`, LastName: `Philips`, Position : 'RB', Salary : 650000, SleeperID : 5407 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Pryor`, Position : 'T', Salary : 650000, SleeperID : 5363 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Oplinger`, Position : 'OLB', Salary : 650000, SleeperID : 5411 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sergio`, LastName: `Bailey`, Position : 'WR', Salary : 650000, SleeperID : 5408 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Townsend`, Position : 'P', Salary : 650000, SleeperID : 5381 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dee`, LastName: `Liner`, Position : 'DE', Salary : 650000, SleeperID : 5200 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Bradley`, Position : 'LS', Salary : 650000, SleeperID : 5321 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Hudson`, Position : 'TE', Salary : 650000, SleeperID : 5409 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Perrizo`, Position : 'DE', Salary : 650000, SleeperID : 5410 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shane`, LastName: `Tripucka`, Position : 'P', Salary : 650000, SleeperID : 5413 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bijhon`, LastName: `Jackson`, Position : 'NT', Salary : 650000, SleeperID : 5414 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zachary`, LastName: `Crabtree`, Position : 'OT', Salary : 650000, SleeperID : 5415 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tremon`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 5328 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Looney`, Position : 'TE', Salary : 650000, SleeperID : 5322 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Equanimeous`, LastName: `St. Brown`, Position : 'WR', Salary : 650000, SleeperID : 5323 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Kelly`, Position : 'CB', Salary : 650000, SleeperID : 5326 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kahlil`, LastName: `McKenzie`, Position : 'DT', Salary : 650000, SleeperID : 5327 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Foyesade`, LastName: `Oluokun`, Position : 'LB', Salary : 650000, SleeperID : 5332 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parris`, LastName: `Bennett`, Position : 'ILB', Salary : 650000, SleeperID : 5324 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Armani`, LastName: `Watts`, Position : 'DB', Salary : 650000, SleeperID : 5329 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Peter`, LastName: `Kalambayi`, Position : 'LB', Salary : 650000, SleeperID : 5325 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dorance`, LastName: `Armstrong`, Position : 'DE', Salary : 650000, SleeperID : 5330 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Covington`, Position : 'LB', Salary : 650000, SleeperID : 5331 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalyn`, LastName: `Holmes`, Position : 'DE', Salary : 650000, SleeperID : 5333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josey`, LastName: `Jewell`, Position : 'LB', Salary : 650000, SleeperID : 5334 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colby`, LastName: `Gossett`, Position : 'OL', Salary : 650000, SleeperID : 5335 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ade`, LastName: `Aruna`, Position : 'DE', Salary : 650000, SleeperID : 5336 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devante`, LastName: `Downs`, Position : 'LB', Salary : 650000, SleeperID : 5337 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 5340 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taron`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 5341 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquis`, LastName: `Haynes`, Position : 'DE', Salary : 650000, SleeperID : 5342 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cornell`, LastName: `Armstrong`, Position : 'DB', Salary : 650000, SleeperID : 5344 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Adams`, Position : 'LB', Salary : 650000, SleeperID : 5345 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zaire`, LastName: `Franklin`, Position : 'LB', Salary : 650000, SleeperID : 5346 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keishawn`, LastName: `Bierria`, Position : 'ILB', Salary : 650000, SleeperID : 5338 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quentin`, LastName: `Poling`, Position : 'LB', Salary : 650000, SleeperID : 5343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Battle`, Position : 'CB', Salary : 650000, SleeperID : 5518 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tavierre`, LastName: `Thomas`, Position : 'DB', Salary : 650000, SleeperID : 5519 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Owens`, Position : 'DB', Salary : 650000, SleeperID : 5520 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brant`, LastName: `Weiss`, Position : 'OT', Salary : 650000, SleeperID : 5521 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Owen`, LastName: `Obasuyi`, Position : 'DE', Salary : 650000, SleeperID : 5522 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Evans`, Position : 'OT', Salary : 650000, SleeperID : 5525 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darious`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 5526 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ramon`, LastName: `Richards`, Position : 'SS', Salary : 650000, SleeperID : 5527 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Montrel`, LastName: `Meander`, Position : 'LB', Salary : 650000, SleeperID : 5528 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Berry`, Position : 'WR', Salary : 650000, SleeperID : 5530 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `DiLauro`, Position : 'T', Salary : 650000, SleeperID : 5531 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Ekuale`, Position : 'DT', Salary : 650000, SleeperID : 5532 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trenton`, LastName: `Thompson`, Position : 'DT', Salary : 650000, SleeperID : 5533 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Campbell`, Position : 'DB', Salary : 650000, SleeperID : 5534 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Hannemann`, Position : 'FS', Salary : 650000, SleeperID : 5535 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lamar`, LastName: `Jordan`, Position : 'QB', Salary : 650000, SleeperID : 5239 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Marx`, Position : 'FB', Salary : 650000, SleeperID : 5240 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Blake`, Position : 'WR', Salary : 650000, SleeperID : 5241 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JoJo`, LastName: `Wicker`, Position : 'DE', Salary : 650000, SleeperID : 5684 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Meredith`, Position : 'OLB', Salary : 650000, SleeperID : 5685 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amari`, LastName: `Coleman`, Position : 'CB', Salary : 650000, SleeperID : 5686 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Sherrils`, Position : 'DB', Salary : 650000, SleeperID : 5687 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Montelus`, Position : 'G', Salary : 650000, SleeperID : 5688 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Fatu`, Position : 'DT', Salary : 650000, SleeperID : 5689 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Al-Rasheed`, LastName: `Benton`, Position : 'ILB', Salary : 650000, SleeperID : 5690 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Ford`, Position : 'DB', Salary : 650000, SleeperID : 5691 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Santoso`, Position : 'K', Salary : 650000, SleeperID : 5692 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Beau`, LastName: `Nunn`, Position : 'G', Salary : 650000, SleeperID : 5693 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antwuan`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 5694 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Powell`, Position : 'WR', Salary : 650000, SleeperID : 5695 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `House`, Position : 'G', Salary : 650000, SleeperID : 5523 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Okoye`, Position : 'DT', Salary : 650000, SleeperID : 5696 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Olstad`, Position : 'RB', Salary : 650000, SleeperID : 5697 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontez`, LastName: `Byrd`, Position : 'WR', Salary : 650000, SleeperID : 5242 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brett`, LastName: `Kendrick`, Position : 'OT', Salary : 650000, SleeperID : 5698 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Fleming`, Position : 'WR', Salary : 650000, SleeperID : 5699 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Martin`, Position : 'RB', Salary : 650000, SleeperID : 5700 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Allen`, Position : 'DE', Salary : 7965382.792, SleeperID : 5840 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Flagel`, Position : 'DE', Salary : 650000, SleeperID : 5701 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mercy`, LastName: `Maston`, Position : 'CB', Salary : 650000, SleeperID : 5832 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Chubb`, Position : 'RB', Salary : 1743747.482, SleeperID : 4988 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre'`, LastName: `Williams`, Position : 'OLB', Salary : 650000, SleeperID : 5836 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Redford`, LastName: `Jones`, Position : 'K', Salary : 650000, SleeperID : 5837 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phillip`, LastName: `Andersen`, Position : 'K', Salary : 650000, SleeperID : 5838 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashan`, LastName: `Gary`, Position : 'LB', Salary : 650000, SleeperID : 5839 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ed`, LastName: `Oliver`, Position : 'DT', Salary : 650000, SleeperID : 5841 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clelin`, LastName: `Ferrell`, Position : 'DE', Salary : 650000, SleeperID : 5842 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hayden`, LastName: `Hurst`, Position : 'TE', Salary : 650000, SleeperID : 4973 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taven`, LastName: `Bryan`, Position : 'DE', Salary : 650000, SleeperID : 4975 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daron`, LastName: `Payne`, Position : 'DT', Salary : 650000, SleeperID : 4976 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Wynn`, Position : 'OL', Salary : 650000, SleeperID : 4977 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashaan`, LastName: `Evans`, Position : 'LB', Salary : 650000, SleeperID : 4978 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaire`, LastName: `Alexander`, Position : 'CB', Salary : 650000, SleeperID : 4979 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leighton`, LastName: `Vander Esch`, Position : 'LB', Salary : 650000, SleeperID : 4980 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Ridley`, Position : 'WR', Salary : 1700153.795, SleeperID : 4981 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Moore`, Position : 'WR', Salary : 7198219.974, SleeperID : 4983 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Allen`, Position : 'QB', Salary : 650000, SleeperID : 4984 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashaad`, LastName: `Penny`, Position : 'RB', Salary : 650000, SleeperID : 4985 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kolton`, LastName: `Miller`, Position : 'T', Salary : 650000, SleeperID : 4986 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frank`, LastName: `Ragnow`, Position : 'C', Salary : 650000, SleeperID : 4987 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Corbett`, Position : 'OL', Salary : 650000, SleeperID : 4989 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaquille`, LastName: `Leonard`, Position : 'LB', Salary : 650000, SleeperID : 4990 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Daniels`, Position : 'OL', Salary : 650000, SleeperID : 4991 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Pettis`, Position : 'WR', Salary : 650000, SleeperID : 4992 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Francis`, LastName: `Kallon`, Position : 'DE', Salary : 650000, SleeperID : 4512 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chanceller`, LastName: `James`, Position : 'SS', Salary : 650000, SleeperID : 4514 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.J.`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 4515 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richard`, LastName: `Levy`, Position : 'G', Salary : 650000, SleeperID : 4516 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricky`, LastName: `Ali'ifua`, Position : 'DT', Salary : 650000, SleeperID : 4517 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devine`, LastName: `Redding`, Position : 'RB', Salary : 650000, SleeperID : 4519 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travin`, LastName: `Dural`, Position : 'WR', Salary : 650000, SleeperID : 4520 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Panfil`, Position : 'DE', Salary : 650000, SleeperID : 4521 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `Edmunds`, Position : 'DB', Salary : 650000, SleeperID : 4974 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Hughes`, Position : 'CB', Salary : 650000, SleeperID : 4982 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ironhead`, LastName: `Gallon`, Position : 'SS', Salary : 650000, SleeperID : 4524 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Krishawn`, LastName: `Hogan`, Position : 'WR', Salary : 650000, SleeperID : 4526 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Kelsey`, Position : 'ILB', Salary : 650000, SleeperID : 4513 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Knight`, Position : 'QB', Salary : 650000, SleeperID : 4528 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lucas`, LastName: `Crowley`, Position : 'C', Salary : 650000, SleeperID : 4523 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sojourn`, LastName: `Shelton`, Position : 'CB', Salary : 650000, SleeperID : 4532 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Lewis`, Position : 'CB', Salary : 650000, SleeperID : 4529 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `McLaughlin`, Position : 'OT', Salary : 650000, SleeperID : 4530 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricky`, LastName: `Seals-Jones`, Position : 'TE', Salary : 650000, SleeperID : 4531 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Summers`, Position : 'RB', Salary : 650000, SleeperID : 4533 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Croom`, Position : 'TE', Salary : 650000, SleeperID : 4536 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquavius`, LastName: `Lewis`, Position : 'DT', Salary : 650000, SleeperID : 4538 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Rehkow`, Position : 'P', Salary : 650000, SleeperID : 4540 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Wroblewski`, Position : 'TE', Salary : 650000, SleeperID : 4535 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Johnson`, Position : 'RB', Salary : 650000, SleeperID : 4537 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Pyke`, Position : 'G', Salary : 650000, SleeperID : 4539 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.T.`, LastName: `Sanders`, Position : 'SS', Salary : 650000, SleeperID : 4542 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Sayles`, Position : 'CB', Salary : 650000, SleeperID : 4543 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daikiel`, LastName: `Shorts`, Position : 'WR', Salary : 650000, SleeperID : 4544 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Towbridge`, Position : 'TE', Salary : 650000, SleeperID : 4545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Voytek`, Position : 'G', Salary : 650000, SleeperID : 4546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nigel`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 4547 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joel`, LastName: `Bouagnon`, Position : 'RB', Salary : 650000, SleeperID : 4548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashaad`, LastName: `Coward`, Position : 'OL', Salary : 650000, SleeperID : 4549 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hendrick`, LastName: `Ekpe`, Position : 'OLB', Salary : 650000, SleeperID : 4550 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Gentry`, Position : 'WR', Salary : 650000, SleeperID : 4551 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquise`, LastName: `Brown`, Position : 'WR', Salary : 1353722.821, SleeperID : 5848 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyler`, LastName: `Murray`, Position : 'QB', Salary : 2485529.796, SleeperID : 5849 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Jacobs`, Position : 'RB', Salary : 6671737.666, SleeperID : 5850 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jachai`, LastName: `Polite`, Position : 'LB', Salary : 650000, SleeperID : 5851 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAndre`, LastName: `Baker`, Position : 'CB', Salary : 650000, SleeperID : 5852 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trayvon`, LastName: `Mullen`, Position : 'CB', Salary : 650000, SleeperID : 5853 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Lock`, Position : 'QB', Salary : 650000, SleeperID : 5854 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Bradbury`, Position : 'C', Salary : 650000, SleeperID : 5855 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Fant`, Position : 'TE', Salary : 650000, SleeperID : 5857 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Brown`, Position : 'WR', Salary : 11943532.63, SleeperID : 5859 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnathan`, LastName: `Abram`, Position : 'DB', Salary : 650000, SleeperID : 5860 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `L.J.`, LastName: `Collier`, Position : 'DE', Salary : 650000, SleeperID : 5861 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Burns`, Position : 'DE', Salary : 650000, SleeperID : 5862 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.J.`, LastName: `Arcega-Whiteside`, Position : 'WR', Salary : 650000, SleeperID : 5863 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Byron`, LastName: `Murphy`, Position : 'CB', Salary : 650000, SleeperID : 5864 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dre'Mont`, LastName: `Jones`, Position : 'DE', Salary : 650000, SleeperID : 5865 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deionte`, LastName: `Thompson`, Position : 'DB', Salary : 650000, SleeperID : 5866 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalton`, LastName: `Risner`, Position : 'G', Salary : 650000, SleeperID : 5867 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Ford`, Position : 'OL', Salary : 650000, SleeperID : 5856 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Dillard`, Position : 'T', Salary : 650000, SleeperID : 5858 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Little`, Position : 'T', Salary : 650000, SleeperID : 5868 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Jordan`, Position : 'G', Salary : 650000, SleeperID : 5869 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Jones`, Position : 'QB', Salary : 650000, SleeperID : 5870 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Allen`, Position : 'DE', Salary : 650000, SleeperID : 5871 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deebo`, LastName: `Samuel`, Position : 'WR', Salary : 3726877.538, SleeperID : 5872 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Niemann`, Position : 'LB', Salary : 650000, SleeperID : 5576 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Munson`, Position : 'LB', Salary : 650000, SleeperID : 4643 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Ridley`, Position : 'WR', Salary : 650000, SleeperID : 5873 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Beebe`, Position : 'WR', Salary : 650000, SleeperID : 5570 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeromy`, LastName: `Irwin`, Position : 'OT', Salary : 650000, SleeperID : 5571 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henry`, LastName: `Mondeaux`, Position : 'DE', Salary : 650000, SleeperID : 5572 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greer`, LastName: `Martini`, Position : 'OLB', Salary : 650000, SleeperID : 5573 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raymond`, LastName: `Davison`, Position : 'ILB', Salary : 650000, SleeperID : 5574 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devondre`, LastName: `Seymour`, Position : 'OT', Salary : 650000, SleeperID : 5575 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Schwan`, Position : 'DE', Salary : 650000, SleeperID : 4645 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henry`, LastName: `Poggi`, Position : 'FB', Salary : 650000, SleeperID : 5586 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nigel`, LastName: `Tribune`, Position : 'CB', Salary : 650000, SleeperID : 4648 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Wheeler`, Position : 'T', Salary : 650000, SleeperID : 4649 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Warren`, Position : 'RB', Salary : 650000, SleeperID : 5587 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Hunter`, Position : 'G', Salary : 650000, SleeperID : 5577 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arrion`, LastName: `Springs`, Position : 'DB', Salary : 650000, SleeperID : 5578 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Montre`, LastName: `Wade`, Position : 'CB', Salary : 650000, SleeperID : 5579 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frankie`, LastName: `Luvu`, Position : 'LB', Salary : 650000, SleeperID : 5580 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brett`, LastName: `Taylor`, Position : 'LB', Salary : 650000, SleeperID : 5582 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Craig`, LastName: `James`, Position : 'CB', Salary : 650000, SleeperID : 5583 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Callender`, Position : 'G', Salary : 650000, SleeperID : 5584 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colin`, LastName: `Thompson`, Position : 'TE', Salary : 650000, SleeperID : 4647 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Billy`, LastName: `Brown`, Position : 'TE', Salary : 650000, SleeperID : 4650 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `Cothren`, Position : 'DT', Salary : 650000, SleeperID : 5589 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Mutcherson`, Position : 'SS', Salary : 650000, SleeperID : 5585 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Smallwood`, Position : 'WR', Salary : 650000, SleeperID : 5590 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `McCray`, Position : 'DE', Salary : 650000, SleeperID : 5591 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tejan`, LastName: `Koroma`, Position : 'C', Salary : 650000, SleeperID : 5592 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ikenna`, LastName: `Nwokeji`, Position : 'OL', Salary : 650000, SleeperID : 5593 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Orlosky`, Position : 'C', Salary : 650000, SleeperID : 4655 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Perry`, Position : 'CB', Salary : 650000, SleeperID : 4658 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nelson`, LastName: `Adams`, Position : 'DE', Salary : 650000, SleeperID : 4659 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Culkin`, Position : 'TE', Salary : 650000, SleeperID : 4660 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dillon`, LastName: `DeBoer`, Position : 'C', Salary : 650000, SleeperID : 4662 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 4661 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amba`, LastName: `Etta-Tawo`, Position : 'WR', Salary : 650000, SleeperID : 4626 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tueni`, LastName: `Lupeamanu`, Position : 'DT', Salary : 650000, SleeperID : 4629 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaShaun`, LastName: `Amos`, Position : 'CB', Salary : 650000, SleeperID : 4637 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Horton`, Position : 'OLB', Salary : 650000, SleeperID : 4628 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `I'Tavius`, LastName: `Mathers`, Position : 'RB', Salary : 650000, SleeperID : 4630 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charlie`, LastName: `Miller`, Position : 'FS', Salary : 650000, SleeperID : 4631 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carroll`, LastName: `Phillips`, Position : 'DE', Salary : 650000, SleeperID : 4632 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ezra`, LastName: `Robinson`, Position : 'CB', Salary : 650000, SleeperID : 4633 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lenard`, LastName: `Tillery`, Position : 'RB', Salary : 650000, SleeperID : 4636 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Banks`, Position : 'DT', Salary : 650000, SleeperID : 4638 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontrell`, LastName: `Hilliard`, Position : 'RB', Salary : 650000, SleeperID : 5536 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Carrizosa`, Position : 'P', Salary : 650000, SleeperID : 5537 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tae`, LastName: `Davis`, Position : 'LB', Salary : 650000, SleeperID : 5646 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grant`, LastName: `Haley`, Position : 'DB', Salary : 650000, SleeperID : 5647 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avery`, LastName: `Gennesy`, Position : 'G', Salary : 650000, SleeperID : 4627 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Howell`, Position : 'OT', Salary : 650000, SleeperID : 5648 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Toby`, LastName: `Weathersby`, Position : 'OT', Salary : 650000, SleeperID : 5651 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ian`, LastName: `Park`, Position : 'G', Salary : 650000, SleeperID : 5652 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Thomas`, Position : 'CB', Salary : 650000, SleeperID : 5653 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Roberts`, Position : 'SS', Salary : 650000, SleeperID : 5654 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Neal`, Position : 'SS', Salary : 650000, SleeperID : 5655 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Evans`, Position : 'G', Salary : 650000, SleeperID : 5656 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominick`, LastName: `Sanders`, Position : 'FS', Salary : 650000, SleeperID : 5650 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dee`, LastName: `Delaney`, Position : 'CB', Salary : 650000, SleeperID : 5658 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Asantay`, LastName: `Brown`, Position : 'OLB', Salary : 650000, SleeperID : 5657 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Atkins`, Position : 'DT', Salary : 650000, SleeperID : 5659 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frank`, LastName: `Herron`, Position : 'DE', Salary : 650000, SleeperID : 5660 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Lacy`, Position : 'WR', Salary : 650000, SleeperID : 5661 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shane`, LastName: `Wimann`, Position : 'RB', Salary : 650000, SleeperID : 5663 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Moore`, Position : 'DB', Salary : 650000, SleeperID : 5662 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Batson`, Position : 'WR', Salary : 650000, SleeperID : 5289 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Ross`, Position : 'WR', Salary : 650000, SleeperID : 5292 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reese`, LastName: `Fleming`, Position : 'CB', Salary : 650000, SleeperID : 4840 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Mays`, Position : 'LB', Salary : 650000, SleeperID : 4843 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ahmad`, LastName: `Thomas`, Position : 'ILB', Salary : 650000, SleeperID : 4859 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Toth`, Position : 'C', Salary : 650000, SleeperID : 4860 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taybor`, LastName: `Pepper`, Position : 'LS', Salary : 650000, SleeperID : 4846 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Joseph`, Position : 'K', Salary : 650000, SleeperID : 5272 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Rosen`, Position : 'QB', Salary : 650000, SleeperID : 4863 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kamryn`, LastName: `Pettway`, Position : 'RB', Salary : 650000, SleeperID : 5273 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kent`, LastName: `Taylor`, Position : 'TE', Salary : 650000, SleeperID : 4861 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Badet`, Position : 'WR', Salary : 650000, SleeperID : 5275 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deon`, LastName: `Yelder`, Position : 'TE', Salary : 650000, SleeperID : 5277 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Hoppes`, Position : 'TE', Salary : 650000, SleeperID : 5276 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarvion`, LastName: `Franklin`, Position : 'RB', Salary : 650000, SleeperID : 5278 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Wilson`, Position : 'RB', Salary : 650000, SleeperID : 5284 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ka'Raun`, LastName: `White`, Position : 'WR', Salary : 650000, SleeperID : 5283 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deontay`, LastName: `Burnett`, Position : 'WR', Salary : 650000, SleeperID : 5290 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Allen`, Position : 'QB', Salary : 650000, SleeperID : 5286 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Veasy`, Position : 'WR', Salary : 650000, SleeperID : 5293 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mikah`, LastName: `Holder`, Position : 'WR', Salary : 650000, SleeperID : 5294 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Mornay`, LastName: `Pierson-El`, Position : 'WR', Salary : 650000, SleeperID : 5295 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Wellman`, Position : 'FB', Salary : 650000, SleeperID : 5296 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Sieler`, Position : 'DT', Salary : 650000, SleeperID : 5297 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradley`, LastName: `Bozeman`, Position : 'OL', Salary : 650000, SleeperID : 5298 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Dwelley`, Position : 'TE', Salary : 650000, SleeperID : 5285 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Senat`, Position : 'T', Salary : 650000, SleeperID : 5299 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Young`, Position : 'LB', Salary : 650000, SleeperID : 5300 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Averett`, Position : 'CB', Salary : 650000, SleeperID : 5301 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bilal`, LastName: `Nichols`, Position : 'DT', Salary : 650000, SleeperID : 5302 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joel`, LastName: `Iyiegbuniwe`, Position : 'LB', Salary : 650000, SleeperID : 5303 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Diontae`, LastName: `Spencer`, Position : 'WR', Salary : 650000, SleeperID : 5828 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Bright`, Position : 'G', Salary : 650000, SleeperID : 5829 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Eguavoen`, Position : 'LB', Salary : 650000, SleeperID : 5830 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryant`, LastName: `Mitchell`, Position : 'WR', Salary : 650000, SleeperID : 5831 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Irving`, Position : 'LB', Salary : 650000, SleeperID : 4552 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `Kirsch`, Position : 'G', Salary : 650000, SleeperID : 4554 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andy`, LastName: `Phillips`, Position : 'K', Salary : 650000, SleeperID : 4555 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Scearce`, Position : 'OLB', Salary : 650000, SleeperID : 4556 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Carter`, Position : 'DB', Salary : 650000, SleeperID : 4689 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ken`, LastName: `Ekanem`, Position : 'OLB', Salary : 650000, SleeperID : 4690 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Blackmon`, Position : 'CB', Salary : 650000, SleeperID : 4691 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Wylie`, Position : 'G', Salary : 650000, SleeperID : 4693 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Lawler`, Position : 'LB', Salary : 650000, SleeperID : 5307 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rod`, LastName: `Taylor`, Position : 'G', Salary : 650000, SleeperID : 5309 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Callaway`, Position : 'WR', Salary : 650000, SleeperID : 5310 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `R.J.`, LastName: `McIntosh`, Position : 'DT', Salary : 650000, SleeperID : 5311 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Richardson`, Position : 'OL', Salary : 650000, SleeperID : 5313 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Cooke`, Position : 'P', Salary : 650000, SleeperID : 5314 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leon`, LastName: `Jacobs`, Position : 'LB', Salary : 650000, SleeperID : 5315 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Clapp`, Position : 'C', Salary : 650000, SleeperID : 5316 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyson`, LastName: `Graham`, Position : 'SS', Salary : 650000, SleeperID : 4692 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travin`, LastName: `Howard`, Position : 'LB', Salary : 650000, SleeperID : 5306 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rick`, LastName: `Leonard`, Position : 'OT', Salary : 650000, SleeperID : 5312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alize`, LastName: `Mack`, Position : 'TE', Salary : 650000, SleeperID : 5975 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Karan`, LastName: `Higdon`, Position : 'RB', Salary : 650000, SleeperID : 5976 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terry`, LastName: `Godwin`, Position : 'WR', Salary : 650000, SleeperID : 5977 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emeke`, LastName: `Egbule`, Position : 'LB', Salary : 650000, SleeperID : 5978 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Connelly`, Position : 'LB', Salary : 650000, SleeperID : 5979 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Gaskin`, Position : 'RB', Salary : 650000, SleeperID : 5980 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquise`, LastName: `Blair`, Position : 'DB', Salary : 650000, SleeperID : 5982 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Gaines`, Position : 'DT', Salary : 650000, SleeperID : 5981 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sheldrick`, LastName: `Redwine`, Position : 'DB', Salary : 650000, SleeperID : 5983 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Ratliff-Williams`, Position : 'WR', Salary : 650000, SleeperID : 5984 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Foster`, LastName: `Moreau`, Position : 'TE', Salary : 650000, SleeperID : 5985 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Miller`, Position : 'LB', Salary : 650000, SleeperID : 5986 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alexander`, LastName: `Mattison`, Position : 'RB', Salary : 650000, SleeperID : 5987 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitch`, LastName: `Hyatt`, Position : 'OL', Salary : 650000, SleeperID : 5988 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terez`, LastName: `Hall`, Position : 'LB', Salary : 650000, SleeperID : 5990 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trysten`, LastName: `Hill`, Position : 'DT', Salary : 650000, SleeperID : 5989 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emmanuel`, LastName: `Beal`, Position : 'ILB', Salary : 650000, SleeperID : 5557 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Martin`, Position : 'RB', Salary : 650000, SleeperID : 5558 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Warren`, LastName: `Long`, Position : 'OLB', Salary : 650000, SleeperID : 5559 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalid`, LastName: `Hill`, Position : 'FB', Salary : 650000, SleeperID : 5560 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brad`, LastName: `Lundblade`, Position : 'C', Salary : 650000, SleeperID : 5561 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Skyler`, LastName: `Phillips`, Position : 'C', Salary : 650000, SleeperID : 5562 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eddy`, LastName: `Wilson`, Position : 'DT', Salary : 650000, SleeperID : 5563 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Pugh`, Position : 'OLB', Salary : 650000, SleeperID : 5564 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Cabinda`, Position : 'FB', Salary : 650000, SleeperID : 5565 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Allen`, Position : 'FB', Salary : 650000, SleeperID : 5566 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Humphrey`, Position : 'DE', Salary : 650000, SleeperID : 5568 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davin`, LastName: `Bellamy`, Position : 'LB', Salary : 650000, SleeperID : 5673 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ed`, LastName: `Shockley`, Position : 'LB', Salary : 650000, SleeperID : 5675 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Queiro`, Position : 'ILB', Salary : 650000, SleeperID : 5676 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mo`, LastName: `Porter`, Position : 'OT', Salary : 650000, SleeperID : 5677 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ike`, LastName: `Boettger`, Position : 'OG', Salary : 650000, SleeperID : 5678 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Carter`, Position : 'CB', Salary : 650000, SleeperID : 5679 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Thompson`, Position : 'OLB', Salary : 650000, SleeperID : 5680 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnathan`, LastName: `Alston`, Position : 'FS', Salary : 650000, SleeperID : 5681 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cayson`, LastName: `Collins`, Position : 'OLB', Salary : 650000, SleeperID : 5682 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Steinmetz`, Position : 'T', Salary : 650000, SleeperID : 5683 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Chachere`, Position : 'DB', Salary : 650000, SleeperID : 5674 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pat`, LastName: `Afriyie`, Position : 'DE', Salary : 650000, SleeperID : 5702 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deontez`, LastName: `Alexander`, Position : 'WR', Salary : 650000, SleeperID : 5703 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Tepper`, Position : '0', Salary : 650000, SleeperID : 5704 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Franklin`, Position : 'WR', Salary : 650000, SleeperID : 5705 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Price`, Position : 'TE', Salary : 650000, SleeperID : 4832 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Bozick`, Position : 'G', Salary : 650000, SleeperID : 4833 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Butler`, Position : 'LB', Salary : 650000, SleeperID : 4834 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrius`, LastName: `Sims`, Position : 'RB', Salary : 650000, SleeperID : 4835 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Langford`, Position : '0', Salary : 650000, SleeperID : 4836 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlos`, LastName: `Davis`, Position : 'DT', Salary : 650000, SleeperID : 4837 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 4234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Albrecht`, Position : 'OL', Salary : 650000, SleeperID : 4838 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitch`, LastName: `Leidner`, Position : 'QB', Salary : 650000, SleeperID : 4839 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keion`, LastName: `Crossen`, Position : 'DB', Salary : 650000, SleeperID : 5317 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Whaun`, LastName: `Bentley`, Position : 'LB', Salary : 650000, SleeperID : 5318 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Madison`, Position : 'C', Salary : 650000, SleeperID : 5319 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nyheim`, LastName: `Hines`, Position : 'RB', Salary : 650000, SleeperID : 5347 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyzir`, LastName: `White`, Position : 'LB', Salary : 650000, SleeperID : 5348 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Odum`, Position : 'DB', Salary : 650000, SleeperID : 5445 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Korey`, LastName: `Cunningham`, Position : 'T', Salary : 650000, SleeperID : 5349 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Campbell`, Position : 'CB', Salary : 650000, SleeperID : 5350 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Da'Shawn`, LastName: `Hand`, Position : 'DT', Salary : 650000, SleeperID : 5351 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Sharga`, Position : 'FB', Salary : 650000, SleeperID : 5355 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Manzo-Lewis`, Position : 'TE', Salary : 650000, SleeperID : 5358 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Schlottmann`, Position : 'G', Salary : 650000, SleeperID : 5439 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lowell`, LastName: `Lotulelei`, Position : 'DL', Salary : 650000, SleeperID : 5441 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Marshall`, Position : 'DB', Salary : 650000, SleeperID : 5442 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Ossai`, Position : 'LB', Salary : 650000, SleeperID : 5444 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tomasi`, LastName: `Laulile`, Position : 'DE', Salary : 650000, SleeperID : 5446 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lashard`, LastName: `Durr`, Position : 'CB', Salary : 650000, SleeperID : 5447 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Jackson`, Position : 'CB', Salary : 650000, SleeperID : 5448 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Cooper`, Position : 'DB', Salary : 650000, SleeperID : 5449 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leon`, LastName: `Johnson`, Position : 'G', Salary : 650000, SleeperID : 5440 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Mikell`, Position : 'CB', Salary : 650000, SleeperID : 5451 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Bush`, Position : 'LB', Salary : 650000, SleeperID : 5843 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Hockenson`, Position : 'TE', Salary : 3542862.96, SleeperID : 5844 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DK`, LastName: `Metcalf`, Position : 'WR', Salary : 7018264.474, SleeperID : 5846 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dexter`, LastName: `Lawrence`, Position : 'DT', Salary : 650000, SleeperID : 5847 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaleb`, LastName: `McGary`, Position : 'T', Salary : 650000, SleeperID : 5874 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erik`, LastName: `McCoy`, Position : 'OL', Salary : 650000, SleeperID : 5875 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Love`, Position : 'DB', Salary : 650000, SleeperID : 5876 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Skai`, LastName: `Moore`, Position : 'LB', Salary : 650000, SleeperID : 5450 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elgton`, LastName: `Jenkins`, Position : 'G', Salary : 650000, SleeperID : 5877 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `N'Keal`, LastName: `Harry`, Position : 'WR', Salary : 650000, SleeperID : 5878 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Ferguson`, Position : 'LB', Salary : 650000, SleeperID : 5879 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parris`, LastName: `Campbell`, Position : 'WR', Salary : 650000, SleeperID : 5880 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nasir`, LastName: `Adderley`, Position : 'DB', Salary : 650000, SleeperID : 5881 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amani`, LastName: `Oruwariye`, Position : 'CB', Salary : 650000, SleeperID : 5888 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damien`, LastName: `Harris`, Position : 'RB', Salary : 650000, SleeperID : 5890 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yodny`, LastName: `Cajuste`, Position : 'OL', Salary : 650000, SleeperID : 5891 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Montgomery`, Position : 'RB', Salary : 3201642.355, SleeperID : 5892 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Gardner-Johnson`, Position : 'DB', Salary : 650000, SleeperID : 5893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Germaine`, LastName: `Pratt`, Position : 'LB', Salary : 650000, SleeperID : 5894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Love`, Position : 'RB', Salary : 650000, SleeperID : 5889 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `P.J.`, LastName: `Hall`, Position : 'DT', Salary : 650000, SleeperID : 5066 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arden`, LastName: `Key`, Position : 'DE', Salary : 650000, SleeperID : 5067 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kerryon`, LastName: `Johnson`, Position : 'RB', Salary : 650000, SleeperID : 5068 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kylie`, LastName: `Fitts`, Position : 'LB', Salary : 650000, SleeperID : 5069 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeShon`, LastName: `Elliott`, Position : 'DB', Salary : 650000, SleeperID : 5071 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sebastian`, LastName: `Joseph-Day`, Position : 'DT', Salary : 650000, SleeperID : 5073 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamil`, LastName: `Demby`, Position : 'OL', Salary : 650000, SleeperID : 5072 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erik`, LastName: `Magnuson`, Position : 'C', Salary : 650000, SleeperID : 4462 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Boston`, LastName: `Scott`, Position : 'RB', Salary : 650000, SleeperID : 5122 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `McCloskey`, Position : 'RB', Salary : 650000, SleeperID : 4463 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Thomas`, Position : 'TE', Salary : 650000, SleeperID : 5117 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Sanders`, Position : 'K', Salary : 650000, SleeperID : 5119 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Etling`, Position : 'QB', Salary : 650000, SleeperID : 5120 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braxton`, LastName: `Berrios`, Position : 'WR', Salary : 650000, SleeperID : 5121 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trenton`, LastName: `Cannon`, Position : 'RB', Salary : 650000, SleeperID : 5123 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `McGough`, Position : 'QB', Salary : 650000, SleeperID : 5124 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Bawden`, Position : 'FB', Salary : 650000, SleeperID : 5116 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damoun`, LastName: `Patterson`, Position : 'WR', Salary : 650000, SleeperID : 5125 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Allen`, Position : 'QB', Salary : 650000, SleeperID : 5127 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Woodside`, Position : 'QB', Salary : 650000, SleeperID : 5128 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adonis`, LastName: `Jennings`, Position : 'WR', Salary : 650000, SleeperID : 5129 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Proehl`, Position : 'WR', Salary : 650000, SleeperID : 5126 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Auden`, LastName: `Tate`, Position : 'WR', Salary : 650000, SleeperID : 5130 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Jackson`, Position : 'RB', Salary : 650000, SleeperID : 5131 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Richardson`, Position : 'DT', Salary : 650000, SleeperID : 5420 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Albert`, LastName: `Havili`, Position : 'DE', Salary : 650000, SleeperID : 5422 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `Scott`, Position : 'T', Salary : 650000, SleeperID : 5423 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.J.`, LastName: `Clay`, Position : 'CB', Salary : 650000, SleeperID : 5421 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shay`, LastName: `Fields`, Position : 'WR', Salary : 650000, SleeperID : 5431 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Timon`, LastName: `Parris`, Position : 'T', Salary : 650000, SleeperID : 5430 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Salesi`, LastName: `Uhatafe`, Position : 'G', Salary : 650000, SleeperID : 5499 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lord`, LastName: `Hyeamang`, Position : 'DT', Salary : 650000, SleeperID : 5501 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mychealon`, LastName: `Thomas`, Position : 'DE', Salary : 650000, SleeperID : 5502 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.C.`, LastName: `Hassenauer`, Position : 'C', Salary : 650000, SleeperID : 5503 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mackendy`, LastName: `Cheridor`, Position : 'DE', Salary : 650000, SleeperID : 5504 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Tuioti-Mariner`, Position : 'LB', Salary : 650000, SleeperID : 5505 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Winbush`, Position : 'DE', Salary : 650000, SleeperID : 5506 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Secdrick`, LastName: `Cooper`, Position : 'S', Salary : 650000, SleeperID : 5507 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Lammons`, Position : 'CB', Salary : 650000, SleeperID : 5508 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Bosch`, Position : 'G', Salary : 650000, SleeperID : 5509 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Frey`, Position : 'LB', Salary : 650000, SleeperID : 5511 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tracy`, LastName: `Sprinkle`, Position : 'DT', Salary : 650000, SleeperID : 5513 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Perroni`, Position : '0', Salary : 650000, SleeperID : 5514 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Olsen`, Position : 'OL', Salary : 650000, SleeperID : 5515 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frank`, LastName: `Ginda`, Position : 'OLB', Salary : 650000, SleeperID : 5516 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Needham`, Position : 'LB', Salary : 650000, SleeperID : 5517 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Mangen`, Position : 'TE', Salary : 650000, SleeperID : 5245 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Roh`, Position : 'TE', Salary : 650000, SleeperID : 5246 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Marvin`, Position : 'K', Salary : 650000, SleeperID : 5247 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gus`, LastName: `Edwards`, Position : 'RB', Salary : 737293.7071, SleeperID : 5248 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Levrone`, Position : 'WR', Salary : 650000, SleeperID : 5249 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Foster`, Position : 'WR', Salary : 650000, SleeperID : 5250 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Detrich`, LastName: `Clark`, Position : 'WR', Salary : 650000, SleeperID : 5243 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Franks`, Position : 'TE', Salary : 650000, SleeperID : 5253 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Davis`, Position : 'K', Salary : 650000, SleeperID : 5251 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ray`, LastName: `Lawry`, Position : 'RB', Salary : 650000, SleeperID : 5252 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Gray`, Position : 'WR', Salary : 650000, SleeperID : 5244 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Mack`, Position : 'TE', Salary : 650000, SleeperID : 5264 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 5254 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Teo`, LastName: `Redding`, Position : 'WR', Salary : 650000, SleeperID : 5255 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Boyle`, Position : 'QB', Salary : 650000, SleeperID : 5257 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vyncint`, LastName: `Smith`, Position : 'WR', Salary : 650000, SleeperID : 5260 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jester`, LastName: `Weah`, Position : 'WR', Salary : 650000, SleeperID : 5261 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Litton`, Position : 'QB', Salary : 650000, SleeperID : 5263 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nic`, LastName: `Shimonek`, Position : 'QB', Salary : 650000, SleeperID : 5265 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Johnson`, Position : 'TE', Salary : 650000, SleeperID : 5268 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Codey`, LastName: `McElroy`, Position : 'TE', Salary : 650000, SleeperID : 5269 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Ross`, Position : 'DT', Salary : 650000, SleeperID : 4617 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Smith`, Position : 'SS', Salary : 650000, SleeperID : 4618 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dee`, LastName: `Virgin`, Position : 'DB', Salary : 650000, SleeperID : 4619 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avery`, LastName: `Williams`, Position : 'OLB', Salary : 650000, SleeperID : 4620 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Alsadek`, Position : 'G', Salary : 650000, SleeperID : 5540 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Bluiett`, Position : 'TE', Salary : 650000, SleeperID : 4621 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keelan`, LastName: `Cole`, Position : 'WR', Salary : 650000, SleeperID : 4622 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Cook`, Position : 'RB', Salary : 650000, SleeperID : 4623 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `P.J.`, LastName: `Davis`, Position : 'ILB', Salary : 650000, SleeperID : 4624 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fred`, LastName: `Lauina`, Position : 'G', Salary : 650000, SleeperID : 5538 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Willies`, Position : 'WR', Salary : 650000, SleeperID : 5539 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Light`, Position : 'T', Salary : 650000, SleeperID : 5541 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Naashon`, LastName: `Hughes`, Position : 'ILB', Salary : 650000, SleeperID : 5542 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Dimick`, Position : 'OLB', Salary : 650000, SleeperID : 4625 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Johnson`, Position : 'ILB', Salary : 650000, SleeperID : 5543 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Porter`, Position : 'ILB', Salary : 650000, SleeperID : 5544 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raven`, LastName: `Greene`, Position : 'DB', Salary : 650000, SleeperID : 5545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Smith`, Position : 'TE', Salary : 650000, SleeperID : 5547 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Meadows`, Position : 'OT', Salary : 650000, SleeperID : 5546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Hall`, Position : 'CB', Salary : 650000, SleeperID : 5554 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dakoda`, LastName: `Shepley`, Position : 'C', Salary : 650000, SleeperID : 5555 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Hall`, Position : 'OLB', Salary : 650000, SleeperID : 5556 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Stanton`, Position : 'FB', Salary : 650000, SleeperID : 5569 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamar`, LastName: `Summers`, Position : 'FS', Salary : 650000, SleeperID : 5594 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Step`, LastName: `Durham`, Position : 'CB', Salary : 650000, SleeperID : 5595 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brogan`, LastName: `Roback`, Position : 'QB', Salary : 650000, SleeperID : 5597 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daronte`, LastName: `Bouldin`, Position : 'OL', Salary : 650000, SleeperID : 5598 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Hilland`, Position : 'G', Salary : 650000, SleeperID : 5599 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lucas`, LastName: `Gravelle`, Position : 'C', Salary : 650000, SleeperID : 5600 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Claudy`, LastName: `Mathieu`, Position : 'DL', Salary : 650000, SleeperID : 5602 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Moten`, Position : 'DL', Salary : 650000, SleeperID : 5603 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quincy`, LastName: `Redmon`, Position : 'CB', Salary : 650000, SleeperID : 5604 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Dickerson`, Position : 'TE', Salary : 650000, SleeperID : 5756 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Reaves`, Position : 'CB', Salary : 650000, SleeperID : 5596 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Woods`, Position : 'LB', Salary : 650000, SleeperID : 5757 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jackson`, LastName: `Porter`, Position : 'CB', Salary : 650000, SleeperID : 5758 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Holley`, Position : 'DB', Salary : 650000, SleeperID : 5759 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Crockett`, Position : 'WR', Salary : 650000, SleeperID : 5760 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Darling`, Position : 'G', Salary : 650000, SleeperID : 5761 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Smith`, Position : 'WR', Salary : 650000, SleeperID : 5762 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bentley`, LastName: `Spain`, Position : 'OT', Salary : 650000, SleeperID : 5763 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juante`, LastName: `Baldwin`, Position : 'CB', Salary : 650000, SleeperID : 5764 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bo`, LastName: `Bower`, Position : 'OLB', Salary : 650000, SleeperID : 5765 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Beal`, Position : 'CB', Salary : 650000, SleeperID : 5766 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dorian`, LastName: `O'Daniel`, Position : 'LB', Salary : 650000, SleeperID : 5033 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ro'Derrick`, LastName: `Hoskins`, Position : 'LB', Salary : 650000, SleeperID : 5769 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre'Quan`, LastName: `Smith`, Position : 'WR', Salary : 650000, SleeperID : 5026 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Geron`, LastName: `Christian`, Position : 'OL', Salary : 650000, SleeperID : 5027 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duke`, LastName: `Dawson`, Position : 'CB', Salary : 650000, SleeperID : 5028 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Akins`, Position : 'TE', Salary : 650000, SleeperID : 5032 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Martinas`, LastName: `Rankin`, Position : 'OT', Salary : 650000, SleeperID : 5034 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Parker`, Position : 'T', Salary : 650000, SleeperID : 5065 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Kiser`, Position : 'LB', Salary : 650000, SleeperID : 5074 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Kelly`, Position : 'RB', Salary : 650000, SleeperID : 5076 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davontae`, LastName: `Harris`, Position : 'CB', Salary : 650000, SleeperID : 5078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Phillips`, Position : 'CB', Salary : 650000, SleeperID : 5079 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damion`, LastName: `Ratley`, Position : 'WR', Salary : 650000, SleeperID : 5080 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Genard`, LastName: `Avery`, Position : 'LB', Salary : 650000, SleeperID : 5081 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ogbo`, LastName: `Okoronkwo`, Position : 'LB', Salary : 650000, SleeperID : 5075 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Natrell`, LastName: `Jamerson`, Position : 'DB', Salary : 650000, SleeperID : 5084 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Sam`, Position : 'OLB', Salary : 650000, SleeperID : 5085 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquez`, LastName: `Valdes-Scantling`, Position : 'WR', Salary : 650000, SleeperID : 5086 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JK`, LastName: `Scott`, Position : 'P', Salary : 650000, SleeperID : 5087 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Brown`, Position : 'DT', Salary : 650000, SleeperID : 5077 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duke`, LastName: `Ejiofor`, Position : 'LB', Salary : 650000, SleeperID : 5088 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `White`, Position : 'QB', Salary : 650000, SleeperID : 5089 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Foley`, LastName: `Fatukasi`, Position : 'DT', Salary : 650000, SleeperID : 5090 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Simeon`, LastName: `Thomas`, Position : 'CB', Salary : 650000, SleeperID : 5082 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parry`, LastName: `Nickerson`, Position : 'CB', Salary : 650000, SleeperID : 5091 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Fumagalli`, Position : 'TE', Salary : 650000, SleeperID : 5092 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Jones`, Position : 'G', Salary : 650000, SleeperID : 5093 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Izzo`, Position : 'TE', Salary : 650000, SleeperID : 5094 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmie`, LastName: `Gilbert`, Position : 'OLB', Salary : 650000, SleeperID : 4459 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Mitchell`, Position : 'WR', Salary : 650000, SleeperID : 5132 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Kirkwood`, Position : 'WR', Salary : 650000, SleeperID : 5134 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Conklin`, Position : 'TE', Salary : 650000, SleeperID : 5133 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Adams`, Position : 'RB', Salary : 650000, SleeperID : 5136 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richie`, LastName: `James`, Position : 'WR', Salary : 650000, SleeperID : 5137 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Martez`, LastName: `Carter`, Position : 'RB', Salary : 650000, SleeperID : 5138 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Quinn`, Position : 'WR', Salary : 650000, SleeperID : 5139 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaelon`, LastName: `Acklin`, Position : 'WR', Salary : 650000, SleeperID : 5140 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Crawford`, Position : 'LB', Salary : 650000, SleeperID : 5141 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hamp`, LastName: `Cheevers`, Position : 'CB', Salary : 650000, SleeperID : 5909 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Powers`, Position : 'G', Salary : 650000, SleeperID : 5910 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Hurd`, Position : 'WR', Salary : 650000, SleeperID : 5911 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carl`, LastName: `Granderson`, Position : 'DE', Salary : 650000, SleeperID : 5912 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `McGovern`, Position : 'G', Salary : 650000, SleeperID : 5913 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Long`, Position : 'CB', Salary : 650000, SleeperID : 5914 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andy`, LastName: `Isabella`, Position : 'WR', Salary : 650000, SleeperID : 5915 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mecole`, LastName: `Hardman`, Position : 'WR', Salary : 650000, SleeperID : 5917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Anderson`, Position : 'RB', Salary : 650000, SleeperID : 5918 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Holyfield`, Position : 'RB', Salary : 650000, SleeperID : 5919 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Harris`, Position : 'DB', Salary : 650000, SleeperID : 5920 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kris`, LastName: `Boyd`, Position : 'CB', Salary : 650000, SleeperID : 5921 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Henderson`, Position : 'RB', Salary : 650000, SleeperID : 5916 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Bell`, Position : 'LB', Salary : 650000, SleeperID : 5922 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Jackson`, Position : 'DE', Salary : 650000, SleeperID : 5923 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emanuel`, LastName: `Hall`, Position : 'WR', Salary : 650000, SleeperID : 5924 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontavius`, LastName: `Russell`, Position : 'NT', Salary : 650000, SleeperID : 5926 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Long`, Position : 'LB', Salary : 650000, SleeperID : 5928 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 5929 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaquan`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 5930 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Beau`, LastName: `Benzschawel`, Position : 'G', Salary : 650000, SleeperID : 5932 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bless`, LastName: `Austin`, Position : 'DB', Salary : 650000, SleeperID : 5933 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Omenihu`, Position : 'DE', Salary : 650000, SleeperID : 5934 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terry`, LastName: `Beckner`, Position : 'DT', Salary : 650000, SleeperID : 5925 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `McNitt`, Position : 'FB', Salary : 650000, SleeperID : 5145 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Gono`, Position : 'OT', Salary : 650000, SleeperID : 5146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terry`, LastName: `McLaurin`, Position : 'WR', Salary : 2549261.329, SleeperID : 5927 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emmanuel`, LastName: `Ellerbee`, Position : 'LB', Salary : 650000, SleeperID : 5147 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Jelks`, Position : 'DE', Salary : 650000, SleeperID : 5935 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Davis`, Position : 'G', Salary : 650000, SleeperID : 5936 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Diontae`, LastName: `Johnson`, Position : 'WR', Salary : 2135239.859, SleeperID : 5937 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lil'Jordan`, LastName: `Humphrey`, Position : 'WR', Salary : 650000, SleeperID : 5938 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrill`, LastName: `Hanks`, Position : 'OLB', Salary : 650000, SleeperID : 5939 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Landis`, LastName: `Durham`, Position : 'OLB', Salary : 650000, SleeperID : 5940 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Felton`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 5941 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Bryant`, Position : 'LB', Salary : 650000, SleeperID : 5942 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Pierschbacher`, Position : 'OL', Salary : 650000, SleeperID : 5943 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Ledbetter`, Position : 'DE', Salary : 650000, SleeperID : 5945 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamel`, LastName: `Dean`, Position : 'CB', Salary : 650000, SleeperID : 5946 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jakobi`, LastName: `Meyers`, Position : 'WR', Salary : 925974.4123, SleeperID : 5947 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobby`, LastName: `Evans`, Position : 'OL', Salary : 650000, SleeperID : 5948 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paul`, LastName: `Adams`, Position : 'OT', Salary : 650000, SleeperID : 5949 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nyqwan`, LastName: `Murray`, Position : 'WR', Salary : 650000, SleeperID : 5950 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 5951 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Hansen`, Position : 'LB', Salary : 650000, SleeperID : 5952 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Brailford`, Position : 'DE', Salary : 650000, SleeperID : 5954 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Herbig`, Position : 'G', Salary : 650000, SleeperID : 5963 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Prince`, Position : 'T', Salary : 650000, SleeperID : 5956 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Nauta`, Position : 'TE', Salary : 650000, SleeperID : 5957 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JoJo`, LastName: `McIntosh`, Position : 'SS', Salary : 650000, SleeperID : 5958 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Edwards`, Position : 'DB', Salary : 650000, SleeperID : 5959 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Edwards`, Position : 'LB', Salary : 650000, SleeperID : 5960 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalin`, LastName: `Moore`, Position : 'RB', Salary : 650000, SleeperID : 5961 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KeeSean`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 5962 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demarcus`, LastName: `Christmas`, Position : 'DT', Salary : 650000, SleeperID : 5953 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Renfrow`, Position : 'WR', Salary : 650000, SleeperID : 5955 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dennis`, LastName: `Daley`, Position : 'G', Salary : 650000, SleeperID : 5964 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `French`, Position : 'OLB', Salary : 650000, SleeperID : 4816 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corin`, LastName: `Brooks`, Position : 'G', Salary : 650000, SleeperID : 4818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamari`, LastName: `Staples`, Position : 'WR', Salary : 650000, SleeperID : 4821 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Foreman`, Position : 'CB', Salary : 650000, SleeperID : 4822 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keevan`, LastName: `Lucas`, Position : 'WR', Salary : 650000, SleeperID : 4823 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Pace`, Position : 'OT', Salary : 650000, SleeperID : 4826 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Germone`, LastName: `Hopper`, Position : 'WR', Salary : 650000, SleeperID : 4827 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashard`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 4828 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 4829 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Gearing`, Position : 'LB', Salary : 650000, SleeperID : 4830 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justice`, LastName: `Liggins`, Position : 'WR', Salary : 650000, SleeperID : 4831 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Rapp`, Position : 'DB', Salary : 650000, SleeperID : 5882 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lonnie`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 5883 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kelvin`, LastName: `Harmon`, Position : 'WR', Salary : 650000, SleeperID : 5884 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Herman`, Position : 'OLB', Salary : 650000, SleeperID : 4817 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Finley`, Position : 'QB', Salary : 650000, SleeperID : 5885 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hakeem`, LastName: `Butler`, Position : 'TE', Salary : 650000, SleeperID : 5886 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dru`, LastName: `Samia`, Position : 'OL', Salary : 650000, SleeperID : 5887 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Lindstrom`, Position : 'G', Salary : 650000, SleeperID : 5895 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Lamar`, Position : 'ILB', Salary : 650000, SleeperID : 5896 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Noteboom`, Position : 'OL', Salary : 650000, SleeperID : 5014 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Hubbard`, Position : 'DE', Salary : 650000, SleeperID : 5015 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Jefferson`, Position : 'LB', Salary : 650000, SleeperID : 5016 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jessie`, LastName: `Bates`, Position : 'DB', Salary : 650000, SleeperID : 5017 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Hernandez`, Position : 'G', Salary : 650000, SleeperID : 5018 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Thomas`, Position : 'DE', Salary : 650000, SleeperID : 5019 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.J.`, LastName: `Hill`, Position : 'DT', Salary : 650000, SleeperID : 5020 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lorenzo`, LastName: `Carter`, Position : 'LB', Salary : 650000, SleeperID : 5021 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chukwuma`, LastName: `Okorafor`, Position : 'OL', Salary : 650000, SleeperID : 5023 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Washington`, Position : 'WR', Salary : 650000, SleeperID : 5024 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronnie`, LastName: `Harrison`, Position : 'DB', Salary : 650000, SleeperID : 5025 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobby`, LastName: `Okereke`, Position : 'LB', Salary : 650000, SleeperID : 5944 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wyatt`, LastName: `Ray`, Position : 'DE', Salary : 650000, SleeperID : 5966 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Pollard`, Position : 'RB', Salary : 3633705.6, SleeperID : 5967 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Fields`, Position : 'CB', Salary : 650000, SleeperID : 5968 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dallas`, LastName: `Goedert`, Position : 'TE', Salary : 1388433.663, SleeperID : 5022 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Oliver`, Position : 'TE', Salary : 650000, SleeperID : 5973 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Miles`, LastName: `Boykin`, Position : 'WR', Salary : 650000, SleeperID : 5965 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chuma`, LastName: `Edoga`, Position : 'OT', Salary : 650000, SleeperID : 5969 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Dortch`, Position : 'WR', Salary : 650000, SleeperID : 5970 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.J.`, LastName: `Autry`, Position : 'G', Salary : 650000, SleeperID : 5972 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trace`, LastName: `McSorley`, Position : 'QB', Salary : 650000, SleeperID : 5974 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maxx`, LastName: `Crosby`, Position : 'DE', Salary : 650000, SleeperID : 5991 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Gilmore`, Position : 'DT', Salary : 650000, SleeperID : 5184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Cota`, LastName: `Dixon`, Position : 'FS', Salary : 650000, SleeperID : 5999 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Bates`, Position : 'OL', Salary : 650000, SleeperID : 6000 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Sample`, Position : 'TE', Salary : 650000, SleeperID : 6001 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Qadree`, LastName: `Ollison`, Position : 'RB', Salary : 650000, SleeperID : 6002 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.C.`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 5193 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Nelson`, Position : 'LB', Salary : 650000, SleeperID : 6003 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `James`, Position : 'C', Salary : 650000, SleeperID : 6004 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ola`, LastName: `Adeniyi`, Position : 'LB', Salary : 650000, SleeperID : 5183 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Allen`, LastName: `Lazard`, Position : 'WR', Salary : 650000, SleeperID : 5185 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quenton`, LastName: `Meeks`, Position : 'CB', Salary : 650000, SleeperID : 5188 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eddy`, LastName: `Pineiro`, Position : 'K', Salary : 650000, SleeperID : 5189 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cory`, LastName: `Helms`, Position : 'G', Salary : 650000, SleeperID : 5190 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Stallworth`, Position : 'DT', Salary : 650000, SleeperID : 5191 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Akrum`, LastName: `Wadley`, Position : 'RB', Salary : 650000, SleeperID : 5195 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ethan`, LastName: `Wolf`, Position : 'TE', Salary : 650000, SleeperID : 5196 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rico`, LastName: `Gafford`, Position : 'WR', Salary : 650000, SleeperID : 5197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Apke`, Position : 'DB', Salary : 650000, SleeperID : 5365 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Jackson`, Position : 'DE', Salary : 650000, SleeperID : 5186 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Settle`, Position : 'DT', Salary : 650000, SleeperID : 5366 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaun Dion`, LastName: `Hamilton`, Position : 'LB', Salary : 650000, SleeperID : 5367 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Stroman`, Position : 'CB', Salary : 650000, SleeperID : 5368 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dane`, LastName: `Cruikshank`, Position : 'DB', Salary : 650000, SleeperID : 5369 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kentavius`, LastName: `Street`, Position : 'DT', Salary : 650000, SleeperID : 5371 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Reed`, Position : 'CB', Salary : 650000, SleeperID : 5372 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Watson`, Position : 'WR', Salary : 650000, SleeperID : 5374 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Whitehead`, Position : 'DB', Salary : 650000, SleeperID : 5375 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Cichy`, Position : 'LB', Salary : 650000, SleeperID : 5376 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamarco`, LastName: `Jones`, Position : 'T', Salary : 650000, SleeperID : 5377 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Martin`, Position : 'DE', Salary : 650000, SleeperID : 5378 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Flowers`, Position : 'CB', Salary : 650000, SleeperID : 5379 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Facyson`, Position : 'CB', Salary : 650000, SleeperID : 5416 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcell`, LastName: `Harris`, Position : 'LB', Salary : 650000, SleeperID : 5370 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devonte`, LastName: `Boyd`, Position : 'WR', Salary : 650000, SleeperID : 5608 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Edmond`, Position : 'DB', Salary : 650000, SleeperID : 5417 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Durant`, Position : 'OT', Salary : 650000, SleeperID : 5419 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Brown`, Position : 'DB', Salary : 650000, SleeperID : 5424 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Diarse`, Position : 'WR', Salary : 650000, SleeperID : 5425 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jullian`, LastName: `Taylor`, Position : 'DT', Salary : 650000, SleeperID : 5373 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `McKay`, LastName: `Murphy`, Position : 'DT', Salary : 650000, SleeperID : 5426 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaQuvionte`, LastName: `Gonzalez`, Position : 'WR', Salary : 650000, SleeperID : 5427 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Kolone`, Position : 'OL', Salary : 650000, SleeperID : 5429 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Marks`, Position : 'WR', Salary : 650000, SleeperID : 5548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrel`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 5549 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John David`, LastName: `Moore`, Position : 'RB', Salary : 650000, SleeperID : 5550 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Murray`, Position : 'OL', Salary : 650000, SleeperID : 5551 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Golson`, Position : 'C', Salary : 650000, SleeperID : 5552 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `James`, Position : 'G', Salary : 650000, SleeperID : 5553 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gaelin`, LastName: `Elmore`, Position : 'DE', Salary : 650000, SleeperID : 5606 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Von`, LastName: `Rolland-Jones`, Position : 'DE', Salary : 650000, SleeperID : 5607 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricky`, LastName: `Jeune`, Position : 'WR', Salary : 650000, SleeperID : 5428 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Baggett`, Position : 'TE', Salary : 650000, SleeperID : 5609 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Brown`, Position : 'C', Salary : 650000, SleeperID : 5610 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrell`, LastName: `Chavis`, Position : 'NT', Salary : 650000, SleeperID : 5611 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Mahoungou`, Position : 'WR', Salary : 650000, SleeperID : 5612 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bruce`, LastName: `Hector`, Position : 'DE', Salary : 650000, SleeperID : 5613 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nico`, LastName: `Falah`, Position : 'C', Salary : 650000, SleeperID : 5614 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Dickerson`, Position : 'DE', Salary : 650000, SleeperID : 5615 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Ramsay`, Position : 'NT', Salary : 650000, SleeperID : 5616 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Barnard`, Position : 'P', Salary : 650000, SleeperID : 5617 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Rose`, Position : 'RB', Salary : 650000, SleeperID : 5618 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalyn`, LastName: `Dawkins`, Position : 'RB', Salary : 650000, SleeperID : 5619 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijaah`, LastName: `Goins`, Position : 'FS', Salary : 650000, SleeperID : 5620 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Gentry`, Position : 'G', Salary : 650000, SleeperID : 5621 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Wilkerson`, Position : 'DE', Salary : 650000, SleeperID : 5622 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Love`, Position : 'DE', Salary : 650000, SleeperID : 5623 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bunmi`, LastName: `Rotimi`, Position : 'DE', Salary : 650000, SleeperID : 5626 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Trumbetti`, Position : 'DE', Salary : 650000, SleeperID : 5627 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cavon`, LastName: `Walker`, Position : 'DT', Salary : 650000, SleeperID : 5628 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Toliver`, Position : 'CB', Salary : 650000, SleeperID : 5629 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Orr`, Position : 'SS', Salary : 650000, SleeperID : 5630 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dejon`, LastName: `Allen`, Position : 'G', Salary : 650000, SleeperID : 5631 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Norris`, Position : 'ILB', Salary : 650000, SleeperID : 5632 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nyles`, LastName: `Morgan`, Position : 'ILB', Salary : 650000, SleeperID : 5633 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Joseph`, Position : 'CB', Salary : 650000, SleeperID : 5634 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashard`, LastName: `Fant`, Position : 'CB', Salary : 650000, SleeperID : 5635 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Abdullah`, LastName: `Anderson`, Position : 'DE', Salary : 650000, SleeperID : 5636 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 5637 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaq`, LastName: `Roland`, Position : 'WR', Salary : 650000, SleeperID : 5638 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Winslow`, Position : 'P', Salary : 650000, SleeperID : 5639 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Junior`, LastName: `Joseph`, Position : 'ILB', Salary : 650000, SleeperID : 5640 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trayvon`, LastName: `Henderson`, Position : 'DB', Salary : 650000, SleeperID : 5642 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Fleer`, Position : 'OT', Salary : 650000, SleeperID : 5643 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryon`, LastName: `Fields`, Position : 'DB', Salary : 650000, SleeperID : 5644 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Gates`, Position : 'C', Salary : 650000, SleeperID : 5645 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `Harris`, Position : 'LB', Salary : 650000, SleeperID : 5664 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Diaz`, Position : 'OT', Salary : 650000, SleeperID : 5666 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Nkansah`, Position : 'OL', Salary : 650000, SleeperID : 5667 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Stinnie`, Position : 'G', Salary : 650000, SleeperID : 5668 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Kalu`, Position : 'DB', Salary : 650000, SleeperID : 5671 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `McKinley`, Position : 'CB', Salary : 650000, SleeperID : 5672 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremi`, LastName: `Hall`, Position : 'G', Salary : 650000, SleeperID : 5706 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrin`, LastName: `Holloway`, Position : 'CB', Salary : 650000, SleeperID : 5707 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sharif`, LastName: `Finch`, Position : 'LB', Salary : 650000, SleeperID : 5669 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Manase`, LastName: `Hungalu`, Position : 'OLB', Salary : 650000, SleeperID : 5717 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luis`, LastName: `Perez`, Position : 'QB', Salary : 650000, SleeperID : 5709 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Holley`, Position : 'WR', Salary : 650000, SleeperID : 5710 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Womac`, Position : 'OLB', Salary : 650000, SleeperID : 5711 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 5713 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Wilson`, Position : 'WR', Salary : 650000, SleeperID : 5714 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `R.J.`, LastName: `Prince`, Position : 'OG', Salary : 650000, SleeperID : 5715 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Smith`, Position : 'OT', Salary : 650000, SleeperID : 5716 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Da'Sean`, LastName: `Downey`, Position : 'DE', Salary : 650000, SleeperID : 5708 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrice`, LastName: `Beverette`, Position : 'FS', Salary : 650000, SleeperID : 5712 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darvin`, LastName: `Kidsy`, Position : 'WR', Salary : 650000, SleeperID : 5722 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Yurachek`, Position : 'TE', Salary : 650000, SleeperID : 5718 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KeShun`, LastName: `Freeman`, Position : 'OLB', Salary : 650000, SleeperID : 5719 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vontae`, LastName: `Diggs`, Position : 'OLB', Salary : 650000, SleeperID : 5720 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Bojorquez`, Position : 'P', Salary : 650000, SleeperID : 5721 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joseph`, LastName: `Este`, Position : 'S', Salary : 650000, SleeperID : 5724 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tobenna`, LastName: `Okeke`, Position : 'OLB', Salary : 650000, SleeperID : 5725 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Spillane`, Position : 'LB', Salary : 650000, SleeperID : 5726 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Thurman`, Position : 'DE', Salary : 650000, SleeperID : 5727 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Calhoun`, Position : 'OT', Salary : 650000, SleeperID : 5728 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinterrius`, LastName: `Eatmon`, Position : 'OT', Salary : 650000, SleeperID : 5729 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Myers`, Position : 'CB', Salary : 650000, SleeperID : 5730 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ruben`, LastName: `Holcomb`, Position : 'G', Salary : 650000, SleeperID : 5731 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Machorro`, Position : 'OT', Salary : 650000, SleeperID : 5732 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clayton`, LastName: `Wilson`, Position : 'TE', Salary : 650000, SleeperID : 5733 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Duncan`, Position : 'WR', Salary : 650000, SleeperID : 5734 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blaine`, LastName: `Woodson`, Position : 'DE', Salary : 650000, SleeperID : 5742 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Airius`, LastName: `Moore`, Position : 'ILB', Salary : 650000, SleeperID : 5735 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dallin`, LastName: `Leavitt`, Position : 'DB', Salary : 650000, SleeperID : 5736 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Scott`, Position : 'LS', Salary : 650000, SleeperID : 5737 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Okonye`, Position : 'CB', Salary : 650000, SleeperID : 5738 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Afolabi`, LastName: `Laguda`, Position : 'FS', Salary : 650000, SleeperID : 5739 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chucky`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 5740 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Parker`, Position : 'DB', Salary : 650000, SleeperID : 5741 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Cirino`, Position : 'S', Salary : 650000, SleeperID : 5743 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eldridge`, LastName: `Massington`, Position : 'WR', Salary : 650000, SleeperID : 5744 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Stevens`, Position : 'QB', Salary : 650000, SleeperID : 5745 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaheed`, LastName: `Salmon`, Position : 'ILB', Salary : 650000, SleeperID : 5746 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Hudson`, Position : 'TE', Salary : 650000, SleeperID : 5750 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeMarquis`, LastName: `Gates`, Position : 'ILB', Salary : 650000, SleeperID : 5748 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Roberts`, Position : 'TE', Salary : 650000, SleeperID : 5751 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sherman`, LastName: `Badie`, Position : 'RB', Salary : 650000, SleeperID : 5752 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kayaune`, LastName: `Ross`, Position : 'WR', Salary : 650000, SleeperID : 5747 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Board`, Position : 'LB', Salary : 650000, SleeperID : 5753 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaeden`, LastName: `Graham`, Position : 'TE', Salary : 650000, SleeperID : 5754 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.P.`, LastName: `Quinn`, Position : 'C', Salary : 650000, SleeperID : 5770 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kiante`, LastName: `Anderson`, Position : 'DE', Salary : 650000, SleeperID : 5771 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Lacombe`, Position : 'WR', Salary : 650000, SleeperID : 5772 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KhaDarel`, LastName: `Hodge`, Position : 'WR', Salary : 650000, SleeperID : 5773 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Serigne`, Position : 'TE', Salary : 650000, SleeperID : 5774 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Murphy`, Position : 'WR', Salary : 650000, SleeperID : 5775 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Watt`, Position : 'LB', Salary : 650000, SleeperID : 4070 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marlon`, LastName: `Humphrey`, Position : 'CB', Salary : 650000, SleeperID : 4071 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre'Davious`, LastName: `White`, Position : 'CB', Salary : 650000, SleeperID : 4072 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taco`, LastName: `Charlton`, Position : 'DE', Salary : 650000, SleeperID : 4073 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Hooker`, Position : 'DB', Salary : 650000, SleeperID : 4074 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Harris`, Position : 'LB', Salary : 650000, SleeperID : 4075 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Ramczyk`, Position : 'T', Salary : 650000, SleeperID : 4076 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAngelo`, LastName: `Yancey`, Position : 'WR', Salary : 650000, SleeperID : 4200 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Hairston`, Position : 'CB', Salary : 650000, SleeperID : 4202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Walker`, Position : 'LB', Salary : 650000, SleeperID : 4203 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ukeme`, LastName: `Eligwe`, Position : 'LB', Salary : 650000, SleeperID : 4205 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davon`, LastName: `Godchaux`, Position : 'DT', Salary : 650000, SleeperID : 4207 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Adams`, Position : 'WR', Salary : 650000, SleeperID : 4208 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Barrett`, LastName: `Gouger`, Position : 'C', Salary : 650000, SleeperID : 4801 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Nelson`, Position : 'C', Salary : 650000, SleeperID : 4802 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Iese`, Position : 'TE', Salary : 650000, SleeperID : 4803 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Brown`, Position : 'OT', Salary : 650000, SleeperID : 4804 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Radcliff`, Position : 'RB', Salary : 650000, SleeperID : 4806 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Estes`, Position : 'TE', Salary : 650000, SleeperID : 4807 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maurice`, LastName: `Swain`, Position : 'DT', Salary : 650000, SleeperID : 4808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Greene`, Position : 'G', Salary : 650000, SleeperID : 4809 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Calitro`, Position : 'LB', Salary : 650000, SleeperID : 4811 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kwayde`, LastName: `Miller`, Position : 'OT', Salary : 650000, SleeperID : 4810 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pig`, LastName: `Howard`, Position : 'WR', Salary : 650000, SleeperID : 4805 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terence`, LastName: `Waugh`, Position : 'LB', Salary : 650000, SleeperID : 4812 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Quan`, LastName: `Hampton`, Position : 'WR', Salary : 650000, SleeperID : 4814 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marvin`, LastName: `Bracy`, Position : 'WR', Salary : 650000, SleeperID : 4813 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Riley`, Position : 'WR', Salary : 650000, SleeperID : 4815 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Banogu`, Position : 'DE', Salary : 650000, SleeperID : 5971 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Crawford`, Position : 'FS', Salary : 650000, SleeperID : 5992 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dakota`, LastName: `Allen`, Position : 'LB', Salary : 650000, SleeperID : 5993 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justice`, LastName: `Hill`, Position : 'RB', Salary : 650000, SleeperID : 5995 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Porter`, LastName: `Gustin`, Position : 'DE', Salary : 650000, SleeperID : 5996 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jazz`, LastName: `Ferguson`, Position : 'WR', Salary : 650000, SleeperID : 5997 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clifton`, LastName: `Duck`, Position : 'CB', Salary : 650000, SleeperID : 5998 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Givens`, Position : 'DT', Salary : 650000, SleeperID : 6005 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `James`, Position : 'C', Salary : 650000, SleeperID : 6004 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Byron`, LastName: `Cowart`, Position : 'DT', Salary : 650000, SleeperID : 6006 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryquell`, LastName: `Armstead`, Position : 'RB', Salary : 650000, SleeperID : 6007 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yosh`, LastName: `Nijman`, Position : 'T', Salary : 650000, SleeperID : 6008 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Miller`, Position : 'DB', Salary : 650000, SleeperID : 6009 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chidi`, LastName: `Okeke`, Position : 'OT', Salary : 650000, SleeperID : 6010 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gardner`, LastName: `Minshew`, Position : 'QB', Salary : 650000, SleeperID : 6011 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Homer`, Position : 'RB', Salary : 650000, SleeperID : 6012 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Saquan`, LastName: `Hampton`, Position : 'DB', Salary : 650000, SleeperID : 6013 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashad`, LastName: `Fenton`, Position : 'CB', Salary : 650000, SleeperID : 6014 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deshaun`, LastName: `Davis`, Position : 'OLB', Salary : 650000, SleeperID : 6015 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dax`, LastName: `Raymond`, Position : 'TE', Salary : 650000, SleeperID : 6016 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaMarkus`, LastName: `Lodge`, Position : 'WR', Salary : 650000, SleeperID : 6017 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Gentry`, Position : 'TE', Salary : 650000, SleeperID : 6018 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnnie`, LastName: `Dixon`, Position : 'WR', Salary : 650000, SleeperID : 6019 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Te'von`, LastName: `Coney`, Position : 'LB', Salary : 650000, SleeperID : 6020 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Brossette`, Position : 'RB', Salary : 650000, SleeperID : 6021 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Albert`, LastName: `Huggins`, Position : 'DT', Salary : 650000, SleeperID : 6026 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Jackson`, Position : 'CB', Salary : 650000, SleeperID : 6025 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Patterson`, Position : 'OL', Salary : 650000, SleeperID : 6022 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Hall`, Position : 'LB', Salary : 650000, SleeperID : 6027 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fred`, LastName: `Johnson`, Position : 'T', Salary : 650000, SleeperID : 6024 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Iman`, LastName: `Marshall`, Position : 'CB', Salary : 650000, SleeperID : 6023 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derwin`, LastName: `Gray`, Position : 'T', Salary : 650000, SleeperID : 6028 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keelan`, LastName: `Doss`, Position : 'WR', Salary : 650000, SleeperID : 6032 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brett`, LastName: `Rypien`, Position : 'QB', Salary : 650000, SleeperID : 6037 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyree`, LastName: `Jackson`, Position : 'TE', Salary : 650000, SleeperID : 6040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damian`, LastName: `Prince`, Position : 'OT', Salary : 650000, SleeperID : 6038 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Buggs`, Position : 'DE', Salary : 650000, SleeperID : 6033 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Brown`, Position : 'DB', Salary : 650000, SleeperID : 6030 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amani`, LastName: `Bledsoe`, Position : 'DE', Salary : 650000, SleeperID : 6043 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Bars`, Position : 'OL', Salary : 650000, SleeperID : 6044 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gary`, LastName: `Jennings`, Position : 'WR', Salary : 650000, SleeperID : 6045 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Dineen`, Position : 'ILB', Salary : 650000, SleeperID : 6046 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dillon`, LastName: `Mitchell`, Position : 'WR', Salary : 650000, SleeperID : 6047 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daylon`, LastName: `Mack`, Position : 'DT', Salary : 650000, SleeperID : 6048 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyre`, LastName: `Brady`, Position : 'WR', Salary : 650000, SleeperID : 6049 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Knight`, Position : 'T', Salary : 650000, SleeperID : 6050 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darryl`, LastName: `Johnson`, Position : 'DE', Salary : 650000, SleeperID : 6051 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jesse`, LastName: `Burkett`, Position : 'C', Salary : 650000, SleeperID : 6053 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Barnes`, Position : 'RB', Salary : 650000, SleeperID : 6031 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jackson`, LastName: `Barton`, Position : 'T', Salary : 650000, SleeperID : 6029 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Johnson`, Position : 'RB', Salary : 650000, SleeperID : 6039 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davante`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 6042 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 6036 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donnie`, LastName: `Lewis`, Position : 'CB', Salary : 650000, SleeperID : 6034 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Baity`, Position : 'CB', Salary : 650000, SleeperID : 6054 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Natrez`, LastName: `Patrick`, Position : 'LB', Salary : 650000, SleeperID : 6055 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dre`, LastName: `Greenlaw`, Position : 'LB', Salary : 650000, SleeperID : 6056 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ulysees`, LastName: `Gilbert`, Position : 'LB', Salary : 650000, SleeperID : 6057 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lamont`, LastName: `Gaillard`, Position : 'C', Salary : 650000, SleeperID : 6058 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Fulgham`, Position : 'WR', Salary : 650000, SleeperID : 6059 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Murphy-Bunting`, Position : 'CB', Salary : 650000, SleeperID : 6060 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Lewis`, Position : 'ILB', Salary : 650000, SleeperID : 6061 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Jones`, Position : 'G', Salary : 650000, SleeperID : 6062 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyron`, LastName: `Billy-Johnson`, Position : 'WR', Salary : 650000, SleeperID : 6063 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `E.J.`, LastName: `Ejiya`, Position : 'OLB', Salary : 650000, SleeperID : 6064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Barton`, Position : 'LB', Salary : 650000, SleeperID : 6065 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joey`, LastName: `Alfieri`, Position : 'OLB', Salary : 650000, SleeperID : 6066 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Pope`, Position : 'OT', Salary : 650000, SleeperID : 6067 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devine`, LastName: `Ozigbo`, Position : 'RB', Salary : 650000, SleeperID : 6068 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stanley`, LastName: `Morgan`, Position : 'WR', Salary : 650000, SleeperID : 6069 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Moreland`, Position : 'CB', Salary : 650000, SleeperID : 6070 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Glenn`, Position : 'S', Salary : 650000, SleeperID : 6071 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bruce`, LastName: `Anderson`, Position : 'RB', Salary : 650000, SleeperID : 6072 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Pulley`, Position : 'CB', Salary : 650000, SleeperID : 6073 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donald`, LastName: `Parham`, Position : 'TE', Salary : 650000, SleeperID : 6074 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jesper`, LastName: `Horsted`, Position : 'TE', Salary : 650000, SleeperID : 6075 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Hollins`, Position : 'LB', Salary : 650000, SleeperID : 6076 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phil`, LastName: `Haynes`, Position : 'OG', Salary : 650000, SleeperID : 6077 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Natour`, Position : 'G', Salary : 650000, SleeperID : 6078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gary`, LastName: `Johnson`, Position : 'ILB', Salary : 650000, SleeperID : 6079 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vitas`, LastName: `Hrynkiewicz`, Position : 'C', Salary : 650000, SleeperID : 6080 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Blanton`, Position : 'TE', Salary : 650000, SleeperID : 6081 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corbin`, LastName: `Kaufusi`, Position : 'OL', Salary : 650000, SleeperID : 6082 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Gay`, Position : 'K', Salary : 650000, SleeperID : 6083 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hjalte`, LastName: `Froholdt`, Position : 'G', Salary : 650000, SleeperID : 6084 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Allison`, Position : 'ILB', Salary : 650000, SleeperID : 6085 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Roemer`, Position : 'OT', Salary : 650000, SleeperID : 6086 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 6087 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bisi`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 6088 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Helm`, Position : 'TE', Salary : 650000, SleeperID : 6089 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Montre`, LastName: `Hartage`, Position : 'DB', Salary : 650000, SleeperID : 6090 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Venzell`, LastName: `Boulware`, Position : 'G', Salary : 650000, SleeperID : 6092 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Bailey`, Position : 'P', Salary : 650000, SleeperID : 6093 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Mustipher`, Position : 'OL', Salary : 650000, SleeperID : 6094 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamell`, LastName: `Garcia-Williams`, Position : 'LB', Salary : 650000, SleeperID : 6096 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Custis`, Position : 'WR', Salary : 650000, SleeperID : 6097 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Burr-Kirven`, Position : 'LB', Salary : 650000, SleeperID : 6098 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.J.`, LastName: `Blunt`, Position : 'ILB', Salary : 650000, SleeperID : 6099 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sua`, LastName: `Opeta`, Position : 'G', Salary : 650000, SleeperID : 6102 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Peavy`, Position : 'CB', Salary : 650000, SleeperID : 6101 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kingsley`, LastName: `Keke`, Position : 'DE', Salary : 650000, SleeperID : 6103 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alijah`, LastName: `Holder`, Position : 'DB', Salary : 650000, SleeperID : 6104 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Giles-Harris`, Position : 'LB', Salary : 650000, SleeperID : 6105 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emmanuel`, LastName: `Butler`, Position : 'WR', Salary : 650000, SleeperID : 6107 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacques`, LastName: `Patrick`, Position : 'RB', Salary : 650000, SleeperID : 6108 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alec`, LastName: `Ingold`, Position : 'FB', Salary : 650000, SleeperID : 6109 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lester`, LastName: `Cotton`, Position : 'G', Salary : 650000, SleeperID : 6110 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Browning`, Position : 'QB', Salary : 650000, SleeperID : 6111 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Brumfield`, Position : 'G', Salary : 650000, SleeperID : 6113 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wes`, LastName: `Hills`, Position : 'RB', Salary : 650000, SleeperID : 6115 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Martez`, LastName: `Ivey`, Position : 'OT', Salary : 650000, SleeperID : 6112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zack`, LastName: `Bailey`, Position : 'G', Salary : 650000, SleeperID : 6114 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `White`, Position : 'LB', Salary : 650000, SleeperID : 6119 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dennis`, LastName: `Allen`, Position : '0', Salary : 650000, SleeperID : 6117 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinnen`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 6118 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Cominsky`, Position : 'DE', Salary : 650000, SleeperID : 6116 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonah`, LastName: `Williams`, Position : 'T', Salary : 650000, SleeperID : 6122 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Wilkins`, Position : 'DT', Salary : 650000, SleeperID : 6120 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jawaan`, LastName: `Taylor`, Position : 'OL', Salary : 650000, SleeperID : 6121 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greedy`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 6123 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Montez`, LastName: `Sweat`, Position : 'DE', Salary : 650000, SleeperID : 6124 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Irv`, LastName: `Smith`, Position : 'TE', Salary : 650000, SleeperID : 6126 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeffery`, LastName: `Simmons`, Position : 'DT', Salary : 650000, SleeperID : 6125 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joejuan`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 6127 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rock`, LastName: `Ya-Sin`, Position : 'CB', Salary : 650000, SleeperID : 6128 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerry`, LastName: `Tillery`, Position : 'DT', Salary : 650000, SleeperID : 6129 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Singletary`, Position : 'RB', Salary : 1133866.855, SleeperID : 6130 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mack`, LastName: `Wilson`, Position : 'LB', Salary : 650000, SleeperID : 6131 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Oshane`, LastName: `Ximines`, Position : 'LB', Salary : 650000, SleeperID : 6132 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Winovich`, Position : 'DE', Salary : 650000, SleeperID : 6133 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gerald`, LastName: `Willis`, Position : 'NT', Salary : 650000, SleeperID : 6134 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrett`, LastName: `Stidham`, Position : 'QB', Salary : 650000, SleeperID : 6136 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Andre`, LastName: `Walker`, Position : 'LB', Salary : 650000, SleeperID : 6135 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Sweeney`, Position : 'TE', Salary : 650000, SleeperID : 6137 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Saivion`, LastName: `Smith`, Position : 'CB', Salary : 650000, SleeperID : 6138 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jace`, LastName: `Sternberger`, Position : 'TE', Salary : 650000, SleeperID : 6139 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Sheffield`, Position : 'DB', Salary : 650000, SleeperID : 6140 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drue`, LastName: `Tranquill`, Position : 'LB', Salary : 650000, SleeperID : 6141 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Wilson`, Position : 'TE', Salary : 650000, SleeperID : 6142 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marvell`, LastName: `Tell`, Position : 'CB', Salary : 650000, SleeperID : 6143 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trayveon`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 6144 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juan`, LastName: `Thornhill`, Position : 'DB', Salary : 650000, SleeperID : 6145 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaden`, LastName: `Smith`, Position : 'TE', Salary : 650000, SleeperID : 6146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clayton`, LastName: `Thorson`, Position : 'QB', Salary : 650000, SleeperID : 6147 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Preston`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 6148 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Slayton`, Position : 'WR', Salary : 650000, SleeperID : 6149 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Scharping`, Position : 'OL', Salary : 650000, SleeperID : 6150 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Miles`, LastName: `Sanders`, Position : 'RB', Salary : 650000, SleeperID : 6151 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khari`, LastName: `Willis`, Position : 'DB', Salary : 650000, SleeperID : 6152 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dexter`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 6153 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Sills`, Position : 'WR', Salary : 650000, SleeperID : 6154 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Smith`, Position : 'WR', Salary : 650000, SleeperID : 6155 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benny`, LastName: `Snell`, Position : 'RB', Salary : 650000, SleeperID : 6156 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Renell`, LastName: `Wren`, Position : 'DT', Salary : 650000, SleeperID : 6157 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Olisaemeka`, LastName: `Udoh`, Position : 'G', Salary : 650000, SleeperID : 6158 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Weber`, Position : 'RB', Salary : 650000, SleeperID : 6159 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josiah`, LastName: `Tauaefa`, Position : 'ILB', Salary : 650000, SleeperID : 6160 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `L.J.`, LastName: `Scott`, Position : 'RB', Salary : 650000, SleeperID : 6161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Wesley`, Position : 'WR', Salary : 650000, SleeperID : 6163 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kahale`, LastName: `Warring`, Position : 'TE', Salary : 650000, SleeperID : 6164 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antoine`, LastName: `Wesley`, Position : 'WR', Salary : 650000, SleeperID : 6165 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Scarlett`, Position : 'RB', Salary : 650000, SleeperID : 6167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Ursua`, Position : 'WR', Salary : 650000, SleeperID : 6168 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 6169 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Williams`, Position : 'OLB', Salary : 650000, SleeperID : 6170 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Thompson`, Position : 'WR', Salary : 650000, SleeperID : 6171 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Worthington`, Position : 'SS', Salary : 650000, SleeperID : 6172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitch`, LastName: `Wishnowsky`, Position : 'P', Salary : 650000, SleeperID : 6173 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Slayton`, Position : 'DT', Salary : 650000, SleeperID : 6174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kerrith`, LastName: `Whyte`, Position : 'RB', Salary : 650000, SleeperID : 6175 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 6176 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalen`, LastName: `Saunders`, Position : 'DT', Salary : 650000, SleeperID : 6177 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jahlani`, LastName: `Tavai`, Position : 'LB', Salary : 650000, SleeperID : 6179 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darwin`, LastName: `Thompson`, Position : 'RB', Salary : 650000, SleeperID : 6178 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 6180 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Wesco`, Position : 'TE', Salary : 650000, SleeperID : 6181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Wyatt`, Position : 'CB', Salary : 650000, SleeperID : 6182 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Van Ginkel`, Position : 'LB', Salary : 650000, SleeperID : 6183 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrek`, LastName: `Thomas`, Position : 'DB', Salary : 650000, SleeperID : 6184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Easton`, LastName: `Stick`, Position : 'QB', Salary : 650000, SleeperID : 6185 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Armon`, LastName: `Watts`, Position : 'DT', Salary : 650000, SleeperID : 6186 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aca'Cedric`, LastName: `Ware`, Position : 'RB', Salary : 650000, SleeperID : 6187 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sutton`, LastName: `Smith`, Position : 'FB', Salary : 650000, SleeperID : 6188 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zedrick`, LastName: `Woods`, Position : 'FS', Salary : 650000, SleeperID : 6189 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Blewitt`, Position : 'K', Salary : 650000, SleeperID : 6192 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Murphy`, Position : 'K', Salary : 650000, SleeperID : 6193 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rick`, LastName: `Spielman`, Position : '0', Salary : 650000, SleeperID : 6194 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damon`, LastName: `Sheehy-Guiseppi`, Position : 'WR', Salary : 650000, SleeperID : 6195 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Wade`, Position : 'RB', Salary : 650000, SleeperID : 6196 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Ankrah`, Position : 'DE', Salary : 650000, SleeperID : 6198 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jakob`, LastName: `Johnson`, Position : 'FB', Salary : 650000, SleeperID : 6202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Durval`, LastName: `Queiroz Neto`, Position : 'G', Salary : 650000, SleeperID : 6197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Valentine`, LastName: `Holmes`, Position : 'RB', Salary : 650000, SleeperID : 6203 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Silvers`, Position : 'QB', Salary : 650000, SleeperID : 6205 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Brown`, Position : 'SS', Salary : 650000, SleeperID : 6206 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elliott`, LastName: `Fry`, Position : 'K', Salary : 650000, SleeperID : 6208 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ishmael`, LastName: `Hyman`, Position : 'WR', Salary : 650000, SleeperID : 6209 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darnell`, LastName: `Savage`, Position : 'DB', Salary : 650000, SleeperID : 6210 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Mazza`, Position : 'LS', Salary : 650000, SleeperID : 6204 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Martin`, Position : 'SS', Salary : 650000, SleeperID : 6207 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sione`, LastName: `Takitaki`, Position : 'LB', Salary : 650000, SleeperID : 6211 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Pipkins`, Position : 'T', Salary : 650000, SleeperID : 6213 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `E.J.`, LastName: `Speed`, Position : 'LB', Salary : 650000, SleeperID : 6218 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wes`, LastName: `Martin`, Position : 'G', Salary : 650000, SleeperID : 6215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ugo`, LastName: `Amadi`, Position : 'DB', Salary : 650000, SleeperID : 6216 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Cashman`, Position : 'LB', Salary : 650000, SleeperID : 6217 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quincy`, LastName: `Williams`, Position : 'LB', Salary : 650000, SleeperID : 6214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Seibert`, Position : 'K', Salary : 650000, SleeperID : 6219 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Holcomb`, Position : 'LB', Salary : 650000, SleeperID : 6220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Skule`, Position : 'OT', Salary : 650000, SleeperID : 6221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juwann`, LastName: `Winfree`, Position : 'WR', Salary : 650000, SleeperID : 6223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Forbes`, Position : 'OG', Salary : 650000, SleeperID : 6224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kahzin`, LastName: `Daniels`, Position : 'OLB', Salary : 650000, SleeperID : 6225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ka'dar`, LastName: `Hollman`, Position : 'DB', Salary : 650000, SleeperID : 6222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javier`, LastName: `Edwards`, Position : 'NT', Salary : 650000, SleeperID : 6230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Easley`, Position : 'WR', Salary : 650000, SleeperID : 6227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adarius`, LastName: `Pickett`, Position : 'SS', Salary : 650000, SleeperID : 6228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Carney`, Position : 'OLB', Salary : 650000, SleeperID : 6229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashard`, LastName: `Causey`, Position : 'CB', Salary : 650000, SleeperID : 6226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Lovett`, Position : 'QB', Salary : 650000, SleeperID : 6231 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalin`, LastName: `Burrell`, Position : 'CB', Salary : 650000, SleeperID : 6232 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Sokol`, Position : 'TE', Salary : 650000, SleeperID : 6233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wilton`, LastName: `Speight`, Position : 'QB', Salary : 650000, SleeperID : 6237 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deonte`, LastName: `Harty`, Position : 'WR', Salary : 650000, SleeperID : 6234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Phillips`, Position : 'DT', Salary : 650000, SleeperID : 6235 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travon`, LastName: `McMillian`, Position : 'RB', Salary : 650000, SleeperID : 6236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyree`, LastName: `Mayfield`, Position : 'TE', Salary : 650000, SleeperID : 6238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Taylor`, Position : 'WR', Salary : 650000, SleeperID : 6239 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Westry`, Position : 'CB', Salary : 650000, SleeperID : 6243 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Rusnak`, Position : 'CB', Salary : 650000, SleeperID : 6244 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Cox`, Position : 'RB', Salary : 650000, SleeperID : 6246 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Moore`, Position : 'WR', Salary : 650000, SleeperID : 6247 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevion`, LastName: `Thompson`, Position : 'WR', Salary : 650000, SleeperID : 6248 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Corcoran`, Position : 'OLB', Salary : 650000, SleeperID : 6249 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Howard`, Position : 'DT', Salary : 650000, SleeperID : 6251 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Peace`, Position : 'OLB', Salary : 650000, SleeperID : 6252 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Zeise`, Position : 'LB', Salary : 650000, SleeperID : 6253 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kemon`, LastName: `Hall`, Position : 'CB', Salary : 650000, SleeperID : 6254 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradford`, LastName: `Lemmons`, Position : 'CB', Salary : 650000, SleeperID : 6255 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Randle`, Position : 'CB', Salary : 650000, SleeperID : 6256 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roderic`, LastName: `Teamer`, Position : 'DB', Salary : 650000, SleeperID : 6257 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jay`, LastName: `Hayes`, Position : 'DE', Salary : 650000, SleeperID : 6264 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nik`, LastName: `Needham`, Position : 'CB', Salary : 650000, SleeperID : 6258 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darnell`, LastName: `Holland`, Position : 'RB', Salary : 650000, SleeperID : 6259 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Powell`, Position : 'TE', Salary : 650000, SleeperID : 6260 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `White`, Position : 'WR', Salary : 650000, SleeperID : 6261 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Harris`, Position : 'DE', Salary : 650000, SleeperID : 6262 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Wood`, Position : 'TE', Salary : 650000, SleeperID : 6263 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Nelson`, Position : 'DE', Salary : 650000, SleeperID : 6265 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alexander`, LastName: `Myres`, Position : 'CB', Salary : 650000, SleeperID : 6266 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dravon`, LastName: `Askew-Henry`, Position : 'SS', Salary : 650000, SleeperID : 6267 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Shurmur`, Position : 'QB', Salary : 650000, SleeperID : 6269 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Wright`, Position : 'K', Salary : 650000, SleeperID : 6268 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Olamide`, LastName: `Zaccheaus`, Position : 'WR', Salary : 650000, SleeperID : 6271 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gerri`, LastName: `Green`, Position : 'DE', Salary : 650000, SleeperID : 6272 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `P.J.`, LastName: `Johnson`, Position : 'DT', Salary : 650000, SleeperID : 6273 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Keenoy`, Position : 'C', Salary : 650000, SleeperID : 6281 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Dillon`, Position : 'TE', Salary : 650000, SleeperID : 6279 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Ballentine`, Position : 'CB', Salary : 650000, SleeperID : 6283 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Epps`, Position : 'DB', Salary : 650000, SleeperID : 6284 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Harris`, Position : 'CB', Salary : 650000, SleeperID : 6286 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Green`, Position : 'WR', Salary : 650000, SleeperID : 6288 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Crawford`, Position : 'DB', Salary : 650000, SleeperID : 6285 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scott`, LastName: `Miller`, Position : 'WR', Salary : 650000, SleeperID : 6290 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donovan`, LastName: `Wilson`, Position : 'DB', Salary : 650000, SleeperID : 6291 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duke`, LastName: `Shelley`, Position : 'DB', Salary : 650000, SleeperID : 6289 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cullen`, LastName: `Gillaspia`, Position : 'RB', Salary : 650000, SleeperID : 6293 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Summers`, Position : 'LB', Salary : 650000, SleeperID : 6294 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinton`, LastName: `Bell`, Position : 'LB', Salary : 650000, SleeperID : 6296 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Asafo-Adjei`, Position : 'OT', Salary : 650000, SleeperID : 6297 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chandler`, LastName: `Cox`, Position : 'FB', Salary : 650000, SleeperID : 6298 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Denmark`, Position : 'CB', Salary : 650000, SleeperID : 6299 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cortez`, LastName: `Broughton`, Position : 'DT', Salary : 650000, SleeperID : 6300 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Scott`, Position : 'DB', Salary : 650000, SleeperID : 6301 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaden`, LastName: `Elliss`, Position : 'LB', Salary : 650000, SleeperID : 6302 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Allegretti`, Position : 'G', Salary : 650000, SleeperID : 6292 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Cutting`, Position : 'LS', Salary : 650000, SleeperID : 6305 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Miles`, Position : 'OL', Salary : 650000, SleeperID : 6303 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Kunaszyk`, Position : 'LB', Salary : 650000, SleeperID : 6307 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Dogbe`, Position : 'DE', Salary : 650000, SleeperID : 6304 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Mekari`, Position : 'OL', Salary : 650000, SleeperID : 6306 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tae`, LastName: `Hayes`, Position : 'CB', Salary : 650000, SleeperID : 6310 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ian`, LastName: `Bunting`, Position : 'TE', Salary : 650000, SleeperID : 6308 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Exford`, Position : 'FS', Salary : 650000, SleeperID : 6309 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Laird`, Position : 'RB', Salary : 650000, SleeperID : 6311 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Lewis`, Position : 'CB', Salary : 650000, SleeperID : 6314 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myquon`, LastName: `Stout`, Position : 'DE', Salary : 650000, SleeperID : 6312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrel`, LastName: `Dodson`, Position : 'LB', Salary : 650000, SleeperID : 6315 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juwan`, LastName: `Foggie`, Position : 'LB', Salary : 650000, SleeperID : 6316 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Dolegala`, Position : 'QB', Salary : 650000, SleeperID : 6317 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Ellis`, Position : 'RB', Salary : 650000, SleeperID : 6318 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyree`, LastName: `Kinnel`, Position : 'SS', Salary : 650000, SleeperID : 6319 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Duhart`, Position : 'WR', Salary : 650000, SleeperID : 6320 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Ward`, Position : 'DE', Salary : 650000, SleeperID : 6321 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Ubosi`, Position : 'WR', Salary : 650000, SleeperID : 6322 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Beck`, Position : 'RB', Salary : 650000, SleeperID : 6323 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Gant`, Position : 'DB', Salary : 650000, SleeperID : 6324 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fredrick`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 6325 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Sanders`, Position : 'NT', Salary : 650000, SleeperID : 6326 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamey`, LastName: `Mosley`, Position : 'OLB', Salary : 650000, SleeperID : 6327 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyron`, LastName: `Brown`, Position : 'CB', Salary : 650000, SleeperID : 6328 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Battle`, Position : 'DB', Salary : 650000, SleeperID : 6329 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Santos`, LastName: `Ramirez`, Position : 'FS', Salary : 650000, SleeperID : 6330 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Koa`, LastName: `Farmer`, Position : 'OLB', Salary : 650000, SleeperID : 6331 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Mabin`, Position : 'CB', Salary : 650000, SleeperID : 6332 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keisean`, LastName: `Nixon`, Position : 'CB', Salary : 650000, SleeperID : 6333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damarea`, LastName: `Crockett`, Position : 'RB', Salary : 650000, SleeperID : 6334 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Walter`, LastName: `Palmore`, Position : 'DT', Salary : 650000, SleeperID : 6335 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jesse`, LastName: `Aniebonam`, Position : 'OLB', Salary : 650000, SleeperID : 6336 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ira`, LastName: `Savage-Lewis`, Position : 'NT', Salary : 650000, SleeperID : 6338 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Davis`, Position : 'DE', Salary : 650000, SleeperID : 6339 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Middleton`, Position : 'LB', Salary : 650000, SleeperID : 6340 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dexter`, LastName: `Wright`, Position : 'S', Salary : 650000, SleeperID : 6341 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Louis`, Position : 'WR', Salary : 650000, SleeperID : 6342 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Manny`, LastName: `Wilkins`, Position : 'QB', Salary : 650000, SleeperID : 6343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Picasso`, LastName: `Nelson`, Position : 'CB', Salary : 650000, SleeperID : 6344 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Durrant`, LastName: `Miles`, Position : 'DE', Salary : 650000, SleeperID : 6345 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yurik`, LastName: `Bethune`, Position : 'DE', Salary : 650000, SleeperID : 6346 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre'`, LastName: `Crawford`, Position : 'ILB', Salary : 650000, SleeperID : 6347 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Del'Shawn`, LastName: `Phillips`, Position : 'LB', Salary : 650000, SleeperID : 6348 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayson`, LastName: `Stanley`, Position : 'CB', Salary : 650000, SleeperID : 6349 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `Baldwin`, Position : 'SS', Salary : 650000, SleeperID : 6350 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corrion`, LastName: `Ballard`, Position : 'S', Salary : 650000, SleeperID : 6351 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Reeder`, Position : 'LB', Salary : 650000, SleeperID : 6353 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Meadors`, Position : 'DB', Salary : 650000, SleeperID : 6354 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Abernathy`, Position : 'FS', Salary : 650000, SleeperID : 6355 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Booker`, Position : 'OLB', Salary : 650000, SleeperID : 6356 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shawn`, LastName: `Bane`, Position : 'WR', Salary : 650000, SleeperID : 6357 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kahlil`, LastName: `Lewis`, Position : 'WR', Salary : 650000, SleeperID : 6358 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Worton`, Position : 'WR', Salary : 650000, SleeperID : 6359 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chandler`, LastName: `Miller`, Position : 'C', Salary : 650000, SleeperID : 6360 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raphael`, LastName: `Leonard`, Position : 'WR', Salary : 650000, SleeperID : 6361 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dredrick`, LastName: `Snelson`, Position : 'WR', Salary : 650000, SleeperID : 6362 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Walker`, Position : 'WR', Salary : 650000, SleeperID : 6363 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Papi`, LastName: `White`, Position : 'WR', Salary : 650000, SleeperID : 6364 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carson`, LastName: `Meier`, Position : 'TE', Salary : 650000, SleeperID : 6365 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Dwight`, Position : 'NT', Salary : 650000, SleeperID : 6337 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 6366 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roderick`, LastName: `Young`, Position : 'DT', Salary : 650000, SleeperID : 6367 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shane`, LastName: `Bowman`, Position : 'DE', Salary : 650000, SleeperID : 6368 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khairi`, LastName: `Clark`, Position : 'DL', Salary : 650000, SleeperID : 6369 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damion`, LastName: `Jeanpiere`, Position : 'WR', Salary : 650000, SleeperID : 6370 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Anderson`, Position : 'QB', Salary : 650000, SleeperID : 6371 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerome`, LastName: `Washington`, Position : 'TE', Salary : 650000, SleeperID : 6375 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Turner`, Position : 'RB', Salary : 650000, SleeperID : 6372 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Richardson`, Position : 'WR', Salary : 650000, SleeperID : 6373 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Folston`, Position : 'OLB', Salary : 650000, SleeperID : 6376 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Immanuel`, LastName: `Turner`, Position : 'DE', Salary : 650000, SleeperID : 6377 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Miles`, LastName: `Brown`, Position : 'NT', Salary : 650000, SleeperID : 6378 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khari`, LastName: `Blasingame`, Position : 'FB', Salary : 650000, SleeperID : 6379 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davion`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 6380 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alexander`, LastName: `Hollins`, Position : 'WR', Salary : 650000, SleeperID : 6381 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tito`, LastName: `Odenigbo`, Position : 'DT', Salary : 650000, SleeperID : 6382 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anree`, LastName: `Saint-Amour`, Position : 'DE', Salary : 650000, SleeperID : 6383 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Sumpter`, Position : 'WR', Salary : 650000, SleeperID : 6384 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Belcher`, Position : 'TE', Salary : 650000, SleeperID : 6374 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Colburn`, Position : 'RB', Salary : 650000, SleeperID : 6385 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Bachman`, Position : 'WR', Salary : 650000, SleeperID : 6386 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nsimba`, LastName: `Webster`, Position : 'WR', Salary : 650000, SleeperID : 6387 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keenen`, LastName: `Brown`, Position : 'TE', Salary : 650000, SleeperID : 6389 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquise`, LastName: `Copeland`, Position : 'DT', Salary : 650000, SleeperID : 6390 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrell`, LastName: `Thompson`, Position : 'DT', Salary : 650000, SleeperID : 6391 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Romello`, LastName: `Brooker`, Position : 'TE', Salary : 650000, SleeperID : 6388 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnathan`, LastName: `Lloyd`, Position : 'WR', Salary : 650000, SleeperID : 6392 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Romell`, LastName: `Guerrier`, Position : 'WR', Salary : 650000, SleeperID : 6394 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trinity`, LastName: `Benson`, Position : 'WR', Salary : 650000, SleeperID : 6395 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Fort`, Position : 'TE', Salary : 650000, SleeperID : 6396 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Johnson`, Position : 'DL', Salary : 650000, SleeperID : 6397 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ahmad`, LastName: `Gooden`, Position : 'LB', Salary : 650000, SleeperID : 6398 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kano`, LastName: `Dillon`, Position : 'TE', Salary : 650000, SleeperID : 6399 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Bee`, Position : 'DE', Salary : 650000, SleeperID : 6400 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Rahming`, Position : 'WR', Salary : 650000, SleeperID : 6401 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Sims`, Position : 'WR', Salary : 650000, SleeperID : 6402 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ian`, LastName: `Berryman`, Position : 'P', Salary : 650000, SleeperID : 6403 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Boogie`, LastName: `Roberts`, Position : 'DT', Salary : 650000, SleeperID : 6404 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ken`, LastName: `Webster`, Position : 'CB', Salary : 650000, SleeperID : 6405 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juwann`, LastName: `Bushell-Beatty`, Position : 'OT', Salary : 650000, SleeperID : 6406 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinn`, LastName: `Bailey`, Position : 'OT', Salary : 650000, SleeperID : 6409 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Wallace`, Position : 'OT', Salary : 650000, SleeperID : 6410 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Vasey`, Position : 'LS', Salary : 650000, SleeperID : 6411 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaelin`, LastName: `Robinson`, Position : 'OT', Salary : 650000, SleeperID : 6412 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lanard`, LastName: `Bonner`, Position : 'OT', Salary : 650000, SleeperID : 6413 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donnell`, LastName: `Greene`, Position : 'OT', Salary : 650000, SleeperID : 6415 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Wingard`, Position : 'DB', Salary : 650000, SleeperID : 6416 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Sweet`, Position : 'OL', Salary : 650000, SleeperID : 6417 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tariq`, LastName: `Cole`, Position : 'G', Salary : 650000, SleeperID : 6418 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `P.J.`, LastName: `Locke`, Position : 'DB', Salary : 650000, SleeperID : 6419 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon'Vea`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 6420 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Guyton`, Position : 'WR', Salary : 650000, SleeperID : 6421 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Conrad`, Position : 'TE', Salary : 650000, SleeperID : 6501 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricky`, LastName: `Walker`, Position : 'DL', Salary : 650000, SleeperID : 6422 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Wise`, Position : 'DT', Salary : 650000, SleeperID : 6423 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.T.`, LastName: `Hassell`, Position : 'DB', Salary : 650000, SleeperID : 6429 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Bolton`, Position : 'LB', Salary : 650000, SleeperID : 6430 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nydair`, LastName: `Rouse`, Position : 'CB', Salary : 650000, SleeperID : 6431 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Otaro`, LastName: `Alaka`, Position : 'LB', Salary : 650000, SleeperID : 6432 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shawn`, LastName: `Poindexter`, Position : 'WR', Salary : 650000, SleeperID : 6554 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Thompson`, Position : 'DT', Salary : 650000, SleeperID : 6440 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Robinson`, Position : 'DT', Salary : 650000, SleeperID : 6442 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Dungey`, Position : 'QB', Salary : 650000, SleeperID : 6443 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrell`, LastName: `Owens`, Position : 'DE', Salary : 650000, SleeperID : 6447 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Harvey`, Position : 'ILB', Salary : 650000, SleeperID : 6444 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bunchy`, LastName: `Stallings`, Position : 'C', Salary : 650000, SleeperID : 6414 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Tate`, Position : 'G', Salary : 650000, SleeperID : 6454 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Hilliman`, Position : 'RB', Salary : 650000, SleeperID : 6446 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrin`, LastName: `Hall`, Position : 'RB', Salary : 650000, SleeperID : 6448 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dorian`, LastName: `Baker`, Position : 'WR', Salary : 650000, SleeperID : 6449 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Blough`, Position : 'QB', Salary : 650000, SleeperID : 6450 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamie`, LastName: `Gillan`, Position : 'P', Salary : 650000, SleeperID : 6452 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Montgomery`, Position : 'WR', Salary : 650000, SleeperID : 6453 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Wright`, Position : 'OL', Salary : 650000, SleeperID : 6455 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Gauthier`, Position : 'OT', Salary : 650000, SleeperID : 6456 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Anderson`, Position : 'T', Salary : 650000, SleeperID : 6457 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyree`, LastName: `St. Louis`, Position : 'OT', Salary : 650000, SleeperID : 6458 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `O'Hagan`, Position : 'C', Salary : 650000, SleeperID : 6445 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Modster`, Position : 'WR', Salary : 650000, SleeperID : 6459 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Carlson`, Position : 'TE', Salary : 650000, SleeperID : 6451 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Herdman`, Position : 'TE', Salary : 650000, SleeperID : 6460 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Orzech`, Position : 'LS', Salary : 650000, SleeperID : 6461 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ellis`, LastName: `Richardson`, Position : 'TE', Salary : 650000, SleeperID : 6462 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lawrence`, LastName: `Marshall`, Position : 'DL', Salary : 650000, SleeperID : 6463 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Wirtel`, Position : 'LS', Salary : 650000, SleeperID : 6464 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Ives`, Position : 'WR', Salary : 650000, SleeperID : 6465 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daryle`, LastName: `Banfield`, Position : 'NT', Salary : 650000, SleeperID : 6466 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Harris`, Position : 'DT', Salary : 650000, SleeperID : 6467 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Eaton`, Position : 'WR', Salary : 650000, SleeperID : 6468 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davis`, LastName: `Koppenhaver`, Position : 'TE', Salary : 650000, SleeperID : 6469 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Herndon`, Position : 'G', Salary : 650000, SleeperID : 6470 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Allen Jr.`, Position : 'G', Salary : 650000, SleeperID : 6471 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Phillips`, Position : 'LB', Salary : 650000, SleeperID : 6472 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Puni`, Position : 'OT', Salary : 650000, SleeperID : 6473 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Hitner`, Position : 'OT', Salary : 650000, SleeperID : 6474 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Angelo`, LastName: `Ross`, Position : 'DB', Salary : 650000, SleeperID : 6476 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Crozier`, Position : 'G', Salary : 650000, SleeperID : 6478 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Markus`, LastName: `Jones`, Position : 'OLB', Salary : 650000, SleeperID : 6483 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Onuoha`, Position : 'OLB', Salary : 650000, SleeperID : 6484 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Azeez`, LastName: `Al-Shaair`, Position : 'LB', Salary : 650000, SleeperID : 6485 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Toogood`, Position : 'G', Salary : 650000, SleeperID : 6486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Scarff`, Position : 'TE', Salary : 650000, SleeperID : 6487 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalil`, LastName: `Morris`, Position : 'DT', Salary : 650000, SleeperID : 6488 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taryn`, LastName: `Christion`, Position : 'QB', Salary : 650000, SleeperID : 6489 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Choice`, Position : 'RB', Salary : 650000, SleeperID : 6491 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terry`, LastName: `Wright`, Position : 'WR', Salary : 650000, SleeperID : 6492 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Johnson`, Position : 'TE', Salary : 650000, SleeperID : 6493 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Mone`, Position : 'DT', Salary : 650000, SleeperID : 6494 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jay-Tee`, LastName: `Tiuli`, Position : 'NT', Salary : 650000, SleeperID : 6495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Hedlund`, Position : 'K', Salary : 650000, SleeperID : 6496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tenny`, LastName: `Adewusi`, Position : 'CB', Salary : 650000, SleeperID : 6497 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Harvey`, Position : 'LB', Salary : 650000, SleeperID : 6498 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mik'Quan`, LastName: `Deane`, Position : 'TE', Salary : 650000, SleeperID : 6490 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sterling`, LastName: `Shippy`, Position : 'DT', Salary : 650000, SleeperID : 6500 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Kaskey`, Position : 'OT', Salary : 650000, SleeperID : 6424 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justice`, LastName: `Powers`, Position : 'G', Salary : 650000, SleeperID : 6425 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chandler`, LastName: `Brewer`, Position : 'OL', Salary : 650000, SleeperID : 6426 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jhavonte`, LastName: `Dean`, Position : 'CB', Salary : 650000, SleeperID : 6499 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trayone`, LastName: `Gray`, Position : 'RB', Salary : 650000, SleeperID : 6506 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ashton`, LastName: `Dulin`, Position : 'WR', Salary : 650000, SleeperID : 6427 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Stubbs`, Position : 'OLB', Salary : 650000, SleeperID : 6508 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Applefield`, Position : 'OT', Salary : 650000, SleeperID : 6509 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Dalton`, Position : 'DT', Salary : 650000, SleeperID : 6510 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Lowery`, Position : 'G', Salary : 650000, SleeperID : 6513 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Blackmar`, Position : 'G', Salary : 650000, SleeperID : 6516 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randy`, LastName: `Ramsey`, Position : 'LB', Salary : 650000, SleeperID : 6517 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Doyin`, LastName: `Jibowu`, Position : 'FS', Salary : 650000, SleeperID : 6512 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAndre`, LastName: `Thompkins`, Position : 'WR', Salary : 650000, SleeperID : 6540 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keegan`, LastName: `Render`, Position : 'C', Salary : 650000, SleeperID : 6541 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Rush`, Position : 'DT', Salary : 650000, SleeperID : 6542 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Wilkins`, Position : 'DE', Salary : 650000, SleeperID : 6543 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Adeoye`, Position : 'LB', Salary : 650000, SleeperID : 6544 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chuck`, LastName: `Harris`, Position : 'DE', Salary : 650000, SleeperID : 6545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Bigelow`, Position : 'DT', Salary : 650000, SleeperID : 6546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Wharton`, Position : 'SS', Salary : 650000, SleeperID : 6547 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Marshall`, Position : 'RB', Salary : 650000, SleeperID : 6548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damion`, LastName: `Willis`, Position : 'WR', Salary : 650000, SleeperID : 6549 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dino`, LastName: `Boyd`, Position : 'OT', Salary : 650000, SleeperID : 6550 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Harris`, Position : 'LB', Salary : 650000, SleeperID : 6551 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Henry`, Position : 'WR', Salary : 650000, SleeperID : 6552 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Walter`, Position : 'RB', Salary : 650000, SleeperID : 6553 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `AJ`, LastName: `Cole`, Position : 'P', Salary : 650000, SleeperID : 6555 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronald`, LastName: `Ollie`, Position : 'DE', Salary : 650000, SleeperID : 6556 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Smith`, Position : 'WR', Salary : 650000, SleeperID : 6557 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `O'Shea`, LastName: `Dugas`, Position : 'T', Salary : 650000, SleeperID : 6558 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Volson`, Position : 'C', Salary : 650000, SleeperID : 6559 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Koda`, LastName: `Martin`, Position : 'OL', Salary : 650000, SleeperID : 6560 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Brown`, Position : 'G', Salary : 650000, SleeperID : 6561 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deion`, LastName: `Calhoun`, Position : 'G', Salary : 650000, SleeperID : 6570 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Newsome`, Position : 'P', Salary : 650000, SleeperID : 6563 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ethan`, LastName: `Greenidge`, Position : 'OL', Salary : 650000, SleeperID : 6564 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Conway`, Position : 'OL', Salary : 650000, SleeperID : 6565 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Hance`, Position : 'OG', Salary : 650000, SleeperID : 6566 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keaton`, LastName: `Sutherland`, Position : 'G', Salary : 650000, SleeperID : 6567 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wyatt`, LastName: `Miller`, Position : 'OT', Salary : 650000, SleeperID : 6568 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Pridgeon`, Position : 'G', Salary : 650000, SleeperID : 6569 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Camper`, Position : 'OT', Salary : 650000, SleeperID : 6562 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Watson`, Position : 'ILB', Salary : 650000, SleeperID : 6571 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Monteiro`, Position : 'OT', Salary : 650000, SleeperID : 6572 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Horton`, Position : 'DB', Salary : 650000, SleeperID : 6573 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rob`, LastName: `Rolle`, Position : 'FS', Salary : 650000, SleeperID : 6574 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Alexandre`, Position : 'DL', Salary : 650000, SleeperID : 6575 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Toa`, LastName: `Lobendahn`, Position : 'C', Salary : 650000, SleeperID : 6576 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquez`, LastName: `Sanford`, Position : 'DB', Salary : 650000, SleeperID : 6577 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Coker`, Position : 'C', Salary : 650000, SleeperID : 6578 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shakial`, LastName: `Taylor`, Position : 'CB', Salary : 650000, SleeperID : 6579 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.T.`, LastName: `Hall`, Position : 'OT', Salary : 650000, SleeperID : 6580 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derick`, LastName: `Roberson`, Position : 'LB', Salary : 650000, SleeperID : 6581 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jocquez`, LastName: `Kalili`, Position : 'DB', Salary : 650000, SleeperID : 6582 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Moon`, Position : 'SS', Salary : 650000, SleeperID : 6583 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deyon`, LastName: `Sizer`, Position : 'DT', Salary : 650000, SleeperID : 6584 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Brooks-James`, Position : 'RB', Salary : 650000, SleeperID : 6585 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Moral`, LastName: `Stephens`, Position : 'TE', Salary : 650000, SleeperID : 6586 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quindarius`, LastName: `Thagard`, Position : 'DT', Salary : 650000, SleeperID : 6587 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tom`, LastName: `Kennedy`, Position : 'WR', Salary : 650000, SleeperID : 6588 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kirk`, LastName: `Barron`, Position : 'C', Salary : 650000, SleeperID : 6589 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Colubiale`, Position : 'TE', Salary : 650000, SleeperID : 6590 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontae`, LastName: `Strickland`, Position : 'RB', Salary : 650000, SleeperID : 6592 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Schnell`, Position : 'WR', Salary : 650000, SleeperID : 6593 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Moore`, Position : 'LS', Salary : 650000, SleeperID : 6594 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Jones`, Position : 'TE', Salary : 650000, SleeperID : 6591 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cortrelle`, LastName: `Simpson`, Position : 'WR', Salary : 650000, SleeperID : 6605 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Fitzgerald`, Position : 'QB', Salary : 650000, SleeperID : 6606 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Trewyn`, Position : 'C', Salary : 650000, SleeperID : 6607 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stacy`, LastName: `Keely`, Position : 'DE', Salary : 650000, SleeperID : 6608 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Reynolds`, Position : 'OG', Salary : 650000, SleeperID : 6609 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demetrius`, LastName: `Flannigan-Fowles`, Position : 'LB', Salary : 650000, SleeperID : 6610 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Dowell`, Position : 'LB', Salary : 650000, SleeperID : 6611 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Gifford`, Position : 'LB', Salary : 650000, SleeperID : 6612 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Rayford`, Position : 'DB', Salary : 650000, SleeperID : 6615 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Iseoluwapo`, LastName: `Jegede`, Position : 'NT', Salary : 650000, SleeperID : 6616 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Lindsey`, Position : 'WR', Salary : 650000, SleeperID : 6617 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kabion`, LastName: `Ento`, Position : 'CB', Salary : 650000, SleeperID : 6619 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lukayus`, LastName: `McNeil`, Position : 'OT', Salary : 650000, SleeperID : 6613 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javien`, LastName: `Hamilton`, Position : 'CB', Salary : 650000, SleeperID : 6620 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Ponder`, Position : 'CB', Salary : 650000, SleeperID : 6614 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devlin`, LastName: `Hodges`, Position : 'QB', Salary : 650000, SleeperID : 6595 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Baron`, Position : 'K', Salary : 650000, SleeperID : 6621 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Thieneman`, Position : 'SS', Salary : 650000, SleeperID : 6622 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jomon`, LastName: `Dotson`, Position : 'DB', Salary : 650000, SleeperID : 6623 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Searight`, Position : 'TE', Salary : 650000, SleeperID : 6618 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Mack`, Position : 'DT', Salary : 650000, SleeperID : 6601 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalan`, LastName: `McClendon`, Position : 'QB', Salary : 650000, SleeperID : 6596 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wes`, LastName: `Farnsworth`, Position : 'LS', Salary : 650000, SleeperID : 6597 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trenton`, LastName: `Irwin`, Position : 'WR', Salary : 650000, SleeperID : 6598 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cory`, LastName: `Thomas`, Position : 'DT', Salary : 650000, SleeperID : 6599 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stone`, LastName: `Wilson`, Position : 'P', Salary : 650000, SleeperID : 6600 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Henderson`, Position : 'CB', Salary : 650000, SleeperID : 6791 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braxton`, LastName: `Hoyett`, Position : 'DT', Salary : 650000, SleeperID : 6602 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Simmons`, Position : 'CB', Salary : 650000, SleeperID : 6624 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Zico`, Position : 'WR', Salary : 650000, SleeperID : 6626 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ventell`, LastName: `Bryant`, Position : 'WR', Salary : 650000, SleeperID : 6628 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Burt`, Position : 'WR', Salary : 650000, SleeperID : 6629 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dakari`, LastName: `Monroe`, Position : 'CB', Salary : 650000, SleeperID : 6630 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Nelson`, Position : 'T', Salary : 650000, SleeperID : 6633 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Allison`, Position : 'DB', Salary : 650000, SleeperID : 6631 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Godsil`, Position : 'LS', Salary : 650000, SleeperID : 6632 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clayton`, LastName: `Hatfield`, Position : 'K', Salary : 650000, SleeperID : 6625 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shy`, LastName: `Tuttle`, Position : 'DT', Salary : 650000, SleeperID : 6627 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Anderson`, Position : 'P', Salary : 650000, SleeperID : 6504 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Brooks`, Position : 'DB', Salary : 650000, SleeperID : 6634 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Kelly`, Position : 'OL', Salary : 650000, SleeperID : 6635 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nico`, LastName: `Evans`, Position : 'RB', Salary : 650000, SleeperID : 6637 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bradlee`, LastName: `Anae`, Position : 'DE', Salary : 650000, SleeperID : 6827 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Doles`, Position : 'OT', Salary : 650000, SleeperID : 6638 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `Bonds`, Position : 'CB', Salary : 650000, SleeperID : 6639 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Herb`, LastName: `Miller`, Position : 'CB', Salary : 650000, SleeperID : 6640 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Kmet`, Position : 'TE', Salary : 1162940.364, SleeperID : 6826 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jay`, LastName: `Liggins`, Position : 'DB', Salary : 650000, SleeperID : 6636 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mathieu`, LastName: `Betts`, Position : 'DL', Salary : 650000, SleeperID : 6604 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `AJ`, LastName: `Dillon`, Position : 'RB', Salary : 650000, SleeperID : 6828 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kristian`, LastName: `Fulton`, Position : 'CB', Salary : 650000, SleeperID : 6829 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquez`, LastName: `Tucker`, Position : 'OT', Salary : 650000, SleeperID : 6603 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Kinlaw`, Position : 'DT', Salary : 650000, SleeperID : 6792 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tristan`, LastName: `Wirfs`, Position : 'T', Salary : 650000, SleeperID : 6793 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Jefferson`, Position : 'WR', Salary : 15000000, SleeperID : 6794 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mekhi`, LastName: `Becton`, Position : 'OT', Salary : 650000, SleeperID : 6796 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Herbert`, Position : 'QB', Salary : 2750429.458, SleeperID : 6797 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Blacklock`, Position : 'DT', Salary : 650000, SleeperID : 6795 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Wonnum`, Position : 'DE', Salary : 650000, SleeperID : 6868 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Trautman`, Position : 'TE', Salary : 650000, SleeperID : 6869 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Dantzler`, Position : 'CB', Salary : 650000, SleeperID : 6871 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Dugger`, Position : 'DB', Salary : 650000, SleeperID : 6872 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Mack`, Position : 'WR', Salary : 650000, SleeperID : 6873 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Willekes`, Position : 'DE', Salary : 650000, SleeperID : 6874 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Edwards`, Position : 'WR', Salary : 650000, SleeperID : 6870 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Green`, Position : 'CB', Salary : 650000, SleeperID : 6876 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `McFarland`, Position : 'RB', Salary : 650000, SleeperID : 6878 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quartney`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 6879 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Wilson`, Position : 'LB', Salary : 650000, SleeperID : 6880 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Hennessy`, Position : 'OL', Salary : 650000, SleeperID : 6881 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Simpson`, Position : 'G', Salary : 650000, SleeperID : 6875 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Ismael`, Position : 'C', Salary : 650000, SleeperID : 6877 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darnay`, LastName: `Holmes`, Position : 'CB', Salary : 650000, SleeperID : 6882 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lucas`, LastName: `Niang`, Position : 'OL', Salary : 650000, SleeperID : 6883 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Stenberg`, Position : 'G', Salary : 650000, SleeperID : 6884 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ke'Shawn`, LastName: `Vaughn`, Position : 'RB', Salary : 650000, SleeperID : 6885 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antoine`, LastName: `Winfield`, Position : 'DB', Salary : 650000, SleeperID : 6888 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Claypool`, Position : 'WR', Salary : 650000, SleeperID : 6886 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colton`, LastName: `McKivitz`, Position : 'OL', Salary : 650000, SleeperID : 6889 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Markus`, LastName: `Bailey`, Position : 'LB', Salary : 650000, SleeperID : 6891 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Stanley`, Position : 'QB', Salary : 650000, SleeperID : 6892 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Windsor`, Position : 'DT', Salary : 650000, SleeperID : 6893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `Wilcox`, Position : 'TE', Salary : 650000, SleeperID : 6894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amik`, LastName: `Robertson`, Position : 'CB', Salary : 650000, SleeperID : 6896 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Soroh`, Position : 'DB', Salary : 650000, SleeperID : 6641 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrence`, LastName: `Alexander`, Position : 'CB', Salary : 650000, SleeperID : 6642 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Akins`, Position : 'OLB', Salary : 650000, SleeperID : 6644 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Freedom`, LastName: `Akinmoladun`, Position : 'DE', Salary : 650000, SleeperID : 6657 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Craig`, LastName: `Reynolds`, Position : 'RB', Salary : 650000, SleeperID : 6659 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Durndraus`, LastName: `Bryant`, Position : 'NT', Salary : 650000, SleeperID : 6646 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brock`, LastName: `Ruble`, Position : 'OT', Salary : 650000, SleeperID : 6648 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dare`, LastName: `Odeyingbo`, Position : 'DE', Salary : 650000, SleeperID : 6649 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `McLaughlin`, Position : 'K', Salary : 650000, SleeperID : 6650 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Pittman`, Position : 'LB', Salary : 650000, SleeperID : 6651 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larrell`, LastName: `Murchison`, Position : 'DT', Salary : 650000, SleeperID : 6887 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kamal`, LastName: `Martin`, Position : 'LB', Salary : 650000, SleeperID : 6890 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quintez`, LastName: `Cephus`, Position : 'WR', Salary : 650000, SleeperID : 6895 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Chesley`, Position : 'CB', Salary : 650000, SleeperID : 6645 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Israel`, LastName: `Helms`, Position : 'OT', Salary : 650000, SleeperID : 6653 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JoJo`, LastName: `Tillery`, Position : 'FS', Salary : 650000, SleeperID : 6654 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Strachan`, Position : 'LB', Salary : 650000, SleeperID : 6656 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Maloata`, Position : 'NT', Salary : 650000, SleeperID : 6658 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `St. Andrew`, Position : 'G', Salary : 650000, SleeperID : 6652 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Bonner`, Position : 'DE', Salary : 650000, SleeperID : 6660 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joseph`, LastName: `Parker`, Position : 'WR', Salary : 650000, SleeperID : 6661 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `Hesse`, Position : 'TE', Salary : 650000, SleeperID : 6662 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Wright`, Position : 'WR', Salary : 650000, SleeperID : 6663 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Ouellette`, Position : 'RB', Salary : 650000, SleeperID : 6664 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Strong`, Position : 'DE', Salary : 650000, SleeperID : 6536 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Greene`, Position : 'WR', Salary : 650000, SleeperID : 6685 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Gibson`, Position : 'G', Salary : 650000, SleeperID : 6688 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jody`, LastName: `Fortson`, Position : 'TE', Salary : 650000, SleeperID : 6665 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kelvin`, LastName: `McKnight`, Position : 'WR', Salary : 650000, SleeperID : 6438 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hale`, LastName: `Hentges`, Position : 'TE', Salary : 650000, SleeperID : 6439 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Fineanganofo`, Position : 'OT', Salary : 650000, SleeperID : 6532 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dedrick`, LastName: `Young`, Position : 'ILB', Salary : 650000, SleeperID : 6533 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ray`, LastName: `Smith`, Position : 'DT', Salary : 650000, SleeperID : 6534 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Becker`, Position : 'TE', Salary : 650000, SleeperID : 6535 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Anderson`, Position : 'G', Salary : 650000, SleeperID : 6537 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dewayne`, LastName: `Hendrix`, Position : 'DE', Salary : 650000, SleeperID : 6538 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devontae`, LastName: `Jackson`, Position : 'RB', Salary : 650000, SleeperID : 6539 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Horn`, Position : 'WR', Salary : 650000, SleeperID : 6686 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Gervase`, Position : 'LB', Salary : 650000, SleeperID : 6687 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryant`, LastName: `Jones`, Position : 'NT', Salary : 650000, SleeperID : 6689 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Geno`, LastName: `Stone`, Position : 'DB', Salary : 650000, SleeperID : 6923 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Netane`, LastName: `Muti`, Position : 'G', Salary : 650000, SleeperID : 6924 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Essang`, LastName: `Bassey`, Position : 'CB', Salary : 650000, SleeperID : 6925 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brycen`, LastName: `Hopkins`, Position : 'TE', Salary : 650000, SleeperID : 6926 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quez`, LastName: `Watkins`, Position : 'WR', Salary : 650000, SleeperID : 6927 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kindle`, LastName: `Vildor`, Position : 'DB', Salary : 650000, SleeperID : 6928 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Cole`, Position : 'DB', Salary : 650000, SleeperID : 6929 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trystan`, LastName: `Colon`, Position : 'C', Salary : 650000, SleeperID : 6930 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Reagor`, Position : 'WR', Salary : 650000, SleeperID : 6798 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grant`, LastName: `Delpit`, Position : 'DB', Salary : 650000, SleeperID : 6799 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K'Lavon`, LastName: `Chaisson`, Position : 'LB', Salary : 650000, SleeperID : 6800 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tee`, LastName: `Higgins`, Position : 'WR', Salary : 6183762.603, SleeperID : 6801 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Aiyuk`, Position : 'WR', Salary : 9509864.777, SleeperID : 6803 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Love`, Position : 'QB', Salary : 2029812.391, SleeperID : 6804 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KJ`, LastName: `Hamler`, Position : 'WR', Salary : 650000, SleeperID : 6805 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.K.`, LastName: `Dobbins`, Position : 'RB', Salary : 650000, SleeperID : 6806 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Queen`, Position : 'LB', Salary : 650000, SleeperID : 6807 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Jones`, Position : 'OL', Salary : 650000, SleeperID : 6808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Diggs`, Position : 'CB', Salary : 650000, SleeperID : 6809 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Thomas`, Position : 'T', Salary : 650000, SleeperID : 6810 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenneth`, LastName: `Murray`, Position : 'LB', Salary : 650000, SleeperID : 6811 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yetur`, LastName: `Gross-Matos`, Position : 'DE', Salary : 650000, SleeperID : 6812 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Taylor`, Position : 'RB', Salary : 11644944.31, SleeperID : 6813 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Laviska`, LastName: `Shenault`, Position : 'WR', Salary : 650000, SleeperID : 6814 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zack`, LastName: `Baun`, Position : 'LB', Salary : 650000, SleeperID : 6815 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cesar`, LastName: `Ruiz`, Position : 'OL', Salary : 650000, SleeperID : 6816 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Igbinoghene`, Position : 'CB', Salary : 650000, SleeperID : 6817 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Epenesa`, Position : 'DE', Salary : 650000, SleeperID : 6802 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Madubuike`, Position : 'DT', Salary : 650000, SleeperID : 6818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Pittman`, Position : 'WR', Salary : 8379100.899, SleeperID : 6819 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Jackson`, Position : 'T', Salary : 650000, SleeperID : 6821 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Eason`, Position : 'QB', Salary : 650000, SleeperID : 6823 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donovan`, LastName: `Peoples-Jones`, Position : 'WR', Salary : 650000, SleeperID : 6824 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Strowbridge`, Position : 'DE', Salary : 650000, SleeperID : 6830 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Hall`, Position : 'CB', Salary : 650000, SleeperID : 6833 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Pinkney`, Position : 'TE', Salary : 650000, SleeperID : 6834 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Biadasz`, Position : 'C', Salary : 650000, SleeperID : 6835 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Terrell`, Position : 'CB', Salary : 650000, SleeperID : 6836 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Elliott`, Position : 'DT', Salary : 650000, SleeperID : 6841 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clyde`, LastName: `Edwards-Helaire`, Position : 'RB', Salary : 650000, SleeperID : 6820 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `Lewis`, Position : 'LB', Salary : 650000, SleeperID : 6837 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marlon`, LastName: `Davidson`, Position : 'DE', Salary : 650000, SleeperID : 6838 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Fromm`, Position : 'QB', Salary : 650000, SleeperID : 6822 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raekwon`, LastName: `Davis`, Position : 'DT', Salary : 650000, SleeperID : 6831 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 6839 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damon`, LastName: `Arnette`, Position : 'CB', Salary : 650000, SleeperID : 6840 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harrison`, LastName: `Bryant`, Position : 'TE', Salary : 650000, SleeperID : 6850 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Gladney`, Position : 'CB', Salary : 650000, SleeperID : 6842 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Albert`, LastName: `Okwuegbunam`, Position : 'TE', Salary : 650000, SleeperID : 6843 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Wilson`, Position : 'T', Salary : 650000, SleeperID : 6844 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zack`, LastName: `Moss`, Position : 'RB', Salary : 858248.0651, SleeperID : 6845 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Bryant`, Position : 'TE', Salary : 650000, SleeperID : 6846 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Duvernay`, Position : 'WR', Salary : 650000, SleeperID : 6847 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anfernee`, LastName: `Jennings`, Position : 'LB', Salary : 650000, SleeperID : 6848 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denzel`, LastName: `Mims`, Position : 'WR', Salary : 650000, SleeperID : 6849 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Okwara`, Position : 'LB', Salary : 650000, SleeperID : 6851 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Stewart`, Position : 'WR', Salary : 650000, SleeperID : 7056 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylinn`, LastName: `Hawkins`, Position : 'SS', Salary : 650000, SleeperID : 7058 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Mack`, Position : 'LB', Salary : 650000, SleeperID : 7059 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Bartch`, Position : 'OL', Salary : 650000, SleeperID : 7060 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Clark`, Position : 'OT', Salary : 650000, SleeperID : 7061 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodrigo`, LastName: `Blankenship`, Position : 'K', Salary : 650000, SleeperID : 7062 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shea`, LastName: `Patterson`, Position : 'QB', Salary : 650000, SleeperID : 7063 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrynton`, LastName: `Evans`, Position : 'RB', Salary : 650000, SleeperID : 7064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Horn`, Position : 'WR', Salary : 650000, SleeperID : 6692 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ketner`, LastName: `Kupp`, Position : 'LB', Salary : 650000, SleeperID : 6693 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Ernest`, LastName: `Johnson`, Position : 'RB', Salary : 650000, SleeperID : 6694 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Brown`, Position : 'CB', Salary : 650000, SleeperID : 6695 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Floyd`, LastName: `Allen`, Position : 'WR', Salary : 650000, SleeperID : 6697 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Casey`, LastName: `Tucker`, Position : 'T', Salary : 650000, SleeperID : 6698 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gunner`, LastName: `Olszewski`, Position : 'WR', Salary : 650000, SleeperID : 6699 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcelias`, LastName: `Sutton`, Position : 'RB', Salary : 650000, SleeperID : 6700 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fred`, LastName: `Trevillion`, Position : 'WR', Salary : 650000, SleeperID : 6701 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taj`, LastName: `McGowan`, Position : 'RB', Salary : 650000, SleeperID : 6705 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sione`, LastName: `Teuhema`, Position : 'OLB', Salary : 650000, SleeperID : 6706 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Langley`, Position : 'DB', Salary : 650000, SleeperID : 6707 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalani`, LastName: `Vakameilalo`, Position : 'DT', Salary : 650000, SleeperID : 6708 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Abdul`, LastName: `Beecham`, Position : 'G', Salary : 650000, SleeperID : 6709 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Thompson`, Position : 'DB', Salary : 650000, SleeperID : 6711 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Simms`, Position : 'WR', Salary : 650000, SleeperID : 6712 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamarius`, LastName: `Way`, Position : 'WR', Salary : 650000, SleeperID : 6713 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mazzi`, LastName: `Wilkins`, Position : 'DB', Salary : 650000, SleeperID : 6714 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Olive`, LastName: `Sagapolu`, Position : 'DT', Salary : 650000, SleeperID : 6716 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ka'John`, LastName: `Armstrong`, Position : 'OT', Salary : 650000, SleeperID : 6717 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `McCray`, Position : 'G', Salary : 650000, SleeperID : 6718 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Allen`, Position : 'G', Salary : 650000, SleeperID : 6719 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Caldwell`, Position : 'RB', Salary : 650000, SleeperID : 6710 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Yarbrough`, Position : 'C', Salary : 650000, SleeperID : 6720 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jawuan`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 6721 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dave`, LastName: `Deguglielmo`, Position : '0', Salary : 650000, SleeperID : 6722 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vincent`, LastName: `Testaverde`, Position : 'QB', Salary : 650000, SleeperID : 6723 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Moore`, Position : 'DB', Salary : 650000, SleeperID : 6724 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rishard`, LastName: `Cook`, Position : 'G', Salary : 650000, SleeperID : 6725 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Lawrence`, Position : 'OT', Salary : 650000, SleeperID : 6726 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Walker`, Position : 'WR', Salary : 650000, SleeperID : 6727 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jackson`, LastName: `Harris`, Position : 'TE', Salary : 650000, SleeperID : 6728 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Ta'amu`, Position : 'QB', Salary : 650000, SleeperID : 6730 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Vornkahl`, Position : 'OT', Salary : 650000, SleeperID : 6732 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeJuan`, LastName: `Neal`, Position : 'DB', Salary : 650000, SleeperID : 6733 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ajene`, LastName: `Harris`, Position : 'DB', Salary : 650000, SleeperID : 6729 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Costigan`, Position : 'DL', Salary : 650000, SleeperID : 6734 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micky`, LastName: `Crum`, Position : 'TE', Salary : 650000, SleeperID : 6737 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Nizialek`, Position : 'P', Salary : 650000, SleeperID : 6739 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brett`, LastName: `Toth`, Position : 'OL', Salary : 650000, SleeperID : 6740 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 6741 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquis`, LastName: `Young`, Position : 'RB', Salary : 650000, SleeperID : 6742 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quart'e`, LastName: `Sapp`, Position : 'LB', Salary : 650000, SleeperID : 6743 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Ward`, Position : 'WR', Salary : 650000, SleeperID : 6744 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryson`, LastName: `Allen-Williams`, Position : 'OLB', Salary : 650000, SleeperID : 6748 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Holland`, Position : 'DB', Salary : 650000, SleeperID : 6749 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Tago`, Position : 'LB', Salary : 650000, SleeperID : 6750 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Gore`, Position : 'RB', Salary : 650000, SleeperID : 6753 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tavonn`, LastName: `Salter`, Position : 'WR', Salary : 650000, SleeperID : 6755 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fisayo`, LastName: `Awolaja`, Position : 'G', Salary : 650000, SleeperID : 6747 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Fisher`, Position : 'LS', Salary : 650000, SleeperID : 6757 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Kelly II`, Position : '0', Salary : 650000, SleeperID : 6752 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kahani`, LastName: `Smith`, Position : 'FS', Salary : 650000, SleeperID : 6756 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Lafleur`, Position : '0', Salary : 650000, SleeperID : 6758 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Kongbo`, Position : 'DE', Salary : 650000, SleeperID : 6760 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Weaver`, Position : '0', Salary : 650000, SleeperID : 6772 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Easterby`, Position : '0', Salary : 650000, SleeperID : 6774 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Van Pelt`, Position : '0', Salary : 650000, SleeperID : 6776 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darion`, LastName: `Clark`, Position : 'TE', Salary : 650000, SleeperID : 6762 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Begelton`, Position : 'WR', Salary : 650000, SleeperID : 6763 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ed`, LastName: `Dodds`, Position : '0', Salary : 650000, SleeperID : 6765 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Brady`, Position : '0', Salary : 650000, SleeperID : 6767 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tua`, LastName: `Tagovailoa`, Position : 'QB', Salary : 1319879.751, SleeperID : 6768 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Burrow`, Position : 'QB', Salary : 4680622.125, SleeperID : 6770 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phil`, LastName: `Snow`, Position : '0', Salary : 650000, SleeperID : 6771 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Scarnecchia`, Position : '0', Salary : 650000, SleeperID : 6773 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Romeo`, LastName: `Crennel`, Position : '0', Salary : 650000, SleeperID : 6775 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Streveler`, Position : 'QB', Salary : 650000, SleeperID : 6778 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Moncrief`, Position : 'LB', Salary : 650000, SleeperID : 6764 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Houston`, Position : '0', Salary : 650000, SleeperID : 6780 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Young`, Position : 'DE', Salary : 650000, SleeperID : 6782 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerry`, LastName: `Jeudy`, Position : 'WR', Salary : 902825.052, SleeperID : 6783 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Okudah`, Position : 'CB', Salary : 650000, SleeperID : 6785 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Simmons`, Position : 'DB', Salary : 650000, SleeperID : 6781 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `CeeDee`, LastName: `Lamb`, Position : 'WR', Salary : 14259375, SleeperID : 6786 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jedrick`, LastName: `Wills`, Position : 'OT', Salary : 650000, SleeperID : 6787 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Brown`, Position : 'DT', Salary : 650000, SleeperID : 6784 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `McKinney`, Position : 'DB', Salary : 650000, SleeperID : 6788 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henry`, LastName: `Ruggs`, Position : 'WR', Salary : 650000, SleeperID : 6789 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austen`, LastName: `Pleasants`, Position : 'OL', Salary : 650000, SleeperID : 7469 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jabari`, LastName: `Zuniga`, Position : 'DE', Salary : 650000, SleeperID : 6897 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Gordon`, Position : 'QB', Salary : 650000, SleeperID : 6898 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terence`, LastName: `Steele`, Position : 'T', Salary : 650000, SleeperID : 6899 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Greenard`, Position : 'DE', Salary : 650000, SleeperID : 6900 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Metellus`, Position : 'DB', Salary : 650000, SleeperID : 6901 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Elliott`, Position : 'DB', Salary : 650000, SleeperID : 6902 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lloyd`, LastName: `Cushenberry`, Position : 'C', Salary : 650000, SleeperID : 6903 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Peart`, Position : 'T', Salary : 650000, SleeperID : 6905 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Gandy-Golden`, Position : 'TE', Salary : 650000, SleeperID : 6906 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Harris`, Position : 'C', Salary : 650000, SleeperID : 6907 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `La'Mical`, LastName: `Perine`, Position : 'RB', Salary : 650000, SleeperID : 6908 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lynn`, LastName: `Bowden`, Position : 'WR', Salary : 650000, SleeperID : 6909 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Taylor`, Position : 'DE', Salary : 650000, SleeperID : 6910 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Jones`, Position : 'DB', Salary : 650000, SleeperID : 6911 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davion`, LastName: `Taylor`, Position : 'LB', Salary : 650000, SleeperID : 6912 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Hurts`, Position : 'QB', Salary : 5731478.341, SleeperID : 6904 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Reed`, Position : 'WR', Salary : 650000, SleeperID : 6913 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alton`, LastName: `Robinson`, Position : 'DE', Salary : 650000, SleeperID : 6915 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stanford`, LastName: `Samuels`, Position : 'CB', Salary : 650000, SleeperID : 6916 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antoine`, LastName: `Brooks`, Position : 'DB', Salary : 650000, SleeperID : 6917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Salvon`, LastName: `Ahmed`, Position : 'RB', Salary : 650000, SleeperID : 6918 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thaddeus`, LastName: `Moss`, Position : 'TE', Salary : 650000, SleeperID : 6919 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Hodgins`, Position : 'WR', Salary : 650000, SleeperID : 6920 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jauan`, LastName: `Jennings`, Position : 'WR', Salary : 650000, SleeperID : 7049 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Uche`, Position : 'LB', Salary : 650000, SleeperID : 6921 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grayland`, LastName: `Arnold`, Position : 'DB', Salary : 650000, SleeperID : 7051 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.R.`, LastName: `Reed`, Position : 'DB', Salary : 650000, SleeperID : 7046 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Olson`, Position : 'LB', Salary : 650000, SleeperID : 7047 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benny`, LastName: `LeMay`, Position : 'RB', Salary : 650000, SleeperID : 7048 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josiah`, LastName: `Deguara`, Position : 'TE', Salary : 650000, SleeperID : 7050 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ezra`, LastName: `Cleveland`, Position : 'G', Salary : 650000, SleeperID : 6922 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Kelley`, Position : 'RB', Salary : 650000, SleeperID : 7045 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Reid`, Position : 'CB', Salary : 650000, SleeperID : 7052 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Thomas`, Position : 'DB', Salary : 650000, SleeperID : 7053 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Kraemer`, Position : 'G', Salary : 650000, SleeperID : 7054 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sewo`, LastName: `Olonilua`, Position : 'FB', Salary : 650000, SleeperID : 7055 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Nigh`, Position : 'FB', Salary : 650000, SleeperID : 7212 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Montano`, Position : 'G', Salary : 650000, SleeperID : 7213 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Taylor`, Position : 'DE', Salary : 650000, SleeperID : 7214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corliss`, LastName: `Waitman`, Position : 'P', Salary : 650000, SleeperID : 7215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Giovanni`, LastName: `Ricci`, Position : 'FB', Salary : 650000, SleeperID : 7216 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevontae`, LastName: `Hights`, Position : 'WR', Salary : 650000, SleeperID : 7217 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Sutton`, Position : 'TE', Salary : 650000, SleeperID : 7218 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrin`, LastName: `Paulo`, Position : 'T', Salary : 650000, SleeperID : 7372 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gus`, LastName: `Cumberlander`, Position : 'DE', Salary : 650000, SleeperID : 7373 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tino`, LastName: `Ellis`, Position : 'CB', Salary : 650000, SleeperID : 7374 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Washington`, Position : 'DB', Salary : 650000, SleeperID : 7375 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Case`, LastName: `Cookus`, Position : 'QB', Salary : 650000, SleeperID : 7376 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Murphy`, Position : 'OL', Salary : 650000, SleeperID : 7377 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Markway`, Position : 'TE', Salary : 650000, SleeperID : 7378 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rysen`, LastName: `John`, Position : 'TE', Salary : 650000, SleeperID : 7379 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Haycraft`, Position : 'T', Salary : 650000, SleeperID : 7380 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Niko`, LastName: `Lalos`, Position : 'DE', Salary : 650000, SleeperID : 7381 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominique`, LastName: `Ross`, Position : 'LB', Salary : 650000, SleeperID : 7382 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dana`, LastName: `Levine`, Position : 'DE', Salary : 650000, SleeperID : 7383 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaquarius`, LastName: `Landrews`, Position : 'DB', Salary : 650000, SleeperID : 7384 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Slomka`, Position : 'RB', Salary : 650000, SleeperID : 7464 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Tiano`, Position : 'QB', Salary : 650000, SleeperID : 7465 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Cottrell`, Position : 'RB', Salary : 650000, SleeperID : 7466 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Gillikin`, Position : 'P', Salary : 650000, SleeperID : 7467 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Gooseberry`, Position : 'G', Salary : 650000, SleeperID : 7468 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Andre`, LastName: `Swift`, Position : 'RB', Salary : 4338278.498, SleeperID : 6790 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Bituli`, Position : 'LB', Salary : 650000, SleeperID : 7485 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frankie`, LastName: `Griffin`, Position : 'LB', Salary : 650000, SleeperID : 7486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Delontae`, LastName: `Scott`, Position : 'LB', Salary : 650000, SleeperID : 7487 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Hubbard`, Position : 'OT', Salary : 650000, SleeperID : 7488 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kylan`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 7489 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `London`, Position : 'LB', Salary : 650000, SleeperID : 7490 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedrick`, LastName: `Lattimore`, Position : 'DT', Salary : 650000, SleeperID : 7491 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Norwood`, Position : 'FS', Salary : 650000, SleeperID : 7492 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyahva`, LastName: `Tezino`, Position : 'LB', Salary : 650000, SleeperID : 7494 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Courtney`, LastName: `Wallace`, Position : 'DT', Salary : 650000, SleeperID : 7495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Westbrook-Ikhine`, Position : 'WR', Salary : 650000, SleeperID : 7496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Davis`, Position : 'TE', Salary : 650000, SleeperID : 7503 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kamaal`, LastName: `Seymour`, Position : 'OT', Salary : 650000, SleeperID : 7497 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rex`, LastName: `Sunahara`, Position : 'LS', Salary : 650000, SleeperID : 7498 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paul`, LastName: `Quessenberry`, Position : 'TE', Salary : 650000, SleeperID : 7499 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Barnett`, Position : 'DT', Salary : 650000, SleeperID : 7500 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominique`, LastName: `Dafney`, Position : 'TE', Salary : 650000, SleeperID : 7502 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Jon`, LastName: `Harris`, Position : 'LB', Salary : 650000, SleeperID : 7504 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eli`, LastName: `Wolf`, Position : 'TE', Salary : 650000, SleeperID : 7065 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K.J.`, LastName: `Osborn`, Position : 'WR', Salary : 650000, SleeperID : 7066 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Simon`, LastName: `Stepaniak`, Position : 'OG', Salary : 650000, SleeperID : 7067 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Francis`, LastName: `Bernard`, Position : 'LB', Salary : 650000, SleeperID : 7068 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Manny`, LastName: `Patterson`, Position : 'DB', Salary : 650000, SleeperID : 7513 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alohi`, LastName: `Gilman`, Position : 'DB', Salary : 650000, SleeperID : 7069 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `Braun`, Position : 'G', Salary : 650000, SleeperID : 7070 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JJ`, LastName: `Molson`, Position : 'K', Salary : 650000, SleeperID : 7071 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Turk`, Position : 'P', Salary : 650000, SleeperID : 7072 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charlie`, LastName: `Woerner`, Position : 'TE', Salary : 650000, SleeperID : 7075 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Thomas`, Position : 'WR', Salary : 650000, SleeperID : 7076 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Bobenmoyer`, Position : 'LS', Salary : 650000, SleeperID : 7077 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 7078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pete`, LastName: `Guerriero`, Position : 'RB', Salary : 650000, SleeperID : 7079 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Omar`, LastName: `Bayless`, Position : 'WR', Salary : 650000, SleeperID : 7080 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Morgan`, Position : 'QB', Salary : 650000, SleeperID : 7081 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalton`, LastName: `Keene`, Position : 'TE', Salary : 650000, SleeperID : 7082 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Adams`, Position : 'WR', Salary : 650000, SleeperID : 7613 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demetric`, LastName: `Felton`, Position : 'RB', Salary : 650000, SleeperID : 7609 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Lance`, Position : 'QB', Salary : 650000, SleeperID : 7610 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rhamondre`, LastName: `Stevenson`, Position : 'RB', Salary : 4229821.535, SleeperID : 7611 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dee`, LastName: `Eskridge`, Position : 'WR', Salary : 650000, SleeperID : 7612 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Carter`, Position : 'RB', Salary : 650000, SleeperID : 7607 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalil`, LastName: `Herbert`, Position : 'RB', Salary : 650000, SleeperID : 7608 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Clark`, Position : 'RB', Salary : 650000, SleeperID : 7614 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Khoury`, Position : 'LS', Salary : 650000, SleeperID : 7615 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kai`, LastName: `Locksley`, Position : 'WR', Salary : 650000, SleeperID : 7616 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pete`, LastName: `Werner`, Position : 'LB', Salary : 650000, SleeperID : 7647 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Bolton`, Position : 'LB', Salary : 650000, SleeperID : 7648 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dayo`, LastName: `Odeyingbo`, Position : 'DE', Salary : 650000, SleeperID : 7649 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dillon`, LastName: `Radunz`, Position : 'OL', Salary : 650000, SleeperID : 7650 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Owusu-Koramoah`, Position : 'LB', Salary : 650000, SleeperID : 7651 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Cosmi`, Position : 'OL', Salary : 650000, SleeperID : 7652 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Banks`, Position : 'OL', Salary : 650000, SleeperID : 7654 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Azeez`, LastName: `Ojulari`, Position : 'LB', Salary : 650000, SleeperID : 7653 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Asante`, LastName: `Samuel`, Position : 'CB', Salary : 650000, SleeperID : 7655 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jackson`, LastName: `Carman`, Position : 'G', Salary : 650000, SleeperID : 7656 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Walker`, LastName: `Little`, Position : 'T', Salary : 650000, SleeperID : 7657 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kelvin`, LastName: `Joseph`, Position : 'CB', Salary : 650000, SleeperID : 7658 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre'von`, LastName: `Moehrig`, Position : 'DB', Salary : 650000, SleeperID : 7659 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Liam`, LastName: `Eichenberg`, Position : 'T', Salary : 650000, SleeperID : 7660 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Levi`, LastName: `Onwuzurike`, Position : 'DE', Salary : 650000, SleeperID : 7661 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richie`, LastName: `Grant`, Position : 'DB', Salary : 650000, SleeperID : 7662 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Teven`, LastName: `Jenkins`, Position : 'OL', Salary : 650000, SleeperID : 7663 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Barmore`, Position : 'DT', Salary : 650000, SleeperID : 7664 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Landon`, LastName: `Dickerson`, Position : 'OL', Salary : 650000, SleeperID : 7665 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyson`, LastName: `Campbell`, Position : 'CB', Salary : 650000, SleeperID : 7667 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Robinson`, Position : 'CB', Salary : 650000, SleeperID : 7668 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paulson`, LastName: `Adebo`, Position : 'CB', Salary : 650000, SleeperID : 7669 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Palmer`, Position : 'WR', Salary : 650000, SleeperID : 7670 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benjamin`, LastName: `St-Juste`, Position : 'DB', Salary : 650000, SleeperID : 7671 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ernest`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 7672 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chazz`, LastName: `Surratt`, Position : 'LB', Salary : 650000, SleeperID : 7673 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jevon`, LastName: `Holland`, Position : 'DB', Salary : 650000, SleeperID : 7666 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pat`, LastName: `Jones`, Position : 'DE', Salary : 650000, SleeperID : 7674 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wyatt`, LastName: `Davis`, Position : 'G', Salary : 650000, SleeperID : 7675 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Koonce`, Position : 'DE', Salary : 650000, SleeperID : 7676 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Divine`, LastName: `Deablo`, Position : 'LB', Salary : 650000, SleeperID : 7677 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joseph`, LastName: `Ossai`, Position : 'DE', Salary : 650000, SleeperID : 7678 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alim`, LastName: `McNeill`, Position : 'NT', Salary : 650000, SleeperID : 7679 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ifeatu`, LastName: `Melifonwu`, Position : 'CB', Salary : 650000, SleeperID : 7680 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kemah`, LastName: `Siverand`, Position : 'DB', Salary : 650000, SleeperID : 7434 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Scarlett`, Position : 'RB', Salary : 650000, SleeperID : 7435 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 7437 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kristian`, LastName: `Wilkerson`, Position : 'WR', Salary : 650000, SleeperID : 7438 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Hudson`, Position : 'TE', Salary : 650000, SleeperID : 7439 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Brewer`, Position : 'OL', Salary : 650000, SleeperID : 7440 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Kemp`, Position : 'T', Salary : 650000, SleeperID : 7441 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `McKinney`, Position : 'OT', Salary : 650000, SleeperID : 7442 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Teair`, LastName: `Tart`, Position : 'DT', Salary : 650000, SleeperID : 7443 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kobe`, LastName: `Smith`, Position : 'DT', Salary : 650000, SleeperID : 7444 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khaylan`, LastName: `Kearse-Thomas`, Position : 'LB', Salary : 650000, SleeperID : 7445 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tucker`, LastName: `McCann`, Position : 'K', Salary : 650000, SleeperID : 7446 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cale`, LastName: `Garrett`, Position : 'LB', Salary : 650000, SleeperID : 7447 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parnell`, LastName: `Motley`, Position : 'CB', Salary : 650000, SleeperID : 7449 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nasir`, LastName: `Player`, Position : 'DL', Salary : 650000, SleeperID : 7450 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Gill`, Position : 'LB', Salary : 650000, SleeperID : 7451 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Pearson`, Position : 'WR', Salary : 650000, SleeperID : 7452 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Hagan`, Position : 'DB', Salary : 650000, SleeperID : 7453 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Jonsen`, Position : 'WR', Salary : 650000, SleeperID : 7454 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Hurst`, Position : 'WR', Salary : 650000, SleeperID : 7455 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benning`, LastName: `Potoa'e`, Position : 'DT', Salary : 650000, SleeperID : 7456 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Dereus`, Position : 'WR', Salary : 650000, SleeperID : 7457 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Leverett`, Position : 'G', Salary : 650000, SleeperID : 7448 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Zuber`, Position : 'WR', Salary : 650000, SleeperID : 7458 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashod`, LastName: `Berry`, Position : 'LB', Salary : 650000, SleeperID : 7459 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Burt`, Position : 'TE', Salary : 650000, SleeperID : 7460 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austrian`, LastName: `Robinson`, Position : 'DE', Salary : 650000, SleeperID : 7461 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Guidry`, Position : 'WR', Salary : 650000, SleeperID : 7462 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Bass`, Position : 'K', Salary : 650000, SleeperID : 7042 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Dishon`, Position : 'DT', Salary : 650000, SleeperID : 7463 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Yeboah`, Position : 'TE', Salary : 650000, SleeperID : 7597 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermar`, LastName: `Jefferson`, Position : 'RB', Salary : 650000, SleeperID : 7599 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Ferguson`, Position : 'LS', Salary : 650000, SleeperID : 7043 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Blackmon`, Position : 'DB', Salary : 650000, SleeperID : 7044 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Pinckney`, Position : 'LB', Salary : 650000, SleeperID : 7514 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sergio`, LastName: `Castillo`, Position : 'K', Salary : 650000, SleeperID : 7515 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Niswander`, Position : 'P', Salary : 650000, SleeperID : 7516 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Russolino`, Position : 'K', Salary : 650000, SleeperID : 7518 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Patrick`, Position : 'DE', Salary : 650000, SleeperID : 7519 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Innis`, LastName: `Gaines`, Position : 'DB', Salary : 650000, SleeperID : 7520 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Blair`, Position : 'WR', Salary : 650000, SleeperID : 7521 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Thedford`, Position : 'TE', Salary : 650000, SleeperID : 7522 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Lawrence`, Position : 'QB', Salary : 1834316.878, SleeperID : 7523 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeVonta`, LastName: `Smith`, Position : 'WR', Salary : 8814307.323, SleeperID : 7525 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Waddle`, Position : 'WR', Salary : 9753707.463, SleeperID : 7526 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mac`, LastName: `Jones`, Position : 'QB', Salary : 650000, SleeperID : 7527 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Najee`, LastName: `Harris`, Position : 'RB', Salary : 2820953.291, SleeperID : 7528 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gary`, LastName: `Brightwell`, Position : 'RB', Salary : 650000, SleeperID : 7529 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frank`, LastName: `Darby`, Position : 'WR', Salary : 650000, SleeperID : 7530 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Feleipe`, LastName: `Franks`, Position : 'TE', Salary : 650000, SleeperID : 7531 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rakeem`, LastName: `Boyd`, Position : 'RB', Salary : 650000, SleeperID : 7532 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sage`, LastName: `Surratt`, Position : 'TE', Salary : 650000, SleeperID : 7576 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myron`, LastName: `Mitchell`, Position : 'WR', Salary : 650000, SleeperID : 7578 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Watkins`, Position : 'WR', Salary : 650000, SleeperID : 7579 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalil`, LastName: `McClain`, Position : 'WR', Salary : 650000, SleeperID : 7580 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kellen`, LastName: `Mond`, Position : 'QB', Salary : 650000, SleeperID : 7581 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jhamon`, LastName: `Ausbon`, Position : 'WR', Salary : 650000, SleeperID : 7582 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Ehlinger`, Position : 'QB', Salary : 650000, SleeperID : 7583 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brennan`, LastName: `Eagles`, Position : 'WR', Salary : 650000, SleeperID : 7584 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davis`, LastName: `Mills`, Position : 'QB', Salary : 650000, SleeperID : 7585 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dazz`, LastName: `Newsome`, Position : 'WR', Salary : 650000, SleeperID : 7586 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javonte`, LastName: `Williams`, Position : 'RB', Salary : 4449516.408, SleeperID : 7588 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ian`, LastName: `Book`, Position : 'QB', Salary : 650000, SleeperID : 7589 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Fields`, Position : 'QB', Salary : 650000, SleeperID : 7591 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Sermon`, Position : 'RB', Salary : 650000, SleeperID : 7593 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emmanuel`, LastName: `Rugamba`, Position : 'CB', Salary : 650000, SleeperID : 8030 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avery`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 7809 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dyami`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 7587 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ade`, LastName: `Ogundeji`, Position : 'LB', Salary : 650000, SleeperID : 7810 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Talanoa`, LastName: `Hufanga`, Position : 'DB', Salary : 650000, SleeperID : 7811 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Simi`, LastName: `Fehoko`, Position : 'WR', Salary : 650000, SleeperID : 7812 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shemar`, LastName: `Jean-Charles`, Position : 'CB', Salary : 650000, SleeperID : 7813 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `McGrone`, Position : 'LB', Salary : 650000, SleeperID : 7814 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K.J.`, LastName: `Britt`, Position : 'LB', Salary : 650000, SleeperID : 7815 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Pinnock`, Position : 'CB', Salary : 650000, SleeperID : 7816 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Kinsey`, Position : 'WR', Salary : 650000, SleeperID : 7436 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Earnest`, LastName: `Brown`, Position : 'DE', Salary : 650000, SleeperID : 7817 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Slaton`, Position : 'DT', Salary : 650000, SleeperID : 7818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shawn`, LastName: `Davis`, Position : 'DB', Salary : 650000, SleeperID : 7825 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deommodore`, LastName: `Lenoir`, Position : 'DB', Salary : 650000, SleeperID : 7819 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daelin`, LastName: `Hayes`, Position : 'LB', Salary : 650000, SleeperID : 7820 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garret`, LastName: `Wallow`, Position : 'LB', Salary : 650000, SleeperID : 7821 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richard`, LastName: `LeCounte`, Position : 'DB', Salary : 650000, SleeperID : 7822 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Taylor`, Position : 'CB', Salary : 650000, SleeperID : 7824 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamar`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 7826 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrick`, LastName: `Forrest`, Position : 'DB', Salary : 650000, SleeperID : 7827 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Gray`, Position : 'TE', Salary : 650000, SleeperID : 7828 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Hobbs`, Position : 'CB', Salary : 650000, SleeperID : 7823 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Doyle`, Position : 'OT', Salary : 650000, SleeperID : 7829 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaun`, LastName: `Wade`, Position : 'CB', Salary : 650000, SleeperID : 7830 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brenden`, LastName: `Jaimes`, Position : 'OL', Salary : 650000, SleeperID : 7831 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josiah`, LastName: `Scott`, Position : 'CB', Salary : 650000, SleeperID : 6935 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joseph`, LastName: `Charlton`, Position : 'P', Salary : 650000, SleeperID : 6936 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Dye`, Position : 'LB', Salary : 650000, SleeperID : 6941 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khaleke`, LastName: `Hudson`, Position : 'LB', Salary : 650000, SleeperID : 6937 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Akers`, Position : 'RB', Salary : 650000, SleeperID : 6938 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Binjimen`, LastName: `Victor`, Position : 'WR', Salary : 650000, SleeperID : 6939 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shyheim`, LastName: `Carter`, Position : 'DB', Salary : 650000, SleeperID : 6940 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabe`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 6943 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tremayne`, LastName: `Anchrum`, Position : 'OL', Salary : 650000, SleeperID : 6944 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Gibson`, Position : 'RB', Salary : 650000, SleeperID : 6945 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K'Von`, LastName: `Wallace`, Position : 'DB', Salary : 650000, SleeperID : 6947 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordyn`, LastName: `Brooks`, Position : 'LB', Salary : 650000, SleeperID : 6949 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leki`, LastName: `Fotu`, Position : 'DT', Salary : 650000, SleeperID : 6948 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braden`, LastName: `Mann`, Position : 'P', Salary : 650000, SleeperID : 6950 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Driscoll`, Position : 'T', Salary : 650000, SleeperID : 6942 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eno`, LastName: `Benjamin`, Position : 'RB', Salary : 650000, SleeperID : 6951 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Bryant`, Position : 'DB', Salary : 650000, SleeperID : 6946 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonah`, LastName: `Jackson`, Position : 'G', Salary : 650000, SleeperID : 6952 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `McDonald`, Position : 'QB', Salary : 650000, SleeperID : 6954 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Robinson`, Position : 'RB', Salary : 650000, SleeperID : 6955 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Asiasi`, Position : 'TE', Salary : 650000, SleeperID : 6956 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Proche`, Position : 'WR', Salary : 650000, SleeperID : 6957 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Danna`, Position : 'DE', Salary : 650000, SleeperID : 6958 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 6960 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LeVante`, LastName: `Bellamy`, Position : 'RB', Salary : 650000, SleeperID : 6959 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gerrid`, LastName: `Doaks`, Position : 'RB', Salary : 650000, SleeperID : 7539 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amari`, LastName: `Rodgers`, Position : 'WR', Salary : 650000, SleeperID : 7540 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cornell`, LastName: `Powell`, Position : 'WR', Salary : 650000, SleeperID : 7541 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Etienne`, Position : 'RB', Salary : 10523395.16, SleeperID : 7543 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marlon`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 7545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Nixon`, Position : 'WR', Salary : 650000, SleeperID : 7546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amon-Ra`, LastName: `St. Brown`, Position : 'WR', Salary : 13555318.36, SleeperID : 7547 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Vaughns`, Position : 'WR', Salary : 650000, SleeperID : 7548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Mitchell`, Position : 'RB', Salary : 650000, SleeperID : 7561 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `CJ`, LastName: `Marable`, Position : 'RB', Salary : 650000, SleeperID : 7549 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Warren`, LastName: `Jackson`, Position : 'WR', Salary : 650000, SleeperID : 7550 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deon`, LastName: `Jackson`, Position : 'RB', Salary : 650000, SleeperID : 7551 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Proehl`, Position : 'WR', Salary : 650000, SleeperID : 7552 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Pitts`, Position : 'TE', Salary : 4800638.077, SleeperID : 7553 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre'`, LastName: `McKitty`, Position : 'TE', Salary : 650000, SleeperID : 7554 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamie`, LastName: `Newman`, Position : 'QB', Salary : 650000, SleeperID : 7555 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquez`, LastName: `Stevenson`, Position : 'WR', Salary : 650000, SleeperID : 7556 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Whop`, LastName: `Philyor`, Position : 'WR', Salary : 650000, SleeperID : 7558 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ihmir`, LastName: `Smith-Marsette`, Position : 'WR', Salary : 650000, SleeperID : 7559 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pooka`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 7560 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tutu`, LastName: `Atwell`, Position : 'WR', Salary : 650000, SleeperID : 7562 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javian`, LastName: `Hawkins`, Position : 'RB', Salary : 650000, SleeperID : 7563 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Marr`, LastName: `Chase`, Position : 'WR', Salary : 14625000, SleeperID : 7564 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrace`, LastName: `Marshall`, Position : 'WR', Salary : 650000, SleeperID : 7565 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenneth`, LastName: `Gainwell`, Position : 'RB', Salary : 650000, SleeperID : 7567 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brevin`, LastName: `Jordan`, Position : 'TE', Salary : 650000, SleeperID : 7568 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nico`, LastName: `Collins`, Position : 'WR', Salary : 6342320.619, SleeperID : 7569 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashod`, LastName: `Bateman`, Position : 'WR', Salary : 650000, SleeperID : 7571 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kylin`, LastName: `Hill`, Position : 'RB', Salary : 650000, SleeperID : 7572 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Jones`, Position : 'RB', Salary : 650000, SleeperID : 7088 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Divinity`, Position : 'LB', Salary : 650000, SleeperID : 7089 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darnell`, LastName: `Mooney`, Position : 'WR', Salary : 650000, SleeperID : 7090 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Parker`, Position : 'WR', Salary : 650000, SleeperID : 7091 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Finke`, Position : 'WR', Salary : 650000, SleeperID : 7092 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Killins`, Position : 'RB', Salary : 650000, SleeperID : 7093 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Dotson`, Position : 'OL', Salary : 650000, SleeperID : 7116 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `L'Jarius`, LastName: `Sneed`, Position : 'CB', Salary : 650000, SleeperID : 7117 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Pinter`, Position : 'G', Salary : 650000, SleeperID : 7118 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Robinson`, Position : 'DB', Salary : 650000, SleeperID : 7119 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevis`, LastName: `Gipson`, Position : 'LB', Salary : 650000, SleeperID : 7120 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Rohrwasser`, Position : 'K', Salary : 650000, SleeperID : 7121 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Broderick`, LastName: `Washington`, Position : 'DT', Salary : 650000, SleeperID : 7122 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Onwenu`, Position : 'OL', Salary : 650000, SleeperID : 7123 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bravvion`, LastName: `Roy`, Position : 'DT', Salary : 650000, SleeperID : 7124 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Borghi`, Position : 'RB', Salary : 650000, SleeperID : 8218 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Saunders`, Position : 'WR', Salary : 650000, SleeperID : 8068 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalil`, LastName: `Davis`, Position : 'DT', Salary : 650000, SleeperID : 7125 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Herron`, Position : 'OL', Salary : 650000, SleeperID : 7126 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaun`, LastName: `Bradley`, Position : 'LB', Salary : 650000, SleeperID : 7127 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Brandel`, Position : 'T', Salary : 650000, SleeperID : 7129 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cassh`, LastName: `Maluia`, Position : 'LB', Salary : 650000, SleeperID : 7130 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Davis`, Position : 'TE', Salary : 650000, SleeperID : 7131 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Duffy`, Position : 'P', Salary : 650000, SleeperID : 8066 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jovan`, LastName: `Swann`, Position : 'DE', Salary : 650000, SleeperID : 8067 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Coon`, Position : 'G', Salary : 650000, SleeperID : 8069 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Toivonen`, Position : 'WR', Salary : 650000, SleeperID : 8070 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `Fraboni`, Position : 'LS', Salary : 650000, SleeperID : 8071 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Yarbary`, Position : 'DT', Salary : 650000, SleeperID : 8072 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Penisini`, Position : 'NT', Salary : 650000, SleeperID : 7128 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrone`, LastName: `Wheatley`, Position : 'OL', Salary : 650000, SleeperID : 8073 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Seybert`, Position : 'TE', Salary : 650000, SleeperID : 8074 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Bandy`, Position : 'WR', Salary : 650000, SleeperID : 8076 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Otis`, LastName: `Anderson`, Position : 'RB', Salary : 650000, SleeperID : 8077 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damon`, LastName: `Lloyd`, Position : 'LB', Salary : 650000, SleeperID : 8075 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Roach`, Position : 'DT', Salary : 650000, SleeperID : 7018 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chauncey`, LastName: `Rivers`, Position : 'LB', Salary : 650000, SleeperID : 7019 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Brown`, Position : 'LB', Salary : 650000, SleeperID : 7020 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rico`, LastName: `Dowdle`, Position : 'RB', Salary : 650000, SleeperID : 7021 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shane`, LastName: `Lemieux`, Position : 'OL', Salary : 650000, SleeperID : 7022 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Gay`, Position : 'LB', Salary : 650000, SleeperID : 7023 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Smith-Williams`, Position : 'DE', Salary : 650000, SleeperID : 7024 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josiah`, LastName: `Coatney`, Position : 'DT', Salary : 650000, SleeperID : 7025 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Gonzalez`, Position : 'OG', Salary : 650000, SleeperID : 7026 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Mayden`, Position : 'DB', Salary : 650000, SleeperID : 7027 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Taylor`, Position : 'T', Salary : 650000, SleeperID : 7028 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Casey`, LastName: `Toohill`, Position : 'DE', Salary : 650000, SleeperID : 7030 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ray`, LastName: `Lima`, Position : 'DT', Salary : 650000, SleeperID : 7031 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrie`, LastName: `Cleveland`, Position : 'WR', Salary : 650000, SleeperID : 7032 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Debione`, LastName: `Renfro`, Position : 'CB', Salary : 650000, SleeperID : 7033 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Dawkins`, Position : 'LB', Salary : 650000, SleeperID : 6666 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Agasiva`, Position : 'G', Salary : 650000, SleeperID : 6667 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Sigler`, Position : 'DB', Salary : 650000, SleeperID : 6668 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Jacobson`, Position : 'OT', Salary : 650000, SleeperID : 6669 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sterling`, LastName: `Sheffield`, Position : 'OLB', Salary : 650000, SleeperID : 6670 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerald`, LastName: `Foster`, Position : 'G', Salary : 650000, SleeperID : 6671 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deion`, LastName: `Harris`, Position : 'DB', Salary : 650000, SleeperID : 6672 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Vahe`, Position : 'G', Salary : 650000, SleeperID : 6673 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `McGhin`, Position : 'OT', Salary : 650000, SleeperID : 6674 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Abraham`, LastName: `Wallace`, Position : 'FS', Salary : 650000, SleeperID : 6675 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juwon`, LastName: `Young`, Position : 'OLB', Salary : 650000, SleeperID : 6676 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Simmons`, Position : 'CB', Salary : 650000, SleeperID : 6678 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tuzar`, LastName: `Skipper`, Position : 'LB', Salary : 650000, SleeperID : 6679 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Mayfield`, Position : 'OT', Salary : 650000, SleeperID : 6681 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kareem`, LastName: `Orr`, Position : 'DB', Salary : 650000, SleeperID : 6682 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Andre`, LastName: `Payne`, Position : 'CB', Salary : 650000, SleeperID : 6683 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaDarius`, LastName: `Wiley`, Position : 'DB', Salary : 650000, SleeperID : 6684 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Roberts`, Position : 'DE', Salary : 650000, SleeperID : 6518 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrius`, LastName: `Shepherd`, Position : 'WR', Salary : 650000, SleeperID : 6519 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amare`, LastName: `Barno`, Position : 'DL', Salary : 650000, SleeperID : 8327 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Parsons`, Position : 'LB', Salary : 650000, SleeperID : 7640 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Kenney`, Position : 'OLB', Salary : 650000, SleeperID : 6680 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pat`, LastName: `Surtain`, Position : 'CB', Salary : 650000, SleeperID : 7641 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaycee`, LastName: `Horn`, Position : 'CB', Salary : 650000, SleeperID : 7642 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Penei`, LastName: `Sewell`, Position : 'T', Salary : 650000, SleeperID : 7643 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Myers`, Position : 'OL', Salary : 650000, SleeperID : 7645 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Creed`, LastName: `Humphrey`, Position : 'OL', Salary : 650000, SleeperID : 7644 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Boogie`, LastName: `Basham`, Position : 'DE', Salary : 650000, SleeperID : 7646 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Barnes`, Position : 'LB', Salary : 650000, SleeperID : 7726 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Hudson`, Position : 'OT', Salary : 650000, SleeperID : 7728 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dez`, LastName: `Fitzpatrick`, Position : 'WR', Salary : 650000, SleeperID : 7729 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darren`, LastName: `Hall`, Position : 'CB', Salary : 650000, SleeperID : 7730 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jay`, LastName: `Tufele`, Position : 'DT', Salary : 650000, SleeperID : 7731 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Hilliard`, Position : 'LB', Salary : 650000, SleeperID : 7732 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Pederson`, Position : 'TE', Salary : 650000, SleeperID : 7733 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Sample`, Position : 'DE', Salary : 650000, SleeperID : 7727 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Vonte`, LastName: `Price`, Position : 'RB', Salary : 650000, SleeperID : 8184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 8185 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devon`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 8186 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `CJ`, LastName: `Verdell`, Position : 'RB', Salary : 650000, SleeperID : 8187 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyquan`, LastName: `Thornton`, Position : 'WR', Salary : 650000, SleeperID : 8188 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trestan`, LastName: `Ebner`, Position : 'RB', Salary : 650000, SleeperID : 8189 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Abram`, LastName: `Smith`, Position : 'RB', Salary : 650000, SleeperID : 8190 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Slade`, LastName: `Bolden`, Position : 'WR', Salary : 650000, SleeperID : 8191 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Hodges`, Position : 'TE', Salary : 650000, SleeperID : 8192 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `E.J.`, LastName: `Perry`, Position : 'QB', Salary : 650000, SleeperID : 8193 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jashaun`, LastName: `Corbin`, Position : 'RB', Salary : 650000, SleeperID : 8194 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronnie`, LastName: `Rivers`, Position : 'RB', Salary : 650000, SleeperID : 8195 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Fryfogle`, Position : 'WR', Salary : 650000, SleeperID : 8196 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Peyton`, LastName: `Hendershot`, Position : 'TE', Salary : 650000, SleeperID : 8197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charleston`, LastName: `Rambo`, Position : 'WR', Salary : 650000, SleeperID : 8198 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Eriq`, LastName: `King`, Position : 'QB', Salary : 650000, SleeperID : 8199 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Coan`, Position : 'QB', Salary : 650000, SleeperID : 8201 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Woods`, Position : 'WR', Salary : 650000, SleeperID : 8202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Austin`, Position : 'WR', Salary : 650000, SleeperID : 8200 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dai'Jean`, LastName: `Dixon`, Position : 'WR', Salary : 650000, SleeperID : 8213 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Hall`, Position : 'TE', Salary : 650000, SleeperID : 8203 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bo`, LastName: `Melton`, Position : 'WR', Salary : 650000, SleeperID : 8204 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isiah`, LastName: `Pacheco`, Position : 'RB', Salary : 6504944.224, SleeperID : 8205 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Skylar`, LastName: `Thompson`, Position : 'QB', Salary : 650000, SleeperID : 8206 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Goodson`, Position : 'RB', Salary : 650000, SleeperID : 8207 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Badie`, Position : 'RB', Salary : 650000, SleeperID : 8208 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Makai`, LastName: `Polk`, Position : 'WR', Salary : 650000, SleeperID : 8209 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrion`, LastName: `Davis-Price`, Position : 'RB', Salary : 650000, SleeperID : 8211 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dustin`, LastName: `Crum`, Position : 'QB', Salary : 650000, SleeperID : 8212 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Turner`, Position : 'TE', Salary : 650000, SleeperID : 8214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Allen`, Position : 'TE', Salary : 650000, SleeperID : 8215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Hanson`, Position : 'C', Salary : 650000, SleeperID : 7132 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Rodgers`, Position : 'CB', Salary : 650000, SleeperID : 7133 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chigoziem`, LastName: `Okonkwo`, Position : 'TE', Salary : 650000, SleeperID : 8210 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Glasgow`, Position : 'LB', Salary : 650000, SleeperID : 7134 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Freddie`, LastName: `Swain`, Position : 'WR', Salary : 650000, SleeperID : 7135 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kamren`, LastName: `Curl`, Position : 'DB', Salary : 650000, SleeperID : 7136 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stantley`, LastName: `Thomas-Oliver`, Position : 'CB', Salary : 650000, SleeperID : 7137 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Claybrooks`, Position : 'DB', Salary : 650000, SleeperID : 7138 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leddie`, LastName: `Brown`, Position : 'RB', Salary : 650000, SleeperID : 8217 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arlington`, LastName: `Hambright`, Position : 'OL', Salary : 650000, SleeperID : 7139 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lachavious`, LastName: `Simmons`, Position : 'OL', Salary : 650000, SleeperID : 7140 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sterling`, LastName: `Hofrichter`, Position : 'P', Salary : 650000, SleeperID : 7141 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dustin`, LastName: `Woodard`, Position : 'OL', Salary : 650000, SleeperID : 7142 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `DiNucci`, Position : 'QB', Salary : 650000, SleeperID : 7143 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clay`, LastName: `Johnston`, Position : 'LB', Salary : 650000, SleeperID : 7144 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jashon`, LastName: `Cornell`, Position : 'DT', Salary : 650000, SleeperID : 7145 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vernon`, LastName: `Scott`, Position : 'DB', Salary : 650000, SleeperID : 7146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Stevens`, Position : 'TE', Salary : 650000, SleeperID : 7149 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bopete`, LastName: `Keyes`, Position : 'CB', Salary : 650000, SleeperID : 7147 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `TJ`, LastName: `Brunson`, Position : 'LB', Salary : 650000, SleeperID : 7148 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 7150 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Neville`, LastName: `Gallimore`, Position : 'DT', Salary : 650000, SleeperID : 6825 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Williamson`, Position : 'CB', Salary : 650000, SleeperID : 7151 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Sloman`, Position : 'K', Salary : 650000, SleeperID : 7152 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Hinton`, Position : 'G', Salary : 650000, SleeperID : 7153 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrek`, LastName: `Tuszka`, Position : 'LB', Salary : 650000, SleeperID : 7154 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tae`, LastName: `Crowder`, Position : 'LB', Salary : 650000, SleeperID : 7155 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J'Mar`, LastName: `Smith`, Position : 'QB', Salary : 650000, SleeperID : 7156 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Love`, Position : 'QB', Salary : 650000, SleeperID : 7157 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Von Rosenberg`, Position : 'P', Salary : 650000, SleeperID : 8035 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zeandae`, LastName: `Johnson`, Position : 'DT', Salary : 650000, SleeperID : 8036 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordyn`, LastName: `Peters`, Position : 'DB', Salary : 650000, SleeperID : 8043 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josiah`, LastName: `Bronson`, Position : 'DT', Salary : 650000, SleeperID : 8037 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Guggemos`, Position : 'TE', Salary : 650000, SleeperID : 8038 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Neal`, Position : 'QB', Salary : 650000, SleeperID : 7158 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jibri`, LastName: `Blount`, Position : 'TE', Salary : 650000, SleeperID : 8039 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinn`, LastName: `Nordin`, Position : 'K', Salary : 650000, SleeperID : 8040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shane`, LastName: `Zylstra`, Position : 'TE', Salary : 650000, SleeperID : 8041 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Naggar`, Position : 'K', Salary : 650000, SleeperID : 8042 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donte`, LastName: `Sylencieux`, Position : 'WR', Salary : 650000, SleeperID : 8052 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Dunn`, Position : 'DB', Salary : 650000, SleeperID : 8044 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `Ferguson`, Position : 'OL', Salary : 650000, SleeperID : 8045 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brendon`, LastName: `White`, Position : 'LB', Salary : 650000, SleeperID : 8046 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaun`, LastName: `Crawford`, Position : 'CB', Salary : 650000, SleeperID : 8047 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `McClain-Sapp`, Position : 'DB', Salary : 650000, SleeperID : 8048 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deuce`, LastName: `Wallace`, Position : 'DB', Salary : 650000, SleeperID : 8049 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lorenzo`, LastName: `Neal`, Position : 'DT', Salary : 650000, SleeperID : 8050 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Johnson`, Position : 'RB', Salary : 650000, SleeperID : 8051 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dareuan`, LastName: `Parker`, Position : 'OL', Salary : 650000, SleeperID : 8053 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Archibong`, Position : 'DT', Salary : 650000, SleeperID : 8054 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Johnson`, Position : 'K', Salary : 650000, SleeperID : 8055 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Verity`, Position : 'K', Salary : 650000, SleeperID : 8056 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenji`, LastName: `Bahar`, Position : 'QB', Salary : 650000, SleeperID : 8057 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `McCrary`, Position : 'RB', Salary : 650000, SleeperID : 8058 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Barrington`, LastName: `Wade`, Position : 'LB', Salary : 650000, SleeperID : 8059 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Murray`, Position : 'OL', Salary : 650000, SleeperID : 8060 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Hausmann`, Position : 'TE', Salary : 650000, SleeperID : 8061 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Coy`, LastName: `Cronk`, Position : 'T', Salary : 650000, SleeperID : 8062 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Silvanic`, Position : 'DT', Salary : 650000, SleeperID : 8063 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Roberts`, Position : 'LB', Salary : 650000, SleeperID : 8064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Warner`, Position : 'DB', Salary : 650000, SleeperID : 8065 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jelani`, LastName: `Woods`, Position : 'TE', Salary : 650000, SleeperID : 8219 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pat`, LastName: `Freiermuth`, Position : 'TE', Salary : 1050935.125, SleeperID : 7600 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keaontay`, LastName: `Ingram`, Position : 'RB', Salary : 650000, SleeperID : 8221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 8222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Velus`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 8223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JaQuan`, LastName: `Hardy`, Position : 'RB', Salary : 650000, SleeperID : 7863 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sincere`, LastName: `McCormick`, Position : 'RB', Salary : 650000, SleeperID : 8220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rondale`, LastName: `Moore`, Position : 'WR', Salary : 650000, SleeperID : 7601 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kylen`, LastName: `Granson`, Position : 'TE', Salary : 650000, SleeperID : 7602 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shi`, LastName: `Smith`, Position : 'WR', Salary : 650000, SleeperID : 7603 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Trask`, Position : 'QB', Salary : 650000, SleeperID : 7605 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Stephens`, Position : 'DB', Salary : 650000, SleeperID : 7685 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ambry`, LastName: `Thomas`, Position : 'CB', Salary : 650000, SleeperID : 7686 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nahshon`, LastName: `Wright`, Position : 'CB', Salary : 650000, SleeperID : 7687 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronnie`, LastName: `Perkins`, Position : 'LB', Salary : 650000, SleeperID : 7688 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Hainsey`, Position : 'OL', Salary : 650000, SleeperID : 7689 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Cleveland`, Position : 'G', Salary : 650000, SleeperID : 7690 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Brown`, Position : 'OT', Salary : 650000, SleeperID : 7691 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendrick`, LastName: `Green`, Position : 'C', Salary : 650000, SleeperID : 7692 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chauncey`, LastName: `Golston`, Position : 'DE', Salary : 650000, SleeperID : 7693 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Tremble`, Position : 'TE', Salary : 650000, SleeperID : 7694 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kadarius`, LastName: `Toney`, Position : 'WR', Salary : 650000, SleeperID : 7606 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Verone`, LastName: `McKinley`, Position : 'DB', Salary : 650000, SleeperID : 8353 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Steele`, Position : 'DB', Salary : 650000, SleeperID : 8352 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yusuf`, LastName: `Corker`, Position : 'DB', Salary : 650000, SleeperID : 8356 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquan`, LastName: `McCall`, Position : 'DL', Salary : 650000, SleeperID : 8357 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Paschal`, Position : 'DL', Salary : 650000, SleeperID : 8358 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Jones`, Position : 'DB', Salary : 650000, SleeperID : 8359 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Hall`, Position : 'DL', Salary : 650000, SleeperID : 8360 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damarion`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 8361 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Sanborn`, Position : 'LB', Salary : 650000, SleeperID : 8363 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `McDuffie`, Position : 'DB', Salary : 650000, SleeperID : 8364 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kayvon`, LastName: `Thibodeaux`, Position : 'DL', Salary : 650000, SleeperID : 8355 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leo`, LastName: `Chenal`, Position : 'LB', Salary : 650000, SleeperID : 8362 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyler`, LastName: `Gordon`, Position : 'DB', Salary : 650000, SleeperID : 8365 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Graham-Mobley`, Position : 'LB', Salary : 650000, SleeperID : 8366 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Marco`, LastName: `Jackson`, Position : 'LB', Salary : 650000, SleeperID : 8367 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Williams`, Position : 'DL', Salary : 650000, SleeperID : 8368 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chance`, LastName: `Campbell`, Position : 'LB', Salary : 650000, SleeperID : 8369 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Jackson`, Position : 'DL', Salary : 650000, SleeperID : 8370 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 8470 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Muma`, Position : 'LB', Salary : 650000, SleeperID : 8371 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAngelo`, LastName: `Malone`, Position : 'DL', Salary : 650000, SleeperID : 8372 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Mitchell`, Position : 'T', Salary : 650000, SleeperID : 8468 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Faalele`, Position : 'T', Salary : 650000, SleeperID : 8469 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `McKethan`, Position : 'OL', Salary : 650000, SleeperID : 8471 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braxton`, LastName: `Jones`, Position : 'OL', Salary : 650000, SleeperID : 8479 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darian`, LastName: `Kinnard`, Position : 'T', Salary : 650000, SleeperID : 8472 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Johnson`, Position : 'DT', Salary : 650000, SleeperID : 8473 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Wattenberg`, Position : 'OL', Salary : 650000, SleeperID : 8474 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Montrell`, LastName: `Washington`, Position : 'WR', Salary : 650000, SleeperID : 8475 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaRon`, LastName: `Bland`, Position : 'DB', Salary : 650000, SleeperID : 8476 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Samuel`, LastName: `Womack`, Position : 'CB', Salary : 650000, SleeperID : 8478 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Waletzko`, Position : 'OL', Salary : 650000, SleeperID : 8477 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamaree`, LastName: `Salyer`, Position : 'OL', Salary : 650000, SleeperID : 8480 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Sir`, LastName: `Taylor`, Position : 'DB', Salary : 650000, SleeperID : 8481 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Hall`, Position : 'DB', Salary : 650000, SleeperID : 7188 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Delrick`, LastName: `Abrams`, Position : 'DB', Salary : 650000, SleeperID : 7189 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bronson`, LastName: `Rechsteiner`, Position : 'RB', Salary : 650000, SleeperID : 7190 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Moore`, Position : 'WR', Salary : 650000, SleeperID : 7191 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daishawn`, LastName: `Dixon`, Position : 'G', Salary : 650000, SleeperID : 7192 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Pollard`, Position : 'C', Salary : 650000, SleeperID : 7193 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalija`, LastName: `Lipscomb`, Position : 'WR', Salary : 650000, SleeperID : 6832 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Crawford`, Position : 'DT', Salary : 650000, SleeperID : 7194 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Daka`, Position : 'DE', Salary : 650000, SleeperID : 7195 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kiondre`, LastName: `Thomas`, Position : 'CB', Salary : 650000, SleeperID : 8031 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Donkor`, Position : 'LB', Salary : 650000, SleeperID : 8032 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bernhard`, LastName: `Seikovits`, Position : 'TE', Salary : 650000, SleeperID : 8033 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alfredo`, LastName: `Gutierrez`, Position : 'OL', Salary : 650000, SleeperID : 8034 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damion`, LastName: `Daniels`, Position : 'DT', Salary : 650000, SleeperID : 8545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cobi`, LastName: `Francis`, Position : 'DB', Salary : 650000, SleeperID : 8546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kurt`, LastName: `Hinish`, Position : 'DT', Salary : 650000, SleeperID : 8547 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Moore`, Position : 'DB', Salary : 650000, SleeperID : 8548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Clark`, Position : 'WR', Salary : 650000, SleeperID : 8549 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Johnson`, Position : 'DE', Salary : 650000, SleeperID : 8550 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brodarious`, LastName: `Hamm`, Position : 'T', Salary : 650000, SleeperID : 8551 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rayshad`, LastName: `Nichols`, Position : 'DT', Salary : 650000, SleeperID : 8552 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aron`, LastName: `Johnson`, Position : 'OL', Salary : 650000, SleeperID : 8554 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chuck`, LastName: `Wiley`, Position : 'LB', Salary : 650000, SleeperID : 8555 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denzel`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 8556 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Diego`, LastName: `Fagot`, Position : 'LB', Salary : 650000, SleeperID : 8557 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Hartlaub`, Position : 'DB', Salary : 650000, SleeperID : 8558 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Babicz`, Position : 'TE', Salary : 650000, SleeperID : 8559 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Ford`, Position : 'QB', Salary : 650000, SleeperID : 8553 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davis`, LastName: `Cheek`, Position : 'QB', Salary : 650000, SleeperID : 8560 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Owens`, Position : 'G', Salary : 650000, SleeperID : 8561 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Tucker`, Position : 'T', Salary : 650000, SleeperID : 8563 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donovan`, LastName: `Jeter`, Position : 'DT', Salary : 650000, SleeperID : 8564 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Watts`, Position : 'CB', Salary : 650000, SleeperID : 8565 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.D.`, LastName: `Moultry`, Position : 'LB', Salary : 650000, SleeperID : 8566 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalan`, LastName: `Tolson`, Position : 'LB', Salary : 650000, SleeperID : 8567 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Lovett`, Position : 'RB', Salary : 650000, SleeperID : 8568 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arron`, LastName: `Mosby`, Position : 'LB', Salary : 650000, SleeperID : 8569 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Wright`, Position : 'WR', Salary : 650000, SleeperID : 8570 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Dixon`, Position : 'T', Salary : 650000, SleeperID : 8562 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ra'Shaun`, LastName: `Henry`, Position : 'WR', Salary : 650000, SleeperID : 8571 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Shelby`, Position : 'LB', Salary : 650000, SleeperID : 8576 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raheem`, LastName: `Layne`, Position : 'DB', Salary : 650000, SleeperID : 8578 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Talolo`, LastName: `Limi-Jones`, Position : 'WR', Salary : 650000, SleeperID : 8573 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Sebastian`, Position : 'CB', Salary : 650000, SleeperID : 8574 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erik`, LastName: `Krommenhoek`, Position : 'TE', Salary : 650000, SleeperID : 8575 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `McCourt`, Position : 'K', Salary : 650000, SleeperID : 8577 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Peters`, Position : 'QB', Salary : 650000, SleeperID : 8579 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Trainer`, Position : 'T', Salary : 650000, SleeperID : 8585 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyreek`, LastName: `Maddox-Williams`, Position : 'LB', Salary : 650000, SleeperID : 8580 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Bradford`, Position : 'WR', Salary : 650000, SleeperID : 8581 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Weaver`, Position : 'C', Salary : 650000, SleeperID : 8582 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stone`, LastName: `Smartt`, Position : 'TE', Salary : 650000, SleeperID : 8583 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Parchment`, Position : 'WR', Salary : 650000, SleeperID : 8572 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Skyler`, LastName: `Thomas`, Position : 'DB', Salary : 650000, SleeperID : 8584 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stanley`, LastName: `Berryhill`, Position : 'WR', Salary : 650000, SleeperID : 8419 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Sorenson`, Position : 'WR', Salary : 650000, SleeperID : 8420 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Harley`, Position : 'WR', Salary : 650000, SleeperID : 8421 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brittain`, LastName: `Brown`, Position : 'RB', Salary : 650000, SleeperID : 8423 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Marks`, Position : 'RB', Salary : 650000, SleeperID : 8424 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zander`, LastName: `Horvath`, Position : 'RB', Salary : 650000, SleeperID : 8428 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Levi`, LastName: `Lewis`, Position : 'QB', Salary : 650000, SleeperID : 8429 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Garbers`, Position : 'QB', Salary : 650000, SleeperID : 8431 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cade`, LastName: `Brewer`, Position : 'TE', Salary : 650000, SleeperID : 8433 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kwamie`, LastName: `Lassiter`, Position : 'WR', Salary : 650000, SleeperID : 8435 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Neil`, LastName: `Pau'u`, Position : 'WR', Salary : 650000, SleeperID : 8436 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Hennigan`, Position : 'WR', Salary : 650000, SleeperID : 8437 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Henningsen`, Position : 'DE', Salary : 650000, SleeperID : 8495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Theo`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 8496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lecitus`, LastName: `Smith`, Position : 'OL', Salary : 650000, SleeperID : 8497 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Harper`, Position : 'LB', Salary : 650000, SleeperID : 8498 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Shaffer`, Position : 'OL', Salary : 650000, SleeperID : 8499 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `FitzPatrick`, Position : 'TE', Salary : 650000, SleeperID : 8500 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Zakelj`, Position : 'OL', Salary : 650000, SleeperID : 8501 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cade`, LastName: `Mays`, Position : 'OL', Salary : 650000, SleeperID : 8502 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gregory`, LastName: `Junior`, Position : 'CB', Salary : 650000, SleeperID : 8503 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Doug`, LastName: `Kramer`, Position : 'OL', Salary : 650000, SleeperID : 8504 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `AJ`, LastName: `Arcuri`, Position : 'OT', Salary : 650000, SleeperID : 8505 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nazeeh`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 8506 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquis`, LastName: `Hayes`, Position : 'OL', Salary : 650000, SleeperID : 8507 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trenton`, LastName: `Gill`, Position : 'P', Salary : 650000, SleeperID : 8508 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Hicks`, Position : 'DB', Salary : 650000, SleeperID : 8509 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Russ`, LastName: `Yeast`, Position : 'DB', Salary : 650000, SleeperID : 8510 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rasheed`, LastName: `Walker`, Position : 'OL', Salary : 650000, SleeperID : 8511 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Anthony`, Position : 'DE', Salary : 650000, SleeperID : 8512 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dawson`, LastName: `Deaton`, Position : 'OL', Salary : 650000, SleeperID : 8513 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Stueber`, Position : 'OL', Salary : 650000, SleeperID : 8514 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Matthew`, Position : 'DB', Salary : 650000, SleeperID : 8515 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erroll`, LastName: `Thompson`, Position : 'LB', Salary : 650000, SleeperID : 6991 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Warren`, Position : 'RB', Salary : 650000, SleeperID : 6992 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominick`, LastName: `Wood-Anderson`, Position : 'TE', Salary : 650000, SleeperID : 6993 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lamar`, LastName: `Jackson`, Position : 'CB', Salary : 650000, SleeperID : 6994 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlos`, LastName: `Davis`, Position : 'DT', Salary : 650000, SleeperID : 6995 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JaMycal`, LastName: `Hasty`, Position : 'RB', Salary : 650000, SleeperID : 6996 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaquille`, LastName: `Quarterman`, Position : 'LB', Salary : 650000, SleeperID : 6997 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gage`, LastName: `Cervenka`, Position : 'OL', Salary : 650000, SleeperID : 6998 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benito`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 6999 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chapelle`, LastName: `Russell`, Position : 'LB', Salary : 650000, SleeperID : 7000 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juwan`, LastName: `Johnson`, Position : 'TE', Salary : 650000, SleeperID : 7002 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damien`, LastName: `Lewis`, Position : 'G', Salary : 650000, SleeperID : 7003 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Herrien`, Position : 'RB', Salary : 650000, SleeperID : 7001 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashard`, LastName: `Lawrence`, Position : 'DT', Salary : 650000, SleeperID : 7004 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scottie`, LastName: `Phillips`, Position : 'RB', Salary : 650000, SleeperID : 7005 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Phillips`, Position : 'LB', Salary : 650000, SleeperID : 7006 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Higby`, Position : 'OL', Salary : 650000, SleeperID : 7007 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabe`, LastName: `Brkic`, Position : 'K', Salary : 650000, SleeperID : 8260 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Lewerke`, Position : 'QB', Salary : 650000, SleeperID : 7008 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trishton`, LastName: `Jackson`, Position : 'WR', Salary : 650000, SleeperID : 7009 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Throckmorton`, Position : 'OL', Salary : 650000, SleeperID : 7010 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carter`, LastName: `Coughlin`, Position : 'LB', Salary : 650000, SleeperID : 7011 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mykal`, LastName: `Walker`, Position : 'LB', Salary : 650000, SleeperID : 7012 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Montez`, Position : 'QB', Salary : 650000, SleeperID : 7013 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Bachie`, Position : 'LB', Salary : 650000, SleeperID : 7014 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Breeland`, Position : 'TE', Salary : 650000, SleeperID : 7015 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Chinn`, Position : 'DB', Salary : 650000, SleeperID : 7016 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trajan`, LastName: `Bandy`, Position : 'CB', Salary : 650000, SleeperID : 7017 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cade`, LastName: `York`, Position : 'K', Salary : 650000, SleeperID : 8258 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Dicker`, Position : 'K', Salary : 650000, SleeperID : 8259 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Jones`, Position : 'DB', Salary : 650000, SleeperID : 8261 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Davidson`, Position : 'DL', Salary : 650000, SleeperID : 8262 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darien`, LastName: `Butler`, Position : 'LB', Salary : 650000, SleeperID : 8263 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Lucas`, Position : 'DB', Salary : 650000, SleeperID : 8264 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joey`, LastName: `Blount`, Position : 'DB', Salary : 650000, SleeperID : 8683 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deontai`, LastName: `Williams`, Position : 'SS', Salary : 650000, SleeperID : 8684 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Mitchell`, Position : 'TE', Salary : 650000, SleeperID : 8685 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Osa`, LastName: `Odighizuwa`, Position : 'DT', Salary : 650000, SleeperID : 7695 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Milton`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 7696 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brady`, LastName: `Christensen`, Position : 'T', Salary : 650000, SleeperID : 7697 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Cisco`, Position : 'DB', Salary : 650000, SleeperID : 7698 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinn`, LastName: `Meinerz`, Position : 'OL', Salary : 650000, SleeperID : 7699 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Kaindoh`, Position : 'DE', Salary : 650000, SleeperID : 7700 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyree`, LastName: `Gillespie`, Position : 'DB', Salary : 650000, SleeperID : 7701 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Royce`, LastName: `Newman`, Position : 'OL', Salary : 650000, SleeperID : 7702 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Harris`, Position : 'WR', Salary : 650000, SleeperID : 7703 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Buddy`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 7704 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Ante`, LastName: `Smith`, Position : 'G', Salary : 650000, SleeperID : 7705 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Ball`, Position : 'OT', Salary : 650000, SleeperID : 7706 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Brown`, Position : 'CB', Salary : 650000, SleeperID : 7707 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marco`, LastName: `Wilson`, Position : 'CB', Salary : 650000, SleeperID : 7708 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashad`, LastName: `Weaver`, Position : 'LB', Salary : 650000, SleeperID : 7709 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Janarius`, LastName: `Robinson`, Position : 'DE', Salary : 650000, SleeperID : 7710 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Togiai`, Position : 'DT', Salary : 650000, SleeperID : 7711 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Rochell`, Position : 'DB', Salary : 650000, SleeperID : 7712 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaelon`, LastName: `Darden`, Position : 'WR', Salary : 650000, SleeperID : 7713 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Moore`, Position : 'OT', Salary : 650000, SleeperID : 7714 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Camryn`, LastName: `Bynum`, Position : 'DB', Salary : 650000, SleeperID : 7715 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Bates`, Position : 'TE', Salary : 650000, SleeperID : 7716 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zech`, LastName: `McPhearson`, Position : 'CB', Salary : 650000, SleeperID : 7717 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Shelvin`, Position : 'DT', Salary : 650000, SleeperID : 7718 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Smith`, Position : 'DE', Salary : 650000, SleeperID : 7719 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ayo`, LastName: `Oyelola`, Position : 'DB', Salary : 650000, SleeperID : 8919 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 8920 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maurice`, LastName: `Alexander`, Position : 'WR', Salary : 650000, SleeperID : 8921 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harrison`, LastName: `Elliott`, Position : 'LS', Salary : 650000, SleeperID : 8922 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vitaliy`, LastName: `Gurman`, Position : 'G', Salary : 650000, SleeperID : 8923 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clint`, LastName: `Ratkovich`, Position : 'FB', Salary : 650000, SleeperID : 8934 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Diaz`, Position : 'P', Salary : 650000, SleeperID : 8935 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrell`, LastName: `Ford`, Position : 'CB', Salary : 650000, SleeperID : 8936 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Holmes`, Position : 'CB', Salary : 650000, SleeperID : 8516 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Thomas`, Position : 'LB', Salary : 650000, SleeperID : 8517 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thayer`, LastName: `Munford`, Position : 'OL', Salary : 650000, SleeperID : 8518 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deane`, LastName: `Leonard`, Position : 'DB', Salary : 650000, SleeperID : 8519 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Hardy`, Position : 'LB', Salary : 650000, SleeperID : 8520 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Faion`, LastName: `Hicks`, Position : 'CB', Salary : 650000, SleeperID : 8521 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Paul`, Position : 'OL', Salary : 650000, SleeperID : 8522 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Muse`, Position : 'TE', Salary : 650000, SleeperID : 8523 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Tyre`, LastName: `Carter`, Position : 'OL', Salary : 650000, SleeperID : 8524 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Robinson`, Position : 'LB', Salary : 650000, SleeperID : 8525 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Goode`, Position : 'LB', Salary : 650000, SleeperID : 8526 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dareke`, LastName: `Young`, Position : 'WR', Salary : 650000, SleeperID : 8527 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Wright`, Position : 'P', Salary : 650000, SleeperID : 8528 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyarise`, LastName: `Stevenson`, Position : 'DT', Salary : 650000, SleeperID : 8529 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luiji`, LastName: `Vilain`, Position : 'LB', Salary : 650000, SleeperID : 8530 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `McCloud`, Position : 'LB', Salary : 650000, SleeperID : 8531 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Brown`, Position : 'DB', Salary : 650000, SleeperID : 8532 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Sokol`, Position : 'C', Salary : 650000, SleeperID : 8533 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Anenih`, Position : 'DE', Salary : 650000, SleeperID : 8535 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Shudak`, Position : 'K', Salary : 650000, SleeperID : 8536 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Swilling`, Position : 'CB', Salary : 650000, SleeperID : 8537 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `McKinley`, Position : 'WR', Salary : 650000, SleeperID : 7890 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brock`, LastName: `Wright`, Position : 'TE', Salary : 650000, SleeperID : 7891 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Schwartz`, Position : 'WR', Salary : 650000, SleeperID : 7533 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seth`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 7534 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Long`, Position : 'TE', Salary : 650000, SleeperID : 7535 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Branden`, LastName: `Bowen`, Position : 'OL', Salary : 650000, SleeperID : 7219 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frederick`, LastName: `Mauigoa`, Position : 'C', Salary : 650000, SleeperID : 7220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Horton`, Position : 'G', Salary : 650000, SleeperID : 7221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Adams`, Position : 'DT', Salary : 650000, SleeperID : 7222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `AJ`, LastName: `Parker`, Position : 'CB', Salary : 650000, SleeperID : 7892 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Ferris`, Position : 'LB', Salary : 650000, SleeperID : 7223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Franklin`, Position : 'DB', Salary : 650000, SleeperID : 7224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Orr`, Position : 'LB', Salary : 650000, SleeperID : 7225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Hartsfield`, Position : 'DB', Salary : 650000, SleeperID : 7226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Artavis`, LastName: `Pierce`, Position : 'RB', Salary : 650000, SleeperID : 7227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ahmad`, LastName: `Wagner`, Position : 'WR', Salary : 650000, SleeperID : 7228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dieter`, LastName: `Eiselen`, Position : 'OL', Salary : 650000, SleeperID : 7229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scotty`, LastName: `Washington`, Position : 'WR', Salary : 650000, SleeperID : 7237 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Badara`, LastName: `Traore`, Position : 'T', Salary : 650000, SleeperID : 7230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaCale`, LastName: `London`, Position : 'DT', Salary : 650000, SleeperID : 7231 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashad`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 7232 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Baccellia`, Position : 'WR', Salary : 650000, SleeperID : 7233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maurice`, LastName: `Ffrench`, Position : 'WR', Salary : 650000, SleeperID : 7234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aleva`, LastName: `Hifo`, Position : 'WR', Salary : 650000, SleeperID : 7235 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devwah`, LastName: `Whaley`, Position : 'RB', Salary : 650000, SleeperID : 7236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Knipfel`, Position : 'T', Salary : 650000, SleeperID : 7238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clay`, LastName: `Cordasco`, Position : 'G', Salary : 650000, SleeperID : 7239 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Futrell`, Position : 'DE', Salary : 650000, SleeperID : 7240 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tarik`, LastName: `Black`, Position : 'WR', Salary : 650000, SleeperID : 8005 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Kaufusi`, Position : 'LB', Salary : 650000, SleeperID : 8006 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Butler`, Position : 'LB', Salary : 650000, SleeperID : 8007 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Imatorbhebhe`, Position : 'WR', Salary : 650000, SleeperID : 8008 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Straughter`, Position : 'CB', Salary : 650000, SleeperID : 8012 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Moses`, Position : 'LB', Salary : 650000, SleeperID : 8009 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Randall`, Position : 'DT', Salary : 650000, SleeperID : 8010 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Daniel`, Position : 'CB', Salary : 650000, SleeperID : 8011 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 8013 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `McCollum`, Position : 'C', Salary : 650000, SleeperID : 8014 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carson`, LastName: `Green`, Position : 'OL', Salary : 650000, SleeperID : 8015 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Pircher`, Position : 'OL', Salary : 650000, SleeperID : 8016 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Heim`, Position : 'OG', Salary : 650000, SleeperID : 8017 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaun`, LastName: `Beyer`, Position : 'TE', Salary : 650000, SleeperID : 8018 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devontres`, LastName: `Dukes`, Position : 'WR', Salary : 650000, SleeperID : 8019 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Rousseau`, Position : 'DE', Salary : 650000, SleeperID : 7627 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Stokes`, Position : 'CB', Salary : 650000, SleeperID : 7628 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Payton`, LastName: `Turner`, Position : 'DE', Salary : 650000, SleeperID : 7629 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Newsome`, Position : 'CB', Salary : 650000, SleeperID : 7630 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Farley`, Position : 'CB', Salary : 650000, SleeperID : 7632 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kwity`, LastName: `Paye`, Position : 'DE', Salary : 650000, SleeperID : 7633 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamin`, LastName: `Davis`, Position : 'LB', Salary : 650000, SleeperID : 7634 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaelan`, LastName: `Phillips`, Position : 'LB', Salary : 650000, SleeperID : 7635 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Leatherwood`, Position : 'T', Salary : 650000, SleeperID : 7636 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashawn`, LastName: `Slater`, Position : 'T', Salary : 650000, SleeperID : 7639 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Darrisaw`, Position : 'T', Salary : 650000, SleeperID : 7631 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nephi`, LastName: `Sewell`, Position : 'LB', Salary : 650000, SleeperID : 8328 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Lloyd`, Position : 'LB', Salary : 650000, SleeperID : 8329 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quentin`, LastName: `Lake`, Position : 'DB', Salary : 650000, SleeperID : 8330 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Otito`, LastName: `Ogbonnia`, Position : 'DL', Salary : 650000, SleeperID : 8331 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Butler`, Position : 'DL', Salary : 650000, SleeperID : 8332 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alontae`, LastName: `Taylor`, Position : 'DB', Salary : 650000, SleeperID : 8333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Rodriguez`, Position : 'LB', Salary : 650000, SleeperID : 8334 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kolby`, LastName: `Harvell-Peel`, Position : 'DB', Salary : 650000, SleeperID : 8335 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyreke`, LastName: `Smith`, Position : 'DL', Salary : 650000, SleeperID : 8336 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Haskell`, LastName: `Garrett`, Position : 'DL', Salary : 650000, SleeperID : 8337 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myron`, LastName: `Tagovailoa-Amosa`, Position : 'DL', Salary : 650000, SleeperID : 8338 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Hamilton`, Position : 'DB', Salary : 650000, SleeperID : 8339 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JoJo`, LastName: `Domann`, Position : 'LB', Salary : 650000, SleeperID : 8340 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Taylor-Britt`, Position : 'DB', Salary : 650000, SleeperID : 8341 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Esezi`, LastName: `Otomewo`, Position : 'DL', Salary : 650000, SleeperID : 8342 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drake`, LastName: `Jackson`, Position : 'DL', Salary : 650000, SleeperID : 8351 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Boye`, LastName: `Mafe`, Position : 'DL', Salary : 650000, SleeperID : 8343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Ford`, Position : 'DL', Salary : 650000, SleeperID : 8344 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bubba`, LastName: `Bolden`, Position : 'DB', Salary : 650000, SleeperID : 8345 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Rose`, Position : 'LB', Salary : 650000, SleeperID : 8346 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eyioma`, LastName: `Uwazurike`, Position : 'DL', Salary : 650000, SleeperID : 8347 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kerby`, LastName: `Joseph`, Position : 'DB', Salary : 650000, SleeperID : 8348 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Hansen`, Position : 'LB', Salary : 650000, SleeperID : 8349 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Taylor-Stuart`, Position : 'DB', Salary : 650000, SleeperID : 8350 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Avery`, Position : 'LB', Salary : 650000, SleeperID : 8696 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Shaa`, Position : 'WR', Salary : 650000, SleeperID : 8697 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shamarious`, LastName: `Gilmore`, Position : 'G', Salary : 650000, SleeperID : 8692 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kehinde`, LastName: `Oginni Hassan`, Position : 'TE', Salary : 650000, SleeperID : 8706 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Tonges`, Position : 'TE', Salary : 650000, SleeperID : 8698 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cyrus`, LastName: `Holder`, Position : 'WR', Salary : 650000, SleeperID : 8699 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Savon`, LastName: `Scarver`, Position : 'WR', Salary : 650000, SleeperID : 8700 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amari`, LastName: `Carter`, Position : 'DB', Salary : 650000, SleeperID : 8701 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 8702 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jean`, LastName: `Delance`, Position : 'T', Salary : 650000, SleeperID : 8703 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JoJo`, LastName: `Ward`, Position : 'WR', Salary : 650000, SleeperID : 7370 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Landon`, LastName: `Lenoir`, Position : 'WR', Salary : 650000, SleeperID : 8705 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Dorn`, Position : 'DB', Salary : 650000, SleeperID : 7366 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Hastings`, Position : 'WR', Salary : 650000, SleeperID : 7367 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Riley`, Position : 'WR', Salary : 650000, SleeperID : 7368 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bill`, LastName: `Murray`, Position : 'DT', Salary : 650000, SleeperID : 7369 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylan`, LastName: `Alexander`, Position : 'LB', Salary : 650000, SleeperID : 8704 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Steckler`, Position : 'OL', Salary : 650000, SleeperID : 7371 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quintin`, LastName: `Morris`, Position : 'TE', Salary : 650000, SleeperID : 7536 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaret`, LastName: `Patterson`, Position : 'RB', Salary : 650000, SleeperID : 7537 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Wilson`, Position : 'QB', Salary : 650000, SleeperID : 7538 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Crawford`, Position : 'TE', Salary : 650000, SleeperID : 8090 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Taylor`, Position : 'RB', Salary : 650000, SleeperID : 8092 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KJ`, LastName: `Sails`, Position : 'DB', Salary : 650000, SleeperID : 8093 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Jacobson`, Position : 'TE', Salary : 650000, SleeperID : 8094 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.T.`, LastName: `Jones`, Position : 'DE', Salary : 650000, SleeperID : 8091 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Graham`, LastName: `Adomitis`, Position : 'TE', Salary : 650000, SleeperID : 8095 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lakiem`, LastName: `Williams`, Position : 'LB', Salary : 650000, SleeperID : 8096 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominique`, LastName: `Martin`, Position : 'CB', Salary : 650000, SleeperID : 8097 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Tchangam`, Position : 'LB', Salary : 650000, SleeperID : 8098 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Gallagher`, Position : 'LB', Salary : 650000, SleeperID : 8100 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Coyle`, Position : 'DB', Salary : 650000, SleeperID : 7942 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Davidson`, Position : 'TE', Salary : 650000, SleeperID : 7943 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Strachan`, Position : 'WR', Salary : 650000, SleeperID : 7944 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Garrett`, Position : 'LB', Salary : 650000, SleeperID : 7945 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Stoll`, Position : 'TE', Salary : 650000, SleeperID : 7946 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kayode`, LastName: `Awosika`, Position : 'OL', Salary : 650000, SleeperID : 7947 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JaQuan`, LastName: `Bailey`, Position : 'DE', Salary : 650000, SleeperID : 7948 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harry`, LastName: `Crider`, Position : 'OL', Salary : 650000, SleeperID : 7950 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.J.`, LastName: `Emmons`, Position : 'RB', Salary : 650000, SleeperID : 7951 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Sullivan`, Position : 'LB', Salary : 650000, SleeperID : 7952 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Mills`, Position : 'CB', Salary : 650000, SleeperID : 7953 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Wedington`, Position : 'WR', Salary : 650000, SleeperID : 7954 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pier-Olivier`, LastName: `Lestage`, Position : 'OG', Salary : 650000, SleeperID : 7955 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Grimes`, Position : 'WR', Salary : 650000, SleeperID : 7949 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cade`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 7956 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Rhattigan`, Position : 'LB', Salary : 650000, SleeperID : 7957 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Eiland`, Position : 'OT', Salary : 650000, SleeperID : 7958 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Simmons`, Position : 'WR', Salary : 650000, SleeperID : 7960 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Montaric`, LastName: `Brown`, Position : 'DB', Salary : 650000, SleeperID : 8305 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Augie`, LastName: `Contressa`, Position : 'DB', Salary : 650000, SleeperID : 7959 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cor'Dale`, LastName: `Flott`, Position : 'DB', Salary : 650000, SleeperID : 8310 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrel`, LastName: `Bernard`, Position : 'LB', Salary : 650000, SleeperID : 8311 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JT`, LastName: `Woods`, Position : 'DB', Salary : 650000, SleeperID : 8313 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leon`, LastName: `O'Neal`, Position : 'DB', Salary : 650000, SleeperID : 8317 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roger`, LastName: `McCreary`, Position : 'DB', Salary : 650000, SleeperID : 8303 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayden`, LastName: `Peevy`, Position : 'DL', Salary : 650000, SleeperID : 8318 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Pitre`, Position : 'DB', Salary : 650000, SleeperID : 8314 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micheal`, LastName: `Clemons`, Position : 'DL', Salary : 650000, SleeperID : 8316 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Ridgeway`, Position : 'DL', Salary : 650000, SleeperID : 8306 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.Hansford`, LastName: `Hansford`, Position : 'LB', Salary : 650000, SleeperID : 8320 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalon`, LastName: `Barnes`, Position : 'DB', Salary : 650000, SleeperID : 8312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jesse`, LastName: `Luketa`, Position : 'LB', Salary : 650000, SleeperID : 8322 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyree`, LastName: `Johnson`, Position : 'DL', Salary : 650000, SleeperID : 8315 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Waller`, Position : 'DB', Salary : 650000, SleeperID : 8326 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Watson`, Position : 'DB', Salary : 650000, SleeperID : 8373 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeMarvin`, LastName: `Leal`, Position : 'DL', Salary : 650000, SleeperID : 8319 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tariq`, LastName: `Castro-Fields`, Position : 'DB', Salary : 650000, SleeperID : 8321 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaquan`, LastName: `Brisker`, Position : 'DB', Salary : 650000, SleeperID : 8323 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 8324 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tayon`, LastName: `Fleet-Davis`, Position : 'RB', Salary : 650000, SleeperID : 8707 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riq`, LastName: `Woolen`, Position : 'DB', Salary : 650000, SleeperID : 8374 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arnold`, LastName: `Ebiketie`, Position : 'DL', Salary : 650000, SleeperID : 8325 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalia`, LastName: `Davis`, Position : 'DL', Salary : 650000, SleeperID : 8375 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Wright`, Position : 'DL', Salary : 650000, SleeperID : 8376 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tycen`, LastName: `Anderson`, Position : 'DB', Salary : 650000, SleeperID : 8377 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaMarcus`, LastName: `Fields`, Position : 'DB', Salary : 650000, SleeperID : 8378 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Thompson`, Position : 'DB', Salary : 650000, SleeperID : 8379 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Booker`, Position : 'DL', Salary : 650000, SleeperID : 8380 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cobie`, LastName: `Durant`, Position : 'DB', Salary : 650000, SleeperID : 8381 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kingsley`, LastName: `Enagbare`, Position : 'DL', Salary : 650000, SleeperID : 8382 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Thomas`, Position : 'DL', Salary : 650000, SleeperID : 8383 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zyon`, LastName: `McCollum`, Position : 'DB', Salary : 650000, SleeperID : 8384 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Karlaftis`, Position : 'DL', Salary : 650000, SleeperID : 8385 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damarri`, LastName: `Mathis`, Position : 'DB', Salary : 650000, SleeperID : 8386 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Gemmel`, Position : 'LB', Salary : 650000, SleeperID : 8387 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Andersen`, Position : 'LB', Salary : 650000, SleeperID : 8388 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Mabry`, Position : 'TE', Salary : 650000, SleeperID : 7427 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Akayleb`, LastName: `Evans`, Position : 'DB', Salary : 650000, SleeperID : 8389 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Martin`, LastName: `Emerson`, Position : 'DB', Salary : 650000, SleeperID : 8390 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominique`, LastName: `Robinson`, Position : 'DL', Salary : 650000, SleeperID : 8391 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Cross`, Position : 'DB', Salary : 650000, SleeperID : 8392 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Qwynnterrio`, LastName: `Cole`, Position : 'DB', Salary : 650000, SleeperID : 8393 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seth`, LastName: `Dawkins`, Position : 'WR', Salary : 650000, SleeperID : 7426 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Champion`, Position : 'T', Salary : 650000, SleeperID : 7428 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Webb`, Position : 'DE', Salary : 650000, SleeperID : 7429 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Avery`, Position : 'DT', Salary : 650000, SleeperID : 7430 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eli`, LastName: `Mencer`, Position : 'DE', Salary : 650000, SleeperID : 7431 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Miller`, Position : 'DB', Salary : 650000, SleeperID : 7432 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gavin`, LastName: `Heslop`, Position : 'CB', Salary : 650000, SleeperID : 7433 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Wilcox`, Position : 'CB', Salary : 650000, SleeperID : 7755 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khyiris`, LastName: `Tonga`, Position : 'DT', Salary : 650000, SleeperID : 7756 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Skowronek`, Position : 'WR', Salary : 650000, SleeperID : 7757 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michal`, LastName: `Menet`, Position : 'OL', Salary : 650000, SleeperID : 7759 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaka`, LastName: `Toney`, Position : 'DE', Salary : 650000, SleeperID : 7760 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Norwood`, Position : 'DB', Salary : 650000, SleeperID : 7761 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Wiggins`, Position : 'DB', Salary : 650000, SleeperID : 7762 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Webb`, Position : 'DB', Salary : 650000, SleeperID : 7763 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Bradley-King`, Position : 'DE', Salary : 650000, SleeperID : 7764 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathon`, LastName: `Cooper`, Position : 'DL', Salary : 650000, SleeperID : 7765 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Farniok`, Position : 'G', Salary : 650000, SleeperID : 7766 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Fries`, Position : 'G', Salary : 650000, SleeperID : 7758 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Magee`, Position : 'OL', Salary : 650000, SleeperID : 7480 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Harrell`, Position : 'TE', Salary : 650000, SleeperID : 7481 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Oluwole`, LastName: `Betiku`, Position : 'DE', Salary : 650000, SleeperID : 7482 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Farrod`, LastName: `Green`, Position : 'TE', Salary : 650000, SleeperID : 7483 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Wellington`, Position : 'LB', Salary : 650000, SleeperID : 7484 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mathew`, LastName: `Sexton`, Position : 'WR', Salary : 650000, SleeperID : 7619 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarvis`, LastName: `Miller`, Position : 'LB', Salary : 650000, SleeperID : 7620 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.T.`, LastName: `Ibe`, Position : 'DB', Salary : 650000, SleeperID : 7621 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darece`, LastName: `Roberson`, Position : 'WR', Salary : 650000, SleeperID : 7623 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carson`, LastName: `Williams`, Position : 'TE', Salary : 650000, SleeperID : 7624 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Tryon-Shoyinka`, Position : 'LB', Salary : 650000, SleeperID : 7625 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Odafe`, LastName: `Oweh`, Position : 'LB', Salary : 650000, SleeperID : 7626 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zaven`, LastName: `Collins`, Position : 'LB', Salary : 650000, SleeperID : 7637 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alijah`, LastName: `Vera-Tucker`, Position : 'OL', Salary : 650000, SleeperID : 7638 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Asamoah`, Position : 'LB', Salary : 650000, SleeperID : 8283 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sammis`, LastName: `Reyes`, Position : 'TE', Salary : 650000, SleeperID : 7622 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vincent`, LastName: `Gray`, Position : 'DB', Salary : 650000, SleeperID : 8284 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Ross`, Position : 'LB', Salary : 650000, SleeperID : 8285 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dax`, LastName: `Hill`, Position : 'DB', Salary : 650000, SleeperID : 8286 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Reiter`, Position : 'LS', Salary : 650000, SleeperID : 8904 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Koontz`, Position : 'TE', Salary : 650000, SleeperID : 8905 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Joseph`, Position : 'DE', Salary : 650000, SleeperID : 8906 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Coco`, Position : 'LS', Salary : 650000, SleeperID : 8907 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Lewis`, Position : 'WR', Salary : 650000, SleeperID : 8908 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Black`, Position : 'DT', Salary : 650000, SleeperID : 8909 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayln`, LastName: `Holmes`, Position : 'DT', Salary : 650000, SleeperID : 8910 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Jordan`, Position : 'DE', Salary : 650000, SleeperID : 8911 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caeveon`, LastName: `Patton`, Position : 'DT', Salary : 650000, SleeperID : 8913 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Sutton`, Position : 'WR', Salary : 650000, SleeperID : 8438 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Hall`, Position : 'WR', Salary : 650000, SleeperID : 8441 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Smith`, Position : 'OL', Salary : 650000, SleeperID : 8449 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrett`, LastName: `Guarantano`, Position : 'QB', Salary : 650000, SleeperID : 8442 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Neal`, Position : 'OL', Salary : 650000, SleeperID : 8443 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalique`, LastName: `Washington`, Position : 'OL', Salary : 650000, SleeperID : 8912 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ed`, LastName: `Ingram`, Position : 'G', Salary : 650000, SleeperID : 8452 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Penning`, Position : 'T', Salary : 650000, SleeperID : 8444 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zion`, LastName: `Johnson`, Position : 'G', Salary : 650000, SleeperID : 8445 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Cross`, Position : 'OL', Salary : 650000, SleeperID : 8446 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenyon`, LastName: `Green`, Position : 'OL', Salary : 650000, SleeperID : 8447 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demetris`, LastName: `Robertson`, Position : 'WR', Salary : 650000, SleeperID : 8439 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Linderbaum`, Position : 'OL', Salary : 650000, SleeperID : 8450 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ikem`, LastName: `Ekwonu`, Position : 'T', Salary : 650000, SleeperID : 8451 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Strange`, Position : 'OL', Salary : 650000, SleeperID : 8448 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Jurgens`, Position : 'OL', Salary : 650000, SleeperID : 8453 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Abraham`, LastName: `Lucas`, Position : 'OL', Salary : 650000, SleeperID : 8457 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Goedeke`, Position : 'OL', Salary : 650000, SleeperID : 8454 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Ezeudu`, Position : 'OL', Salary : 650000, SleeperID : 8455 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Bruss`, Position : 'OL', Salary : 650000, SleeperID : 8456 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nicholas`, LastName: `Petit-Frere`, Position : 'T', Salary : 650000, SleeperID : 8461 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Parham`, Position : 'OL', Salary : 650000, SleeperID : 8458 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bernhard`, LastName: `Raimann`, Position : 'OL', Salary : 650000, SleeperID : 8459 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Rhyan`, Position : 'OL', Salary : 650000, SleeperID : 8460 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Fortner`, Position : 'G', Salary : 650000, SleeperID : 8462 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Tom`, Position : 'OL', Salary : 650000, SleeperID : 8463 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cordell`, LastName: `Volson`, Position : 'T', Salary : 650000, SleeperID : 8464 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Burford`, Position : 'OL', Salary : 650000, SleeperID : 8465 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Camarda`, Position : 'P', Salary : 650000, SleeperID : 8466 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Elliss`, Position : 'DT', Salary : 650000, SleeperID : 8715 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nasir`, LastName: `Greer`, Position : 'DB', Salary : 650000, SleeperID : 8708 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Caliendo`, Position : 'G', Salary : 650000, SleeperID : 8709 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Cochrane`, Position : 'LB', Salary : 650000, SleeperID : 8710 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gene`, LastName: `Pryor`, Position : 'T', Salary : 650000, SleeperID : 8711 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Blackwell`, Position : 'CB', Salary : 650000, SleeperID : 8714 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Stout`, Position : 'P', Salary : 650000, SleeperID : 8467 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrid`, LastName: `Williams`, Position : 'OL', Salary : 650000, SleeperID : 8716 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Ortiz`, Position : 'LS', Salary : 650000, SleeperID : 8717 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Thomas`, Position : 'DB', Salary : 650000, SleeperID : 8719 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Montre`, LastName: `Tuggle`, Position : 'RB', Salary : 650000, SleeperID : 8721 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Alexander`, Position : 'DB', Salary : 650000, SleeperID : 8722 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Julien`, Position : 'P', Salary : 650000, SleeperID : 8723 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Hafford`, Position : 'DB', Salary : 650000, SleeperID : 8724 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Albright`, Position : 'LB', Salary : 650000, SleeperID : 8720 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Araiza`, Position : 'P', Salary : 650000, SleeperID : 8486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brenden`, LastName: `Schooler`, Position : 'DB', Salary : 650000, SleeperID : 8726 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kody`, LastName: `Russey`, Position : 'OL', Salary : 650000, SleeperID : 8728 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Glaser`, Position : 'G', Salary : 650000, SleeperID : 8729 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Vereen`, Position : 'CB', Salary : 650000, SleeperID : 8730 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shemar`, LastName: `Bridges`, Position : 'WR', Salary : 650000, SleeperID : 8731 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vederian`, LastName: `Lowe`, Position : 'OT', Salary : 650000, SleeperID : 8482 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyron`, LastName: `Johnson`, Position : 'DE', Salary : 650000, SleeperID : 8483 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ko`, LastName: `Kieft`, Position : 'TE', Salary : 650000, SleeperID : 8484 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Tenuta`, Position : 'OL', Salary : 650000, SleeperID : 8485 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Liam`, LastName: `Shanahan`, Position : 'OL', Salary : 650000, SleeperID : 8727 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Dunkle`, Position : 'OL', Salary : 650000, SleeperID : 8712 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Sills`, Position : 'OL', Salary : 650000, SleeperID : 8713 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carson`, LastName: `Taylor`, Position : 'DE', Salary : 650000, SleeperID : 8718 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaMarcus`, LastName: `Mitchell`, Position : 'DE', Salary : 650000, SleeperID : 8725 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deonte`, LastName: `Banks`, Position : 'DB', Salary : 650000, SleeperID : 10878 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calijah`, LastName: `Kancey`, Position : 'DL', Salary : 650000, SleeperID : 10879 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travon`, LastName: `Walker`, Position : 'LB', Salary : 650000, SleeperID : 8265 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quay`, LastName: `Walker`, Position : 'LB', Salary : 650000, SleeperID : 8266 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nakobe`, LastName: `Dean`, Position : 'LB', Salary : 650000, SleeperID : 8267 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lewis`, LastName: `Cine`, Position : 'DB', Salary : 650000, SleeperID : 8268 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Davis`, Position : 'DL', Salary : 650000, SleeperID : 8269 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devonte`, LastName: `Wyatt`, Position : 'DL', Salary : 650000, SleeperID : 8270 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derion`, LastName: `Kendrick`, Position : 'DB', Salary : 650000, SleeperID : 8271 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Channing`, LastName: `Tindall`, Position : 'LB', Salary : 650000, SleeperID : 8272 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phidarian`, LastName: `Mathis`, Position : 'DL', Salary : 650000, SleeperID : 8273 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaBryan`, LastName: `Ray`, Position : 'DL', Salary : 650000, SleeperID : 8274 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jovante`, LastName: `Moffatt`, Position : 'DB', Salary : 650000, SleeperID : 7251 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Benton`, Position : 'DB', Salary : 650000, SleeperID : 7252 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ron'Dell`, LastName: `Carter`, Position : 'LB', Salary : 650000, SleeperID : 7253 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Azur`, LastName: `Kamara`, Position : 'DE', Salary : 650000, SleeperID : 7254 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Marino`, Position : 'DE', Salary : 650000, SleeperID : 7255 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Watts`, Position : 'OT', Salary : 650000, SleeperID : 7256 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Douglas`, LastName: `Coleman`, Position : 'S', Salary : 650000, SleeperID : 7257 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Sellers`, Position : 'FB', Salary : 650000, SleeperID : 7258 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobby`, LastName: `Price`, Position : 'DB', Salary : 650000, SleeperID : 7259 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Dinson`, Position : 'S', Salary : 650000, SleeperID : 7260 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arryn`, LastName: `Siposs`, Position : 'P', Salary : 650000, SleeperID : 7261 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Morton`, Position : 'QB', Salary : 650000, SleeperID : 7262 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Wirtel`, Position : 'LS', Salary : 650000, SleeperID : 7263 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Jones`, Position : 'FB', Salary : 650000, SleeperID : 7264 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zack`, LastName: `Johnson`, Position : 'OL', Salary : 650000, SleeperID : 7265 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Bruffy`, Position : 'OL', Salary : 650000, SleeperID : 7266 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willington`, LastName: `Previlon`, Position : 'DT', Salary : 650000, SleeperID : 7267 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Krys`, LastName: `Barnes`, Position : 'LB', Salary : 650000, SleeperID : 7268 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Sunderland`, Position : 'CB', Salary : 650000, SleeperID : 7270 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henry`, LastName: `Black`, Position : 'DB', Salary : 650000, SleeperID : 7271 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cordel`, LastName: `Iwuagwu`, Position : 'OG', Salary : 650000, SleeperID : 7273 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marc-Antoine`, LastName: `Dequoy`, Position : 'DB', Salary : 650000, SleeperID : 7269 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Simmons`, Position : 'WR', Salary : 650000, SleeperID : 7274 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elex`, LastName: `Woodworth`, Position : 'C', Salary : 650000, SleeperID : 7275 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Auzoyah`, LastName: `Alufohai`, Position : 'DT', Salary : 650000, SleeperID : 7276 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamir`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 7277 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Stapleton`, Position : 'TE', Salary : 650000, SleeperID : 7272 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jan`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 7278 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeMichael`, LastName: `Harris`, Position : 'WR', Salary : 650000, SleeperID : 7279 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Reed`, Position : 'CB', Salary : 650000, SleeperID : 7284 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carter`, LastName: `O'Donnell`, Position : 'T', Salary : 650000, SleeperID : 7280 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kameron`, LastName: `Cline`, Position : 'DE', Salary : 650000, SleeperID : 7281 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Coleman`, Position : 'DE', Salary : 650000, SleeperID : 7282 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 7283 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donald`, LastName: `Rutledge`, Position : 'LB', Salary : 650000, SleeperID : 7285 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marvelle`, LastName: `Ross`, Position : 'WR', Salary : 650000, SleeperID : 7286 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Hammond`, Position : 'WR', Salary : 650000, SleeperID : 7287 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Ellefson`, Position : 'TE', Salary : 650000, SleeperID : 7288 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Doug`, LastName: `Costin`, Position : 'DT', Salary : 650000, SleeperID : 7289 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steve`, LastName: `Nielsen`, Position : 'OL', Salary : 650000, SleeperID : 7290 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Evans`, Position : 'LB', Salary : 650000, SleeperID : 7291 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kobe`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 7292 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tershawn`, LastName: `Wharton`, Position : 'DT', Salary : 650000, SleeperID : 7301 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ross`, LastName: `Matiscik`, Position : 'LS', Salary : 650000, SleeperID : 7295 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Wright`, Position : 'P', Salary : 650000, SleeperID : 7296 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darryl`, LastName: `Williams`, Position : 'OL', Salary : 650000, SleeperID : 7298 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justice`, LastName: `Shelton-Mosley`, Position : 'WR', Salary : 650000, SleeperID : 7299 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luq`, LastName: `Barcoo`, Position : 'CB', Salary : 650000, SleeperID : 7293 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jovahn`, LastName: `Fair`, Position : 'G', Salary : 650000, SleeperID : 7300 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amari`, LastName: `Henderson`, Position : 'CB', Salary : 650000, SleeperID : 7294 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Bowers`, Position : 'TE', Salary : 650000, SleeperID : 7308 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Omari`, LastName: `Cobb`, Position : 'LB', Salary : 650000, SleeperID : 7302 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Wright`, Position : 'LB', Salary : 650000, SleeperID : 7303 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Clemons`, Position : 'DB', Salary : 650000, SleeperID : 7304 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yasir`, LastName: `Durant`, Position : 'G', Salary : 650000, SleeperID : 7297 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Madre`, LastName: `Harper`, Position : 'CB', Salary : 650000, SleeperID : 7312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Julius`, Position : 'CB', Salary : 650000, SleeperID : 7305 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobby`, LastName: `Holly`, Position : 'FB', Salary : 650000, SleeperID : 7315 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Townsend`, Position : 'P', Salary : 650000, SleeperID : 7307 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Siaosi`, LastName: `Mariner`, Position : 'WR', Salary : 650000, SleeperID : 7309 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Panasiuk`, Position : 'DT', Salary : 650000, SleeperID : 7310 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javin`, LastName: `White`, Position : 'LB', Salary : 650000, SleeperID : 7311 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Liam`, LastName: `McCullough`, Position : 'LS', Salary : 650000, SleeperID : 7313 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominik`, LastName: `Eberle`, Position : 'K', Salary : 650000, SleeperID : 7314 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Bradwell`, Position : 'RB', Salary : 650000, SleeperID : 7316 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hakeem`, LastName: `Bailey`, Position : 'CB', Salary : 650000, SleeperID : 7306 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Rice`, Position : 'TE', Salary : 650000, SleeperID : 7317 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabe`, LastName: `Nabers`, Position : 'FB', Salary : 650000, SleeperID : 7318 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Cotton`, Position : 'WR', Salary : 650000, SleeperID : 7319 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalton`, LastName: `Schoen`, Position : 'WR', Salary : 650000, SleeperID : 7320 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Roberts`, Position : 'OT', Salary : 650000, SleeperID : 7321 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Fields`, Position : 'LB', Salary : 650000, SleeperID : 7836 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caden`, LastName: `Sterns`, Position : 'DB', Salary : 650000, SleeperID : 7837 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Borom`, Position : 'OL', Salary : 650000, SleeperID : 7838 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `McPherson`, Position : 'K', Salary : 650000, SleeperID : 7839 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ta'Quon`, LastName: `Graham`, Position : 'DT', Salary : 650000, SleeperID : 7840 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamien`, LastName: `Sherwood`, Position : 'LB', Salary : 650000, SleeperID : 7841 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Farrell`, Position : 'TE', Salary : 650000, SleeperID : 7842 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dionte`, LastName: `Ruffin`, Position : 'DB', Salary : 650000, SleeperID : 7843 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Schaffer`, Position : 'DE', Salary : 650000, SleeperID : 7845 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 7846 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Ammendola`, Position : 'K', Salary : 650000, SleeperID : 7617 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Simon`, Position : 'WR', Salary : 650000, SleeperID : 7618 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Adams`, Position : 'G', Salary : 650000, SleeperID : 7470 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Reese`, Position : 'LB', Salary : 650000, SleeperID : 7471 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Kamara`, Position : 'LB', Salary : 650000, SleeperID : 7844 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Tecklenburg`, Position : 'C', Salary : 650000, SleeperID : 7472 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lee`, LastName: `Autry`, Position : 'DT', Salary : 650000, SleeperID : 7473 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaiir`, LastName: `Elam`, Position : 'DB', Salary : 650000, SleeperID : 8296 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Moon`, Position : 'LB', Salary : 650000, SleeperID : 8297 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mario`, LastName: `Goodrich`, Position : 'DB', Salary : 650000, SleeperID : 8298 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Baylon`, LastName: `Spector`, Position : 'LB', Salary : 650000, SleeperID : 8299 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Booth`, Position : 'DB', Salary : 650000, SleeperID : 8300 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zakoby`, LastName: `McClain`, Position : 'LB', Salary : 650000, SleeperID : 8301 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Smoke`, LastName: `Monday`, Position : 'DB', Salary : 650000, SleeperID : 8302 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Neil`, LastName: `Farrell`, Position : 'DL', Salary : 650000, SleeperID : 8307 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Stingley`, Position : 'DB', Salary : 650000, SleeperID : 8308 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damone`, LastName: `Clark`, Position : 'LB', Salary : 650000, SleeperID : 8309 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Percy`, LastName: `Butler`, Position : 'DB', Salary : 650000, SleeperID : 8394 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dane`, LastName: `Belton`, Position : 'DB', Salary : 650000, SleeperID : 8395 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bruno`, LastName: `Labelle`, Position : 'TE', Salary : 650000, SleeperID : 7734 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cary`, LastName: `Angeline`, Position : 'TE', Salary : 650000, SleeperID : 7735 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Batho`, Position : 'OL', Salary : 650000, SleeperID : 7737 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eli`, LastName: `Howard`, Position : 'DE', Salary : 650000, SleeperID : 7738 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Hargrove`, Position : 'OL', Salary : 650000, SleeperID : 7739 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kobe`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 7740 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Huntley`, Position : 'RB', Salary : 650000, SleeperID : 7741 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kion`, LastName: `Smith`, Position : 'T', Salary : 650000, SleeperID : 7742 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Nunn`, Position : 'WR', Salary : 650000, SleeperID : 7744 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Murphy`, Position : 'DB', Salary : 650000, SleeperID : 7745 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Sculthorpe`, Position : 'OL', Salary : 650000, SleeperID : 7747 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Leake`, Position : 'RB', Salary : 650000, SleeperID : 6966 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Fuller`, Position : 'WR', Salary : 650000, SleeperID : 6967 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Fuller`, Position : 'DB', Salary : 650000, SleeperID : 6968 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lorenzo`, LastName: `Burns`, Position : 'CB', Salary : 650000, SleeperID : 7736 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dane`, LastName: `Jackson`, Position : 'CB', Salary : 650000, SleeperID : 6969 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephen`, LastName: `Sullivan`, Position : 'TE', Salary : 650000, SleeperID : 6970 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alani`, LastName: `Pututau`, Position : 'LB', Salary : 650000, SleeperID : 7743 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Solomon`, LastName: `Kindley`, Position : 'G', Salary : 650000, SleeperID : 6971 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Trammell`, Position : 'WR', Salary : 650000, SleeperID : 7746 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaVon`, LastName: `Hamilton`, Position : 'DT', Salary : 650000, SleeperID : 6972 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.J.`, LastName: `Taylor`, Position : 'RB', Salary : 650000, SleeperID : 6973 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javaris`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 6974 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrion`, LastName: `Daniels`, Position : 'DT', Salary : 650000, SleeperID : 6975 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Shackelford`, Position : 'C', Salary : 650000, SleeperID : 6976 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Strnad`, Position : 'LB', Salary : 650000, SleeperID : 6977 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Runyan`, Position : 'G', Salary : 650000, SleeperID : 6980 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Muse`, Position : 'LB', Salary : 650000, SleeperID : 6990 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tipa`, LastName: `Galeai`, Position : 'LB', Salary : 650000, SleeperID : 6981 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cheyenne`, LastName: `O'Grady`, Position : 'TE', Salary : 650000, SleeperID : 6982 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Ojemudia`, Position : 'CB', Salary : 650000, SleeperID : 6983 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Jones`, Position : 'RB', Salary : 650000, SleeperID : 6984 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dezmon`, LastName: `Patmon`, Position : 'WR', Salary : 650000, SleeperID : 6985 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nigel`, LastName: `Warrior`, Position : 'DB', Salary : 650000, SleeperID : 6986 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hakeem`, LastName: `Adeniji`, Position : 'G', Salary : 650000, SleeperID : 6987 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raymond`, LastName: `Calais`, Position : 'RB', Salary : 650000, SleeperID : 6988 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquez`, LastName: `Callaway`, Position : 'WR', Salary : 650000, SleeperID : 6989 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedric`, LastName: `Tillman`, Position : 'WR', Salary : 650000, SleeperID : 10444 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `DeLuca`, Position : 'DB', Salary : 650000, SleeperID : 7939 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Langan`, Position : 'LS', Salary : 650000, SleeperID : 7940 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Ralston`, Position : 'RB', Salary : 650000, SleeperID : 7941 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jose`, LastName: `Borregales`, Position : 'K', Salary : 650000, SleeperID : 7961 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sadarius`, LastName: `Hutcherson`, Position : 'OG', Salary : 650000, SleeperID : 7962 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Ponder`, Position : 'LB', Salary : 650000, SleeperID : 7963 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leighton`, LastName: `McCarthy`, Position : 'LB', Salary : 650000, SleeperID : 7964 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Kinley`, Position : 'CB', Salary : 650000, SleeperID : 7965 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Ashley`, Position : 'OT', Salary : 650000, SleeperID : 7966 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lawrence`, LastName: `White`, Position : 'DB', Salary : 650000, SleeperID : 7967 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Kelly`, Position : 'DT', Salary : 650000, SleeperID : 7968 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Ealy`, Position : 'OT', Salary : 650000, SleeperID : 7969 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ar'Darius`, LastName: `Washington`, Position : 'DB', Salary : 650000, SleeperID : 7970 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Cooper`, Position : 'G', Salary : 650000, SleeperID : 7971 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Poljan`, Position : 'TE', Salary : 650000, SleeperID : 7972 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Foster`, LastName: `Sarell`, Position : 'T', Salary : 650000, SleeperID : 7973 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `McCloud`, Position : 'CB', Salary : 650000, SleeperID : 7974 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hassan`, LastName: `Haskins`, Position : 'RB', Salary : 650000, SleeperID : 8123 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Austin`, Position : 'WR', Salary : 650000, SleeperID : 8125 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wan'Dale`, LastName: `Robinson`, Position : 'WR', Salary : 650000, SleeperID : 8126 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charlie`, LastName: `Kolar`, Position : 'TE', Salary : 650000, SleeperID : 8127 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dameon`, LastName: `Pierce`, Position : 'RB', Salary : 650000, SleeperID : 8129 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `McBride`, Position : 'TE', Salary : 7572092.017, SleeperID : 8130 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Likely`, Position : 'TE', Salary : 795475.2652, SleeperID : 8131 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Allgeier`, Position : 'RB', Salary : 650000, SleeperID : 8132 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalil`, LastName: `Shakir`, Position : 'WR', Salary : 650000, SleeperID : 8134 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Treylon`, LastName: `Burks`, Position : 'WR', Salary : 650000, SleeperID : 8135 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rachaad`, LastName: `White`, Position : 'RB', Salary : 7382789.716, SleeperID : 8136 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Pickens`, Position : 'WR', Salary : 4124075.997, SleeperID : 8137 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Cook`, Position : 'RB', Salary : 6842807.862, SleeperID : 8138 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zamir`, LastName: `White`, Position : 'RB', Salary : 2189989.599, SleeperID : 8139 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justyn`, LastName: `Ross`, Position : 'WR', Salary : 650000, SleeperID : 8140 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alec`, LastName: `Pierce`, Position : 'WR', Salary : 650000, SleeperID : 8142 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tucker`, LastName: `Addington`, Position : 'LS', Salary : 650000, SleeperID : 8933 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Stonehouse`, Position : 'P', Salary : 650000, SleeperID : 8538 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Benzinger`, Position : 'OT', Salary : 650000, SleeperID : 7163 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Okuayinonu`, Position : 'DE', Salary : 650000, SleeperID : 8540 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Griffin`, Position : 'DB', Salary : 650000, SleeperID : 8541 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tristin`, LastName: `McCollum`, Position : 'DB', Salary : 650000, SleeperID : 8542 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Estrada`, Position : 'WR', Salary : 650000, SleeperID : 8543 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myron`, LastName: `Cunningham`, Position : 'OL', Salary : 650000, SleeperID : 8544 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reid`, LastName: `Sinnett`, Position : 'QB', Salary : 650000, SleeperID : 7159 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ledarius`, LastName: `Mack`, Position : 'LB', Salary : 650000, SleeperID : 7160 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shane`, LastName: `Leatherbury`, Position : 'WR', Salary : 650000, SleeperID : 7161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Becker`, Position : 'TE', Salary : 650000, SleeperID : 7162 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seth`, LastName: `Green`, Position : 'TE', Salary : 650000, SleeperID : 8539 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jackson`, LastName: `Dennis`, Position : 'OL', Salary : 650000, SleeperID : 7164 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Dickinson`, Position : 'G', Salary : 650000, SleeperID : 7165 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Carter`, Position : 'DE', Salary : 650000, SleeperID : 7166 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Shuler`, Position : 'DE', Salary : 650000, SleeperID : 7167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zane`, LastName: `Lewis`, Position : 'CB', Salary : 650000, SleeperID : 7168 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jace`, LastName: `Whittaker`, Position : 'CB', Salary : 650000, SleeperID : 7169 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarren`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 7170 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Floyd`, Position : 'DB', Salary : 650000, SleeperID : 7171 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mikey`, LastName: `Daniel`, Position : 'RB', Salary : 650000, SleeperID : 7172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `McCleskey`, Position : 'WR', Salary : 650000, SleeperID : 7173 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juwan`, LastName: `Green`, Position : 'WR', Salary : 650000, SleeperID : 7174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Rowland`, Position : 'WR', Salary : 650000, SleeperID : 7175 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Repp`, Position : 'TE', Salary : 650000, SleeperID : 7176 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Moore`, Position : 'WR', Salary : 650000, SleeperID : 7596 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Angulo`, Position : 'DB', Salary : 650000, SleeperID : 7385 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Elmore`, Position : 'DB', Salary : 650000, SleeperID : 7386 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Campbell`, Position : 'WR', Salary : 650000, SleeperID : 7387 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Hilbers`, Position : 'T', Salary : 650000, SleeperID : 7388 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sterling`, LastName: `Johnson`, Position : 'DT', Salary : 650000, SleeperID : 7389 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Domenique`, LastName: `Davis`, Position : 'DL', Salary : 650000, SleeperID : 7390 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Huff`, Position : 'DE', Salary : 650000, SleeperID : 7391 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javelin`, LastName: `Guidry`, Position : 'CB', Salary : 650000, SleeperID : 7392 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermiah`, LastName: `Braswell`, Position : 'WR', Salary : 650000, SleeperID : 7394 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashad`, LastName: `Medaris`, Position : 'WR', Salary : 650000, SleeperID : 7395 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Phelps`, Position : 'WR', Salary : 650000, SleeperID : 7396 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `Houston`, Position : 'TE', Salary : 650000, SleeperID : 7397 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Walker`, Position : 'LB', Salary : 650000, SleeperID : 7398 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bejour`, LastName: `Wilson`, Position : 'CB', Salary : 650000, SleeperID : 7399 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Manasseh`, LastName: `Bailey`, Position : 'WR', Salary : 650000, SleeperID : 7400 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalil`, LastName: `Tate`, Position : 'WR', Salary : 650000, SleeperID : 7401 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Good-Jones`, Position : 'G', Salary : 650000, SleeperID : 7402 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Juriga`, Position : 'C', Salary : 650000, SleeperID : 7403 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Togiai`, Position : 'TE', Salary : 650000, SleeperID : 7404 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Jacquet`, Position : 'CB', Salary : 650000, SleeperID : 7405 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Riley`, Position : 'DB', Salary : 650000, SleeperID : 7406 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Wright`, Position : 'WR', Salary : 650000, SleeperID : 7407 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Prince`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 7408 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Alarcon`, Position : 'OT', Salary : 650000, SleeperID : 7409 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Bada`, Position : 'DT', Salary : 650000, SleeperID : 7410 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Leo`, Position : 'DE', Salary : 650000, SleeperID : 7411 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sandro`, LastName: `Platzgummer`, Position : 'RB', Salary : 650000, SleeperID : 7412 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Molchon`, Position : 'G', Salary : 650000, SleeperID : 7413 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnathon`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 7414 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Houston`, Position : 'LB', Salary : 650000, SleeperID : 7415 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leo`, LastName: `Lewis`, Position : 'LB', Salary : 650000, SleeperID : 7416 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Lockhart`, Position : 'LB', Salary : 650000, SleeperID : 7417 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Broc`, LastName: `Rutter`, Position : 'QB', Salary : 650000, SleeperID : 7418 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Pierre`, Position : 'CB', Salary : 650000, SleeperID : 7419 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Hokit`, Position : 'FB', Salary : 650000, SleeperID : 7420 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonas`, LastName: `Griffith`, Position : 'LB', Salary : 650000, SleeperID : 7421 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeMarkus`, LastName: `Acy`, Position : 'CB', Salary : 650000, SleeperID : 7423 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Jones`, Position : 'RB', Salary : 650000, SleeperID : 7424 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Carr`, Position : 'RB', Salary : 650000, SleeperID : 7425 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronnell`, LastName: `Perkins`, Position : 'S', Salary : 650000, SleeperID : 7422 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kary`, LastName: `Vincent`, Position : 'CB', Salary : 650000, SleeperID : 7767 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Anderson`, Position : 'G', Salary : 650000, SleeperID : 7768 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wyatt`, LastName: `Hubert`, Position : 'DE', Salary : 650000, SleeperID : 7769 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 7770 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Funk`, Position : 'RB', Salary : 650000, SleeperID : 7771 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phil`, LastName: `Hoskins`, Position : 'DT', Salary : 650000, SleeperID : 7772 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larnel`, LastName: `Coleman`, Position : 'T', Salary : 650000, SleeperID : 7773 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Morrissey`, Position : 'OL', Salary : 650000, SleeperID : 7774 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Graham`, Position : 'DB', Salary : 650000, SleeperID : 7775 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Israel`, LastName: `Mukuamu`, Position : 'DB', Salary : 650000, SleeperID : 7776 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Smith`, Position : 'OL', Salary : 650000, SleeperID : 7777 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Camaron`, LastName: `Cheeseman`, Position : 'LS', Salary : 650000, SleeperID : 7778 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JaCoby`, LastName: `Stevens`, Position : 'LB', Salary : 650000, SleeperID : 7779 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tay`, LastName: `Gowan`, Position : 'CB', Salary : 650000, SleeperID : 7780 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Hines`, Position : 'LB', Salary : 650000, SleeperID : 7857 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brenden`, LastName: `Knox`, Position : 'RB', Salary : 650000, SleeperID : 7858 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Osirus`, LastName: `Mitchell`, Position : 'WR', Salary : 650000, SleeperID : 7860 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braylon`, LastName: `Jones`, Position : 'C', Salary : 650000, SleeperID : 7861 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Vasher`, Position : 'WR', Salary : 650000, SleeperID : 7862 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Ford`, Position : 'OL', Salary : 650000, SleeperID : 8643 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denzel`, LastName: `Okafor`, Position : 'OL', Salary : 650000, SleeperID : 8644 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Eubanks`, Position : 'TE', Salary : 650000, SleeperID : 7859 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benjie`, LastName: `Franklin`, Position : 'CB', Salary : 650000, SleeperID : 8645 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Israel`, LastName: `Antwine`, Position : 'DT', Salary : 650000, SleeperID : 8646 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lujuan`, LastName: `Winningham`, Position : 'WR', Salary : 650000, SleeperID : 8650 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seth`, LastName: `Vernon`, Position : 'P', Salary : 650000, SleeperID : 8655 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyshaun`, LastName: `James`, Position : 'WR', Salary : 650000, SleeperID : 8656 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kuony`, LastName: `Deng`, Position : 'LB', Salary : 650000, SleeperID : 8657 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Vrabel`, Position : 'T', Salary : 650000, SleeperID : 8658 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Landman`, Position : 'LB', Salary : 650000, SleeperID : 8659 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Tatum`, Position : 'OL', Salary : 650000, SleeperID : 8649 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leroy`, LastName: `Watson`, Position : 'TE', Salary : 650000, SleeperID : 8660 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Hankins`, Position : 'CB', Salary : 650000, SleeperID : 8661 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Timmy`, LastName: `Horne`, Position : 'DT', Salary : 650000, SleeperID : 8654 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Rodgers`, Position : 'DT', Salary : 650000, SleeperID : 8662 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Bernhardt`, Position : 'WR', Salary : 650000, SleeperID : 8663 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Armani`, LastName: `Rogers`, Position : 'TE', Salary : 650000, SleeperID : 8665 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Baker`, Position : 'CB', Salary : 650000, SleeperID : 8666 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Wilson`, Position : 'OL', Salary : 650000, SleeperID : 8667 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John Parker`, LastName: `Romo`, Position : 'K', Salary : 650000, SleeperID : 8670 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Whelan`, Position : 'P', Salary : 650000, SleeperID : 8674 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashid`, LastName: `Shaheed`, Position : 'WR', Salary : 650000, SleeperID : 8676 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Koerner`, Position : 'DB', Salary : 650000, SleeperID : 8668 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brad`, LastName: `Hawkins`, Position : 'DB', Salary : 650000, SleeperID : 8664 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Pryor`, Position : 'LB', Salary : 650000, SleeperID : 8677 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sage`, LastName: `Doxtater`, Position : 'OL', Salary : 650000, SleeperID : 8669 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lewis`, LastName: `Kidd`, Position : 'OL', Salary : 650000, SleeperID : 8672 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joel`, LastName: `Dublanko`, Position : 'LB', Salary : 650000, SleeperID : 8673 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Schweiger`, Position : 'OL', Salary : 650000, SleeperID : 8675 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hayden`, LastName: `Howerton`, Position : 'OL', Salary : 650000, SleeperID : 8679 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Rupcich`, Position : 'OT', Salary : 650000, SleeperID : 8678 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Gibbens`, Position : 'LB', Salary : 650000, SleeperID : 8680 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Turner`, Position : 'DB', Salary : 650000, SleeperID : 8681 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kene`, LastName: `Nwangwu`, Position : 'RB', Salary : 650000, SleeperID : 7720 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Rumph`, Position : 'LB', Salary : 650000, SleeperID : 7721 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bobby`, LastName: `Brown`, Position : 'DT', Salary : 650000, SleeperID : 7722 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elerson`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 7723 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jabril`, LastName: `Cox`, Position : 'LB', Salary : 650000, SleeperID : 7724 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Dalman`, Position : 'OL', Salary : 650000, SleeperID : 7725 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keandre`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 7474 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Napoleon`, LastName: `Maxwell`, Position : 'RB', Salary : 650000, SleeperID : 7475 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `McSwain`, Position : 'DT', Salary : 650000, SleeperID : 7476 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Clark`, Position : 'DT', Salary : 650000, SleeperID : 7477 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luther`, LastName: `Kirk`, Position : 'DB', Salary : 650000, SleeperID : 7478 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre'Vour`, LastName: `Wallace-Simms`, Position : 'OL', Salary : 650000, SleeperID : 7479 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Ojabo`, Position : 'DL', Salary : 650000, SleeperID : 8287 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christopher`, LastName: `Hinton`, Position : 'DL', Salary : 650000, SleeperID : 8288 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sauce`, LastName: `Gardner`, Position : 'DB', Salary : 650000, SleeperID : 8290 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrian`, LastName: `Beavers`, Position : 'LB', Salary : 650000, SleeperID : 8292 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Marcus`, LastName: `Ingram`, Position : 'CB', Salary : 650000, SleeperID : 8901 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tae`, LastName: `Daley`, Position : 'DB', Salary : 650000, SleeperID : 8902 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Long`, Position : 'OL', Salary : 650000, SleeperID : 8903 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Benford`, Position : 'DB', Salary : 650000, SleeperID : 8487 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aidan`, LastName: `Hutchinson`, Position : 'DL', Salary : 650000, SleeperID : 8289 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myjai`, LastName: `Sanders`, Position : 'DL', Salary : 650000, SleeperID : 8291 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Thomas`, Position : 'OL', Salary : 650000, SleeperID : 8488 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Ogletree`, Position : 'TE', Salary : 650000, SleeperID : 8489 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Brooks`, Position : 'DT', Salary : 650000, SleeperID : 8490 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Deculus`, Position : 'T', Salary : 650000, SleeperID : 8491 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Akingbulu`, Position : 'OL', Salary : 650000, SleeperID : 8918 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Newman`, Position : 'OL', Salary : 650000, SleeperID : 8926 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Roberts`, Position : 'DE', Salary : 650000, SleeperID : 8492 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chasen`, LastName: `Hines`, Position : 'G', Salary : 650000, SleeperID : 8493 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Houston`, Position : 'LB', Salary : 650000, SleeperID : 8494 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Pharms`, Position : 'DT', Salary : 650000, SleeperID : 8914 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Plitt`, Position : 'QB', Salary : 650000, SleeperID : 8915 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keenan`, LastName: `Forbes`, Position : 'G', Salary : 650000, SleeperID : 8916 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KaVontae`, LastName: `Turpin`, Position : 'WR', Salary : 650000, SleeperID : 8917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Avery`, Position : 'CB', Salary : 650000, SleeperID : 8925 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `McKenzie`, Position : 'T', Salary : 650000, SleeperID : 8927 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Odukoya`, Position : 'TE', Salary : 650000, SleeperID : 8924 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Atkinson`, Position : 'OT', Salary : 650000, SleeperID : 7178 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daylen`, LastName: `Baldwin`, Position : 'WR', Salary : 650000, SleeperID : 8928 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Wieland`, Position : 'LB', Salary : 650000, SleeperID : 8929 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Dublin`, Position : 'OL', Salary : 650000, SleeperID : 8930 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Ali`, Position : 'WR', Salary : 650000, SleeperID : 8931 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lucas`, LastName: `Havrisik`, Position : 'K', Salary : 650000, SleeperID : 8932 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evin`, LastName: `Ksiezarczyk`, Position : 'OL', Salary : 650000, SleeperID : 7177 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Capps`, Position : 'C', Salary : 650000, SleeperID : 7179 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scottie`, LastName: `Dill`, Position : 'OT', Salary : 650000, SleeperID : 7180 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chandon`, LastName: `Herring`, Position : 'OL', Salary : 650000, SleeperID : 7851 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tory`, LastName: `Carter`, Position : 'FB', Salary : 650000, SleeperID : 7852 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Naquan`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 7853 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Miller`, LastName: `Forristall`, Position : 'TE', Salary : 650000, SleeperID : 7854 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Haubeil`, Position : 'K', Salary : 650000, SleeperID : 7855 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Banwart`, Position : 'G', Salary : 650000, SleeperID : 7856 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Coby`, LastName: `Bryant`, Position : 'DB', Salary : 650000, SleeperID : 8293 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Cook`, Position : 'DB', Salary : 650000, SleeperID : 8294 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dwayne`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 7748 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JR`, LastName: `Pace`, Position : 'DB', Salary : 650000, SleeperID : 7749 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grant`, LastName: `Stuard`, Position : 'LB', Salary : 650000, SleeperID : 7750 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dax`, LastName: `Milne`, Position : 'WR', Salary : 650000, SleeperID : 7751 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kawaan`, LastName: `Baker`, Position : 'WR', Salary : 650000, SleeperID : 7752 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Carter`, Position : 'DL', Salary : 650000, SleeperID : 8295 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pressley`, LastName: `Harvin`, Position : 'P', Salary : 650000, SleeperID : 7753 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquiss`, LastName: `Spencer`, Position : 'DE', Salary : 650000, SleeperID : 7754 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Baron`, LastName: `Browning`, Position : 'LB', Salary : 650000, SleeperID : 7681 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Molden`, Position : 'CB', Salary : 650000, SleeperID : 7682 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Monty`, LastName: `Rice`, Position : 'LB', Salary : 650000, SleeperID : 7683 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Clary`, Position : 'OL', Salary : 650000, SleeperID : 8852 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 8853 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ferrod`, LastName: `Gardner`, Position : 'DB', Salary : 650000, SleeperID : 8586 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Drayden`, Position : 'CB', Salary : 650000, SleeperID : 8587 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Vante`, LastName: `Cross`, Position : 'CB', Salary : 650000, SleeperID : 8588 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacub`, LastName: `Panasiuk`, Position : 'DE', Salary : 650000, SleeperID : 8590 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Walker`, Position : 'LB', Salary : 650000, SleeperID : 8591 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `White`, Position : 'LB', Salary : 650000, SleeperID : 8593 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrese`, LastName: `Robinson`, Position : 'G', Salary : 650000, SleeperID : 8594 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyric`, LastName: `McGowan`, Position : 'WR', Salary : 650000, SleeperID : 8595 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Taylor`, Position : 'CB', Salary : 650000, SleeperID : 8596 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Santos-Silva`, Position : 'TE', Salary : 650000, SleeperID : 8589 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Akial`, LastName: `Byers`, Position : 'DT', Salary : 650000, SleeperID : 8598 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caliph`, LastName: `Brice`, Position : 'LB', Salary : 650000, SleeperID : 8599 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ellis`, LastName: `Brooks`, Position : 'LB', Salary : 650000, SleeperID : 8600 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 8602 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chauncey`, LastName: `Manac`, Position : 'LB', Salary : 650000, SleeperID : 8603 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Schneider`, Position : 'C', Salary : 650000, SleeperID : 8604 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Moore`, Position : 'G', Salary : 650000, SleeperID : 8605 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jahmir`, LastName: `Johnson`, Position : 'T', Salary : 650000, SleeperID : 8606 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Sterling`, Position : 'DB', Salary : 650000, SleeperID : 8607 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Kelley`, Position : 'QB', Salary : 650000, SleeperID : 8224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raleigh`, LastName: `Texada`, Position : 'CB', Salary : 650000, SleeperID : 8608 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Bellinger`, Position : 'TE', Salary : 650000, SleeperID : 8225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Jones`, Position : 'T', Salary : 650000, SleeperID : 8610 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Qwuantrezz`, LastName: `Knight`, Position : 'CB', Salary : 650000, SleeperID : 8612 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taysir`, LastName: `Mack`, Position : 'WR', Salary : 650000, SleeperID : 8613 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Chandler`, Position : 'RB', Salary : 650000, SleeperID : 8230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tayler`, LastName: `Hawkins`, Position : 'DB', Salary : 650000, SleeperID : 8615 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hauati`, LastName: `Pututau`, Position : 'DT', Salary : 650000, SleeperID : 8609 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Bell`, Position : 'RB', Salary : 650000, SleeperID : 8226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Weston`, Position : 'WR', Salary : 650000, SleeperID : 8229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Atkins`, Position : 'DT', Salary : 650000, SleeperID : 8614 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Teagan`, LastName: `Quitoriano`, Position : 'TE', Salary : 650000, SleeperID : 8227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Warren`, Position : 'RB', Salary : 2246143.178, SleeperID : 8228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Samori`, LastName: `Toure`, Position : 'WR', Salary : 650000, SleeperID : 8235 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerreth`, LastName: `Sterns`, Position : 'WR', Salary : 650000, SleeperID : 8236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emeka`, LastName: `Emezie`, Position : 'WR', Salary : 650000, SleeperID : 8238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shermari`, LastName: `Jones`, Position : 'RB', Salary : 650000, SleeperID : 8242 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAndre`, LastName: `Torrey`, Position : 'RB', Salary : 650000, SleeperID : 8243 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Deese`, Position : 'TE', Salary : 650000, SleeperID : 8245 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryant`, LastName: `Koback`, Position : 'RB', Salary : 650000, SleeperID : 8246 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gerrit`, LastName: `Prince`, Position : 'TE', Salary : 650000, SleeperID : 8247 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalil`, LastName: `Pimpleton`, Position : 'WR', Salary : 650000, SleeperID : 8248 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lucas`, LastName: `Krull`, Position : 'TE', Salary : 650000, SleeperID : 8249 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tay`, LastName: `Martin`, Position : 'WR', Salary : 650000, SleeperID : 8250 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Jackson`, Position : 'WR', Salary : 650000, SleeperID : 8251 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deven`, LastName: `Thompkins`, Position : 'WR', Salary : 650000, SleeperID : 8253 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julius`, LastName: `Chestnut`, Position : 'RB', Salary : 650000, SleeperID : 8254 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raheem`, LastName: `Blackshear`, Position : 'RB', Salary : 650000, SleeperID : 8255 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brontae`, LastName: `Harris`, Position : 'DB', Salary : 650000, SleeperID : 8078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aashari`, LastName: `Crosswell`, Position : 'DB', Salary : 650000, SleeperID : 8080 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travell`, LastName: `Harris`, Position : 'WR', Salary : 650000, SleeperID : 8252 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalif`, LastName: `Jackson`, Position : 'TE', Salary : 650000, SleeperID : 8088 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Hocker`, Position : 'G', Salary : 650000, SleeperID : 8082 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrod`, LastName: `Hewitt`, Position : 'DT', Salary : 650000, SleeperID : 8083 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Gilbert`, Position : 'CB', Salary : 650000, SleeperID : 8084 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mekhi`, LastName: `Sargent`, Position : 'RB', Salary : 650000, SleeperID : 8085 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Smith`, Position : 'P', Salary : 650000, SleeperID : 8086 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Curhan`, Position : 'T', Salary : 650000, SleeperID : 8081 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `LaPorta`, Position : 'TE', Salary : 10793225.81, SleeperID : 10859 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K.J.`, LastName: `Costello`, Position : 'QB', Salary : 650000, SleeperID : 8087 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harrison`, LastName: `Hand`, Position : 'CB', Salary : 650000, SleeperID : 6961 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `La'Darius`, LastName: `Hamilton`, Position : 'LB', Salary : 650000, SleeperID : 6962 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Taylor`, Position : 'RB', Salary : 650000, SleeperID : 6963 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Garvin`, Position : 'LB', Salary : 650000, SleeperID : 6965 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sal`, LastName: `Cannella`, Position : 'TE', Salary : 650000, SleeperID : 8089 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Cunningham`, Position : 'QB', Salary : 650000, SleeperID : 10860 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xazavian`, LastName: `Valladay`, Position : 'RB', Salary : 650000, SleeperID : 10861 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `McKeon`, Position : 'TE', Salary : 650000, SleeperID : 6964 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dorian`, LastName: `Thompson-Robinson`, Position : 'QB', Salary : 650000, SleeperID : 10862 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deneric`, LastName: `Prince`, Position : 'RB', Salary : 650000, SleeperID : 10870 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.T.`, LastName: `Perry`, Position : 'WR', Salary : 650000, SleeperID : 10863 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Dye`, Position : 'RB', Salary : 650000, SleeperID : 10865 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aidan`, LastName: `O'Connell`, Position : 'QB', Salary : 650000, SleeperID : 10866 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leonard`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 10868 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Landers`, Position : 'WR', Salary : 0, SleeperID : 10869 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Schoonmaker`, Position : 'TE', Salary : 650000, SleeperID : 10871 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Felix`, LastName: `Anudike-Uzomah`, Position : 'DE', Salary : 650000, SleeperID : 10872 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nolan`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 10873 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Bobo`, Position : 'WR', Salary : 650000, SleeperID : 10867 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Bresee`, Position : 'DT', Salary : 650000, SleeperID : 10874 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Murphy`, Position : 'DL', Salary : 650000, SleeperID : 10875 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anton`, LastName: `Harrison`, Position : 'OL', Salary : 650000, SleeperID : 10876 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mazi`, LastName: `Smith`, Position : 'DL', Salary : 650000, SleeperID : 10877 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Jobe`, Position : 'DB', Salary : 650000, SleeperID : 8275 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalyn`, LastName: `Armour-Davis`, Position : 'DB', Salary : 650000, SleeperID : 8276 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christopher`, LastName: `Allen`, Position : 'LB', Salary : 650000, SleeperID : 8277 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Harris`, Position : 'LB', Salary : 650000, SleeperID : 8278 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Perrion`, LastName: `Winfrey`, Position : 'DL', Salary : 650000, SleeperID : 8279 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nik`, LastName: `Bonitto`, Position : 'LB', Salary : 650000, SleeperID : 8280 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Thomas`, Position : 'DL', Salary : 650000, SleeperID : 8281 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Delarrin`, LastName: `Turner-Yell`, Position : 'DB', Salary : 650000, SleeperID : 8282 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Williams`, Position : 'G', Salary : 650000, SleeperID : 6520 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Harvey`, Position : 'DB', Salary : 650000, SleeperID : 6521 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Linta`, Position : 'QB', Salary : 650000, SleeperID : 6522 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Fox`, Position : 'P', Salary : 650000, SleeperID : 6523 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamire`, LastName: `Jordan`, Position : 'WR', Salary : 650000, SleeperID : 6524 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demetrius`, LastName: `Knox`, Position : 'G', Salary : 650000, SleeperID : 6525 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Thomas`, Position : 'LB', Salary : 650000, SleeperID : 6526 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rico`, LastName: `Bussey`, Position : 'WR', Salary : 650000, SleeperID : 7901 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Bundage`, Position : 'LB', Salary : 650000, SleeperID : 7902 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Briley`, LastName: `Moore`, Position : 'TE', Salary : 650000, SleeperID : 7903 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stevie`, LastName: `Scott`, Position : 'RB', Salary : 650000, SleeperID : 7904 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Soehner`, Position : 'TE', Salary : 650000, SleeperID : 7905 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Brown`, Position : 'OL', Salary : 650000, SleeperID : 7906 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Burrell`, Position : 'DB', Salary : 650000, SleeperID : 7908 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nolan`, LastName: `Cooney`, Position : 'P', Salary : 650000, SleeperID : 7909 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lawrence`, LastName: `Woods`, Position : 'CB', Salary : 650000, SleeperID : 7910 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Hoffman`, Position : 'OL', Salary : 650000, SleeperID : 7911 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trill`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 7912 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaq`, LastName: `Smith`, Position : 'LB', Salary : 650000, SleeperID : 7913 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jovan`, LastName: `Grant`, Position : 'DB', Salary : 650000, SleeperID : 7914 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Landen`, LastName: `Akers`, Position : 'WR', Salary : 650000, SleeperID : 7915 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Jones`, Position : 'G', Salary : 650000, SleeperID : 7923 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paris`, LastName: `Ford`, Position : 'DB', Salary : 650000, SleeperID : 7916 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charlie`, LastName: `Taumoepeau`, Position : 'TE', Salary : 650000, SleeperID : 6932 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raequan`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 6933 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordon`, LastName: `Scott`, Position : 'DT', Salary : 650000, SleeperID : 7919 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tuf`, LastName: `Borland`, Position : 'LB', Salary : 650000, SleeperID : 7920 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Elliss`, Position : 'LB', Salary : 650000, SleeperID : 7921 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Patterson`, Position : 'K', Salary : 650000, SleeperID : 7922 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Thompson`, Position : 'CB', Salary : 650000, SleeperID : 7907 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeeJay`, LastName: `Dallas`, Position : 'RB', Salary : 650000, SleeperID : 6931 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Turner`, LastName: `Bernard`, Position : 'LS', Salary : 650000, SleeperID : 7917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Rose`, Position : 'RB', Salary : 650000, SleeperID : 7918 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Bredeson`, Position : 'G', Salary : 650000, SleeperID : 6934 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Cosby`, Position : 'CB', Salary : 650000, SleeperID : 8845 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deandre`, LastName: `Johnson`, Position : 'DE', Salary : 650000, SleeperID : 8846 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kellen`, LastName: `Diesch`, Position : 'OL', Salary : 650000, SleeperID : 8847 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Owen`, LastName: `Carney`, Position : 'DT', Salary : 650000, SleeperID : 8848 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Conner`, Position : 'TE', Salary : 650000, SleeperID : 8849 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blaise`, LastName: `Andries`, Position : 'OL', Salary : 650000, SleeperID : 8851 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaquarii`, LastName: `Roberson`, Position : 'WR', Salary : 650000, SleeperID : 8232 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mataeo`, LastName: `Durant`, Position : 'RB', Salary : 650000, SleeperID : 8233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaivon`, LastName: `Heiligh`, Position : 'WR', Salary : 650000, SleeperID : 8234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricky`, LastName: `Person`, Position : 'RB', Salary : 650000, SleeperID : 8239 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `BJ`, LastName: `Baylor`, Position : 'RB', Salary : 650000, SleeperID : 8240 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Brown`, Position : 'QB', Salary : 650000, SleeperID : 8241 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Stille`, Position : 'DT', Salary : 650000, SleeperID : 8850 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Droogsma`, Position : 'G', Salary : 650000, SleeperID : 6527 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joey`, LastName: `Slye`, Position : 'K', Salary : 650000, SleeperID : 6528 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Myarick`, Position : 'TE', Salary : 650000, SleeperID : 6529 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `McLaurin`, Position : 'LB', Salary : 650000, SleeperID : 6530 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Carlock`, Position : 'LB', Salary : 650000, SleeperID : 6531 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Silas`, LastName: `Stewart`, Position : 'OLB', Salary : 650000, SleeperID : 6433 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Aston`, Position : 'FB', Salary : 650000, SleeperID : 6435 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Reed`, Position : 'LB', Salary : 650000, SleeperID : 6436 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Leglue`, Position : 'OT', Salary : 650000, SleeperID : 6437 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Delonte`, LastName: `Hood`, Position : 'CB', Salary : 650000, SleeperID : 8779 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clarence`, LastName: `Hicks`, Position : 'LB', Salary : 650000, SleeperID : 8780 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carson`, LastName: `Wells`, Position : 'LB', Salary : 650000, SleeperID : 8781 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendric`, LastName: `Pryor`, Position : 'WR', Salary : 650000, SleeperID : 8783 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Cochran`, Position : 'OL', Salary : 650000, SleeperID : 8784 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cal`, LastName: `Adomitis`, Position : 'LS', Salary : 650000, SleeperID : 8785 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Willoughby`, Position : 'LB', Salary : 650000, SleeperID : 7196 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bookie`, LastName: `Radley-Hiles`, Position : 'DB', Salary : 650000, SleeperID : 8782 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kristian`, LastName: `Welch`, Position : 'LB', Salary : 650000, SleeperID : 7197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Hector`, Position : 'CB', Salary : 650000, SleeperID : 7198 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalil`, LastName: `Dorsey`, Position : 'CB', Salary : 650000, SleeperID : 7200 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Vogel`, Position : 'K', Salary : 650000, SleeperID : 7201 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dom`, LastName: `Maggio`, Position : 'P', Salary : 650000, SleeperID : 7202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 7203 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Gilliam`, Position : 'RB', Salary : 650000, SleeperID : 7204 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquel`, LastName: `Harrell`, Position : 'G', Salary : 650000, SleeperID : 7205 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Nurse`, Position : 'DB', Salary : 650000, SleeperID : 7199 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Walton`, Position : 'OT', Salary : 650000, SleeperID : 7206 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ashtyn`, LastName: `Davis`, Position : 'DB', Salary : 650000, SleeperID : 6855 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ike`, LastName: `Brown`, Position : 'CB', Salary : 650000, SleeperID : 7207 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Thomas`, Position : 'DB', Salary : 650000, SleeperID : 7208 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Hinton`, Position : 'WR', Salary : 650000, SleeperID : 7210 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zimari`, LastName: `Manning`, Position : 'WR', Salary : 650000, SleeperID : 7211 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Adams`, Position : 'OT', Salary : 650000, SleeperID : 6852 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Collin`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 6857 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Van`, LastName: `Jefferson`, Position : 'WR', Salary : 650000, SleeperID : 6853 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Weaver`, Position : 'DE', Salary : 650000, SleeperID : 6854 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Pride`, Position : 'CB', Salary : 650000, SleeperID : 6856 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Taylor`, Position : 'S', Salary : 650000, SleeperID : 7209 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Prince Tega`, LastName: `Wanogho`, Position : 'T', Salary : 650000, SleeperID : 6859 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Coe`, Position : 'DE', Salary : 650000, SleeperID : 6861 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Saahdiq`, LastName: `Charles`, Position : 'T', Salary : 650000, SleeperID : 6862 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lavert`, LastName: `Hill`, Position : 'CB', Salary : 650000, SleeperID : 6863 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Lynch`, Position : 'DT', Salary : 650000, SleeperID : 6858 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Akeem`, LastName: `Davis-Gaither`, Position : 'LB', Salary : 650000, SleeperID : 6860 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Anderson`, Position : 'RB', Salary : 650000, SleeperID : 7038 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `White`, Position : 'WR', Salary : 650000, SleeperID : 7039 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Weaver`, Position : 'LB', Salary : 650000, SleeperID : 7040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Hunt`, Position : 'OL', Salary : 650000, SleeperID : 7041 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Artayvious`, LastName: `Lynn`, Position : 'TE', Salary : 650000, SleeperID : 7864 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Smith`, Position : 'WR', Salary : 650000, SleeperID : 7865 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Faoliu`, Position : 'DT', Salary : 650000, SleeperID : 7866 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Stokke`, Position : 'FB', Salary : 650000, SleeperID : 7868 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Moore`, Position : 'G', Salary : 650000, SleeperID : 7869 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paddy`, LastName: `Fisher`, Position : 'LB', Salary : 650000, SleeperID : 7870 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Brown`, Position : 'RB', Salary : 650000, SleeperID : 7867 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Oscar`, LastName: `Draguicevich`, Position : 'P', Salary : 650000, SleeperID : 7871 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alaric`, LastName: `Jackson`, Position : 'OL', Salary : 650000, SleeperID : 7872 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Meredith`, Position : 'OL', Salary : 650000, SleeperID : 7873 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Haydel`, Position : 'WR', Salary : 650000, SleeperID : 7874 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Teton`, LastName: `Saltes`, Position : 'OL', Salary : 650000, SleeperID : 7875 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hamilcar`, LastName: `Rashed`, Position : 'DE', Salary : 650000, SleeperID : 7877 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Milo`, LastName: `Eifler`, Position : 'LB', Salary : 650000, SleeperID : 7878 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tristen`, LastName: `Hoge`, Position : 'G', Salary : 650000, SleeperID : 7879 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Dwumfour`, Position : 'DT', Salary : 650000, SleeperID : 7876 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Capra`, Position : 'OL', Salary : 650000, SleeperID : 7883 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendrick`, LastName: `Rogers`, Position : 'WR', Salary : 650000, SleeperID : 7035 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Heflin`, Position : 'DT', Salary : 650000, SleeperID : 7880 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Dietzen`, Position : 'OL', Salary : 650000, SleeperID : 7881 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlo`, LastName: `Kemp`, Position : 'LB', Salary : 650000, SleeperID : 7884 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KJ`, LastName: `Hill`, Position : 'WR', Salary : 650000, SleeperID : 6866 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Harrison`, Position : 'LB', Salary : 650000, SleeperID : 6867 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `Burgess`, Position : 'DB', Salary : 650000, SleeperID : 7036 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bailey`, LastName: `Gaither`, Position : 'WR', Salary : 650000, SleeperID : 7885 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drake`, LastName: `Jackson`, Position : 'C', Salary : 650000, SleeperID : 7886 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Angelo`, LastName: `Amos`, Position : 'DB', Salary : 650000, SleeperID : 7887 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tavante`, LastName: `Beckett`, Position : 'LB', Salary : 650000, SleeperID : 7888 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Uphoff`, Position : 'DB', Salary : 650000, SleeperID : 7882 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerry`, LastName: `Jacobs`, Position : 'CB', Salary : 650000, SleeperID : 7889 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcel`, LastName: `Spears`, Position : 'LB', Salary : 650000, SleeperID : 7241 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isiah`, LastName: `Swann`, Position : 'CB', Salary : 650000, SleeperID : 7242 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Marcus`, LastName: `Bradley`, Position : 'WR', Salary : 650000, SleeperID : 7244 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Wieting`, Position : 'TE', Salary : 650000, SleeperID : 7245 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drake`, LastName: `Dorbeck`, Position : 'T', Salary : 650000, SleeperID : 7246 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Solomon`, LastName: `Ajayi`, Position : 'LB', Salary : 650000, SleeperID : 7249 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Obinna`, Position : 'LB', Salary : 650000, SleeperID : 7247 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Davidson`, Position : 'QB', Salary : 650000, SleeperID : 7243 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeffery`, LastName: `Whatley`, Position : 'DT', Salary : 650000, SleeperID : 7248 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Dunlop`, Position : 'OL', Salary : 650000, SleeperID : 7322 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Gilliam`, Position : 'G', Salary : 650000, SleeperID : 7323 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jessie`, LastName: `Lemonier`, Position : 'LB', Salary : 650000, SleeperID : 7324 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jameson`, LastName: `Houston`, Position : 'CB', Salary : 650000, SleeperID : 7250 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ali`, LastName: `Fayad`, Position : 'DL', Salary : 650000, SleeperID : 8404 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tariq`, LastName: `Carpenter`, Position : 'DB', Salary : 650000, SleeperID : 8405 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Pledger`, Position : 'RB', Salary : 650000, SleeperID : 8407 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Mason`, Position : 'RB', Salary : 650000, SleeperID : 8408 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Snead`, Position : 'WR', Salary : 650000, SleeperID : 8409 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jequez`, LastName: `Ezzard`, Position : 'WR', Salary : 650000, SleeperID : 8410 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devon`, LastName: `Allen`, Position : 'WR', Salary : 650000, SleeperID : 8411 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roy`, LastName: `Mbaeteka`, Position : 'OL', Salary : 650000, SleeperID : 8412 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reed`, LastName: `Blankenship`, Position : 'DB', Salary : 650000, SleeperID : 8406 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Oladokun`, Position : 'QB', Salary : 650000, SleeperID : 8413 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Britain`, LastName: `Covey`, Position : 'WR', Salary : 650000, SleeperID : 8414 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Haggai`, LastName: `Ndubuisi`, Position : 'T', Salary : 650000, SleeperID : 8415 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Smart`, Position : 'WR', Salary : 650000, SleeperID : 8418 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenneth`, LastName: `George`, Position : 'DB', Salary : 650000, SleeperID : 8879 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Grant`, Position : 'CB', Salary : 650000, SleeperID : 8880 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 8881 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nijuel`, LastName: `Hill`, Position : 'DB', Salary : 650000, SleeperID : 8882 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Virgil`, Position : 'WR', Salary : 650000, SleeperID : 8416 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Stills`, Position : 'DT', Salary : 650000, SleeperID : 7991 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drue`, LastName: `Chrisman`, Position : 'P', Salary : 650000, SleeperID : 7984 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pro`, LastName: `Wells`, Position : 'TE', Salary : 650000, SleeperID : 7985 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Phillips`, Position : 'CB', Salary : 650000, SleeperID : 7986 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Hodge`, Position : 'DE', Salary : 650000, SleeperID : 7987 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Lees`, Position : 'WR', Salary : 650000, SleeperID : 7988 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Turner`, Position : 'WR', Salary : 650000, SleeperID : 7989 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Groshek`, Position : 'RB', Salary : 650000, SleeperID : 7990 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Bushman`, Position : 'TE', Salary : 650000, SleeperID : 7992 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Ragas`, Position : 'RB', Salary : 650000, SleeperID : 7993 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `TJ`, LastName: `Morrison`, Position : 'CB', Salary : 650000, SleeperID : 7994 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Richardson`, Position : 'LB', Salary : 650000, SleeperID : 7995 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Herring`, Position : 'DE', Salary : 650000, SleeperID : 8001 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devery`, LastName: `Hamilton`, Position : 'T', Salary : 650000, SleeperID : 7997 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Cole`, Position : 'LB', Salary : 650000, SleeperID : 7998 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marlon`, LastName: `Character`, Position : 'DB', Salary : 650000, SleeperID : 7999 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zayne`, LastName: `Anderson`, Position : 'DB', Salary : 650000, SleeperID : 8000 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dillon`, LastName: `Stoner`, Position : 'WR', Salary : 650000, SleeperID : 7996 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shane`, LastName: `Buechele`, Position : 'QB', Salary : 650000, SleeperID : 8002 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dicaprio`, LastName: `Bootle`, Position : 'DB', Salary : 650000, SleeperID : 8003 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devon`, LastName: `Key`, Position : 'DB', Salary : 650000, SleeperID : 8004 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daviyon`, LastName: `Nixon`, Position : 'DT', Salary : 650000, SleeperID : 7832 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Robinson`, Position : 'LB', Salary : 650000, SleeperID : 8020 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Branden`, LastName: `Mack`, Position : 'WR', Salary : 650000, SleeperID : 8021 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Himmelman`, Position : 'OT', Salary : 650000, SleeperID : 8022 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nolan`, LastName: `Laufenberg`, Position : 'G', Salary : 650000, SleeperID : 8023 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Mintze`, Position : 'LB', Salary : 650000, SleeperID : 8024 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Prentice`, Position : 'FB', Salary : 650000, SleeperID : 8025 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Landon`, LastName: `Rice`, Position : 'TE', Salary : 650000, SleeperID : 8026 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Harbison`, Position : 'RB', Salary : 650000, SleeperID : 8027 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marvin`, LastName: `Wilson`, Position : 'DT', Salary : 650000, SleeperID : 8029 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiahh`, LastName: `Loudermilk`, Position : 'DE', Salary : 650000, SleeperID : 7833 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Moore`, Position : 'OL', Salary : 650000, SleeperID : 7834 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Carter`, Position : 'CB', Salary : 650000, SleeperID : 7835 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gunnar`, LastName: `Vogel`, Position : 'OT', Salary : 650000, SleeperID : 7847 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Romeo`, LastName: `McKnight`, Position : 'DE', Salary : 650000, SleeperID : 8028 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scooter`, LastName: `Harrington`, Position : 'TE', Salary : 650000, SleeperID : 7848 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Snowden`, Position : 'LB', Salary : 650000, SleeperID : 7849 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justus`, LastName: `Reed`, Position : 'DE', Salary : 650000, SleeperID : 7850 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Mayfield`, Position : 'OL', Salary : 650000, SleeperID : 7684 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Huntley`, Position : 'QB', Salary : 650000, SleeperID : 7083 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Luton`, Position : 'QB', Salary : 650000, SleeperID : 7084 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Coulter`, Position : 'WR', Salary : 650000, SleeperID : 7085 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Hightower`, Position : 'WR', Salary : 650000, SleeperID : 7086 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Ward`, Position : 'RB', Salary : 650000, SleeperID : 7087 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Tolbert`, Position : 'WR', Salary : 650000, SleeperID : 8117 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Bell`, Position : 'WR', Salary : 650000, SleeperID : 8118 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jahan`, LastName: `Dotson`, Position : 'WR', Salary : 1223342.921, SleeperID : 8119 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerrion`, LastName: `Ealy`, Position : 'RB', Salary : 650000, SleeperID : 8120 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Romeo`, LastName: `Doubs`, Position : 'WR', Salary : 880254.4257, SleeperID : 8121 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zonovan`, LastName: `Knight`, Position : 'RB', Salary : 650000, SleeperID : 8122 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerome`, LastName: `Ford`, Position : 'RB', Salary : 756198.674, SleeperID : 8143 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Olave`, Position : 'WR', Salary : 10260310.28, SleeperID : 8144 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Ruckert`, Position : 'TE', Salary : 650000, SleeperID : 8145 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Wilson`, Position : 'WR', Salary : 12563873.9, SleeperID : 8146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Metchie`, Position : 'WR', Salary : 650000, SleeperID : 8147 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jameson`, LastName: `Williams`, Position : 'WR', Salary : 1254710.688, SleeperID : 8148 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Master`, LastName: `Teague`, Position : 'RB', Salary : 650000, SleeperID : 8149 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyren`, LastName: `Williams`, Position : 'RB', Salary : 8593949.64, SleeperID : 8150 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenneth`, LastName: `Walker`, Position : 'RB', Salary : 8169623.377, SleeperID : 8151 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kennedy`, LastName: `Brooks`, Position : 'RB', Salary : 650000, SleeperID : 8152 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Spiller`, Position : 'RB', Salary : 650000, SleeperID : 8153 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Robinson`, Position : 'RB', Salary : 1979067.081, SleeperID : 8154 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Breece`, LastName: `Hall`, Position : 'RB', Salary : 13216435.4, SleeperID : 8155 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bailey`, LastName: `Zappe`, Position : 'QB', Salary : 650000, SleeperID : 8157 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carson`, LastName: `Strong`, Position : 'QB', Salary : 650000, SleeperID : 8158 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Desmond`, LastName: `Ridder`, Position : 'QB', Salary : 650000, SleeperID : 8159 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Pickett`, Position : 'QB', Salary : 650000, SleeperID : 8160 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Willis`, Position : 'QB', Salary : 650000, SleeperID : 8161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Howell`, Position : 'QB', Salary : 650000, SleeperID : 8162 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Corral`, Position : 'QB', Salary : 650000, SleeperID : 8164 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `ZaQuandre`, LastName: `White`, Position : 'RB', Salary : 650000, SleeperID : 8173 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Watson`, Position : 'WR', Salary : 1616208.701, SleeperID : 8167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Skyy`, LastName: `Moore`, Position : 'WR', Salary : 650000, SleeperID : 8168 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Turner`, Position : 'WR', Salary : 650000, SleeperID : 8169 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Mitchell`, Position : 'TE', Salary : 650000, SleeperID : 8170 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Dulcich`, Position : 'TE', Salary : 650000, SleeperID : 8172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontario`, LastName: `Drummond`, Position : 'WR', Salary : 650000, SleeperID : 8166 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Harris`, Position : 'RB', Salary : 650000, SleeperID : 8174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Roberson`, Position : 'WR', Salary : 650000, SleeperID : 8175 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Philips`, Position : 'WR', Salary : 650000, SleeperID : 8171 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Danny`, LastName: `Gray`, Position : 'WR', Salary : 650000, SleeperID : 8176 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Nailor`, Position : 'WR', Salary : 650000, SleeperID : 8180 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grant`, LastName: `Calcaterra`, Position : 'TE', Salary : 650000, SleeperID : 8177 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braylon`, LastName: `Sanders`, Position : 'WR', Salary : 650000, SleeperID : 8178 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Snoop`, LastName: `Conner`, Position : 'RB', Salary : 650000, SleeperID : 8179 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Heyward`, Position : 'TE', Salary : 650000, SleeperID : 8181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Allen`, Position : 'TE', Salary : 650000, SleeperID : 8182 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brock`, LastName: `Purdy`, Position : 'QB', Salary : 1536408.396, SleeperID : 8183 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lirim`, LastName: `Hajrullahu`, Position : 'K', Salary : 650000, SleeperID : 7094 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `MacGinnis`, Position : 'K', Salary : 650000, SleeperID : 7095 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty'Son`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 7098 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Dillon`, Position : 'WR', Salary : 650000, SleeperID : 7100 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Smith`, Position : 'RB', Salary : 650000, SleeperID : 7101 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tavien`, LastName: `Feaster`, Position : 'RB', Salary : 650000, SleeperID : 7103 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ramiz`, LastName: `Ahmed`, Position : 'K', Salary : 650000, SleeperID : 7105 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lawrence`, LastName: `Cager`, Position : 'TE', Salary : 650000, SleeperID : 7106 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Huntley`, Position : 'RB', Salary : 650000, SleeperID : 7107 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Perry`, Position : 'WR', Salary : 650000, SleeperID : 7109 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `McTelvin`, LastName: `Agim`, Position : 'DT', Salary : 650000, SleeperID : 7111 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyre`, LastName: `Phillips`, Position : 'G', Salary : 650000, SleeperID : 7112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Highsmith`, Position : 'LB', Salary : 650000, SleeperID : 7113 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Robinson`, Position : 'CB', Salary : 650000, SleeperID : 7114 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charlie`, LastName: `Heck`, Position : 'T', Salary : 650000, SleeperID : 7115 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `McFadden`, Position : 'LB', Salary : 650000, SleeperID : 8396 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juanyeh`, LastName: `Thomas`, Position : 'DB', Salary : 650000, SleeperID : 8397 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 8398 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Markquese`, LastName: `Bell`, Position : 'DB', Salary : 650000, SleeperID : 8399 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Jones`, Position : 'DL', Salary : 650000, SleeperID : 8400 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Gunter`, Position : 'LB', Salary : 650000, SleeperID : 8402 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sterling`, LastName: `Weatherford`, Position : 'DB', Salary : 650000, SleeperID : 8403 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Notree`, Position : 'LB', Salary : 650000, SleeperID : 8883 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JJ`, LastName: `Howland`, Position : 'TE', Salary : 650000, SleeperID : 8884 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tucker`, LastName: `Fisk`, Position : 'TE', Salary : 650000, SleeperID : 8885 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Webb`, Position : 'DB', Salary : 650000, SleeperID : 8886 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Greene`, Position : 'DT', Salary : 650000, SleeperID : 8887 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Kwenkeu`, Position : 'LB', Salary : 650000, SleeperID : 8889 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Naz`, LastName: `Bohannon`, Position : 'TE', Salary : 650000, SleeperID : 8890 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `McDaniel`, Position : 'WR', Salary : 650000, SleeperID : 8891 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keric`, LastName: `Wheatfall`, Position : 'WR', Salary : 650000, SleeperID : 8892 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Sciba`, Position : 'K', Salary : 650000, SleeperID : 8893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevon`, LastName: `Mason`, Position : 'DT', Salary : 650000, SleeperID : 8894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlins`, LastName: `Platel`, Position : 'CB', Salary : 650000, SleeperID : 8895 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Liam`, LastName: `Ryan`, Position : 'T', Salary : 650000, SleeperID : 8897 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Brewer`, Position : 'DT', Salary : 650000, SleeperID : 8898 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Prince`, LastName: `Emili`, Position : 'DT', Salary : 650000, SleeperID : 8899 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Ulmer`, Position : 'T', Salary : 650000, SleeperID : 8900 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sailosi`, LastName: `Latu`, Position : 'DT', Salary : 650000, SleeperID : 7181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hinwa`, LastName: `Allieu`, Position : 'DT', Salary : 650000, SleeperID : 7182 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 8896 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Edwards`, Position : 'DE', Salary : 650000, SleeperID : 7183 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryson`, LastName: `Young`, Position : 'LB', Salary : 650000, SleeperID : 7184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ray`, LastName: `Wilborn`, Position : 'LB', Salary : 650000, SleeperID : 7185 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Williams`, Position : 'LB', Salary : 650000, SleeperID : 7186 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rojesterman`, LastName: `Farris`, Position : 'DB', Salary : 650000, SleeperID : 7187 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Owen`, Position : 'OL', Salary : 650000, SleeperID : 8772 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alec`, LastName: `Anderson`, Position : 'OL', Salary : 650000, SleeperID : 8774 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Mevis`, Position : 'K', Salary : 650000, SleeperID : 8775 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kingsley`, LastName: `Jonathan`, Position : 'DT', Salary : 650000, SleeperID : 8776 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travon`, LastName: `Fuller`, Position : 'CB', Salary : 650000, SleeperID : 8777 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Blackshear`, Position : 'G', Salary : 650000, SleeperID : 8773 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Rigg`, Position : 'TE', Salary : 650000, SleeperID : 8778 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colby`, LastName: `Parkinson`, Position : 'TE', Salary : 650000, SleeperID : 6865 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalid`, LastName: `Kareem`, Position : 'DE', Salary : 650000, SleeperID : 6864 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Gaziano`, Position : 'DE', Salary : 650000, SleeperID : 7325 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Smith`, Position : 'DT', Salary : 650000, SleeperID : 7327 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Asmar`, LastName: `Bilal`, Position : 'LB', Salary : 650000, SleeperID : 7328 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Neuzil`, Position : 'OL', Salary : 650000, SleeperID : 7979 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Christiansen`, Position : 'LB', Salary : 650000, SleeperID : 7329 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Kampmoyer`, Position : 'TE', Salary : 650000, SleeperID : 7938 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Breiden`, LastName: `Fehoko`, Position : 'NT', Salary : 650000, SleeperID : 7326 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tariq`, LastName: `Thompson`, Position : 'DB', Salary : 650000, SleeperID : 7975 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Olaijah`, LastName: `Griffin`, Position : 'CB', Salary : 650000, SleeperID : 7976 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Syrus`, LastName: `Tuitele`, Position : 'OG', Salary : 650000, SleeperID : 7977 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Walker`, Position : 'WR', Salary : 650000, SleeperID : 7978 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Raine`, Position : 'FB', Salary : 650000, SleeperID : 7980 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `McGill`, Position : 'DB', Salary : 650000, SleeperID : 7332 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dorian`, LastName: `Etheridge`, Position : 'LB', Salary : 650000, SleeperID : 7982 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Collin`, LastName: `Hill`, Position : 'QB', Salary : 650000, SleeperID : 7983 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Romeo`, LastName: `Finley`, Position : 'LB', Salary : 650000, SleeperID : 7330 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Brannon`, Position : 'CB', Salary : 650000, SleeperID : 7331 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zac`, LastName: `Dawe`, Position : 'DT', Salary : 650000, SleeperID : 7981 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donte`, LastName: `Vaughn`, Position : 'CB', Salary : 650000, SleeperID : 7333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.J.`, LastName: `Koski`, Position : 'WR', Salary : 650000, SleeperID : 7339 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Earnest`, LastName: `Edwards`, Position : 'WR', Salary : 650000, SleeperID : 7334 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Perkins`, Position : 'QB', Salary : 650000, SleeperID : 7335 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Gilbert`, Position : 'RB', Salary : 650000, SleeperID : 7336 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Easop`, LastName: `Winston`, Position : 'WR', Salary : 650000, SleeperID : 7337 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cohl`, LastName: `Cabral`, Position : 'C', Salary : 650000, SleeperID : 7338 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Polk`, Position : 'WR', Salary : 650000, SleeperID : 7340 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Banks`, Position : 'DE', Salary : 650000, SleeperID : 7341 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Hoecht`, Position : 'DT', Salary : 650000, SleeperID : 7342 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Renner`, Position : 'DT', Salary : 650000, SleeperID : 7343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonah`, LastName: `Williams`, Position : 'DE', Salary : 650000, SleeperID : 7344 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Greg`, LastName: `Reaves`, Position : 'OLB', Salary : 650000, SleeperID : 7345 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Rozeboom`, Position : 'LB', Salary : 650000, SleeperID : 7346 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrique`, LastName: `McGhee`, Position : 'DB', Salary : 650000, SleeperID : 7349 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Levonta`, LastName: `Taylor`, Position : 'CB', Salary : 650000, SleeperID : 7350 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Cole`, Position : 'WR', Salary : 650000, SleeperID : 7352 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JuJu`, LastName: `Hughes`, Position : 'DB', Salary : 650000, SleeperID : 7347 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nakia`, LastName: `Griffin-Stewart`, Position : 'TE', Salary : 650000, SleeperID : 7359 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donell`, LastName: `Stanley`, Position : 'C', Salary : 650000, SleeperID : 7354 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dayan`, LastName: `Lake`, Position : 'DB', Salary : 650000, SleeperID : 7348 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Kaltmayer`, Position : 'OT', Salary : 650000, SleeperID : 7355 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kirk`, LastName: `Merritt`, Position : 'WR', Salary : 650000, SleeperID : 7351 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brady`, LastName: `Aiello`, Position : 'T', Salary : 650000, SleeperID : 7360 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Chisena`, Position : 'WR', Salary : 650000, SleeperID : 7357 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Bargas`, Position : 'FB', Salary : 650000, SleeperID : 7358 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Sterk`, Position : 'TE', Salary : 650000, SleeperID : 7353 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nevelle`, LastName: `Clarke`, Position : 'CB', Salary : 650000, SleeperID : 7365 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyshun`, LastName: `Render`, Position : 'DE', Salary : 650000, SleeperID : 7356 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Vital`, Position : 'TE', Salary : 650000, SleeperID : 8101 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Lacina`, Position : 'OL', Salary : 650000, SleeperID : 7361 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Moa`, Position : 'DT', Salary : 650000, SleeperID : 7362 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Fehr`, Position : 'LB', Salary : 650000, SleeperID : 7363 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Lynch`, Position : 'LB', Salary : 650000, SleeperID : 7364 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeShon`, LastName: `Williams`, Position : 'TE', Salary : 650000, SleeperID : 8102 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Willis`, Position : 'QB', Salary : 650000, SleeperID : 8103 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dee`, LastName: `Alford`, Position : 'DB', Salary : 650000, SleeperID : 8104 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Murray`, Position : 'T', Salary : 650000, SleeperID : 8106 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nikola`, LastName: `Kalinic`, Position : 'TE', Salary : 650000, SleeperID : 8107 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeVonte`, LastName: `Dedmon`, Position : 'WR', Salary : 650000, SleeperID : 8108 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Ferguson`, Position : 'TE', Salary : 1460548.232, SleeperID : 8110 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cade`, LastName: `Otton`, Position : 'TE', Salary : 650000, SleeperID : 8111 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brayden`, LastName: `Lenius`, Position : 'TE', Salary : 650000, SleeperID : 8105 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Desjarlais`, Position : 'OL', Salary : 650000, SleeperID : 8109 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drake`, LastName: `London`, Position : 'WR', Salary : 9040315.203, SleeperID : 8112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erik`, LastName: `Ezukanma`, Position : 'WR', Salary : 650000, SleeperID : 8114 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pierre`, LastName: `Strong`, Position : 'RB', Salary : 650000, SleeperID : 8116 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damon`, LastName: `Hazelton`, Position : 'WR', Salary : 650000, SleeperID : 7573 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dedrick`, LastName: `Mills`, Position : 'RB', Salary : 650000, SleeperID : 7893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lamont`, LastName: `Wade`, Position : 'DB', Salary : 650000, SleeperID : 7894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mac`, LastName: `McCain`, Position : 'CB', Salary : 650000, SleeperID : 7895 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donovan`, LastName: `Stiner`, Position : 'DB', Salary : 650000, SleeperID : 7896 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roland`, LastName: `Rivers`, Position : 'QB', Salary : 650000, SleeperID : 7897 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shakur`, LastName: `Brown`, Position : 'CB', Salary : 650000, SleeperID : 7898 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Wydermyer`, Position : 'TE', Salary : 650000, SleeperID : 8115 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Rountree`, Position : 'RB', Salary : 650000, SleeperID : 7574 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `McKoy`, Position : 'WR', Salary : 650000, SleeperID : 7899 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carl`, LastName: `Tucker`, Position : 'TE', Salary : 650000, SleeperID : 7926 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamar`, LastName: `Watson`, Position : 'LB', Salary : 650000, SleeperID : 7900 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaytlin`, LastName: `Askew`, Position : 'CB', Salary : 650000, SleeperID : 7924 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Burton`, Position : 'G', Salary : 650000, SleeperID : 7929 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chuba`, LastName: `Hubbard`, Position : 'RB', Salary : 666283.395, SleeperID : 7594 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerome`, LastName: `Johnson`, Position : 'DT', Salary : 650000, SleeperID : 7925 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grant`, LastName: `Hermanns`, Position : 'OL', Salary : 650000, SleeperID : 7927 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brett`, LastName: `Heggie`, Position : 'C', Salary : 650000, SleeperID : 7928 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tylan`, LastName: `Wallace`, Position : 'WR', Salary : 650000, SleeperID : 7595 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Van Lanen`, Position : 'OL', Salary : 650000, SleeperID : 7785 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Fletcher`, Position : 'LS', Salary : 650000, SleeperID : 7781 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `McDuffie`, Position : 'LB', Salary : 650000, SleeperID : 7782 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quincy`, LastName: `Roche`, Position : 'LB', Salary : 650000, SleeperID : 7783 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brady`, LastName: `Breeze`, Position : 'DB', Salary : 650000, SleeperID : 7784 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rachad`, LastName: `Wildgoose`, Position : 'CB', Salary : 650000, SleeperID : 7786 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Racey`, LastName: `McMath`, Position : 'WR', Salary : 650000, SleeperID : 7793 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Evans`, Position : 'RB', Salary : 650000, SleeperID : 7794 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodarius`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 7795 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandin`, LastName: `Echols`, Position : 'CB', Salary : 650000, SleeperID : 7796 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Twyman`, Position : 'DT', Salary : 650000, SleeperID : 7797 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Sherman`, Position : 'T', Salary : 650000, SleeperID : 7798 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roy`, LastName: `Lopez`, Position : 'DT', Salary : 650000, SleeperID : 7799 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deonte`, LastName: `Brown`, Position : 'G', Salary : 650000, SleeperID : 7800 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinton`, LastName: `Bohanna`, Position : 'DT', Salary : 650000, SleeperID : 7801 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tarron`, LastName: `Jackson`, Position : 'DE', Salary : 650000, SleeperID : 7802 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Hill`, Position : 'C', Salary : 650000, SleeperID : 7803 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marlon`, LastName: `Tuipulotu`, Position : 'DT', Salary : 650000, SleeperID : 7804 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Bledsoe`, Position : 'DB', Salary : 650000, SleeperID : 7805 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hamsah`, LastName: `Nasirildeen`, Position : 'LB', Salary : 650000, SleeperID : 7806 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Niemann`, Position : 'LB', Salary : 650000, SleeperID : 7807 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Mason`, Position : 'FB', Salary : 650000, SleeperID : 7808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raymond`, LastName: `Johnson`, Position : 'DE', Salary : 650000, SleeperID : 7930 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Forrest`, LastName: `Merrill`, Position : 'DT', Salary : 650000, SleeperID : 7931 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Harper`, Position : 'OL', Salary : 650000, SleeperID : 7932 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Kessman`, Position : 'K', Salary : 650000, SleeperID : 7933 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Goldwire`, Position : 'DT', Salary : 650000, SleeperID : 7934 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eli`, LastName: `Stove`, Position : 'WR', Salary : 650000, SleeperID : 7935 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amen`, LastName: `Ogbongbemiga`, Position : 'LB', Salary : 650000, SleeperID : 7936 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damar`, LastName: `Hamlin`, Position : 'DB', Salary : 650000, SleeperID : 7787 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Victor`, LastName: `Dimukeje`, Position : 'LB', Salary : 650000, SleeperID : 7788 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Camp`, Position : 'WR', Salary : 650000, SleeperID : 7789 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stone`, LastName: `Forsythe`, Position : 'T', Salary : 650000, SleeperID : 7790 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Spalding`, Position : 'OL', Salary : 650000, SleeperID : 7937 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Marshall`, Position : 'DT', Salary : 650000, SleeperID : 7791 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Landon`, LastName: `Young`, Position : 'T', Salary : 650000, SleeperID : 7792 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Schlueter`, Position : 'OL', Salary : 650000, SleeperID : 8617 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcelino`, LastName: `McCrary-Ball`, Position : 'LB', Salary : 650000, SleeperID : 8618 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Poe`, Position : 'OL', Salary : 650000, SleeperID : 8619 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Segun`, LastName: `Olubi`, Position : 'LB', Salary : 650000, SleeperID : 8620 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Walston`, Position : 'TE', Salary : 650000, SleeperID : 8621 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dohnovan`, LastName: `West`, Position : 'OL', Salary : 650000, SleeperID : 8622 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Changa`, LastName: `Hodge`, Position : 'WR', Salary : 650000, SleeperID : 8624 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malkelm`, LastName: `Morrison`, Position : 'CB', Salary : 650000, SleeperID : 8842 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bamidele`, LastName: `Olaseni`, Position : 'OT', Salary : 650000, SleeperID : 8843 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Webb`, Position : 'CB', Salary : 650000, SleeperID : 8844 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tomon`, LastName: `Fox`, Position : 'LB', Salary : 650000, SleeperID : 8854 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Heatherly`, Position : 'P', Salary : 650000, SleeperID : 8855 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Hamilton`, Position : 'CB', Salary : 650000, SleeperID : 8856 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kader`, LastName: `Kohou`, Position : 'CB', Salary : 650000, SleeperID : 8857 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Hutchinson`, Position : 'WR', Salary : 650000, SleeperID : 10218 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Rodriguez`, Position : 'RB', Salary : 650000, SleeperID : 10219 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Mallory`, Position : 'TE', Salary : 650000, SleeperID : 10220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronnie`, LastName: `Bell`, Position : 'WR', Salary : 650000, SleeperID : 10221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayden`, LastName: `Reed`, Position : 'WR', Salary : 3822438.501, SleeperID : 10222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Gray`, Position : 'RB', Salary : 650000, SleeperID : 10223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brayden`, LastName: `Willis`, Position : 'TE', Salary : 650000, SleeperID : 10224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Mingo`, Position : 'WR', Salary : 650000, SleeperID : 10225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrei`, LastName: `Iosivas`, Position : 'WR', Salary : 650000, SleeperID : 10226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Payne`, LastName: `Durham`, Position : 'TE', Salary : 650000, SleeperID : 10227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charlie`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 10228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashee`, LastName: `Rice`, Position : 'WR', Salary : 5588191.383, SleeperID : 10229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Wayne`, Position : 'WR', Salary : 650000, SleeperID : 10230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Higgins`, Position : 'TE', Salary : 650000, SleeperID : 10231 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Duggan`, Position : 'QB', Salary : 650000, SleeperID : 10233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Wilson`, Position : 'WR', Salary : 650000, SleeperID : 10232 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brayden`, LastName: `Thomas`, Position : 'DE', Salary : 650000, SleeperID : 8734 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Hummel`, Position : 'LB', Salary : 650000, SleeperID : 8733 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caesar`, LastName: `Dancy-Williams`, Position : 'CB', Salary : 650000, SleeperID : 8740 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jairon`, LastName: `McVea`, Position : 'DB', Salary : 650000, SleeperID : 8735 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raleigh`, LastName: `Webb`, Position : 'WR', Salary : 650000, SleeperID : 8732 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Garcia`, Position : 'DT', Salary : 650000, SleeperID : 8736 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dan`, LastName: `Isom`, Position : 'DB', Salary : 650000, SleeperID : 8737 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dion`, LastName: `Novil`, Position : 'DT', Salary : 650000, SleeperID : 8738 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keir`, LastName: `Thomas`, Position : 'DE', Salary : 650000, SleeperID : 8739 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Pettigrew`, Position : 'TE', Salary : 650000, SleeperID : 8741 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duron`, LastName: `Lowe`, Position : 'CB', Salary : 650000, SleeperID : 8742 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrzej`, LastName: `Hughes-Murray`, Position : 'LB', Salary : 650000, SleeperID : 8743 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Snyder`, Position : 'C', Salary : 650000, SleeperID : 8744 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendre`, LastName: `Miller`, Position : 'RB', Salary : 999045.2029, SleeperID : 9757 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Addison`, Position : 'WR', Salary : 4923731.361, SleeperID : 9756 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Stroud`, Position : 'QB', Salary : 5878439.325, SleeperID : 9758 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 9759 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zay`, LastName: `Flowers`, Position : 'WR', Salary : 6029168.538, SleeperID : 9997 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hendon`, LastName: `Hooker`, Position : 'QB', Salary : 650000, SleeperID : 9998 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Manny`, LastName: `Jones`, Position : 'DT', Salary : 650000, SleeperID : 8625 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kekaula`, LastName: `Kaniho`, Position : 'DB', Salary : 650000, SleeperID : 8627 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jontre`, LastName: `Kirklin`, Position : 'WR', Salary : 650000, SleeperID : 8628 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derius`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 10234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roschon`, LastName: `Johnson`, Position : 'RB', Salary : 718861.3645, SleeperID : 10235 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalton`, LastName: `Kincaid`, Position : 'TE', Salary : 5312274.433, SleeperID : 10236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mohamed`, LastName: `Ibrahim`, Position : 'RB', Salary : 650000, SleeperID : 10651 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stetson`, LastName: `Bennett`, Position : 'QB', Salary : 650000, SleeperID : 10857 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Quevedo`, Position : 'K', Salary : 650000, SleeperID : 10858 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Miles`, Position : 'DT', Salary : 650000, SleeperID : 8629 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaRon`, LastName: `Stokes`, Position : 'DT', Salary : 650000, SleeperID : 8631 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JaVonta`, LastName: `Payton`, Position : 'WR', Salary : 650000, SleeperID : 8632 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Stephon`, LastName: `Robinson`, Position : 'WR', Salary : 650000, SleeperID : 8633 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Pierce`, Position : 'TE', Salary : 650000, SleeperID : 8634 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chandler`, LastName: `Wooten`, Position : 'LB', Salary : 650000, SleeperID : 8635 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Allan`, LastName: `George`, Position : 'CB', Salary : 650000, SleeperID : 8788 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Desmond`, LastName: `Noel`, Position : 'OL', Salary : 650000, SleeperID : 8786 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Brown`, Position : 'OG', Salary : 650000, SleeperID : 8787 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tariqious`, LastName: `Tisdale`, Position : 'DT', Salary : 650000, SleeperID : 8789 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brock`, LastName: `Hoffman`, Position : 'C', Salary : 650000, SleeperID : 8790 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaun`, LastName: `Jolly`, Position : 'CB', Salary : 650000, SleeperID : 8791 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Junior`, LastName: `Faulk`, Position : 'CB', Salary : 650000, SleeperID : 8797 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Petrula`, Position : 'T', Salary : 650000, SleeperID : 8792 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Glen`, LastName: `Logan`, Position : 'DT', Salary : 650000, SleeperID : 8793 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roderick`, LastName: `Perry`, Position : 'DT', Salary : 650000, SleeperID : 8794 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Anthony`, LastName: `Bell`, Position : 'DB', Salary : 650000, SleeperID : 8795 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Silas`, LastName: `Kelly`, Position : 'LB', Salary : 650000, SleeperID : 8796 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zaire`, LastName: `Mitchell-Paden`, Position : 'TE', Salary : 650000, SleeperID : 8799 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dennis`, LastName: `Houston`, Position : 'WR', Salary : 650000, SleeperID : 8801 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Felix`, LastName: `Harper`, Position : 'QB', Salary : 650000, SleeperID : 8798 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Davis`, Position : 'RB', Salary : 650000, SleeperID : 8800 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alec`, LastName: `Lindstrom`, Position : 'C', Salary : 650000, SleeperID : 8802 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quandre`, LastName: `Mosely`, Position : 'DB', Salary : 650000, SleeperID : 8803 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grayson`, LastName: `Gunter`, Position : 'TE', Salary : 650000, SleeperID : 8638 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shabari`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 8639 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lew`, LastName: `Nichols`, Position : 'RB', Salary : 650000, SleeperID : 9510 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keaton`, LastName: `Mitchell`, Position : 'RB', Salary : 650000, SleeperID : 9511 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeWayne`, LastName: `McBride`, Position : 'RB', Salary : 650000, SleeperID : 9512 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Charbonnet`, Position : 'RB', Salary : 1575803.484, SleeperID : 9753 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quentin`, LastName: `Johnston`, Position : 'WR', Salary : 650000, SleeperID : 9754 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keshunn`, LastName: `Abram`, Position : 'WR', Salary : 650000, SleeperID : 8858 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DQ`, LastName: `Thomas`, Position : 'LB', Salary : 650000, SleeperID : 8859 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tony`, LastName: `Adams`, Position : 'DB', Salary : 650000, SleeperID : 8860 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Beise`, Position : 'TE', Salary : 650000, SleeperID : 8864 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Cook`, Position : 'T', Salary : 650000, SleeperID : 8865 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Blackwell`, Position : 'OL', Salary : 650000, SleeperID : 8867 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Valentine-Turner`, Position : 'DB', Salary : 650000, SleeperID : 8862 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaylon`, LastName: `Geiger`, Position : 'WR', Salary : 650000, SleeperID : 8863 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JoJo`, LastName: `Ozougwu`, Position : 'DE', Salary : 650000, SleeperID : 8866 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Irvin`, LastName: `Charles`, Position : 'WR', Salary : 650000, SleeperID : 8861 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Young`, Position : 'LB', Salary : 650000, SleeperID : 8868 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.J.`, LastName: `Russell`, Position : 'LB', Salary : 650000, SleeperID : 8869 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Olakunle`, LastName: `Fatukasi`, Position : 'LB', Salary : 650000, SleeperID : 8870 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nolan`, LastName: `Turner`, Position : 'DB', Salary : 650000, SleeperID : 8871 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyler`, LastName: `McMichael`, Position : 'DB', Salary : 650000, SleeperID : 8873 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scott`, LastName: `Nelson`, Position : 'SS', Salary : 650000, SleeperID : 8686 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Onujiogu`, Position : 'LB', Salary : 650000, SleeperID : 8687 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Gotel`, Position : 'DT', Salary : 650000, SleeperID : 8689 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Don`, LastName: `Gardner`, Position : 'CB', Salary : 650000, SleeperID : 8872 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Herslow`, Position : 'WR', Salary : 650000, SleeperID : 8690 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Vi`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 8691 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Dew-Treadway`, Position : 'DT', Salary : 650000, SleeperID : 8693 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henry`, LastName: `Litwin`, Position : 'WR', Salary : 650000, SleeperID : 8694 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Allie`, LastName: `Green`, Position : 'CB', Salary : 650000, SleeperID : 8695 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalin`, LastName: `Hyatt`, Position : 'WR', Salary : 650000, SleeperID : 9497 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Ford-Wheaton`, Position : 'WR', Salary : 650000, SleeperID : 9499 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Downs`, Position : 'WR', Salary : 1286882.757, SleeperID : 9500 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeMario`, LastName: `Douglas`, Position : 'WR', Salary : 650000, SleeperID : 9501 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tank`, LastName: `Dell`, Position : 'WR', Salary : 5179467.573, SleeperID : 9502 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Copeland`, Position : 'WR', Salary : 650000, SleeperID : 9503 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kayshon`, LastName: `Boutte`, Position : 'WR', Salary : 650000, SleeperID : 9504 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deuce`, LastName: `Vaughn`, Position : 'RB', Salary : 650000, SleeperID : 9505 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Tucker`, Position : 'RB', Salary : 650000, SleeperID : 9506 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jadon`, LastName: `Haselwood`, Position : 'WR', Salary : 650000, SleeperID : 9498 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Titus`, LastName: `Swen`, Position : 'RB', Salary : 650000, SleeperID : 9507 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyjae`, LastName: `Spears`, Position : 'RB', Salary : 2681668.722, SleeperID : 9508 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bijan`, LastName: `Robinson`, Position : 'RB', Salary : 13902890.63, SleeperID : 9509 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nolan`, LastName: `Givan`, Position : 'TE', Salary : 650000, SleeperID : 8817 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Obinna`, LastName: `Eze`, Position : 'T', Salary : 650000, SleeperID : 8818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adedayo`, LastName: `Odeleye`, Position : 'DE', Salary : 650000, SleeperID : 8821 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Van Demark`, Position : 'OL', Salary : 650000, SleeperID : 8822 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Mollette`, Position : 'OL', Salary : 650000, SleeperID : 8823 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Samson`, LastName: `Nacua`, Position : 'WR', Salary : 650000, SleeperID : 8824 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kekoa`, LastName: `Crawford`, Position : 'WR', Salary : 650000, SleeperID : 8825 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scott`, LastName: `Patchan`, Position : 'DE', Salary : 650000, SleeperID : 8826 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Seltzner`, Position : 'OL', Salary : 650000, SleeperID : 8827 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Young`, Position : 'WR', Salary : 650000, SleeperID : 8828 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cullen`, LastName: `Wick`, Position : 'DE', Salary : 650000, SleeperID : 8829 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Pola-Mao`, Position : 'DB', Salary : 650000, SleeperID : 8838 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Masterson`, Position : 'LB', Salary : 650000, SleeperID : 8839 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wesley`, LastName: `French`, Position : 'OL', Salary : 650000, SleeperID : 8830 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ethan`, LastName: `Fernea`, Position : 'WR', Salary : 650000, SleeperID : 8831 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `McKinley`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 8832 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dallis`, LastName: `Flowers`, Position : 'CB', Salary : 650000, SleeperID : 8833 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Denbow`, Position : 'DB', Salary : 650000, SleeperID : 8834 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Skalski`, Position : 'LB', Salary : 650000, SleeperID : 8835 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Forrest`, LastName: `Rhyne`, Position : 'LB', Salary : 650000, SleeperID : 8836 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcel`, LastName: `Dabo`, Position : 'DB', Salary : 650000, SleeperID : 8837 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Shaan`, LastName: `Dixon`, Position : 'LB', Salary : 650000, SleeperID : 8641 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Mahone`, Position : 'DB', Salary : 650000, SleeperID : 8640 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grant`, LastName: `Morgan`, Position : 'LB', Salary : 650000, SleeperID : 8642 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Tangelo`, Position : 'DT', Salary : 650000, SleeperID : 8653 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Carter`, Position : 'DL', Salary : 650000, SleeperID : 10888 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Paris`, LastName: `Johnson`, Position : 'OL', Salary : 650000, SleeperID : 10890 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyree`, LastName: `Wilson`, Position : 'LB', Salary : 650000, SleeperID : 10889 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devon`, LastName: `Witherspoon`, Position : 'DB', Salary : 650000, SleeperID : 10891 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Anderson`, Position : 'LB', Salary : 650000, SleeperID : 10892 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Juice`, LastName: `Scruggs`, Position : 'OL', Salary : 650000, SleeperID : 10893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Turner`, Position : 'DB', Salary : 650000, SleeperID : 10894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `O'Cyrus`, LastName: `Torrence`, Position : 'OL', Salary : 650000, SleeperID : 10895 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John Michael`, LastName: `Schmitz`, Position : 'OL', Salary : 650000, SleeperID : 10896 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrique`, LastName: `Stevenson`, Position : 'CB', Salary : 650000, SleeperID : 10897 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tuli`, LastName: `Tuipulotu`, Position : 'DL', Salary : 650000, SleeperID : 10898 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 10900 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gervon`, LastName: `Dexter`, Position : 'DL', Salary : 650000, SleeperID : 10899 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Mauch`, Position : 'T', Salary : 650000, SleeperID : 10902 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keeanu`, LastName: `Benton`, Position : 'NT', Salary : 650000, SleeperID : 10901 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quan`, LastName: `Martin`, Position : 'DB', Salary : 650000, SleeperID : 10903 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keion`, LastName: `White`, Position : 'DL', Salary : 650000, SleeperID : 10904 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JuJu`, LastName: `Brents`, Position : 'DB', Salary : 650000, SleeperID : 10906 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Branch`, Position : 'DB', Salary : 650000, SleeperID : 10905 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derick`, LastName: `Hall`, Position : 'DE', Salary : 650000, SleeperID : 10911 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Bergeron`, Position : 'OL', Salary : 650000, SleeperID : 10910 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Tippmann`, Position : 'OL', Salary : 650000, SleeperID : 10907 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `BJ`, LastName: `Ojulari`, Position : 'DE', Salary : 650000, SleeperID : 10908 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steve`, LastName: `Avila`, Position : 'C', Salary : 650000, SleeperID : 10912 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Foskey`, Position : 'DL', Salary : 650000, SleeperID : 10909 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sydney`, LastName: `Brown`, Position : 'DB', Salary : 650000, SleeperID : 10919 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricky`, LastName: `Stromberg`, Position : 'OL', Salary : 650000, SleeperID : 10915 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mekhi`, LastName: `Blackmon`, Position : 'CB', Salary : 650000, SleeperID : 10918 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kobie`, LastName: `Turner`, Position : 'DT', Salary : 650000, SleeperID : 10916 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daiyan`, LastName: `Henley`, Position : 'LB', Salary : 650000, SleeperID : 10914 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yaya`, LastName: `Diaby`, Position : 'DL', Salary : 650000, SleeperID : 10921 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Steen`, Position : 'OL', Salary : 650000, SleeperID : 10920 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Byron`, LastName: `Young`, Position : 'LB', Salary : 650000, SleeperID : 10917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dorian`, LastName: `Williams`, Position : 'LB', Salary : 650000, SleeperID : 10923 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zacch`, LastName: `Pickens`, Position : 'DL', Salary : 650000, SleeperID : 10924 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marte`, LastName: `Mapu`, Position : 'DB', Salary : 650000, SleeperID : 10926 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joey`, LastName: `Porter`, Position : 'DB', Salary : 650000, SleeperID : 10913 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Byron`, LastName: `Young`, Position : 'DL', Salary : 650000, SleeperID : 10925 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Battle`, Position : 'DB', Salary : 650000, SleeperID : 10927 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brodric`, LastName: `Martin`, Position : 'DT', Salary : 650000, SleeperID : 10928 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drew`, LastName: `Sanders`, Position : 'LB', Salary : 650000, SleeperID : 10929 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Moss`, Position : 'DB', Salary : 650000, SleeperID : 10930 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Siaki`, LastName: `Ika`, Position : 'DL', Salary : 650000, SleeperID : 10931 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 10932 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeMarvion`, LastName: `Overshown`, Position : 'LB', Salary : 650000, SleeperID : 10933 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Harrison`, Position : 'DE', Salary : 650000, SleeperID : 10934 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trenton`, LastName: `Simpson`, Position : 'LB', Salary : 650000, SleeperID : 10935 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ji'Ayir`, LastName: `Brown`, Position : 'DB', Salary : 650000, SleeperID : 10936 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Moody`, Position : 'K', Salary : 650000, SleeperID : 10937 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wanya`, LastName: `Morris`, Position : 'OL', Salary : 650000, SleeperID : 10922 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 10938 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jay`, LastName: `Ward`, Position : 'DB', Salary : 650000, SleeperID : 10939 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Herbig`, Position : 'LB', Salary : 650000, SleeperID : 10940 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Lacy`, Position : 'DE', Salary : 650000, SleeperID : 10941 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Viliami`, LastName: `Fehoko`, Position : 'DL', Salary : 650000, SleeperID : 10942 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `McGuire`, Position : 'DL', Salary : 650000, SleeperID : 10943 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tavius`, LastName: `Robinson`, Position : 'DE', Salary : 650000, SleeperID : 10944 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Young`, Position : 'DT', Salary : 650000, SleeperID : 10945 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jon`, LastName: `Gaines`, Position : 'OL', Salary : 650000, SleeperID : 10946 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ventrell`, LastName: `Miller`, Position : 'LB', Salary : 650000, SleeperID : 10947 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carter`, LastName: `Warren`, Position : 'OL', Salary : 650000, SleeperID : 10948 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chamarri`, LastName: `Conner`, Position : 'DB', Salary : 650000, SleeperID : 10949 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braeden`, LastName: `Daniels`, Position : 'OL', Salary : 650000, SleeperID : 10950 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sidy`, LastName: `Sow`, Position : 'OL', Salary : 650000, SleeperID : 10951 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colby`, LastName: `Wooden`, Position : 'DE', Salary : 650000, SleeperID : 10952 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chandler`, LastName: `Zavala`, Position : 'G', Salary : 650000, SleeperID : 10953 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clark`, LastName: `Phillips`, Position : 'CB', Salary : 650000, SleeperID : 10954 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Ryland`, Position : 'K', Salary : 650000, SleeperID : 10955 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dawand`, LastName: `Jones`, Position : 'OL', Salary : 650000, SleeperID : 10956 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adetomiwa`, LastName: `Adebawore`, Position : 'DL', Salary : 650000, SleeperID : 10957 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Horton`, Position : 'DL', Salary : 650000, SleeperID : 10958 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Bradford`, Position : 'OL', Salary : 650000, SleeperID : 10959 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Andrews`, Position : 'OL', Salary : 650000, SleeperID : 10960 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Freeland`, Position : 'OL', Salary : 650000, SleeperID : 10961 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kelee`, LastName: `Ringo`, Position : 'DB', Salary : 650000, SleeperID : 10962 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jakorian`, LastName: `Bennett`, Position : 'DB', Salary : 650000, SleeperID : 10963 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Saldiveri`, Position : 'OL', Salary : 650000, SleeperID : 10964 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Warren`, LastName: `McClendon`, Position : 'OL', Salary : 650000, SleeperID : 10965 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Beal`, Position : 'LB', Salary : 650000, SleeperID : 10966 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 10967 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Asim`, LastName: `Richards`, Position : 'OL', Salary : 650000, SleeperID : 10968 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henry`, LastName: `To'oTo'o`, Position : 'LB', Salary : 650000, SleeperID : 10970 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Owen`, LastName: `Pappoe`, Position : 'LB', Salary : 650000, SleeperID : 10969 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `BJ`, LastName: `Thompson`, Position : 'DE', Salary : 650000, SleeperID : 10971 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terell`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 10972 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Hampton`, Position : 'LB', Salary : 650000, SleeperID : 10973 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 10974 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Scott`, Position : 'DB', Salary : 650000, SleeperID : 10975 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyu Blu`, LastName: `Kelly`, Position : 'CB', Salary : 650000, SleeperID : 10976 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `McFadden`, Position : 'OL', Salary : 650000, SleeperID : 10977 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Luter`, Position : 'CB', Salary : 650000, SleeperID : 10978 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Olu`, LastName: `Oluwatimi`, Position : 'C', Salary : 650000, SleeperID : 10979 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `SirVocea`, LastName: `Dennis`, Position : 'LB', Salary : 650000, SleeperID : 10980 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colby`, LastName: `Sorsdal`, Position : 'OL', Salary : 650000, SleeperID : 10981 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Morris`, Position : 'DE', Salary : 650000, SleeperID : 10982 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Clifford`, Position : 'QB', Salary : 650000, SleeperID : 10983 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Sewell`, Position : 'LB', Salary : 650000, SleeperID : 10984 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Howden`, Position : 'DB', Salary : 650000, SleeperID : 10985 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jammie`, LastName: `Robinson`, Position : 'DB', Salary : 650000, SleeperID : 10986 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Atonio`, LastName: `Mafi`, Position : 'OL', Salary : 650000, SleeperID : 10987 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Mitchell`, Position : 'DB', Salary : 650000, SleeperID : 10988 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaquelin`, LastName: `Roy`, Position : 'DT', Salary : 650000, SleeperID : 10989 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Rush`, Position : 'DB', Salary : 650000, SleeperID : 10990 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KJ`, LastName: `Henry`, Position : 'DE', Salary : 650000, SleeperID : 10991 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yasir`, LastName: `Abdullah`, Position : 'LB', Salary : 650000, SleeperID : 10992 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Hawkins`, Position : 'CB', Salary : 650000, SleeperID : 10993 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scott`, LastName: `Matlock`, Position : 'DT', Salary : 650000, SleeperID : 10994 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Tomlinson`, Position : 'CB', Salary : 650000, SleeperID : 10995 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ochaun`, LastName: `Mathis`, Position : 'DE', Salary : 650000, SleeperID : 10996 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerrick`, LastName: `Reed`, Position : 'DB', Salary : 650000, SleeperID : 10997 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jose`, LastName: `Ramirez`, Position : 'DL', Salary : 650000, SleeperID : 10998 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Hayes`, Position : 'CB', Salary : 650000, SleeperID : 10999 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zaire`, LastName: `Barnes`, Position : 'LB', Salary : 650000, SleeperID : 11000 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrick`, LastName: `Bernard-Converse`, Position : 'CB', Salary : 650000, SleeperID : 11001 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keondre`, LastName: `Coburn`, Position : 'DT', Salary : 650000, SleeperID : 11002 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amari`, LastName: `Burney`, Position : 'LB', Salary : 650000, SleeperID : 11003 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Titus`, LastName: `Leo`, Position : 'LB', Salary : 650000, SleeperID : 11004 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrett`, LastName: `Patterson`, Position : 'OL', Salary : 650000, SleeperID : 11005 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ameer`, LastName: `Speed`, Position : 'CB', Salary : 650000, SleeperID : 11006 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Baringer`, Position : 'P', Salary : 650000, SleeperID : 11007 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anders`, LastName: `Carlson`, Position : 'K', Salary : 650000, SleeperID : 11008 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Karl`, LastName: `Brooks`, Position : 'DT', Salary : 650000, SleeperID : 11009 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brad`, LastName: `Robbins`, Position : 'P', Salary : 650000, SleeperID : 11010 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JL`, LastName: `Skinner`, Position : 'DB', Salary : 650000, SleeperID : 11011 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaelyn`, LastName: `Duncan`, Position : 'OL', Salary : 650000, SleeperID : 11012 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Wypler`, Position : 'OL', Salary : 650000, SleeperID : 11013 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kei'Trel`, LastName: `Clark`, Position : 'DB', Salary : 650000, SleeperID : 11014 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Stills`, Position : 'DT', Salary : 650000, SleeperID : 11015 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malaesala`, LastName: `Aumavae-Laulu`, Position : 'OL', Salary : 650000, SleeperID : 11016 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dee`, LastName: `Winters`, Position : 'LB', Salary : 650000, SleeperID : 11017 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Braswell`, Position : 'DB', Salary : 650000, SleeperID : 11018 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erick`, LastName: `Hallett`, Position : 'DB', Salary : 650000, SleeperID : 11019 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Scott`, Position : 'DB', Salary : 650000, SleeperID : 11020 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Desjuan`, LastName: `Johnson`, Position : 'DT', Salary : 650000, SleeperID : 11021 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Williamson`, Position : 'DB', Salary : 650000, SleeperID : 11022 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Forsyth`, Position : 'OL', Salary : 650000, SleeperID : 11023 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grant`, LastName: `DuBose`, Position : 'WR', Salary : 650000, SleeperID : 11024 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Graham`, Position : 'DB', Salary : 650000, SleeperID : 11025 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gervarrius`, LastName: `Owens`, Position : 'DB', Salary : 650000, SleeperID : 11026 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Austin`, Position : 'DB', Salary : 650000, SleeperID : 11027 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Anderson`, Position : 'OL', Salary : 650000, SleeperID : 11028 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nic`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 11029 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Moro`, LastName: `Ojomo`, Position : 'DE', Salary : 650000, SleeperID : 11030 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Hill`, Position : 'DB', Salary : 650000, SleeperID : 11031 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Ivey`, Position : 'CB', Salary : 650000, SleeperID : 11032 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Bolden`, Position : 'CB', Salary : 650000, SleeperID : 11033 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Brooks`, Position : 'WR', Salary : 650000, SleeperID : 11034 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordon`, LastName: `Riley`, Position : 'DL', Salary : 650000, SleeperID : 11035 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 11036 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cory`, LastName: `Trice`, Position : 'CB', Salary : 650000, SleeperID : 11037 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Parish`, Position : 'DL', Salary : 650000, SleeperID : 11038 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Hayes`, Position : 'OL', Salary : 650000, SleeperID : 11039 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Taylor`, Position : 'DB', Salary : 650000, SleeperID : 11040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Jones`, Position : 'DE', Salary : 650000, SleeperID : 11041 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carrington`, LastName: `Valentine`, Position : 'DB', Salary : 650000, SleeperID : 11042 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nesta Jade`, LastName: `Silvera`, Position : 'DT', Salary : 650000, SleeperID : 11043 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Broeker`, Position : 'OL', Salary : 650000, SleeperID : 11044 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Vorhees`, Position : 'OL', Salary : 650000, SleeperID : 11045 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colton`, LastName: `Dowell`, Position : 'WR', Salary : 650000, SleeperID : 11046 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raymond`, LastName: `Vohasek`, Position : 'DT', Salary : 650000, SleeperID : 11047 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cooper`, LastName: `Hodges`, Position : 'OL', Salary : 650000, SleeperID : 11048 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jovaughn`, LastName: `Gwyn`, Position : 'OL', Salary : 650000, SleeperID : 11049 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeMarcco`, LastName: `Hellams`, Position : 'DB', Salary : 650000, SleeperID : 11050 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ethan`, LastName: `Evans`, Position : 'P', Salary : 650000, SleeperID : 11051 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Jones`, Position : 'DB', Salary : 650000, SleeperID : 11052 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antoine`, LastName: `Green`, Position : 'WR', Salary : 650000, SleeperID : 11053 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Bell`, Position : 'DL', Salary : 650000, SleeperID : 11054 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Witt`, Position : 'OL', Salary : 650000, SleeperID : 11055 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Wayne`, Position : 'WR', Salary : 650000, SleeperID : 11056 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Grupe`, Position : 'K', Salary : 650000, SleeperID : 11058 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aubrey`, LastName: `Miller`, Position : 'LB', Salary : 650000, SleeperID : 11059 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Burns`, Position : 'RB', Salary : 650000, SleeperID : 11060 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emil`, LastName: `Ekiyor`, Position : 'OG', Salary : 650000, SleeperID : 11061 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ali`, LastName: `Gaye`, Position : 'DE', Salary : 650000, SleeperID : 11062 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Connor`, LastName: `Galvin`, Position : 'T', Salary : 650000, SleeperID : 11063 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Darkangelo`, Position : 'LB', Salary : 650000, SleeperID : 11064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Martinez`, Position : 'QB', Salary : 650000, SleeperID : 11065 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Ajiake`, Position : 'LB', Salary : 650000, SleeperID : 11066 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alan`, LastName: `Ali`, Position : 'OL', Salary : 650000, SleeperID : 11067 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `Tinsley`, Position : 'WR', Salary : 650000, SleeperID : 11068 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kazmeir`, LastName: `Allen`, Position : 'WR', Salary : 650000, SleeperID : 11070 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Henderson`, Position : 'DB', Salary : 650000, SleeperID : 11069 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Brooks`, Position : 'OL', Salary : 650000, SleeperID : 11071 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thayer`, LastName: `Thomas`, Position : 'WR', Salary : 650000, SleeperID : 11073 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Abraham`, LastName: `Beauplan`, Position : 'LB', Salary : 650000, SleeperID : 11074 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacky`, LastName: `Chen`, Position : 'OL', Salary : 650000, SleeperID : 11075 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Pryor`, Position : 'DL', Salary : 650000, SleeperID : 11072 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `NaJee`, LastName: `Thompson`, Position : 'CB', Salary : 650000, SleeperID : 11076 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Podlesny`, Position : 'K', Salary : 650000, SleeperID : 11077 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Avery`, Position : 'DT', Salary : 650000, SleeperID : 11078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wilson`, LastName: `Huber`, Position : 'LB', Salary : 650000, SleeperID : 11079 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Knowles`, Position : 'WR', Salary : 650000, SleeperID : 11080 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylin`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 11085 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cephus`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 11081 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Sims`, Position : 'TE', Salary : 650000, SleeperID : 11082 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Coldon`, Position : 'CB', Salary : 650000, SleeperID : 11083 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Carter`, Position : 'LB', Salary : 650000, SleeperID : 11084 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ivan`, LastName: `Pace`, Position : 'LB', Salary : 650000, SleeperID : 11086 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Downing`, Position : 'C', Salary : 650000, SleeperID : 11087 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Byrd`, Position : 'WR', Salary : 650000, SleeperID : 11089 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Nyamwaya`, Position : 'DE', Salary : 650000, SleeperID : 11091 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `B.T.`, LastName: `Potter`, Position : 'K', Salary : 650000, SleeperID : 11092 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Perales`, Position : 'LB', Salary : 650000, SleeperID : 11093 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Monte`, LastName: `Pottebaum`, Position : 'FB', Salary : 650000, SleeperID : 11088 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Rush`, Position : 'LB', Salary : 650000, SleeperID : 11094 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shakel`, LastName: `Brown`, Position : 'DT', Salary : 650000, SleeperID : 11095 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `TK`, LastName: `McLendon`, Position : 'DL', Salary : 650000, SleeperID : 11096 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Ojukwu`, Position : 'OT', Salary : 650000, SleeperID : 11097 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Murphy`, Position : 'DE', Salary : 650000, SleeperID : 11098 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Dotson`, Position : 'RB', Salary : 650000, SleeperID : 11114 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrell`, LastName: `Bynum`, Position : 'WR', Salary : 650000, SleeperID : 11115 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kearis`, LastName: `Jackson`, Position : 'WR', Salary : 650000, SleeperID : 11099 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `East`, Position : 'LB', Salary : 650000, SleeperID : 11116 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 11100 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maxwell`, LastName: `Worship`, Position : 'LB', Salary : 650000, SleeperID : 11101 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `McClelland`, Position : 'RB', Salary : 650000, SleeperID : 11102 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Pihlstrom`, Position : 'OL', Salary : 650000, SleeperID : 11103 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `SaRodorick`, LastName: `Thompson`, Position : 'RB', Salary : 650000, SleeperID : 11104 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaquan`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 11105 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Anderson`, Position : 'LB', Salary : 650000, SleeperID : 11106 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lou`, LastName: `Hedley`, Position : 'P', Salary : 650000, SleeperID : 11107 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Evans`, Position : 'OL', Salary : 650000, SleeperID : 11109 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sy`, LastName: `Barnett`, Position : 'WR', Salary : 650000, SleeperID : 11111 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 11112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joel`, LastName: `Wilson`, Position : 'TE', Salary : 650000, SleeperID : 11113 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anfernee`, LastName: `Orji`, Position : 'LB', Salary : 650000, SleeperID : 11108 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johari`, LastName: `Branch`, Position : 'C', Salary : 650000, SleeperID : 11117 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brevin`, LastName: `Allen`, Position : 'LB', Salary : 650000, SleeperID : 11118 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrance`, LastName: `Lang`, Position : 'DL', Salary : 650000, SleeperID : 11124 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Ezeike`, Position : 'TE', Salary : 650000, SleeperID : 11126 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerrod`, LastName: `Clark`, Position : 'DL', Salary : 650000, SleeperID : 11119 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Baker-Williams`, Position : 'DB', Salary : 650000, SleeperID : 11120 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `AJ`, LastName: `Finley`, Position : 'DB', Salary : 650000, SleeperID : 11121 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Farmer`, Position : 'LB', Salary : 650000, SleeperID : 11122 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Hoosman`, Position : 'RB', Salary : 0, SleeperID : 11123 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tiawan`, LastName: `Mullen`, Position : 'CB', Salary : 650000, SleeperID : 11125 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amechi`, LastName: `Uzodinma`, Position : 'CB', Salary : 650000, SleeperID : 11127 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mikel`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 11128 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nic`, LastName: `Melsop`, Position : 'T', Salary : 650000, SleeperID : 11129 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pokey`, LastName: `Wilson`, Position : 'WR', Salary : 650000, SleeperID : 11130 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Divaad`, LastName: `Wilson`, Position : 'CB', Salary : 650000, SleeperID : 11132 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaray`, LastName: `Jenkins`, Position : 'WR', Salary : 650000, SleeperID : 11133 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaleb`, LastName: `Hayes`, Position : 'CB', Salary : 650000, SleeperID : 11134 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Coleman`, Position : 'LB', Salary : 650000, SleeperID : 11135 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Oliver`, LastName: `Martin`, Position : 'WR', Salary : 650000, SleeperID : 11136 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dequan`, LastName: `Jackson`, Position : 'LB', Salary : 650000, SleeperID : 11137 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Samuel`, LastName: `Jackson`, Position : 'OL', Salary : 650000, SleeperID : 11138 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Cooks`, Position : 'WR', Salary : 650000, SleeperID : 11139 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leonard`, LastName: `Taylor`, Position : 'TE', Salary : 650000, SleeperID : 11140 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayson`, LastName: `Ademilola`, Position : 'DT', Salary : 650000, SleeperID : 11141 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Timarcus`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 11142 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kelechi`, LastName: `Anyalebechi`, Position : 'LB', Salary : 650000, SleeperID : 11143 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Collin`, LastName: `Duncan`, Position : 'DB', Salary : 650000, SleeperID : 11144 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Brown`, Position : 'K', Salary : 650000, SleeperID : 11145 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christopher`, LastName: `Dunn`, Position : 'K', Salary : 650000, SleeperID : 11146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyon`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 11147 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Maginn`, Position : 'C', Salary : 650000, SleeperID : 11149 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quindell`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 11150 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Ingle`, Position : 'DB', Salary : 650000, SleeperID : 11151 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `McAllister`, Position : 'C', Salary : 650000, SleeperID : 11152 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 11153 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Jester`, Position : 'LB', Salary : 650000, SleeperID : 11154 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Sims`, Position : 'TE', Salary : 650000, SleeperID : 11155 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `McCutcheon`, Position : 'CB', Salary : 650000, SleeperID : 11156 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brycen`, LastName: `Tremayne`, Position : 'WR', Salary : 650000, SleeperID : 11157 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Sample`, Position : 'WR', Salary : 650000, SleeperID : 11158 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zion`, LastName: `Bowens`, Position : 'WR', Salary : 650000, SleeperID : 11159 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Whiteside`, Position : 'CB', Salary : 650000, SleeperID : 11160 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 11161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Hudson`, Position : 'WR', Salary : 650000, SleeperID : 11148 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashad`, LastName: `Torrence`, Position : 'DB', Salary : 650000, SleeperID : 11164 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `Stirgus`, Position : 'CB', Salary : 650000, SleeperID : 11162 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tim`, LastName: `DeMorat`, Position : 'QB', Salary : 650000, SleeperID : 11163 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Smenda`, Position : 'LB', Salary : 650000, SleeperID : 11165 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaiden`, LastName: `Woodbey`, Position : 'LB', Salary : 650000, SleeperID : 11166 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Ward`, Position : 'LS', Salary : 650000, SleeperID : 11167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Smith`, Position : 'WR', Salary : 650000, SleeperID : 11168 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dresser`, LastName: `Winn`, Position : 'QB', Salary : 650000, SleeperID : 11169 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAndre`, LastName: `Square`, Position : 'LB', Salary : 650000, SleeperID : 11170 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shae`, LastName: `Wyatt`, Position : 'WR', Salary : 650000, SleeperID : 11172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Shawn`, LastName: `Jamison`, Position : 'CB', Salary : 650000, SleeperID : 11173 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Colletto`, Position : 'FB', Salary : 650000, SleeperID : 11174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ilm`, LastName: `Manning`, Position : 'OL', Salary : 650000, SleeperID : 11175 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalan`, LastName: `Laborn`, Position : 'RB', Salary : 650000, SleeperID : 11176 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avery`, LastName: `Young`, Position : 'DB', Salary : 650000, SleeperID : 11177 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mariano`, LastName: `Sori-Marin`, Position : 'LB', Salary : 650000, SleeperID : 11178 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joey`, LastName: `Fisher`, Position : 'OL', Salary : 650000, SleeperID : 11179 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Luciano`, Position : 'OL', Salary : 650000, SleeperID : 11180 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Waege`, Position : 'DL', Salary : 650000, SleeperID : 11181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronald`, LastName: `Awatt`, Position : 'RB', Salary : 650000, SleeperID : 11182 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Arias`, Position : 'WR', Salary : 650000, SleeperID : 11184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendell`, LastName: `Brooks`, Position : 'DB', Salary : 650000, SleeperID : 11185 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlos`, LastName: `Washington`, Position : 'RB', Salary : 650000, SleeperID : 11186 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 11187 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ikenna`, LastName: `Enechukwu`, Position : 'DL', Salary : 650000, SleeperID : 11188 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zay`, LastName: `Malone`, Position : 'WR', Salary : 650000, SleeperID : 11189 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keilahn`, LastName: `Harris`, Position : 'WR', Salary : 650000, SleeperID : 11190 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Marshall`, Position : 'WR', Salary : 650000, SleeperID : 11191 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Flowers`, Position : 'WR', Salary : 650000, SleeperID : 11192 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brenton`, LastName: `Cox`, Position : 'LB', Salary : 650000, SleeperID : 11193 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keshawn`, LastName: `Banks`, Position : 'LB', Salary : 650000, SleeperID : 11194 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Whiteheart`, Position : 'TE', Salary : 650000, SleeperID : 11201 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Soelle`, Position : 'LB', Salary : 650000, SleeperID : 11196 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joel`, LastName: `Honigford`, Position : 'TE', Salary : 650000, SleeperID : 11197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quavian`, LastName: `White`, Position : 'CB', Salary : 650000, SleeperID : 11198 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emari`, LastName: `Demercado`, Position : 'RB', Salary : 650000, SleeperID : 11199 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marvin`, LastName: `Pierre`, Position : 'LB', Salary : 650000, SleeperID : 11200 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Slade`, Position : 'DL', Salary : 650000, SleeperID : 11202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Hembrough`, Position : 'LS', Salary : 650000, SleeperID : 11203 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duece`, LastName: `Watts`, Position : 'WR', Salary : 650000, SleeperID : 11204 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Morgan`, Position : 'DB', Salary : 650000, SleeperID : 11205 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benny`, LastName: `Sapp`, Position : 'DB', Salary : 650000, SleeperID : 11206 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Lewan`, Position : 'DL', Salary : 650000, SleeperID : 11207 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chuck`, LastName: `Filiaga`, Position : 'G', Salary : 650000, SleeperID : 11208 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henry`, LastName: `Pearson`, Position : 'FB', Salary : 650000, SleeperID : 11209 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Phillips`, Position : 'LB', Salary : 650000, SleeperID : 11212 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Camren`, LastName: `McDonald`, Position : 'TE', Salary : 650000, SleeperID : 11213 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Morgan`, Position : 'QB', Salary : 650000, SleeperID : 11214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `MJ`, LastName: `Anderson`, Position : 'DE', Salary : 650000, SleeperID : 11215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Holton`, LastName: `Ahlers`, Position : 'QB', Salary : 650000, SleeperID : 11216 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Ayers`, Position : 'LB', Salary : 650000, SleeperID : 11217 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Bright`, Position : 'LB', Salary : 650000, SleeperID : 11218 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lance`, LastName: `Boykin`, Position : 'CB', Salary : 650000, SleeperID : 11219 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arquon`, LastName: `Bush`, Position : 'CB', Salary : 650000, SleeperID : 11220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Campbell`, Position : 'CB', Salary : 650000, SleeperID : 11221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Heath`, Position : 'WR', Salary : 650000, SleeperID : 11210 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kadeem`, LastName: `Telfort`, Position : 'T', Salary : 650000, SleeperID : 11211 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Cooper`, Position : 'RB', Salary : 650000, SleeperID : 11222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Hall`, Position : 'WR', Salary : 650000, SleeperID : 11223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Griffin`, LastName: `Hebert`, Position : 'TE', Salary : 650000, SleeperID : 11224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Gindorff`, Position : 'TE', Salary : 650000, SleeperID : 11225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyjon`, LastName: `Lindsey`, Position : 'WR', Salary : 650000, SleeperID : 11226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ifeanyi`, LastName: `Maijeh`, Position : 'DL', Salary : 650000, SleeperID : 11227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Ferguson`, Position : 'DE', Salary : 650000, SleeperID : 11228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calvin`, LastName: `Tyler`, Position : 'RB', Salary : 650000, SleeperID : 11229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `O'Connell`, Position : 'LB', Salary : 650000, SleeperID : 11230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Trahan`, Position : 'TE', Salary : 650000, SleeperID : 11231 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Winstead`, Position : 'WR', Salary : 0, SleeperID : 11232 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Okada`, Position : 'DB', Salary : 650000, SleeperID : 11233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Randolph`, Position : 'OL', Salary : 650000, SleeperID : 11234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Morrell`, LastName: `Osling`, Position : 'DB', Salary : 650000, SleeperID : 11235 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devonnsha`, LastName: `Maxwell`, Position : 'DL', Salary : 650000, SleeperID : 11236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Saylors`, Position : 'RB', Salary : 650000, SleeperID : 11237 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaxson`, LastName: `Kirkland`, Position : 'G', Salary : 650000, SleeperID : 11238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `CJ`, LastName: `Okoye`, Position : 'DL', Salary : 650000, SleeperID : 11240 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Junior`, LastName: `Aho`, Position : 'DL', Salary : 650000, SleeperID : 11241 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Moody`, Position : 'LB', Salary : 650000, SleeperID : 11239 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Godrick`, Position : 'OL', Salary : 650000, SleeperID : 11242 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Agoha`, Position : 'DL', Salary : 650000, SleeperID : 11243 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenneth`, LastName: `Odumegwu`, Position : 'DL', Salary : 650000, SleeperID : 11244 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Murtagh`, Position : 'TE', Salary : 650000, SleeperID : 11245 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Larry`, LastName: `Brooks`, Position : 'DB', Salary : 650000, SleeperID : 11246 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mac`, LastName: `Hippenhammer`, Position : 'WR', Salary : 650000, SleeperID : 11247 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Stoll`, Position : 'LS', Salary : 650000, SleeperID : 11248 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Smith`, Position : 'RB', Salary : 650000, SleeperID : 11249 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shaka`, LastName: `Heyward`, Position : 'LB', Salary : 650000, SleeperID : 11251 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Young`, Position : 'DB', Salary : 650000, SleeperID : 11252 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonah`, LastName: `Tavai`, Position : 'DL', Salary : 650000, SleeperID : 11253 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Baskerville`, Position : 'LB', Salary : 650000, SleeperID : 11254 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Amoah`, Position : 'OL', Salary : 650000, SleeperID : 11255 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyson`, LastName: `Bagent`, Position : 'QB', Salary : 650000, SleeperID : 11256 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shedrick`, LastName: `Jackson`, Position : 'WR', Salary : 650000, SleeperID : 11257 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Harris`, Position : 'DL', Salary : 650000, SleeperID : 11258 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabriel`, LastName: `Houy`, Position : 'OL', Salary : 650000, SleeperID : 11259 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Burns`, Position : 'FB', Salary : 650000, SleeperID : 11260 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Sutherland`, Position : 'DB', Salary : 650000, SleeperID : 11250 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Szmyt`, Position : 'K', Salary : 650000, SleeperID : 11261 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Damien`, LastName: `Caffrey`, Position : 'TE', Salary : 650000, SleeperID : 11262 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lorenz`, LastName: `Metz`, Position : 'OL', Salary : 650000, SleeperID : 11263 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Macon`, LastName: `Clark`, Position : 'DB', Salary : 650000, SleeperID : 11264 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thyrick`, LastName: `Pitts`, Position : 'WR', Salary : 650000, SleeperID : 11266 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aron`, LastName: `Cruickshank`, Position : 'WR', Salary : 650000, SleeperID : 11267 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Haskins`, Position : 'OL', Salary : 650000, SleeperID : 11265 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hassan`, LastName: `Hall`, Position : 'RB', Salary : 650000, SleeperID : 11270 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Broiles`, Position : 'DB', Salary : 650000, SleeperID : 11268 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charlie`, LastName: `Thomas`, Position : 'LB', Salary : 650000, SleeperID : 11272 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Biggers`, Position : 'CB', Salary : 650000, SleeperID : 11269 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Martin`, Position : 'DL', Salary : 650000, SleeperID : 11271 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Reid`, Position : 'OT', Salary : 650000, SleeperID : 11274 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mekhi`, LastName: `Garner`, Position : 'CB', Salary : 650000, SleeperID : 11275 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joseph`, LastName: `Ngata`, Position : 'WR', Salary : 650000, SleeperID : 11276 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Zentner`, Position : 'P', Salary : 650000, SleeperID : 11277 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `VanSumeren`, Position : 'LB', Salary : 650000, SleeperID : 11273 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Liam`, LastName: `Anderson`, Position : 'LB', Salary : 650000, SleeperID : 11281 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `King`, Position : 'WR', Salary : 650000, SleeperID : 11282 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Chrest`, Position : 'WR', Salary : 650000, SleeperID : 11284 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Coleman`, Position : 'CB', Salary : 650000, SleeperID : 11285 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harris`, LastName: `LaChance`, Position : 'OG', Salary : 650000, SleeperID : 11286 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Richardson`, Position : 'CB', Salary : 650000, SleeperID : 11287 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donavan`, LastName: `Mutin`, Position : 'LB', Salary : 650000, SleeperID : 11288 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Maddox`, Position : 'DB', Salary : 650000, SleeperID : 11289 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Cook`, Position : 'DB', Salary : 650000, SleeperID : 11290 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Hagans`, Position : 'RB', Salary : 650000, SleeperID : 11283 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eku`, LastName: `Leota`, Position : 'LB', Salary : 650000, SleeperID : 11336 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Swoboda`, Position : 'OL', Salary : 650000, SleeperID : 11337 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cory`, LastName: `Durden`, Position : 'DT', Salary : 650000, SleeperID : 11338 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Joseph`, Position : 'DB', Salary : 650000, SleeperID : 11339 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brad`, LastName: `Cecil`, Position : 'OL', Salary : 650000, SleeperID : 11340 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nash`, LastName: `Jensen`, Position : 'OL', Salary : 650000, SleeperID : 11341 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricky`, LastName: `Lee`, Position : 'OL', Salary : 650000, SleeperID : 11342 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Starling`, LastName: `Thomas`, Position : 'CB', Salary : 650000, SleeperID : 11343 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keytaon`, LastName: `Thompson`, Position : 'WR', Salary : 650000, SleeperID : 11344 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Smith`, Position : 'DL', Salary : 650000, SleeperID : 11345 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Morton`, Position : 'DL', Salary : 650000, SleeperID : 11346 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Cota`, Position : 'WR', Salary : 650000, SleeperID : 11347 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Nowaske`, Position : 'LB', Salary : 650000, SleeperID : 11348 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lonnie`, LastName: `Phelps`, Position : 'DE', Salary : 650000, SleeperID : 11349 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mohamoud`, LastName: `Diabate`, Position : 'LB', Salary : 650000, SleeperID : 11350 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Greaney`, Position : 'TE', Salary : 650000, SleeperID : 11351 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronnie`, LastName: `Hickman`, Position : 'DB', Salary : 650000, SleeperID : 11352 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Anthony`, LastName: `Jones`, Position : 'DL', Salary : 650000, SleeperID : 11354 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Lugg`, Position : 'OL', Salary : 650000, SleeperID : 11355 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bralen`, LastName: `Trahan`, Position : 'DB', Salary : 650000, SleeperID : 11356 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Adams`, Position : 'WR', Salary : 650000, SleeperID : 11357 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `McCalister`, Position : 'DB', Salary : 650000, SleeperID : 11353 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Woods`, Position : 'DL', Salary : 650000, SleeperID : 11358 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Broughton`, LastName: `Hatcher`, Position : 'LS', Salary : 650000, SleeperID : 11361 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kody`, LastName: `Case`, Position : 'WR', Salary : 650000, SleeperID : 11359 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Vanderslice`, Position : 'OL', Salary : 650000, SleeperID : 11360 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Hooper`, Position : 'CB', Salary : 650000, SleeperID : 11363 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyreque`, LastName: `Jones`, Position : 'DB', Salary : 650000, SleeperID : 11365 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Coleman`, Position : 'WR', Salary : 650000, SleeperID : 11366 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daewood`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 11367 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alama`, LastName: `Uluave`, Position : 'OL', Salary : 650000, SleeperID : 11368 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaquan`, LastName: `Amos`, Position : 'DB', Salary : 650000, SleeperID : 11364 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Jensen`, Position : 'OL', Salary : 650000, SleeperID : 11369 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Brooks`, Position : 'RB', Salary : 650000, SleeperID : 11370 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eli`, LastName: `Ricks`, Position : 'CB', Salary : 650000, SleeperID : 11278 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chim`, LastName: `Okorafor`, Position : 'OT', Salary : 650000, SleeperID : 11279 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brady`, LastName: `Russell`, Position : 'TE', Salary : 650000, SleeperID : 11280 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Hill`, Position : 'TE', Salary : 650000, SleeperID : 11371 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Tunstall`, Position : 'OL', Salary : 650000, SleeperID : 11372 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Scaife`, Position : 'OL', Salary : 650000, SleeperID : 11373 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Blackman`, Position : 'QB', Salary : 650000, SleeperID : 11374 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrett`, LastName: `Horst`, Position : 'OL', Salary : 650000, SleeperID : 11375 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Richard`, LastName: `Gouraige`, Position : 'OL', Salary : 650000, SleeperID : 11376 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrell`, LastName: `Shavers`, Position : 'WR', Salary : 650000, SleeperID : 11377 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Mims`, Position : 'RB', Salary : 650000, SleeperID : 11378 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Henderson`, Position : 'OL', Salary : 650000, SleeperID : 11379 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Lumpkin`, Position : 'TE', Salary : 650000, SleeperID : 11380 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Moultrie`, Position : 'DL', Salary : 650000, SleeperID : 11362 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Vokolek`, Position : 'TE', Salary : 650000, SleeperID : 11381 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontay`, LastName: `Demus`, Position : 'WR', Salary : 650000, SleeperID : 11382 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tashawn`, LastName: `Manning`, Position : 'OG', Salary : 650000, SleeperID : 11383 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Owen`, LastName: `Wright`, Position : 'RB', Salary : 650000, SleeperID : 11384 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Walker`, Position : 'TE', Salary : 650000, SleeperID : 11385 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sean`, LastName: `Ryan`, Position : 'WR', Salary : 650000, SleeperID : 11387 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nolan`, LastName: `Henderson`, Position : 'QB', Salary : 650000, SleeperID : 11388 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `TyKeem`, LastName: `Doss`, Position : 'OG', Salary : 650000, SleeperID : 11389 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Guidone`, Position : 'OG', Salary : 650000, SleeperID : 11390 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Kipper`, Position : 'OL', Salary : 650000, SleeperID : 11386 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travez`, LastName: `Moore`, Position : 'LB', Salary : 650000, SleeperID : 11332 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Luther`, Position : 'WR', Salary : 650000, SleeperID : 11312 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marquis`, LastName: `Waters`, Position : 'DB', Salary : 650000, SleeperID : 11313 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Levi`, LastName: `Bell`, Position : 'DT', Salary : 650000, SleeperID : 11314 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Witherstone`, Position : 'CB', Salary : 650000, SleeperID : 11316 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Montrae`, LastName: `Braswell`, Position : 'CB', Salary : 650000, SleeperID : 11317 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Moore`, Position : 'LB', Salary : 650000, SleeperID : 11318 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Truman`, LastName: `Jones`, Position : 'DE', Salary : 650000, SleeperID : 11319 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nikko`, LastName: `Remigio`, Position : 'WR', Salary : 650000, SleeperID : 11320 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Cook`, Position : 'DB', Salary : 650000, SleeperID : 11321 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerome`, LastName: `Carvin`, Position : 'OL', Salary : 650000, SleeperID : 11322 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Norman`, Position : 'DB', Salary : 650000, SleeperID : 11323 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anderson`, LastName: `Hardy`, Position : 'OL', Salary : 650000, SleeperID : 11324 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reese`, LastName: `Taylor`, Position : 'CB', Salary : 650000, SleeperID : 11325 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Martez`, LastName: `Manuel`, Position : 'DB', Salary : 650000, SleeperID : 11326 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Haynes`, Position : 'OL', Salary : 650000, SleeperID : 11327 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Vann`, Position : 'WR', Salary : 650000, SleeperID : 11329 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rejzohn`, LastName: `Wright`, Position : 'CB', Salary : 650000, SleeperID : 11330 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colby`, LastName: `Richardson`, Position : 'CB', Salary : 650000, SleeperID : 11331 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Milton`, Position : 'CB', Salary : 650000, SleeperID : 11333 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Jones`, Position : 'LB', Salary : 650000, SleeperID : 11315 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Redmond`, Position : 'DE', Salary : 650000, SleeperID : 11328 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ethan`, LastName: `Bonner`, Position : 'CB', Salary : 650000, SleeperID : 11391 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Montalvo`, Position : 'DT', Salary : 650000, SleeperID : 11392 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randy`, LastName: `Charlton`, Position : 'DE', Salary : 650000, SleeperID : 11393 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mitchell`, LastName: `Agude`, Position : 'LB', Salary : 650000, SleeperID : 11394 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nico`, LastName: `Bolden`, Position : 'DB', Salary : 650000, SleeperID : 11334 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keidron`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 11395 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bumper`, LastName: `Pool`, Position : 'LB', Salary : 650000, SleeperID : 11335 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jourdan`, LastName: `Heilig`, Position : 'LB', Salary : 650000, SleeperID : 11402 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaieem`, LastName: `Caesar`, Position : 'DT', Salary : 650000, SleeperID : 11403 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Lucien`, Position : 'CB', Salary : 650000, SleeperID : 11404 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Botts`, Position : 'DT', Salary : 650000, SleeperID : 11405 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Mayfield`, Position : 'CB', Salary : 650000, SleeperID : 11406 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Hamm`, Position : 'LB', Salary : 650000, SleeperID : 11407 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kelle`, LastName: `Sanders`, Position : 'LB', Salary : 650000, SleeperID : 11408 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grant`, LastName: `Miller`, Position : 'OL', Salary : 650000, SleeperID : 11409 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braxton`, LastName: `Burmeister`, Position : 'WR', Salary : 650000, SleeperID : 11410 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Sykes`, Position : 'DE', Salary : 650000, SleeperID : 11412 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ronnie`, LastName: `Brown`, Position : 'RB', Salary : 650000, SleeperID : 11413 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Raiqwon`, LastName: `O'Neal`, Position : 'OT', Salary : 650000, SleeperID : 11414 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Taula`, Position : 'TE', Salary : 650000, SleeperID : 11415 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Murray`, Position : 'OL', Salary : 650000, SleeperID : 11416 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taye`, LastName: `Barber`, Position : 'WR', Salary : 650000, SleeperID : 11418 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kade`, LastName: `Warner`, Position : 'WR', Salary : 650000, SleeperID : 11419 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Cooper`, Position : 'NT', Salary : 650000, SleeperID : 11411 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Haggard`, Position : 'OT', Salary : 650000, SleeperID : 11420 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Miller`, Position : 'WR', Salary : 650000, SleeperID : 11421 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.D.`, LastName: `DiRenzo`, Position : 'OL', Salary : 650000, SleeperID : 11422 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nelson`, LastName: `Mbanasor`, Position : 'LB', Salary : 650000, SleeperID : 11423 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrek`, LastName: `Pitts`, Position : 'CB', Salary : 650000, SleeperID : 11425 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Silas`, LastName: `Dzansi`, Position : 'OL', Salary : 650000, SleeperID : 11417 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Izien`, Position : 'DB', Salary : 650000, SleeperID : 11427 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Bouyer-Randle`, Position : 'LB', Salary : 650000, SleeperID : 11428 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaevon`, LastName: `Merriweather`, Position : 'DB', Salary : 650000, SleeperID : 11429 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keenan`, LastName: `Isaac`, Position : 'CB', Salary : 650000, SleeperID : 11424 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Bowser`, Position : 'RB', Salary : 650000, SleeperID : 11431 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Markees`, LastName: `Watts`, Position : 'DE', Salary : 650000, SleeperID : 11432 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Adkins`, Position : 'TE', Salary : 650000, SleeperID : 11433 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Deckers`, Position : 'LS', Salary : 650000, SleeperID : 11426 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clifford`, LastName: `Chattman`, Position : 'DB', Salary : 650000, SleeperID : 11482 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ellis`, LastName: `Merriweather`, Position : 'RB', Salary : 650000, SleeperID : 11483 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Banks`, Position : 'LB', Salary : 650000, SleeperID : 11430 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Haynes`, Position : 'LB', Salary : 650000, SleeperID : 11437 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dallas`, LastName: `Daniels`, Position : 'WR', Salary : 650000, SleeperID : 11438 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaleel`, LastName: `McLaughlin`, Position : 'RB', Salary : 650000, SleeperID : 11439 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Art`, LastName: `Green`, Position : 'CB', Salary : 650000, SleeperID : 11440 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Incoom`, Position : 'LB', Salary : 650000, SleeperID : 11441 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrious`, LastName: `Gaines`, Position : 'CB', Salary : 650000, SleeperID : 11442 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Palczewski`, Position : 'T', Salary : 650000, SleeperID : 11443 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Latavious`, LastName: `Brini`, Position : 'DB', Salary : 650000, SleeperID : 11473 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Drummond`, Position : 'WR', Salary : 650000, SleeperID : 11474 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `BJ`, LastName: `Wilson`, Position : 'OL', Salary : 650000, SleeperID : 11475 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Armani`, LastName: `Marsh`, Position : 'DB', Salary : 650000, SleeperID : 11476 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alonzo`, LastName: `Davis`, Position : 'CB', Salary : 650000, SleeperID : 11477 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gavin`, LastName: `Holmes`, Position : 'WR', Salary : 650000, SleeperID : 11478 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Garror`, Position : 'CB', Salary : 650000, SleeperID : 11479 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taron`, LastName: `Vincent`, Position : 'DT', Salary : 650000, SleeperID : 11480 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Natrone`, LastName: `Brooks`, Position : 'CB', Salary : 650000, SleeperID : 11481 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerron`, LastName: `Cage`, Position : 'DT', Salary : 650000, SleeperID : 11488 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Thompson`, Position : 'WR', Salary : 650000, SleeperID : 11495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antwuan`, LastName: `Jackson`, Position : 'DT', Salary : 650000, SleeperID : 11485 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demontrey`, LastName: `Jacobs`, Position : 'T', Salary : 650000, SleeperID : 11444 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Ojile`, Position : 'FB', Salary : 650000, SleeperID : 11486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Gilmore`, Position : 'CB', Salary : 650000, SleeperID : 11487 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kahlef`, LastName: `Hailassie`, Position : 'CB', Salary : 650000, SleeperID : 11489 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ian`, LastName: `Swenson`, Position : 'LB', Salary : 650000, SleeperID : 11490 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Aune`, Position : 'QB', Salary : 650000, SleeperID : 11491 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ace`, LastName: `Eley`, Position : 'LB', Salary : 650000, SleeperID : 11492 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adrian`, LastName: `Frye`, Position : 'DB', Salary : 650000, SleeperID : 11484 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Scott`, LastName: `Lashley`, Position : 'OT', Salary : 650000, SleeperID : 11499 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Thomas`, Position : 'OL', Salary : 650000, SleeperID : 11500 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Taylor`, Position : 'LB', Salary : 650000, SleeperID : 11494 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braydon`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 11496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tautala`, LastName: `Pesefea`, Position : 'DL', Salary : 650000, SleeperID : 11497 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Murray`, Position : 'LB', Salary : 650000, SleeperID : 11498 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Murray`, Position : 'TE', Salary : 650000, SleeperID : 11493 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justus`, LastName: `Tavai`, Position : 'DT', Salary : 650000, SleeperID : 11501 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Cobbs`, Position : 'WR', Salary : 650000, SleeperID : 11502 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Brown`, Position : 'CB', Salary : 650000, SleeperID : 11503 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jose`, LastName: `Barbon`, Position : 'WR', Salary : 650000, SleeperID : 11504 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Durden`, Position : 'WR', Salary : 650000, SleeperID : 11505 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Cropper`, Position : 'WR', Salary : 650000, SleeperID : 11506 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D'Angelo`, LastName: `Mandell`, Position : 'CB', Salary : 650000, SleeperID : 11507 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Princeton`, LastName: `Fant`, Position : 'TE', Salary : 650000, SleeperID : 11508 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Durrell`, LastName: `Johnson`, Position : 'DE', Salary : 650000, SleeperID : 11509 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Luepke`, Position : 'FB', Salary : 650000, SleeperID : 11510 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Stephens`, Position : 'WR', Salary : 650000, SleeperID : 11511 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Habakkuk`, LastName: `Baldonado`, Position : 'DE', Salary : 650000, SleeperID : 11291 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `DeVito`, Position : 'QB', Salary : 650000, SleeperID : 11292 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Brown`, Position : 'LB', Salary : 650000, SleeperID : 11293 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Lyons`, Position : 'LS', Salary : 650000, SleeperID : 11295 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gemon`, LastName: `Green`, Position : 'DB', Salary : 650000, SleeperID : 11296 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dyontae`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 11297 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Jones`, Position : 'TE', Salary : 650000, SleeperID : 11298 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Sampson`, Position : 'DT', Salary : 650000, SleeperID : 11294 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zavier`, LastName: `Scott`, Position : 'WR', Salary : 650000, SleeperID : 11299 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Guy`, LastName: `Thomas`, Position : 'DE', Salary : 650000, SleeperID : 11300 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braxton`, LastName: `Westfield`, Position : 'WR', Salary : 650000, SleeperID : 11301 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maalik`, LastName: `Hall`, Position : 'LB', Salary : 650000, SleeperID : 11302 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 11303 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `E.J.`, LastName: `Jenkins`, Position : 'TE', Salary : 650000, SleeperID : 11304 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Claudin`, LastName: `Cherelus`, Position : 'LB', Salary : 650000, SleeperID : 11305 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Gipson`, Position : 'WR', Salary : 650000, SleeperID : 11306 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brent`, LastName: `Laing`, Position : 'OL', Salary : 650000, SleeperID : 11307 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deslin`, LastName: `Alexandre`, Position : 'DE', Salary : 650000, SleeperID : 11308 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Dean`, Position : 'DB', Salary : 650000, SleeperID : 11309 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `Langford`, Position : 'CB', Salary : 650000, SleeperID : 11310 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Brownlee`, Position : 'WR', Salary : 650000, SleeperID : 11311 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrus`, LastName: `Wheat`, Position : 'DE', Salary : 650000, SleeperID : 11512 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Earl`, LastName: `Bostick`, Position : 'OL', Salary : 650000, SleeperID : 11513 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Land`, Position : 'LB', Salary : 650000, SleeperID : 11514 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Brooks`, Position : 'CB', Salary : 650000, SleeperID : 11515 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Bass`, Position : 'OL', Salary : 650000, SleeperID : 11516 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nicholas`, LastName: `Williams`, Position : 'DT', Salary : 650000, SleeperID : 11517 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Toby`, LastName: `Ndukwe`, Position : 'LB', Salary : 650000, SleeperID : 11518 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaTrell`, LastName: `Bumphus`, Position : 'DL', Salary : 650000, SleeperID : 11519 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lucky`, LastName: `Jackson`, Position : 'WR', Salary : 650000, SleeperID : 11520 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jadakis`, LastName: `Bonds`, Position : 'WR', Salary : 650000, SleeperID : 11521 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Chrest`, Position : 'WR', Salary : 650000, SleeperID : 11523 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Izaiah`, LastName: `Gathings`, Position : 'TE', Salary : 650000, SleeperID : 11524 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Whelan`, Position : 'P', Salary : 650000, SleeperID : 11525 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malachi`, LastName: `Carter`, Position : 'WR', Salary : 650000, SleeperID : 11526 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Swann`, Position : 'CB', Salary : 650000, SleeperID : 11527 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ed`, LastName: `Lee`, Position : 'WR', Salary : 650000, SleeperID : 11529 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 11530 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jerome`, LastName: `Kapp`, Position : 'WR', Salary : 650000, SleeperID : 11522 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Tutsie`, Position : 'DB', Salary : 650000, SleeperID : 11531 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Trickett`, Position : 'K', Salary : 650000, SleeperID : 11532 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Aubrey`, Position : 'K', Salary : 650000, SleeperID : 11533 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garett`, LastName: `Maag`, Position : 'WR', Salary : 650000, SleeperID : 11528 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chuck`, LastName: `Wiley`, Position : 'DE', Salary : 650000, SleeperID : 11541 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Whitaker`, Position : 'CB', Salary : 650000, SleeperID : 11534 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Vanterpool`, Position : 'OL', Salary : 650000, SleeperID : 11545 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Butler`, Position : 'CB', Salary : 650000, SleeperID : 11535 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Milton`, LastName: `Wright`, Position : 'WR', Salary : 650000, SleeperID : 11536 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wayne`, LastName: `Taulapapa`, Position : 'RB', Salary : 650000, SleeperID : 11537 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Sanders`, Position : 'DT', Salary : 650000, SleeperID : 11549 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Bates`, Position : 'K', Salary : 650000, SleeperID : 11539 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `La'Michael`, LastName: `Pettway`, Position : 'TE', Salary : 650000, SleeperID : 11540 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaColby`, LastName: `Tucker`, Position : 'OL', Salary : 650000, SleeperID : 11542 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lachlan`, LastName: `Pitts`, Position : 'TE', Salary : 650000, SleeperID : 11543 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avery`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 11544 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Landherr`, Position : 'LS', Salary : 650000, SleeperID : 11546 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nehemiah`, LastName: `Shelton`, Position : 'CB', Salary : 650000, SleeperID : 11547 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Dykes`, Position : 'RB', Salary : 650000, SleeperID : 11548 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Toriano`, LastName: `Clinton`, Position : 'RB', Salary : 650000, SleeperID : 11538 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaShaun`, LastName: `White`, Position : 'LB', Salary : 650000, SleeperID : 11550 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Tarpley`, Position : 'DB', Salary : 650000, SleeperID : 11551 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Durell`, LastName: `Nchami`, Position : 'LB', Salary : 650000, SleeperID : 11552 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chad`, LastName: `Cota`, Position : 'S', Salary : 650000, SleeperID : 11553 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Cota`, Position : 'WR', Salary : 650000, SleeperID : 11554 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Matheson`, Position : 'LS', Salary : 650000, SleeperID : 11555 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Leary`, Position : 'QB', Salary : 0, SleeperID : 11556 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Milton`, Position : 'QB', Salary : 0, SleeperID : 11557 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Hartman`, Position : 'QB', Salary : 0, SleeperID : 11558 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Penix`, Position : 'QB', Salary : 0, SleeperID : 11559 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Williams`, Position : 'QB', Salary : 0, SleeperID : 11560 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Pratt`, Position : 'QB', Salary : 0, SleeperID : 11561 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Rattler`, Position : 'QB', Salary : 0, SleeperID : 11562 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bo`, LastName: `Nix`, Position : 'QB', Salary : 0, SleeperID : 11563 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drake`, LastName: `Maye`, Position : 'QB', Salary : 0, SleeperID : 11564 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `J.J.`, LastName: `McCarthy`, Position : 'QB', Salary : 0, SleeperID : 11565 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayden`, LastName: `Daniels`, Position : 'QB', Salary : 0, SleeperID : 11566 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Travis`, Position : 'QB', Salary : 0, SleeperID : 11567 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Watson`, Position : 'RB', Salary : 0, SleeperID : 11568 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rasheen`, LastName: `Ali`, Position : 'RB', Salary : 0, SleeperID : 11570 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Davis`, Position : 'RB', Salary : 0, SleeperID : 11571 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frank`, LastName: `Gore`, Position : 'RB', Salary : 0, SleeperID : 11573 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Laube`, Position : 'RB', Salary : 0, SleeperID : 11574 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarquez`, LastName: `Hunter`, Position : 'RB', Salary : 0, SleeperID : 11569 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ray`, LastName: `Davis`, Position : 'RB', Salary : 0, SleeperID : 11575 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braelon`, LastName: `Allen`, Position : 'RB', Salary : 0, SleeperID : 11576 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Shipley`, Position : 'RB', Salary : 0, SleeperID : 11577 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Audric`, LastName: `Estime`, Position : 'RB', Salary : 0, SleeperID : 11579 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dillon`, LastName: `Johnson`, Position : 'RB', Salary : 0, SleeperID : 11580 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `MarShawn`, LastName: `Lloyd`, Position : 'RB', Salary : 0, SleeperID : 11581 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carson`, LastName: `Steele`, Position : 'RB', Salary : 0, SleeperID : 11582 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Miyan`, LastName: `Williams`, Position : 'RB', Salary : 0, SleeperID : 11578 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bucky`, LastName: `Irving`, Position : 'RB', Salary : 0, SleeperID : 11584 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Corum`, Position : 'RB', Salary : 0, SleeperID : 11586 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jawhar`, LastName: `Jordan`, Position : 'RB', Salary : 0, SleeperID : 11588 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 11587 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Benson`, Position : 'RB', Salary : 0, SleeperID : 11589 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jase`, LastName: `McClellan`, Position : 'RB', Salary : 0, SleeperID : 11590 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marshel`, LastName: `Martin`, Position : 'TE', Salary : 650000, SleeperID : 11591 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Erick`, LastName: `All`, Position : 'TE', Salary : 0, SleeperID : 11592 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brevyn`, LastName: `Spann-Ford`, Position : 'TE', Salary : 0, SleeperID : 11593 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brant`, LastName: `Kuithe`, Position : 'TE', Salary : 0, SleeperID : 11594 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathon`, LastName: `Brooks`, Position : 'RB', Salary : 0, SleeperID : 11583 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chop`, LastName: `Robinson`, Position : 'DE', Salary : 650000, SleeperID : 11663 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Wiley`, Position : 'TE', Salary : 0, SleeperID : 11595 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Pili`, Position : 'DT', Salary : 650000, SleeperID : 11396 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Morgan`, Position : 'OL', Salary : 650000, SleeperID : 11660 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terrion`, LastName: `Arnold`, Position : 'DB', Salary : 650000, SleeperID : 11661 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinyon`, LastName: `Mitchell`, Position : 'CB', Salary : 650000, SleeperID : 11662 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Fautanu`, Position : 'OL', Salary : 650000, SleeperID : 11664 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Verse`, Position : 'DL', Salary : 650000, SleeperID : 11665 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amarius`, LastName: `Mims`, Position : 'OL', Salary : 650000, SleeperID : 11666 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dallas`, LastName: `Turner`, Position : 'LB', Salary : 650000, SleeperID : 11667 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Byron`, LastName: `Murphy`, Position : 'DL', Salary : 650000, SleeperID : 11668 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Laiatu`, LastName: `Latu`, Position : 'DL', Salary : 650000, SleeperID : 11669 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Olumuyiwa`, LastName: `Fashanu`, Position : 'OL', Salary : 650000, SleeperID : 11671 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JC`, LastName: `Latham`, Position : 'OL', Salary : 650000, SleeperID : 11672 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Alt`, Position : 'OL', Salary : 650000, SleeperID : 11673 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Nubin`, Position : 'DB', Salary : 650000, SleeperID : 11674 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kool-Aid`, LastName: `McKinstry`, Position : 'DB', Salary : 650000, SleeperID : 11675 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Sainristil`, Position : 'DB', Salary : 650000, SleeperID : 11676 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braden`, LastName: `Fiske`, Position : 'DL', Salary : 650000, SleeperID : 11677 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cooper`, LastName: `DeJean`, Position : 'DB', Salary : 650000, SleeperID : 11678 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 11989 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Braswell`, Position : 'LB', Salary : 650000, SleeperID : 11679 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taliese`, LastName: `Fuaga`, Position : 'OL', Salary : 650000, SleeperID : 11670 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `Paul`, Position : 'OL', Salary : 650000, SleeperID : 11680 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kingsley`, LastName: `Suamataia`, Position : 'OL', Salary : 650000, SleeperID : 11681 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Bishop`, Position : 'DB', Salary : 650000, SleeperID : 11682 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jackson`, LastName: `Powers-Johnson`, Position : 'OL', Salary : 650000, SleeperID : 11683 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Fisher`, Position : 'OL', Salary : 650000, SleeperID : 11684 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kamari`, LastName: `Lassiter`, Position : 'DB', Salary : 650000, SleeperID : 11685 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Mion`, LastName: `Franklin`, Position : 'DT', Salary : 650000, SleeperID : 11980 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deion`, LastName: `Jennings`, Position : 'LB', Salary : 650000, SleeperID : 11981 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Washington`, Position : 'WR', Salary : 650000, SleeperID : 11982 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Evans`, Position : 'DE', Salary : 650000, SleeperID : 11983 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeVere`, LastName: `Levelston`, Position : 'DE', Salary : 650000, SleeperID : 11984 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Grier`, Position : 'LB', Salary : 650000, SleeperID : 11986 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daniel`, LastName: `Grzesiak`, Position : 'DL', Salary : 650000, SleeperID : 11987 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Judge`, LastName: `Culpepper`, Position : 'DT', Salary : 650000, SleeperID : 11988 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Avery`, LastName: `Jones`, Position : 'C', Salary : 650000, SleeperID : 11991 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrek`, LastName: `Funderburk`, Position : 'CB', Salary : 650000, SleeperID : 11993 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kameron`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 11994 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zack`, LastName: `Annexstad`, Position : 'QB', Salary : 650000, SleeperID : 11995 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Delgado`, Position : 'G', Salary : 650000, SleeperID : 11996 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eyabi`, LastName: `Okie-Anoma`, Position : 'DL', Salary : 650000, SleeperID : 11997 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emani`, LastName: `Bailey`, Position : 'RB', Salary : 0, SleeperID : 11998 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carlton`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 11985 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keaton`, LastName: `Bills`, Position : 'OL', Salary : 650000, SleeperID : 11999 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalen`, LastName: `DeLoach`, Position : 'LB', Salary : 650000, SleeperID : 11990 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nolan`, LastName: `Potter`, Position : 'OL', Salary : 650000, SleeperID : 11992 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathaniel`, LastName: `Peat`, Position : 'RB', Salary : 650000, SleeperID : 12007 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lawrence`, LastName: `Keys`, Position : 'WR', Salary : 650000, SleeperID : 12001 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gunnar`, LastName: `Watson`, Position : 'QB', Salary : 650000, SleeperID : 12003 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Branson`, LastName: `Deen`, Position : 'DL', Salary : 650000, SleeperID : 12004 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sheridan`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 12006 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keni-H`, LastName: `Lovely`, Position : 'CB', Salary : 650000, SleeperID : 12000 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeShawn`, LastName: `Gaddie`, Position : 'CB', Salary : 650000, SleeperID : 12008 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alfahiym`, LastName: `Walcott`, Position : 'DB', Salary : 650000, SleeperID : 12002 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Browning`, Position : 'K/P', Salary : 650000, SleeperID : 12005 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Weaver`, Position : 'WR', Salary : 0, SleeperID : 11921 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Shimko`, Position : 'LS', Salary : 650000, SleeperID : 11920 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drake`, LastName: `Nugent`, Position : 'OL', Salary : 650000, SleeperID : 11922 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Anderson`, Position : 'DL', Salary : 650000, SleeperID : 11923 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `Mordecai`, Position : 'QB', Salary : 650000, SleeperID : 11924 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Terique`, LastName: `Owens`, Position : 'WR', Salary : 650000, SleeperID : 11925 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Owen`, LastName: `Porter`, Position : 'DL', Salary : 650000, SleeperID : 11926 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Doug`, LastName: `Nester`, Position : 'OL', Salary : 650000, SleeperID : 11927 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Rolland`, Position : 'OL', Salary : 650000, SleeperID : 11928 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bo`, LastName: `Richter`, Position : 'DE', Salary : 650000, SleeperID : 11929 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taki`, LastName: `Taimani`, Position : 'DL', Salary : 650000, SleeperID : 11930 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Reed`, Position : 'QB', Salary : 0, SleeperID : 11931 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `DiLiello`, Position : 'QB', Salary : 650000, SleeperID : 11932 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Nikkel`, Position : 'DB', Salary : 650000, SleeperID : 11933 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Cain`, Position : 'RB', Salary : 650000, SleeperID : 12061 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Owens`, Position : 'DB', Salary : 650000, SleeperID : 11934 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `A.J.`, LastName: `Woods`, Position : 'CB', Salary : 650000, SleeperID : 11935 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Norell`, LastName: `Pollard`, Position : 'DL', Salary : 650000, SleeperID : 11936 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Nwaogwugwu`, Position : 'T', Salary : 650000, SleeperID : 11937 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rome`, LastName: `Odunze`, Position : 'WR', Salary : 0, SleeperID : 11620 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brenden`, LastName: `Rice`, Position : 'WR', Salary : 0, SleeperID : 11621 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Whittington`, Position : 'WR', Salary : 0, SleeperID : 11623 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Legette`, Position : 'WR', Salary : 0, SleeperID : 11626 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marvin`, LastName: `Harrison`, Position : 'WR', Salary : 0, SleeperID : 11628 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devontez`, LastName: `Walker`, Position : 'WR', Salary : 0, SleeperID : 11629 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roman`, LastName: `Wilson`, Position : 'WR', Salary : 0, SleeperID : 11630 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Grandy`, Position : 'TE', Salary : 650000, SleeperID : 12062 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rocky`, LastName: `Lombardi`, Position : 'QB', Salary : 650000, SleeperID : 12063 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lance`, LastName: `Robinson`, Position : 'DB', Salary : 650000, SleeperID : 12064 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Worthy`, Position : 'WR', Salary : 0, SleeperID : 11624 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Miller`, Position : 'OL', Salary : 650000, SleeperID : 12065 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adonai`, LastName: `Mitchell`, Position : 'WR', Salary : 0, SleeperID : 11625 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Franklin`, Position : 'WR', Salary : 0, SleeperID : 11627 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Collins`, Position : 'RB', Salary : 650000, SleeperID : 12066 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Briason`, LastName: `Mays`, Position : 'C', Salary : 650000, SleeperID : 12067 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gottlieb`, LastName: `Ayedze`, Position : 'T', Salary : 650000, SleeperID : 12069 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `McCallan`, LastName: `Castles`, Position : 'TE', Salary : 650000, SleeperID : 12070 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Winston`, LastName: `Reid`, Position : 'LB', Salary : 650000, SleeperID : 12083 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabe`, LastName: `Hall`, Position : 'DT', Salary : 650000, SleeperID : 12071 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Roberts`, Position : 'LB', Salary : 650000, SleeperID : 12072 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jett`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 12073 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Pline`, Position : 'TE', Salary : 650000, SleeperID : 12068 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Talik`, LastName: `Keaton`, Position : 'WR', Salary : 650000, SleeperID : 12074 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anim`, LastName: `Dankwah`, Position : 'T', Salary : 650000, SleeperID : 12075 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `McNamara`, Position : 'P', Salary : 650000, SleeperID : 12076 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lincoln`, LastName: `Victor`, Position : 'WR', Salary : 650000, SleeperID : 12077 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Allen`, Position : 'DL', Salary : 650000, SleeperID : 12078 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Treyton`, LastName: `Welch`, Position : 'TE', Salary : 650000, SleeperID : 12079 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aidan`, LastName: `Robbins`, Position : 'RB', Salary : 650000, SleeperID : 12080 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DyShawn`, LastName: `Gales-Cozart`, Position : 'CB', Salary : 650000, SleeperID : 12081 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maason`, LastName: `Smith`, Position : 'DT', Salary : 650000, SleeperID : 11698 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Sundell`, Position : 'C', Salary : 650000, SleeperID : 12082 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jer'Zhan`, LastName: `Newton`, Position : 'DL', Salary : 650000, SleeperID : 11699 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Bullard`, Position : 'DB', Salary : 650000, SleeperID : 11686 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kris`, LastName: `Jenkins`, Position : 'DL', Salary : 650000, SleeperID : 11688 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ennis`, LastName: `Rakestraw`, Position : 'DB', Salary : 650000, SleeperID : 11689 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Frazier`, Position : 'OL', Salary : 650000, SleeperID : 11690 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T'Vondre`, LastName: `Sweat`, Position : 'DL', Salary : 650000, SleeperID : 11691 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Hall`, Position : 'DT', Salary : 650000, SleeperID : 11692 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Melton`, Position : 'DB', Salary : 650000, SleeperID : 11693 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marshawn`, LastName: `Kneeland`, Position : 'DL', Salary : 650000, SleeperID : 11694 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ruke`, LastName: `Orhorhoro`, Position : 'DT', Salary : 650000, SleeperID : 11695 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roger`, LastName: `Rosengarten`, Position : 'OL', Salary : 650000, SleeperID : 11696 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Renardo`, LastName: `Green`, Position : 'DB', Salary : 650000, SleeperID : 11697 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andru`, LastName: `Phillips`, Position : 'DB', Salary : 650000, SleeperID : 11700 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Junior`, LastName: `Colson`, Position : 'LB', Salary : 650000, SleeperID : 11701 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Edgerrin`, LastName: `Cooper`, Position : 'LB', Salary : 650000, SleeperID : 11687 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Coleman`, Position : 'OL', Salary : 650000, SleeperID : 11702 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalyx`, LastName: `Hunt`, Position : 'DE', Salary : 650000, SleeperID : 11703 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Haynes`, Position : 'OL', Salary : 650000, SleeperID : 11704 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tykee`, LastName: `Smith`, Position : 'DB', Salary : 650000, SleeperID : 11705 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeWayne`, LastName: `Carter`, Position : 'DT', Salary : 650000, SleeperID : 11706 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kiran`, LastName: `Amegadjie`, Position : 'OL', Salary : 650000, SleeperID : 11707 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Delmar`, LastName: `Glaze`, Position : 'OL', Salary : 650000, SleeperID : 11708 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emanuel`, LastName: `Wilson`, Position : 'RB', Salary : 650000, SleeperID : 11435 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `PJ`, LastName: `Mustipher`, Position : 'NT', Salary : 650000, SleeperID : 11436 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Goncalves`, Position : 'OL', Salary : 650000, SleeperID : 11709 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Calen`, LastName: `Bullock`, Position : 'DB', Salary : 650000, SleeperID : 11710 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caedan`, LastName: `Wallace`, Position : 'OL', Salary : 650000, SleeperID : 11711 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty'Ron`, LastName: `Hopper`, Position : 'LB', Salary : 650000, SleeperID : 11712 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `McKinnley`, LastName: `Jackson`, Position : 'DL', Salary : 650000, SleeperID : 11713 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonah`, LastName: `Elliss`, Position : 'DE', Salary : 650000, SleeperID : 11714 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zak`, LastName: `Zinter`, Position : 'OL', Salary : 650000, SleeperID : 11715 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tip`, LastName: `Reiman`, Position : 'TE', Salary : 0, SleeperID : 11716 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Jones`, Position : 'DB', Salary : 650000, SleeperID : 11717 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Adams`, Position : 'OL', Salary : 650000, SleeperID : 11718 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seth`, LastName: `Benson`, Position : 'LB', Salary : 650000, SleeperID : 11434 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cooper`, LastName: `Beebe`, Position : 'OL', Salary : 650000, SleeperID : 11719 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marist`, LastName: `Liufau`, Position : 'LB', Salary : 650000, SleeperID : 11720 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bralen`, LastName: `Trice`, Position : 'DE', Salary : 650000, SleeperID : 11721 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Harper`, Position : 'DB', Salary : 650000, SleeperID : 11872 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominick`, LastName: `Puni`, Position : 'OL', Salary : 650000, SleeperID : 11723 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaelen`, LastName: `Gill`, Position : 'WR', Salary : 650000, SleeperID : 11867 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micheal`, LastName: `Mason`, Position : 'DL', Salary : 650000, SleeperID : 11868 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Kennedy`, Position : 'DB', Salary : 650000, SleeperID : 11869 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Jean-Baptiste`, Position : 'LB', Salary : 650000, SleeperID : 11870 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Akeem`, LastName: `Dent`, Position : 'DB', Salary : 650000, SleeperID : 11871 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adisa`, LastName: `Isaac`, Position : 'DE', Salary : 650000, SleeperID : 11722 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willis`, LastName: `Patrick`, Position : 'OL', Salary : 650000, SleeperID : 11873 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 11874 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sincere`, LastName: `Haynesworth`, Position : 'OL', Salary : 650000, SleeperID : 11903 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lawrence`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 11904 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `James`, Position : 'WR', Salary : 650000, SleeperID : 11906 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dallas`, LastName: `Gant`, Position : 'LB', Salary : 650000, SleeperID : 11907 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabriel`, LastName: `Murphy`, Position : 'DL', Salary : 650000, SleeperID : 11908 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Manoa`, Position : 'DL', Salary : 650000, SleeperID : 11909 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeshaun`, LastName: `Jones`, Position : 'WR', Salary : 650000, SleeperID : 11911 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donovan`, LastName: `Manuel`, Position : 'LB', Salary : 650000, SleeperID : 11910 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `K.J.`, LastName: `Cloyd`, Position : 'LB', Salary : 650000, SleeperID : 11912 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devron`, LastName: `Harper`, Position : 'WR', Salary : 650000, SleeperID : 11913 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremy`, LastName: `Flax`, Position : 'OL', Salary : 650000, SleeperID : 11914 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Cindric`, Position : 'OL', Salary : 650000, SleeperID : 11915 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Edmonds`, Position : 'DB', Salary : 650000, SleeperID : 11916 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javion`, LastName: `Cohen`, Position : 'OL', Salary : 650000, SleeperID : 11917 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ahmarean`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 11918 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lorenzo`, LastName: `Thompson`, Position : 'OL', Salary : 650000, SleeperID : 11919 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Wallace`, Position : 'DB', Salary : 650000, SleeperID : 12132 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drake`, LastName: `Stoops`, Position : 'WR', Salary : 650000, SleeperID : 12133 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Wiglusz`, Position : 'WR', Salary : 650000, SleeperID : 12134 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Neal`, LastName: `Johnson`, Position : 'TE', Salary : 650000, SleeperID : 12135 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tuli`, LastName: `Letuligasenoa`, Position : 'DL', Salary : 650000, SleeperID : 12136 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Woods`, Position : 'CB', Salary : 650000, SleeperID : 12137 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Goodlow`, Position : 'DE', Salary : 650000, SleeperID : 12138 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `Logan`, Position : 'DB', Salary : 650000, SleeperID : 12139 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Omar`, LastName: `Speights`, Position : 'LB', Salary : 650000, SleeperID : 12140 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `McCollough`, Position : 'DB', Salary : 650000, SleeperID : 12142 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bayron`, LastName: `Matos`, Position : 'OL', Salary : 650000, SleeperID : 12143 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Je'Quan`, LastName: `Burton`, Position : 'WR', Salary : 650000, SleeperID : 12144 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Dedich`, Position : 'OL', Salary : 650000, SleeperID : 12141 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Meyer`, Position : 'C', Salary : 650000, SleeperID : 12145 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Grayson`, LastName: `Murphy`, Position : 'DL', Salary : 650000, SleeperID : 12146 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Storm`, LastName: `Duck`, Position : 'CB', Salary : 650000, SleeperID : 12147 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Messiah`, LastName: `Swinson`, Position : 'TE', Salary : 650000, SleeperID : 12154 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gavin`, LastName: `Hardison`, Position : 'QB', Salary : 650000, SleeperID : 12149 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarveon`, LastName: `Howard`, Position : 'RB', Salary : 650000, SleeperID : 12150 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Curtis`, LastName: `Jacobs`, Position : 'LB', Salary : 650000, SleeperID : 12164 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Hicks`, Position : 'WR', Salary : 650000, SleeperID : 12151 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trente`, LastName: `Jones`, Position : 'OT', Salary : 650000, SleeperID : 12152 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Donovan`, LastName: `Jennings`, Position : 'OL', Salary : 650000, SleeperID : 12153 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Jones`, Position : 'G', Salary : 650000, SleeperID : 12148 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Lynn`, Position : 'DL', Salary : 650000, SleeperID : 12155 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Turner`, Position : 'K', Salary : 650000, SleeperID : 12156 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chelen`, LastName: `Garnes`, Position : 'DB', Salary : 650000, SleeperID : 12157 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DaRon`, LastName: `Gilbert`, Position : 'LB', Salary : 650000, SleeperID : 12158 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Morice`, LastName: `Norris`, Position : 'DB', Salary : 650000, SleeperID : 12160 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Loren`, LastName: `Strickland`, Position : 'DB', Salary : 650000, SleeperID : 12161 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ethan`, LastName: `Driskell`, Position : 'OL', Salary : 650000, SleeperID : 12162 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Rehkow`, Position : 'P', Salary : 650000, SleeperID : 12163 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Sanders`, Position : 'QB', Salary : 0, SleeperID : 12165 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Potts`, Position : 'RB', Salary : 650000, SleeperID : 12166 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Lockett`, Position : 'DB', Salary : 650000, SleeperID : 12167 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hogan`, LastName: `Hatten`, Position : 'LS', Salary : 650000, SleeperID : 12159 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reggie`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 12169 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Mathis`, Position : 'WR', Salary : 650000, SleeperID : 12168 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `British`, LastName: `Brooks`, Position : 'RB', Salary : 650000, SleeperID : 12171 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pheldarius`, LastName: `Payne`, Position : 'DL', Salary : 650000, SleeperID : 12172 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaaron`, LastName: `Hayek`, Position : 'WR', Salary : 650000, SleeperID : 12170 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaxon`, LastName: `Janke`, Position : 'WR', Salary : 650000, SleeperID : 12173 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ralen`, LastName: `Goforth`, Position : 'LB', Salary : 650000, SleeperID : 12174 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Ester`, Position : 'DT', Salary : 650000, SleeperID : 12175 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Porter`, LastName: `Wilson`, Position : 'P', Salary : 650000, SleeperID : 12176 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Bertoia`, Position : 'C', Salary : 650000, SleeperID : 12177 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Don`, LastName: `Callis`, Position : 'CB', Salary : 650000, SleeperID : 12178 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Mathews`, Position : 'DT', Salary : 650000, SleeperID : 12179 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `McNulty`, Position : 'K', Salary : 650000, SleeperID : 12180 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Peter`, LastName: `Bowden`, Position : 'LS', Salary : 650000, SleeperID : 12181 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Miles`, LastName: `Battle`, Position : 'CB', Salary : 650000, SleeperID : 12182 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Roland-Wallace`, Position : 'CB', Salary : 650000, SleeperID : 12183 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalton`, LastName: `Tucker`, Position : 'OL', Salary : 650000, SleeperID : 12184 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Spencer`, LastName: `Shrader`, Position : 'K', Salary : 650000, SleeperID : 12185 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Coe`, Position : 'DL', Salary : 650000, SleeperID : 12186 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malcolm`, LastName: `Epps`, Position : 'TE', Salary : 650000, SleeperID : 12187 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Strong`, Position : 'RB', Salary : 650000, SleeperID : 12188 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Rigerman`, Position : 'TE', Salary : 650000, SleeperID : 12246 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julian`, LastName: `Pearl`, Position : 'T', Salary : 650000, SleeperID : 12247 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrell`, LastName: `Simpson`, Position : 'OL', Salary : 650000, SleeperID : 12248 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Randen`, LastName: `Plattner`, Position : 'LS', Salary : 650000, SleeperID : 12249 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Riley`, LastName: `Sharp`, Position : 'TE', Salary : 650000, SleeperID : 12250 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Toles`, Position : 'DB', Salary : 650000, SleeperID : 12251 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Burton`, Position : 'WR', Salary : 0, SleeperID : 11640 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Laekin`, LastName: `Vakalahi`, Position : 'T', Salary : 650000, SleeperID : 12252 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Washington`, LastName: `Commanders`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Louis`, LastName: `Rees-Zammit`, Position : 'RB', Salary : 650000, SleeperID : 11652 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `New York`, LastName: `Jets`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Las Vegas`, LastName: `Raiders`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Thomas`, Position : 'WR', Salary : 0, SleeperID : 11631 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Nabers`, Position : 'WR', Salary : 0, SleeperID : 11632 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamari`, LastName: `Thrash`, Position : 'WR', Salary : 0, SleeperID : 11633 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ladd`, LastName: `McConkey`, Position : 'WR', Salary : 0, SleeperID : 11635 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Johnny`, LastName: `Wilson`, Position : 'WR', Salary : 0, SleeperID : 11636 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keon`, LastName: `Coleman`, Position : 'WR', Salary : 0, SleeperID : 11637 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ricky`, LastName: `Pearsall`, Position : 'WR', Salary : 0, SleeperID : 11638 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lwal`, LastName: `Uguak`, Position : 'DL', Salary : 650000, SleeperID : 11642 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cody`, LastName: `Schrader`, Position : 'RB', Salary : 0, SleeperID : 11644 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Baker`, Position : 'WR', Salary : 0, SleeperID : 11645 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kimani`, LastName: `Vidal`, Position : 'RB', Salary : 0, SleeperID : 11647 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kedon`, LastName: `Slovis`, Position : 'QB', Salary : 0, SleeperID : 11648 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kendall`, LastName: `Milton`, Position : 'RB', Salary : 0, SleeperID : 11649 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `McCaffrey`, Position : 'WR', Salary : 0, SleeperID : 11650 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Guerendo`, Position : 'RB', Salary : 0, SleeperID : 11651 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dallas`, LastName: `Cowboys`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charlie`, LastName: `Smyth`, Position : 'K', Salary : 650000, SleeperID : 11653 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominic`, LastName: `Lovett`, Position : 'WR', Salary : 650000, SleeperID : 11634 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrone`, LastName: `Tracy`, Position : 'RB', Salary : 0, SleeperID : 11655 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Wright`, Position : 'RB', Salary : 0, SleeperID : 11643 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Coker`, Position : 'WR', Salary : 0, SleeperID : 11646 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nate`, LastName: `Wiggins`, Position : 'CB', Salary : 650000, SleeperID : 11656 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Guyton`, Position : 'OL', Salary : 650000, SleeperID : 11657 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dante`, LastName: `Miller`, Position : 'RB', Salary : 650000, SleeperID : 11654 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Knox`, Position : 'TE', Salary : 0, SleeperID : 11601 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Tavion`, LastName: `Sanders`, Position : 'TE', Salary : 0, SleeperID : 11600 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryson`, LastName: `Nesbit`, Position : 'TE', Salary : 0, SleeperID : 11602 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `AJ`, LastName: `Barner`, Position : 'TE', Salary : 0, SleeperID : 11603 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brock`, LastName: `Bowers`, Position : 'TE', Salary : 0, SleeperID : 11604 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaheim`, LastName: `Bell`, Position : 'TE', Salary : 0, SleeperID : 11605 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dallin`, LastName: `Holker`, Position : 'TE', Salary : 0, SleeperID : 11606 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hayden`, LastName: `Hatten`, Position : 'WR', Salary : 650000, SleeperID : 11607 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 11608 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zakhari`, LastName: `Franklin`, Position : 'WR', Salary : 0, SleeperID : 11609 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Washington`, Position : 'WR', Salary : 0, SleeperID : 11610 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Corian`, LastName: `Clark`, Position : 'WR', Salary : 650000, SleeperID : 11611 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bru`, LastName: `McCoy`, Position : 'WR', Salary : 650000, SleeperID : 11612 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Theo`, LastName: `Wease`, Position : 'WR', Salary : 650000, SleeperID : 11613 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Barry`, LastName: `Wesley`, Position : 'OL', Salary : 650000, SleeperID : 11469 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Drake`, LastName: `Thomas`, Position : 'LB', Salary : 650000, SleeperID : 11470 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dalton`, LastName: `Wagner`, Position : 'T', Salary : 650000, SleeperID : 11471 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alfonzo`, LastName: `Graham`, Position : 'RB', Salary : 650000, SleeperID : 11472 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khyree`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 11741 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedric`, LastName: `Gray`, Position : 'LB', Salary : 650000, SleeperID : 11742 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dadrion`, LastName: `Taylor-Demerson`, Position : 'DB', Salary : 650000, SleeperID : 11744 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Layden`, LastName: `Robinson`, Position : 'OL', Salary : 650000, SleeperID : 11745 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Giovanni`, LastName: `Manu`, Position : 'OL', Salary : 650000, SleeperID : 11746 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Moose`, LastName: `Muhammad`, Position : 'WR', Salary : 0, SleeperID : 11614 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylan`, LastName: `Ford`, Position : 'LB', Salary : 650000, SleeperID : 11747 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bub`, LastName: `Means`, Position : 'WR', Salary : 0, SleeperID : 11748 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Hart`, Position : 'CB', Salary : 650000, SleeperID : 11749 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dominique`, LastName: `Hampton`, Position : 'CB', Salary : 650000, SleeperID : 11750 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brennan`, LastName: `Jackson`, Position : 'DE', Salary : 650000, SleeperID : 11751 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Trotter`, Position : 'LB', Salary : 650000, SleeperID : 11752 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mohamed`, LastName: `Kamara`, Position : 'DL', Salary : 650000, SleeperID : 11754 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Qwan'tez`, LastName: `Stiggers`, Position : 'CB', Salary : 650000, SleeperID : 11755 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hunter`, LastName: `Nourzad`, Position : 'OL', Salary : 650000, SleeperID : 11756 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Eboigbe`, Position : 'DL', Salary : 650000, SleeperID : 11743 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Edefuan`, LastName: `Ulofoshio`, Position : 'LB', Salary : 650000, SleeperID : 11757 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sedrick`, LastName: `Van Pran-Granger`, Position : 'OL', Salary : 650000, SleeperID : 11758 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Solomon`, Position : 'DE', Salary : 650000, SleeperID : 11759 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Booker`, Position : 'DL', Salary : 650000, SleeperID : 11760 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tommy`, LastName: `Eichenberg`, Position : 'LB', Salary : 650000, SleeperID : 11761 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nehemiah`, LastName: `Pritchett`, Position : 'CB', Salary : 650000, SleeperID : 11753 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kitan`, LastName: `Oladapo`, Position : 'DB', Salary : 650000, SleeperID : 11763 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Gould`, Position : 'WR', Salary : 0, SleeperID : 11762 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garret`, LastName: `Greenfield`, Position : 'OT', Salary : 650000, SleeperID : 12044 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Westover`, Position : 'TE', Salary : 0, SleeperID : 12045 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chevan`, LastName: `Cordeiro`, Position : 'QB', Salary : 650000, SleeperID : 12046 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ro`, LastName: `Torrence`, Position : 'DB', Salary : 650000, SleeperID : 12047 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Holani`, Position : 'RB', Salary : 0, SleeperID : 12048 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Beasley`, Position : 'LB', Salary : 650000, SleeperID : 12049 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dee`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 12050 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jackson`, LastName: `Sirmon`, Position : 'LB', Salary : 650000, SleeperID : 12051 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shyheim`, LastName: `Battle`, Position : 'CB', Salary : 650000, SleeperID : 12052 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyreek`, LastName: `Johnson`, Position : 'DE', Salary : 650000, SleeperID : 12053 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jimmy`, LastName: `Ciarlo`, Position : 'LB', Salary : 650000, SleeperID : 12054 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarius`, LastName: `Monroe`, Position : 'CB', Salary : 650000, SleeperID : 12056 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Maema`, LastName: `Njongmeta`, Position : 'LB', Salary : 650000, SleeperID : 12057 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `PJ`, LastName: `Jules`, Position : 'DB', Salary : 650000, SleeperID : 12058 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Casey`, Position : 'LB', Salary : 650000, SleeperID : 12060 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Dowell`, Position : 'DB', Salary : 650000, SleeperID : 12059 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seattle`, LastName: `Seahawks`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arizona`, LastName: `Cardinals`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pittsburgh`, LastName: `Steelers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Baltimore`, LastName: `Ravens`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Jenkins`, Position : 'DB', Salary : 650000, SleeperID : 12055 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `New England`, LastName: `Patriots`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Houston`, LastName: `Texans`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevin`, LastName: `Wallace`, Position : 'LB', Salary : 650000, SleeperID : 11724 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrian`, LastName: `Jones`, Position : 'DB', Salary : 650000, SleeperID : 11725 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kamren`, LastName: `Kinchens`, Position : 'DB', Salary : 650000, SleeperID : 11726 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Payton`, LastName: `Wilson`, Position : 'LB', Salary : 650000, SleeperID : 11727 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaden`, LastName: `Hicks`, Position : 'DB', Salary : 650000, SleeperID : 11728 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Indianapolis`, LastName: `Colts`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sione`, LastName: `Vaki`, Position : 'DB', Salary : 650000, SleeperID : 11729 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `T.J.`, LastName: `Tampa`, Position : 'DB', Salary : 650000, SleeperID : 11730 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Mustapha`, Position : 'DB', Salary : 650000, SleeperID : 11731 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tory`, LastName: `Taylor`, Position : 'P', Salary : 650000, SleeperID : 11732 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `McCormick`, Position : 'OL', Salary : 650000, SleeperID : 11733 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyrice`, LastName: `Knight`, Position : 'LB', Salary : 650000, SleeperID : 11734 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanor`, LastName: `Bortolini`, Position : 'OL', Salary : 650000, SleeperID : 11735 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Jefferson`, Position : 'DT', Salary : 650000, SleeperID : 11736 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javon`, LastName: `Foster`, Position : 'OL', Salary : 650000, SleeperID : 11737 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Deatherage`, Position : 'T', Salary : 650000, SleeperID : 11451 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Decamerion`, LastName: `Richardson`, Position : 'CB', Salary : 650000, SleeperID : 11738 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Wolff`, Position : 'K', Salary : 650000, SleeperID : 11457 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Grimes`, Position : 'WR', Salary : 650000, SleeperID : 11445 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Henry`, LastName: `Byrd`, Position : 'G', Salary : 650000, SleeperID : 11446 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kris`, LastName: `Leach`, Position : 'TE', Salary : 650000, SleeperID : 11447 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ekow`, LastName: `Boye-Doe`, Position : 'CB', Salary : 650000, SleeperID : 11448 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Joiner`, Position : 'DB', Salary : 650000, SleeperID : 11449 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Beach`, Position : 'OL', Salary : 650000, SleeperID : 11450 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jesse`, LastName: `Matthews`, Position : 'WR', Salary : 650000, SleeperID : 11452 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Otis`, LastName: `Reese`, Position : 'LB', Salary : 650000, SleeperID : 11454 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Kendall`, Position : 'CB', Salary : 650000, SleeperID : 11455 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre'Shaun`, LastName: `Harrison`, Position : 'WR', Salary : 650000, SleeperID : 11456 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 11739 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Jackson`, Position : 'DB', Salary : 650000, SleeperID : 11458 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kedrick`, LastName: `Whitehead`, Position : 'DB', Salary : 650000, SleeperID : 11459 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Azizi`, LastName: `Hearn`, Position : 'CB', Salary : 650000, SleeperID : 11460 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `George`, LastName: `Tarlas`, Position : 'DE', Salary : 650000, SleeperID : 11461 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaydon`, LastName: `Grant`, Position : 'DB', Salary : 650000, SleeperID : 11462 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brock`, LastName: `Martin`, Position : 'DE', Salary : 650000, SleeperID : 11463 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `McClendon`, LastName: `Curtis`, Position : 'G', Salary : 650000, SleeperID : 11464 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joe`, LastName: `Doyle`, Position : 'P', Salary : 650000, SleeperID : 11453 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Rosemy-Jacksaint`, Position : 'WR', Salary : 0, SleeperID : 11946 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Jones`, Position : 'RB', Salary : 650000, SleeperID : 11947 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Robert`, LastName: `Javier`, Position : 'CB', Salary : 650000, SleeperID : 11948 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brayden`, LastName: `Narveson`, Position : 'K', Salary : 650000, SleeperID : 11949 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rod`, LastName: `Gattison`, Position : 'CB', Salary : 650000, SleeperID : 11950 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Beau`, LastName: `Brade`, Position : 'DB', Salary : 650000, SleeperID : 11951 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tramel`, LastName: `Walthour`, Position : 'DL', Salary : 650000, SleeperID : 11953 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Bullock`, Position : 'OL', Salary : 650000, SleeperID : 11954 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Spencer`, Position : 'G', Salary : 650000, SleeperID : 11955 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brian`, LastName: `Dooley`, Position : 'T', Salary : 650000, SleeperID : 11956 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Schnee`, Position : 'WR', Salary : 650000, SleeperID : 11957 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darrian`, LastName: `Dalcourt`, Position : 'OL', Salary : 650000, SleeperID : 11952 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Ukwu`, Position : 'DE', Salary : 650000, SleeperID : 12128 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalon`, LastName: `Calhoun`, Position : 'WR', Salary : 650000, SleeperID : 12129 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steele`, LastName: `Chambers`, Position : 'LB', Salary : 650000, SleeperID : 12130 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryan`, LastName: `Hudson`, Position : 'OL', Salary : 650000, SleeperID : 12131 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Plummer`, Position : 'QB', Salary : 650000, SleeperID : 12009 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Popo`, LastName: `Aumavae`, Position : 'DT', Salary : 650000, SleeperID : 12010 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demani`, LastName: `Richardson`, Position : 'DB', Salary : 650000, SleeperID : 12011 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derrick`, LastName: `McLendon`, Position : 'LB', Salary : 650000, SleeperID : 12012 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaden`, LastName: `Shirden`, Position : 'RB', Salary : 0, SleeperID : 12013 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Drew`, Position : 'CB', Salary : 650000, SleeperID : 12014 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Harrison`, LastName: `Mevis`, Position : 'K', Salary : 650000, SleeperID : 12015 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeremiah`, LastName: `Crawford`, Position : 'OL', Salary : 650000, SleeperID : 12016 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clayton`, LastName: `Isbell`, Position : 'DB', Salary : 650000, SleeperID : 12017 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `Pinckney`, Position : 'WR', Salary : 650000, SleeperID : 12018 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Carter`, Position : 'WR', Salary : 650000, SleeperID : 12019 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jackson`, LastName: `Mitchell`, Position : 'LB', Salary : 650000, SleeperID : 12021 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rason`, LastName: `Williams`, Position : 'DL', Salary : 650000, SleeperID : 12023 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leo`, LastName: `Lowin`, Position : 'LB', Salary : 650000, SleeperID : 12020 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Taylor`, LastName: `Upshaw`, Position : 'DE', Salary : 650000, SleeperID : 12022 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sundiata`, LastName: `Anderson`, Position : 'DE', Salary : 650000, SleeperID : 12024 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nelson`, LastName: `Ceaser`, Position : 'DE', Salary : 650000, SleeperID : 12025 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kobe`, LastName: `Lewis`, Position : 'RB', Salary : 650000, SleeperID : 12026 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mike`, LastName: `Novitsky`, Position : 'C', Salary : 650000, SleeperID : 12027 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `TaMerik`, LastName: `Williams`, Position : 'RB', Salary : 650000, SleeperID : 12028 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Harrell`, Position : 'WR', Salary : 650000, SleeperID : 12029 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Braiden`, LastName: `McGregor`, Position : 'DE', Salary : 650000, SleeperID : 12030 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leonard`, LastName: `Taylor`, Position : 'DL', Salary : 650000, SleeperID : 12031 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travian`, LastName: `Blaylock`, Position : 'DB', Salary : 650000, SleeperID : 12032 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ian`, LastName: `Wheeler`, Position : 'RB', Salary : 650000, SleeperID : 12036 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Oj`, LastName: `Hiliare`, Position : 'WR', Salary : 650000, SleeperID : 12033 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Satkowski`, Position : 'OL', Salary : 650000, SleeperID : 12034 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamree`, LastName: `Kromah`, Position : 'DL', Salary : 650000, SleeperID : 12035 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Atagi`, Position : 'OL', Salary : 650000, SleeperID : 12038 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Reddy`, LastName: `Steward`, Position : 'CB', Salary : 650000, SleeperID : 12037 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `TD`, LastName: `Ayo-Durojaiye`, Position : 'RB', Salary : 650000, SleeperID : 12039 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leon`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 12040 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carl`, LastName: `Jones`, Position : 'DL', Salary : 650000, SleeperID : 12041 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keith`, LastName: `Randolph`, Position : 'DL', Salary : 650000, SleeperID : 12042 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Turk`, Position : 'P', Salary : 650000, SleeperID : 11397 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bennett`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 11398 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Garrett`, LastName: `Nelson`, Position : 'LB', Salary : 650000, SleeperID : 11399 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zeke`, LastName: `Vandenburgh`, Position : 'LB', Salary : 650000, SleeperID : 11400 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DJ`, LastName: `Dale`, Position : 'DT', Salary : 650000, SleeperID : 11401 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ben`, LastName: `Sinnott`, Position : 'TE', Salary : 0, SleeperID : 11596 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Theo`, LastName: `Johnson`, Position : 'TE', Salary : 0, SleeperID : 11597 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `McLachlan`, Position : 'TE', Salary : 0, SleeperID : 11598 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cade`, LastName: `Stover`, Position : 'TE', Salary : 0, SleeperID : 11599 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Cowing`, Position : 'WR', Salary : 0, SleeperID : 11616 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Proctor`, Position : 'DB', Salary : 650000, SleeperID : 12225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lorenzo`, LastName: `Lingard`, Position : 'RB', Salary : 650000, SleeperID : 12226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eli`, LastName: `Neal`, Position : 'LB', Salary : 650000, SleeperID : 12227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Blake`, LastName: `Larson`, Position : 'OL', Salary : 650000, SleeperID : 12228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `Olajiga`, Position : 'DL', Salary : 650000, SleeperID : 12229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Lampkin`, Position : 'DB', Salary : 650000, SleeperID : 12230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ainias`, LastName: `Smith`, Position : 'WR', Salary : 0, SleeperID : 11615 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `David`, LastName: `White`, Position : 'WR', Salary : 0, SleeperID : 12224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JJ`, LastName: `Laap`, Position : 'WR', Salary : 650000, SleeperID : 12231 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Omar`, LastName: `Brown`, Position : 'CB', Salary : 650000, SleeperID : 12232 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Willie`, LastName: `Tyler`, Position : 'OL', Salary : 650000, SleeperID : 12233 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shemar`, LastName: `Bartholomew`, Position : 'CB', Salary : 650000, SleeperID : 12234 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chigozie`, LastName: `Anusiem`, Position : 'CB', Salary : 650000, SleeperID : 11938 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Wooden`, Position : 'WR', Salary : 650000, SleeperID : 11941 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Colson`, LastName: `Yankoff`, Position : 'TE', Salary : 650000, SleeperID : 11942 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Wiley`, Position : 'RB', Salary : 0, SleeperID : 11943 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Vaval`, Position : 'CB', Salary : 650000, SleeperID : 11944 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Sanborn`, Position : 'P', Salary : 650000, SleeperID : 11945 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Oliver`, Position : 'WR', Salary : 650000, SleeperID : 11959 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emory`, LastName: `Jones`, Position : 'QB', Salary : 650000, SleeperID : 11961 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Johnson`, Position : 'WR', Salary : 0, SleeperID : 11969 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tayvion`, LastName: `Robinson`, Position : 'WR', Salary : 0, SleeperID : 11962 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dayton`, LastName: `Wade`, Position : 'WR', Salary : 0, SleeperID : 11964 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Banks`, Position : 'DB', Salary : 650000, SleeperID : 11965 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rashad`, LastName: `Wisdom`, Position : 'DB', Salary : 650000, SleeperID : 11966 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Don`, LastName: `Chapman`, Position : 'DB', Salary : 650000, SleeperID : 11967 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Collier`, Position : 'RB', Salary : 650000, SleeperID : 11963 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gunner`, LastName: `Britton`, Position : 'OL', Salary : 650000, SleeperID : 11970 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `McDonald`, Position : 'CB', Salary : 650000, SleeperID : 11968 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Colbert`, Position : 'DL', Salary : 650000, SleeperID : 11976 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Iton`, Position : 'DL', Salary : 650000, SleeperID : 11972 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Gabe`, LastName: `Jeudy-Lally`, Position : 'CB', Salary : 650000, SleeperID : 11973 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khalid`, LastName: `Duke`, Position : 'DE', Salary : 650000, SleeperID : 11974 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Terrell`, Position : 'RB', Salary : 650000, SleeperID : 11975 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keaton`, LastName: `Ellis`, Position : 'DB', Salary : 650000, SleeperID : 11971 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Stilianos`, Position : 'TE', Salary : 650000, SleeperID : 11960 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Coll`, Position : 'OT', Salary : 650000, SleeperID : 11939 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `X'Zauvea`, LastName: `Gadlin`, Position : 'G', Salary : 650000, SleeperID : 11958 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JaQuae`, LastName: `Jackson`, Position : 'WR', Salary : 650000, SleeperID : 11940 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Yvandy`, LastName: `Rigby`, Position : 'LB', Salary : 650000, SleeperID : 11977 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAngelo`, LastName: `Hardy`, Position : 'DB', Salary : 650000, SleeperID : 11979 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Johnson`, Position : 'LB', Salary : 650000, SleeperID : 12084 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alec`, LastName: `Holler`, Position : 'TE', Salary : 650000, SleeperID : 12085 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 12086 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emany`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 12087 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `McCartan`, Position : 'LB', Salary : 650000, SleeperID : 11978 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brock`, LastName: `Mogensen`, Position : 'LB', Salary : 650000, SleeperID : 12088 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julius`, LastName: `Wood`, Position : 'DB', Salary : 650000, SleeperID : 12089 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Corey`, LastName: `Crooms`, Position : 'WR', Salary : 650000, SleeperID : 12090 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Byron`, LastName: `Vaughns`, Position : 'LB', Salary : 650000, SleeperID : 12091 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `DeBerry`, Position : 'CB', Salary : 650000, SleeperID : 12092 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Geordon`, LastName: `Porter`, Position : 'WR', Salary : 650000, SleeperID : 12093 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kris`, LastName: `Abrams-Draine`, Position : 'DB', Salary : 650000, SleeperID : 11765 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarvis`, LastName: `Brownlee`, Position : 'DB', Salary : 650000, SleeperID : 11766 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `Thomas`, Position : 'DE', Salary : 650000, SleeperID : 11767 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caelen`, LastName: `Carson`, Position : 'DB', Salary : 650000, SleeperID : 11769 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `JD`, LastName: `Bertrand`, Position : 'LB', Salary : 650000, SleeperID : 11770 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chau`, LastName: `Smith-Wade`, Position : 'DB', Salary : 650000, SleeperID : 11771 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Keilan`, LastName: `Robinson`, Position : 'RB', Salary : 0, SleeperID : 11773 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sataoa`, LastName: `Laumea`, Position : 'OL', Salary : 650000, SleeperID : 11810 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Logan`, LastName: `Lee`, Position : 'DL', Salary : 650000, SleeperID : 11811 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Walter`, LastName: `Rouse`, Position : 'OL', Salary : 650000, SleeperID : 11812 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Jerrell`, Position : 'OT', Salary : 650000, SleeperID : 11813 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josiah`, LastName: `Ezirim`, Position : 'OT', Salary : 650000, SleeperID : 11814 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cornelius`, LastName: `Johnson`, Position : 'WR', Salary : 0, SleeperID : 11815 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Javontae`, LastName: `Jean-Baptiste`, Position : 'DL', Salary : 650000, SleeperID : 11816 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `KT`, LastName: `Leveston`, Position : 'OL', Salary : 650000, SleeperID : 11817 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Jurgens`, Position : 'OL', Salary : 650000, SleeperID : 11818 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Levi Drake`, LastName: `Rodriguez`, Position : 'DT', Salary : 650000, SleeperID : 11819 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devin`, LastName: `Culp`, Position : 'TE', Salary : 0, SleeperID : 11820 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Jones`, Position : 'OL', Salary : 650000, SleeperID : 11768 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tahj`, LastName: `Washington`, Position : 'WR', Salary : 0, SleeperID : 11821 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Key`, Position : 'DB', Salary : 650000, SleeperID : 11822 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `C.J.`, LastName: `Hanson`, Position : 'OG', Salary : 650000, SleeperID : 11823 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Clayton`, Position : 'OT', Salary : 650000, SleeperID : 11824 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deantre`, LastName: `Prince`, Position : 'CB', Salary : 650000, SleeperID : 11772 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `M.J.`, LastName: `Devonshire`, Position : 'DB', Salary : 650000, SleeperID : 11825 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Taylor`, Position : 'DB', Salary : 650000, SleeperID : 11826 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonah`, LastName: `Laulu`, Position : 'DL', Salary : 650000, SleeperID : 11827 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Rogers`, Position : 'DL', Salary : 650000, SleeperID : 11839 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Harris`, Position : 'DL', Salary : 650000, SleeperID : 11828 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Solomon`, LastName: `Byrd`, Position : 'DE', Salary : 650000, SleeperID : 11829 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kalen`, LastName: `King`, Position : 'CB', Salary : 650000, SleeperID : 11830 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daijahn`, LastName: `Anthony`, Position : 'DB', Salary : 650000, SleeperID : 11831 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matt`, LastName: `Lee`, Position : 'C', Salary : 650000, SleeperID : 11832 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Devaughn`, LastName: `Vele`, Position : 'WR', Salary : 0, SleeperID : 11834 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylen`, LastName: `Harrell`, Position : 'DE', Salary : 650000, SleeperID : 11835 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Harden`, Position : 'DB', Salary : 650000, SleeperID : 11836 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaden`, LastName: `Davis`, Position : 'DB', Salary : 650000, SleeperID : 11837 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Thomas`, Position : 'OL', Salary : 650000, SleeperID : 11838 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Samac`, Position : 'C', Salary : 650000, SleeperID : 11840 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Gargiulo`, Position : 'OL', Salary : 650000, SleeperID : 11833 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sanoussi`, LastName: `Kane`, Position : 'DB', Salary : 650000, SleeperID : 11841 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tatum`, LastName: `Bethune`, Position : 'LB', Salary : 650000, SleeperID : 11842 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Barrett`, Position : 'LB', Salary : 650000, SleeperID : 11843 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Cole`, Position : 'LB', Salary : 650000, SleeperID : 11844 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `LaDarius`, LastName: `Henderson`, Position : 'OL', Salary : 650000, SleeperID : 11845 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jowon`, LastName: `Briggs`, Position : 'DE', Salary : 650000, SleeperID : 11846 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Williams`, Position : 'DB', Salary : 650000, SleeperID : 11847 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Casey`, LastName: `Bauman`, Position : 'QB', Salary : 650000, SleeperID : 11848 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Karsen`, LastName: `Barnhart`, Position : 'OL', Salary : 650000, SleeperID : 11849 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jeff`, LastName: `Foreman`, Position : 'WR', Salary : 650000, SleeperID : 11850 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carter`, LastName: `Bradley`, Position : 'QB', Salary : 650000, SleeperID : 11851 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `TJ`, LastName: `Franklin`, Position : 'DL', Salary : 650000, SleeperID : 11852 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tomari`, LastName: `Fox`, Position : 'DL', Salary : 650000, SleeperID : 11853 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Coker`, Position : 'OL', Salary : 650000, SleeperID : 11854 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amari`, LastName: `Gainer`, Position : 'LB', Salary : 650000, SleeperID : 11855 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clark`, LastName: `Barrington`, Position : 'OL', Salary : 650000, SleeperID : 11856 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daijun`, LastName: `Edwards`, Position : 'RB', Salary : 0, SleeperID : 11857 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Julius`, LastName: `Welschof`, Position : 'DL', Salary : 650000, SleeperID : 11858 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John Rhys`, LastName: `Plumlee`, Position : 'QB', Salary : 0, SleeperID : 11859 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Beanie`, LastName: `Bishop`, Position : 'CB', Salary : 650000, SleeperID : 11860 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacoby`, LastName: `Windmon`, Position : 'LB', Salary : 650000, SleeperID : 11861 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Benson`, Position : 'TE', Salary : 650000, SleeperID : 11862 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Leon`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 11863 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Heins`, Position : 'TE', Salary : 650000, SleeperID : 11864 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalyn`, LastName: `Phillips`, Position : 'DB', Salary : 650000, SleeperID : 11865 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `McLellan`, Position : 'OL', Salary : 650000, SleeperID : 11866 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre'Mon`, LastName: `Morris-Brash`, Position : 'DE', Salary : 650000, SleeperID : 11875 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Savion`, LastName: `Jackson`, Position : 'DE', Salary : 650000, SleeperID : 11876 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Noah`, LastName: `Shannon`, Position : 'DL', Salary : 650000, SleeperID : 11877 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Putnam`, Position : 'C', Salary : 650000, SleeperID : 11878 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rayshad`, LastName: `Williams`, Position : 'CB', Salary : 650000, SleeperID : 11879 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Woo Governor`, LastName: `Governor`, Position : 'DB', Salary : 650000, SleeperID : 11880 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Johanning`, Position : 'C', Salary : 650000, SleeperID : 11881 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Quan`, LastName: `Sheppard`, Position : 'DB', Salary : 650000, SleeperID : 11882 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Phalen`, LastName: `Sanford`, Position : 'DB', Salary : 650000, SleeperID : 11883 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ron`, LastName: `Stone`, Position : 'DE', Salary : 650000, SleeperID : 11884 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lideatrick`, LastName: `Griffin`, Position : 'WR', Salary : 0, SleeperID : 11886 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bucky`, LastName: `Williams`, Position : 'OL', Salary : 650000, SleeperID : 11887 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zamari`, LastName: `Walton`, Position : 'CB', Salary : 650000, SleeperID : 11888 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Smith`, Position : 'OL', Salary : 650000, SleeperID : 11889 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luquay`, LastName: `Washington`, Position : 'LB', Salary : 650000, SleeperID : 11890 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Hergel`, Position : 'OL', Salary : 650000, SleeperID : 11891 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Latu`, Position : 'DE', Salary : 650000, SleeperID : 11892 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ramel`, LastName: `Keyton`, Position : 'WR', Salary : 0, SleeperID : 11885 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Stalbird`, Position : 'LB', Salary : 650000, SleeperID : 11893 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mason`, LastName: `Tipton`, Position : 'WR', Salary : 650000, SleeperID : 11895 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyler`, LastName: `Baugh`, Position : 'DL', Salary : 650000, SleeperID : 11894 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Shawn`, LastName: `Bowman`, Position : 'TE', Salary : 650000, SleeperID : 12217 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alex`, LastName: `Johnson`, Position : 'CB', Salary : 650000, SleeperID : 12094 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jjay`, LastName: `Mcafee`, Position : 'TE', Salary : 650000, SleeperID : 12095 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Jackson`, Position : 'RB', Salary : 650000, SleeperID : 12218 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brevin`, LastName: `Easton`, Position : 'WR', Salary : 650000, SleeperID : 12220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre`, LastName: `Carter`, Position : 'DL', Salary : 650000, SleeperID : 12221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joseph`, LastName: `Scates`, Position : 'WR', Salary : 650000, SleeperID : 12222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Parker`, Position : 'LB', Salary : 650000, SleeperID : 12223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Casey`, LastName: `Rogers`, Position : 'DL', Salary : 650000, SleeperID : 12096 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ayir`, LastName: `Asante`, Position : 'WR', Salary : 650000, SleeperID : 12097 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ovie`, LastName: `Oghoufo`, Position : 'DE', Salary : 650000, SleeperID : 12098 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `William`, LastName: `Barnes`, Position : 'T', Salary : 650000, SleeperID : 12099 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chris`, LastName: `Autman-Bell`, Position : 'WR', Salary : 650000, SleeperID : 12100 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcellus`, LastName: `Johnson`, Position : 'OL', Salary : 650000, SleeperID : 12101 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andre'`, LastName: `Sam`, Position : 'DB', Salary : 650000, SleeperID : 12102 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Miller`, Position : 'DT', Salary : 650000, SleeperID : 12103 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Alec`, LastName: `Mock`, Position : 'LB', Salary : 650000, SleeperID : 12104 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Matterson`, Position : 'DL', Salary : 650000, SleeperID : 12106 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Leonard`, Position : 'TE', Salary : 650000, SleeperID : 12107 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Quinton`, LastName: `Newsome`, Position : 'DB', Salary : 650000, SleeperID : 12108 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Caleb`, LastName: `Nelson`, Position : 'CB', Salary : 650000, SleeperID : 12109 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Frank`, LastName: `Crum`, Position : 'OT', Salary : 650000, SleeperID : 12110 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Levelle`, LastName: `Bailey`, Position : 'LB', Salary : 650000, SleeperID : 12111 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Camden`, LastName: `Lewis`, Position : 'K', Salary : 650000, SleeperID : 12112 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Thomas`, LastName: `Yassmin`, Position : 'TE', Salary : 650000, SleeperID : 12113 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Cephus`, Position : 'WR', Salary : 0, SleeperID : 12114 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dell`, LastName: `Pettus`, Position : 'DB', Salary : 650000, SleeperID : 12115 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mikey`, LastName: `Victor`, Position : 'CB', Salary : 650000, SleeperID : 12116 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jontrey`, LastName: `Hunter`, Position : 'LB', Salary : 650000, SleeperID : 12117 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Warren`, Position : 'TE', Salary : 650000, SleeperID : 12118 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `D'Ambra`, Position : 'LS', Salary : 650000, SleeperID : 12105 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Charles`, LastName: `Turner`, Position : 'OL', Salary : 650000, SleeperID : 12119 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zuri`, LastName: `Henry`, Position : 'OL', Salary : 650000, SleeperID : 12120 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Deshaun`, LastName: `Fenwick`, Position : 'RB', Salary : 650000, SleeperID : 12121 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Morgan`, Position : 'DE', Salary : 650000, SleeperID : 12122 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaleb`, LastName: `Ford-Dement`, Position : 'CB', Salary : 650000, SleeperID : 12123 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaiah`, LastName: `Johnson`, Position : 'DB', Salary : 650000, SleeperID : 12124 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mark`, LastName: `Perry`, Position : 'DB', Salary : 650000, SleeperID : 12125 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Isaac`, LastName: `Rex`, Position : 'TE', Salary : 650000, SleeperID : 12126 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Duke`, LastName: `Clemens`, Position : 'OL', Salary : 650000, SleeperID : 12127 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malachi`, LastName: `Corley`, Position : 'WR', Salary : 0, SleeperID : 11617 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Peewee`, LastName: `Jarrett`, Position : 'QB', Salary : 650000, SleeperID : 12239 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `McMillan`, Position : 'WR', Salary : 0, SleeperID : 11618 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Lynn`, LastName: `Polk`, Position : 'WR', Salary : 0, SleeperID : 11619 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcus`, LastName: `Riley`, Position : 'WR', Salary : 650000, SleeperID : 12235 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Jones`, Position : 'CB', Salary : 650000, SleeperID : 12236 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lincoln`, LastName: `Sefcik`, Position : 'TE', Salary : 650000, SleeperID : 12237 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Eric`, LastName: `Watts`, Position : 'DL', Salary : 650000, SleeperID : 12238 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brady`, LastName: `Latham`, Position : 'OL', Salary : 650000, SleeperID : 12240 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Al`, LastName: `Blades`, Position : 'DB', Salary : 650000, SleeperID : 12241 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Easton`, LastName: `Gibbs`, Position : 'LB', Salary : 650000, SleeperID : 12242 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bump`, LastName: `Cooper`, Position : 'CB', Salary : 650000, SleeperID : 12243 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `DeAngelo`, LastName: `Hardy`, Position : 'WR', Salary : 650000, SleeperID : 12244 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jelani`, LastName: `Baker`, Position : 'WR', Salary : 650000, SleeperID : 12245 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Minnesota`, LastName: `Vikings`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kansas City`, LastName: `Chiefs`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denver`, LastName: `Broncos`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cincinnati`, LastName: `Bengals`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chicago`, LastName: `Bears`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tennessee`, LastName: `Titans`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `New York`, LastName: `Giants`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `San Francisco`, LastName: `49ers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Philadelphia`, LastName: `Eagles`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Buffalo`, LastName: `Bills`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Detroit`, LastName: `Lions`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Green Bay`, LastName: `Packers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `New Orleans`, LastName: `Saints`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Los Angeles`, LastName: `Rams`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacksonville`, LastName: `Jaguars`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carolina`, LastName: `Panthers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Miami`, LastName: `Dolphins`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Atlanta`, LastName: `Falcons`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cleveland`, LastName: `Browns`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tampa Bay`, LastName: `Buccaneers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Robinson`, Position : 'DL', Salary : 650000, SleeperID : 11658 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Graham`, LastName: `Barton`, Position : 'OL', Salary : 650000, SleeperID : 11659 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Hayden`, LastName: `Gillum`, Position : 'OL', Salary : 650000, SleeperID : 12043 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Dorlus`, Position : 'DE', Salary : 650000, SleeperID : 11740 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Adam`, LastName: `Plant`, Position : 'DE', Salary : 650000, SleeperID : 11465 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Perryman`, Position : 'CB', Salary : 650000, SleeperID : 11466 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John Samuel`, LastName: `Shenker`, Position : 'TE', Salary : 650000, SleeperID : 11467 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kilian`, LastName: `Zierer`, Position : 'T', Salary : 650000, SleeperID : 11468 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Newton`, Position : 'CB', Salary : 650000, SleeperID : 11764 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trevor`, LastName: `Keegan`, Position : 'OL', Salary : 650000, SleeperID : 11774 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylin`, LastName: `Simpson`, Position : 'DB', Salary : 650000, SleeperID : 11775 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Monk`, Position : 'OL', Salary : 650000, SleeperID : 11776 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `Carlies`, Position : 'DB', Salary : 650000, SleeperID : 11777 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Magee`, Position : 'LB', Salary : 650000, SleeperID : 11778 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tarheeb`, LastName: `Still`, Position : 'DB', Salary : 650000, SleeperID : 11779 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Elijah`, LastName: `Klein`, Position : 'OG', Salary : 650000, SleeperID : 11780 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Daequan`, LastName: `Hardy`, Position : 'CB', Salary : 650000, SleeperID : 11781 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Beaux`, LastName: `Limmer`, Position : 'OL', Salary : 650000, SleeperID : 11782 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Flournoy`, Position : 'WR', Salary : 0, SleeperID : 11783 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jarrett`, LastName: `Kingston`, Position : 'OL', Salary : 650000, SleeperID : 11784 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedric`, LastName: `Johnson`, Position : 'DE', Salary : 650000, SleeperID : 11785 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cam`, LastName: `Little`, Position : 'K', Salary : 650000, SleeperID : 11786 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kamal`, LastName: `Hadden`, Position : 'DB', Salary : 650000, SleeperID : 11787 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Mahogany`, Position : 'OL', Salary : 650000, SleeperID : 11788 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaden`, LastName: `Crumedy`, Position : 'DT', Salary : 650000, SleeperID : 11795 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathaniel`, LastName: `Watson`, Position : 'LB', Salary : 650000, SleeperID : 11790 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tylan`, LastName: `Grable`, Position : 'OL', Salary : 650000, SleeperID : 11791 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Reichard`, Position : 'K', Salary : 650000, SleeperID : 11792 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Travis`, LastName: `Glover`, Position : 'OT', Salary : 650000, SleeperID : 11793 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Micah`, LastName: `Abraham`, Position : 'CB', Salary : 650000, SleeperID : 11794 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Joshua`, LastName: `Karty`, Position : 'K', Salary : 650000, SleeperID : 11789 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Khristian`, LastName: `Boyd`, Position : 'DL', Salary : 650000, SleeperID : 11796 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Patrick`, LastName: `McMorris`, Position : 'DB', Salary : 650000, SleeperID : 11797 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zion`, LastName: `Logue`, Position : 'DL', Salary : 650000, SleeperID : 11798 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Davis`, Position : 'DT', Salary : 650000, SleeperID : 11799 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryan`, LastName: `Watts`, Position : 'DB', Salary : 650000, SleeperID : 11800 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `D.J.`, LastName: `James`, Position : 'CB', Salary : 650000, SleeperID : 11801 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tejhaun`, LastName: `Palmer`, Position : 'WR', Salary : 0, SleeperID : 11802 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `McMahon`, Position : 'OL', Salary : 650000, SleeperID : 11803 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jamal`, LastName: `Hill`, Position : 'LB', Salary : 650000, SleeperID : 11805 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Casey`, LastName: `Washington`, Position : 'WR', Salary : 0, SleeperID : 11806 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jha'Quan`, LastName: `Jackson`, Position : 'WR', Salary : 0, SleeperID : 11808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Xavier`, LastName: `White`, Position : 'WR', Salary : 650000, SleeperID : 12189 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Millard`, LastName: `Bradford`, Position : 'DB', Salary : 650000, SleeperID : 11896 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jermaine`, LastName: `Jackson`, Position : 'WR', Salary : 650000, SleeperID : 11897 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Matthew`, LastName: `Hayball`, Position : 'P', Salary : 650000, SleeperID : 11898 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rico`, LastName: `Payton`, Position : 'CB', Salary : 650000, SleeperID : 11899 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacob`, LastName: `Kibodi`, Position : 'RB', Salary : 650000, SleeperID : 11900 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mekhi`, LastName: `Wingo`, Position : 'DT', Salary : 650000, SleeperID : 11804 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kyle`, LastName: `Sheets`, Position : 'WR', Salary : 650000, SleeperID : 11901 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nouredin`, LastName: `Nouili`, Position : 'OL', Salary : 650000, SleeperID : 11902 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dwight`, LastName: `McGlothern`, Position : 'DB', Salary : 650000, SleeperID : 11905 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darius`, LastName: `Muasau`, Position : 'LB', Salary : 650000, SleeperID : 11807 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marcellas`, LastName: `Dial`, Position : 'DB', Salary : 650000, SleeperID : 11809 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jason`, LastName: `Bean`, Position : 'QB', Salary : 650000, SleeperID : 12190 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trent`, LastName: `Pennix`, Position : 'TE', Salary : 650000, SleeperID : 12191 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Austin`, LastName: `Stogner`, Position : 'TE', Salary : 0, SleeperID : 12212 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Craig`, LastName: `Young`, Position : 'LB', Salary : 650000, SleeperID : 12192 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Max`, LastName: `Tooley`, Position : 'LB', Salary : 650000, SleeperID : 12193 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tarique`, LastName: `Barnes`, Position : 'LB', Salary : 650000, SleeperID : 12194 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Foelsch`, Position : 'TE', Salary : 650000, SleeperID : 12215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Myles`, LastName: `Murphy`, Position : 'DL', Salary : 650000, SleeperID : 12195 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jayden`, LastName: `Price`, Position : 'CB', Salary : 650000, SleeperID : 12196 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Andrew`, LastName: `Raym`, Position : 'OL', Salary : 650000, SleeperID : 12214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Steven`, LastName: `Jones`, Position : 'OL', Salary : 650000, SleeperID : 12216 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Kiser`, Position : 'LB', Salary : 650000, SleeperID : 12219 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `John`, LastName: `Paddock`, Position : 'QB', Salary : 650000, SleeperID : 12197 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Wayne`, LastName: `Ruby`, Position : 'WR', Salary : 650000, SleeperID : 12198 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Gallagher`, Position : 'LB', Salary : 650000, SleeperID : 12199 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Fabien`, LastName: `Lovett`, Position : 'DL', Salary : 650000, SleeperID : 12200 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nick`, LastName: `Torres`, Position : 'G', Salary : 650000, SleeperID : 12201 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Sao`, Position : 'CB', Salary : 650000, SleeperID : 12213 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaylon`, LastName: `White-McClain`, Position : 'DE', Salary : 650000, SleeperID : 12202 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `McKade`, LastName: `Mettauer`, Position : 'G', Salary : 650000, SleeperID : 12203 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Griffin`, LastName: `McDowell`, Position : 'OT', Salary : 650000, SleeperID : 12204 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jalen`, LastName: `Cunningham`, Position : 'OG', Salary : 650000, SleeperID : 12205 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mario`, LastName: `Kendricks`, Position : 'DL', Salary : 650000, SleeperID : 12206 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Houston`, Position : 'LB', Salary : 650000, SleeperID : 12207 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Theo`, LastName: `Benedet`, Position : 'OT', Salary : 650000, SleeperID : 12208 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Blazek`, Position : 'DL', Salary : 650000, SleeperID : 12209 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Burgess`, Position : 'WR', Salary : 650000, SleeperID : 12210 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denzel`, LastName: `Daxon`, Position : 'DL', Salary : 650000, SleeperID : 12211 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lance`, LastName: `McCutcheon`, Position : 'WR', Salary : 650000, SleeperID : 8745 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyreik`, LastName: `McAllister`, Position : 'RB', Salary : 650000, SleeperID : 8749 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Niese`, Position : 'G', Salary : 650000, SleeperID : 8750 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Roger`, LastName: `Carter`, Position : 'TE', Salary : 650000, SleeperID : 8747 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dylan`, LastName: `Parham`, Position : 'TE', Salary : 650000, SleeperID : 8751 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ja'Quan`, LastName: `McMillian`, Position : 'CB', Salary : 650000, SleeperID : 8752 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Benton`, LastName: `Whitley`, Position : 'DE', Salary : 650000, SleeperID : 8746 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `TJ`, LastName: `Carter`, Position : 'DB', Salary : 650000, SleeperID : 8748 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cortez`, LastName: `Davis`, Position : 'DB', Salary : 650000, SleeperID : 8753 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sebastian`, LastName: `Gutierrez`, Position : 'T', Salary : 650000, SleeperID : 8754 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kaden`, LastName: `Davis`, Position : 'WR', Salary : 650000, SleeperID : 8755 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brandon`, LastName: `Johnson`, Position : 'WR', Salary : 650000, SleeperID : 8756 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rodney`, LastName: `Williams`, Position : 'TE', Salary : 650000, SleeperID : 8757 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kana'i`, LastName: `Mauga`, Position : 'LB', Salary : 650000, SleeperID : 8758 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kadofi`, LastName: `Wright`, Position : 'LB', Salary : 650000, SleeperID : 8759 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dre`, LastName: `Miller`, Position : 'TE', Salary : 650000, SleeperID : 8760 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Rivas`, Position : 'G', Salary : 650000, SleeperID : 8761 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jabari`, LastName: `Ellis`, Position : 'DT', Salary : 650000, SleeperID : 8762 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trenton`, LastName: `Thompson`, Position : 'DB', Salary : 650000, SleeperID : 8763 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darren`, LastName: `Evans`, Position : 'DB', Salary : 650000, SleeperID : 8764 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zyon`, LastName: `Gilbert`, Position : 'DB', Salary : 650000, SleeperID : 8765 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Antonio`, LastName: `Valentino`, Position : 'DT', Salary : 650000, SleeperID : 8766 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nolan`, LastName: `Cockrill`, Position : 'DT', Salary : 650000, SleeperID : 8767 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jesse`, LastName: `Bramble`, Position : 'DB', Salary : 650000, SleeperID : 8768 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Bist`, Position : 'TE', Salary : 650000, SleeperID : 8769 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Malik`, LastName: `Williams`, Position : 'WR', Salary : 650000, SleeperID : 8770 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Derek`, LastName: `Kerstetter`, Position : 'OL', Salary : 650000, SleeperID : 8771 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Mika`, LastName: `Tafua`, Position : 'DE', Salary : 650000, SleeperID : 8805 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Storey`, LastName: `Jackson`, Position : 'LB', Salary : 650000, SleeperID : 8806 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Big Kat`, LastName: `Bryant`, Position : 'DE', Salary : 650000, SleeperID : 8807 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `La'Kendrick`, LastName: `Van Zandt`, Position : 'DB', Salary : 650000, SleeperID : 8808 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Aaron`, LastName: `Shampklin`, Position : 'RB', Salary : 650000, SleeperID : 8809 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Amon`, LastName: `Simon`, Position : 'T', Salary : 650000, SleeperID : 8810 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `James`, LastName: `Empey`, Position : 'C', Salary : 650000, SleeperID : 8811 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jonathan`, LastName: `Garibay`, Position : 'K', Salary : 650000, SleeperID : 8812 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Turner`, Position : 'WR', Salary : 650000, SleeperID : 8813 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zein`, LastName: `Obeid`, Position : 'G', Salary : 650000, SleeperID : 8814 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cedric`, LastName: `Boswell`, Position : 'DB', Salary : 650000, SleeperID : 8815 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Demetrius`, LastName: `Taylor`, Position : 'DT', Salary : 650000, SleeperID : 8816 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Jarvis`, Position : 'G', Salary : 650000, SleeperID : 8819 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kevin`, LastName: `Kassis`, Position : 'WR', Salary : 650000, SleeperID : 8876 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cole`, LastName: `Fotheringham`, Position : 'TE', Salary : 650000, SleeperID : 8840 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Troy`, LastName: `Hairston`, Position : 'RB', Salary : 650000, SleeperID : 8820 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `VanValkenburg`, Position : 'DE', Salary : 650000, SleeperID : 8841 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ryder`, LastName: `Anderson`, Position : 'DE', Salary : 650000, SleeperID : 8874 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jordan`, LastName: `Mosley`, Position : 'DB', Salary : 650000, SleeperID : 8875 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Abu`, LastName: `Daramy-Swaray`, Position : 'CB', Salary : 650000, SleeperID : 8877 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jared`, LastName: `Scott`, Position : 'TE', Salary : 650000, SleeperID : 8878 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kameron`, LastName: `Brown`, Position : 'WR', Salary : 650000, SleeperID : 8888 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `White`, Position : 'K', Salary : 650000, SleeperID : 8937 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Nathan`, LastName: `Rourke`, Position : 'QB', Salary : 650000, SleeperID : 8938 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Evan`, LastName: `Hull`, Position : 'RB', Salary : 650000, SleeperID : 9220 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jahmyr`, LastName: `Gibbs`, Position : 'RB', Salary : 12886024.52, SleeperID : 9221 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zach`, LastName: `Evans`, Position : 'RB', Salary : 650000, SleeperID : 9222 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tiyon`, LastName: `Evans`, Position : 'RB', Salary : 650000, SleeperID : 9223 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chase`, LastName: `Brown`, Position : 'RB', Salary : 1024661.747, SleeperID : 9224 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tank`, LastName: `Bigsby`, Position : 'RB', Salary : 650000, SleeperID : 9225 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `De'Von`, LastName: `Achane`, Position : 'RB', Salary : 9272118.157, SleeperID : 9226 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Israel`, LastName: `Abanikanda`, Position : 'RB', Salary : 650000, SleeperID : 9227 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Bryce`, LastName: `Young`, Position : 'QB', Salary : 836791.8635, SleeperID : 9228 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Anthony`, LastName: `Richardson`, Position : 'QB', Salary : 3920449.744, SleeperID : 9229 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tanner`, LastName: `McKee`, Position : 'QB', Salary : 650000, SleeperID : 9230 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaren`, LastName: `Hall`, Position : 'QB', Salary : 650000, SleeperID : 9231 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darnell`, LastName: `Washington`, Position : 'TE', Salary : 650000, SleeperID : 9479 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Brenton`, LastName: `Strange`, Position : 'TE', Salary : 650000, SleeperID : 9480 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Luke`, LastName: `Musgrave`, Position : 'TE', Salary : 815872.0669, SleeperID : 9481 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Michael`, LastName: `Mayer`, Position : 'TE', Salary : 700889.8304, SleeperID : 9482 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Zack`, LastName: `Kuntz`, Position : 'TE', Salary : 650000, SleeperID : 9483 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dontayvion`, LastName: `Wicks`, Position : 'WR', Salary : 650000, SleeperID : 9486 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tucker`, LastName: `Kraft`, Position : 'TE', Salary : 650000, SleeperID : 9484 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Parker`, LastName: `Washington`, Position : 'WR', Salary : 650000, SleeperID : 9487 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jaxon`, LastName: `Smith-Njigba`, Position : 'WR', Salary : 4563606.572, SleeperID : 9488 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Justin`, LastName: `Shorter`, Position : 'WR', Salary : 650000, SleeperID : 9489 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tyler`, LastName: `Scott`, Position : 'WR', Salary : 650000, SleeperID : 9490 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Ty`, LastName: `Scott`, Position : 'WR', Salary : 650000, SleeperID : 9491 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Trey`, LastName: `Palmer`, Position : 'WR', Salary : 650000, SleeperID : 9492 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Puka`, LastName: `Nacua`, Position : 'WR', Salary : 11353820.71, SleeperID : 9493 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Marvin`, LastName: `Mims`, Position : 'WR', Salary : 650000, SleeperID : 9494 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Rakim`, LastName: `Jarrett`, Position : 'WR', Salary : 650000, SleeperID : 9495 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cameron`, LastName: `Latu`, Position : 'TE', Salary : 650000, SleeperID : 10210 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Sam`, LastName: `James`, Position : 'WR', Salary : 650000, SleeperID : 9496 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `Levis`, Position : 'QB', Salary : 650000, SleeperID : 9999 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Camerun`, LastName: `Peoples`, Position : 'RB', Salary : 650000, SleeperID : 10211 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Josh`, LastName: `Whyle`, Position : 'TE', Salary : 650000, SleeperID : 10212 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tre`, LastName: `Tucker`, Position : 'WR', Salary : 650000, SleeperID : 10213 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Davis`, LastName: `Allen`, Position : 'TE', Salary : 650000, SleeperID : 10214 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jake`, LastName: `Haener`, Position : 'QB', Salary : 650000, SleeperID : 10215 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kenny`, LastName: `McIntosh`, Position : 'RB', Salary : 650000, SleeperID : 10216 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jack`, LastName: `Campbell`, Position : 'LB', Salary : 650000, SleeperID : 10880 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Clayton`, LastName: `Tune`, Position : 'QB', Salary : 650000, SleeperID : 10217 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Christian`, LastName: `Gonzalez`, Position : 'DB', Salary : 650000, SleeperID : 10881 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Emmanuel`, LastName: `Forbes`, Position : 'CB', Salary : 650000, SleeperID : 10882 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Will`, LastName: `McDonald`, Position : 'DE', Salary : 650000, SleeperID : 10883 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Broderick`, LastName: `Jones`, Position : 'OL', Salary : 650000, SleeperID : 10884 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Lukas`, LastName: `Van Ness`, Position : 'DL', Salary : 650000, SleeperID : 10885 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Peter`, LastName: `Skoronski`, Position : 'OL', Salary : 650000, SleeperID : 10886 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Darnell`, LastName: `Wright`, Position : 'OL', Salary : 650000, SleeperID : 10887 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Minnesota`, LastName: `Vikings`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Kansas City`, LastName: `Chiefs`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Denver`, LastName: `Broncos`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cincinnati`, LastName: `Bengals`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Chicago`, LastName: `Bears`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tennessee`, LastName: `Titans`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `New York`, LastName: `Giants`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `San Francisco`, LastName: `49ers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Philadelphia`, LastName: `Eagles`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Buffalo`, LastName: `Bills`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Detroit`, LastName: `Lions`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Miami`, LastName: `Dolphins`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Green Bay`, LastName: `Packers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `New Orleans`, LastName: `Saints`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Los Angeles`, LastName: `Rams`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Jacksonville`, LastName: `Jaguars`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Carolina`, LastName: `Panthers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Atlanta`, LastName: `Falcons`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Cleveland`, LastName: `Browns`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Tampa Bay`, LastName: `Buccaneers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Los Angeles`, LastName: `Chargers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Washington`, LastName: `Commanders`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Seattle`, LastName: `Seahawks`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Dallas`, LastName: `Cowboys`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `New York`, LastName: `Jets`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Las Vegas`, LastName: `Raiders`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Arizona`, LastName: `Cardinals`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Indianapolis`, LastName: `Colts`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Pittsburgh`, LastName: `Steelers`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Baltimore`, LastName: `Ravens`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `Houston`, LastName: `Texans`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});
allDatabasePlayers.push(  { FirstName: `New England`, LastName: `Patriots`, Position : 'DEF', Salary : 650000, SleeperID : 0 ,ESPNID : 0, YahooId: 0, IsActive: false, IsRookie: false, ExcludeFromSalary: false});


        return allDatabasePlayers;
    }
}
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { delay } from 'rxjs';
import { CdkVirtualScrollViewport, ScrollingModule} from '@angular/cdk/scrolling'
import { PlayerService } from './player.service';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HttpClientModule, CommonModule, FormsModule, NgbNavModule, ScrollingModule ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Fantasy Helper Tool';
  localDb: any;
  @ViewChild(CdkVirtualScrollViewport, { static: false })  cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  @ViewChild('selectViewPort', {static: false}) selectViewPort: CdkVirtualScrollViewport;
  // sleeper api data
  leagueId_Dynasty: string = '1067970449826476032';
  sleeperApiStringRosters: string = `https://api.sleeper.app/v1/league/${this.leagueId_Dynasty}/rosters`;
  sleeperApiStringUsers: string = `https://api.sleeper.app/v1/league/${this.leagueId_Dynasty}/users`;
  sleeperApiStringAllPlayers: string = 'https://api.sleeper.app/v1/players/nfl';
  allDatabasePlayers: player[]=[];
  allSleeperUsers: user[] =[];
  selectedUser: user = new user();
  userPlayers: player[] =[];
  totalSalary: number = 0;
  tempPlayers: player[] = [];
  allSleeperUserPlayers: sleeperUserPlayer[] =[];
  selectedTempPlayer: player = new player();
  freeAgentTargets: player[]=[];
  tempRosterSalary: number = 0;
  playersForFreeAgency: player[]=[];
  active = 1;
  constructor(private http: HttpClient, private playerService: PlayerService){
    
  }

  ngOnInit(): void {  
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: headers
    }
    // // 595321162108551168
    // this.http.get('https://localhost:5001/api/Player').subscribe(this.playersLoaded.bind(this));
    // this.http.get('https://localhost:5001/api/SleeperUser').subscribe(this.usersLoaded.bind(this));
    // this.http.get('/assets/PlayerDb.csv', {responseType: 'text'}).subscribe((data) => {
    //   let csvToRowArray = data.split("\n");
    //   for (let index = 1; index < csvToRowArray.length; index++) {
    //     let row = csvToRowArray[index].split(",");
    //     let p = new player();
    //     p.FirstName = row[1];
    //     p.LastName = row[2];
    //     p.Position = row[3];
    //     p.SleeperID = parseInt(row[6]);
    //     p.Salary = parseFloat(row[8]);        
    //     this.allDatabasePlayers.push(p);
    //   }
    //   this.playersForFreeAgency = JSON.parse(JSON.stringify(this.allDatabasePlayers));
    //   this.allDatabasePlayers.sort((a,b) => b.Salary - a.Salary);      
      
    //   this.playersForFreeAgency.sort((a,b) =>{
    //     if((a.FirstName + a.LastName) < (b.FirstName + b.LastName)) { return -1; }
    //     if((a.FirstName + a.LastName) > (b.FirstName + b.LastName)) { return 1; }
    //     return 0;
    // });
    //   this.getSleeperUsers();
    // });
    
    // this.http.post('https://localhost:5001/api/PlayersByUserId', { SleeperUserId: '595321162108551168' }).subscribe((data) => {
    //   console.log(data);
    // });
    this.allDatabasePlayers = this.playerService.getPlayerDatabase();
    this.playersForFreeAgency = JSON.parse(JSON.stringify(this.allDatabasePlayers));
    this.allDatabasePlayers.sort((a,b) => b.Salary - a.Salary); 
      this.playersForFreeAgency.sort((a,b) =>{
        if((a.FirstName + a.LastName) < (b.FirstName + b.LastName)) { return -1; }
        if((a.FirstName + a.LastName) > (b.FirstName + b.LastName)) { return 1; }
        return 0;
    });
    
    this.getSleeperUsers();
  }
  
   // Subscriptions
   playersLoaded(data: any){
    if(data.value){
      data.value.forEach((v: player) => {
        this.tempPlayers.push(v);
        
      });
    }
        
    if(data?.nextLink){
      this.http.get(data.nextLink).subscribe(this.playersLoaded.bind(this));
    } else{
      
      this.tempPlayers.sort((a,b) => b.Salary - a.Salary);
      this.allDatabasePlayers = this.tempPlayers;
    }
    
  }

  usersLoaded(data: any){
    if(data.value){
      data.value.forEach((v: user) => {
        this.allSleeperUsers.push(v);
      });
      this.selectedUser = this.allSleeperUsers[0];
      this.http.post('https://localhost:5001/api/PlayersByUserId', { SleeperUserId: this.selectedUser.SleeperUserId }).subscribe(this.userTeamLoaded.bind(this));
    }
    
  }

  userTeamLoaded(data:any){
    this.userPlayers = [];
    this.totalSalary = 0;
    if(data.value){
      data.value.forEach((v: player) =>{
        this.userPlayers.push(v);
        this.totalSalary += v.Salary;
      });
    }
  }

  // Sleeper Api Calls
  getSleeperUsers(){
    this.http.get(this.sleeperApiStringUsers, {responseType: 'json'}).subscribe(data =>{
      let dataObj = data as any;
      let keys = Object.keys(data);
      for(let i =0; i < keys.length; i++){
        let dataUser = dataObj[keys[i]];
        let dbUser = new user();
        dbUser.SleeperUserId =dataUser.user_id;
        dbUser.DisplayName = dataUser.display_name;
        if(dataUser.metadata && dataUser.metadata.team_name){          
          dbUser.TeamName = dataUser.metadata.team_name;

          if(dataUser.metadata.avatar){
            dbUser.AvatarUrl = dataUser.metadata.avatar;
          }
          
        }
        this.allSleeperUsers.push(dbUser);
      }
      this.selectedUser = this.allSleeperUsers[0];
      this.getRosters();
    });
  }

  getUsersAndUploadToDb(){    
    this.http.get(this.sleeperApiStringUsers, {responseType: 'json'}).subscribe(data =>{
      let dataObj = data as any;
      let keys = Object.keys(data);
      for(let i =0; i < keys.length; i++){
        let dataUser = dataObj[keys[i]];
        let dbUser = new user();
        dbUser.SleeperUserId =dataUser.user_id;
        dbUser.DisplayName = dataUser.display_name;
        if(dataUser.metadata && dataUser.metadata.team_name){          
          dbUser.TeamName = dataUser.metadata.team_name;

          if(dataUser.metadata.avatar){
            dbUser.AvatarUrl = dataUser.metadata.avatar;
          }
          
        }
        // console.log(dbUser);
        this.http.post('https://localhost:5001/api/SleeperUser',dbUser).subscribe((data) =>{});
      }
    });
  }

  getRosters(){
    this.http.get(this.sleeperApiStringRosters, {responseType: 'json'}).subscribe(data =>{
      
      let dataObj = data as any;
      let keys = Object.keys(data);
      for(let i =0; i < keys.length; i++){
        let roster = dataObj[keys[i]];
        let rosterPlayers = roster.players;
        
        for(let p =0; p < rosterPlayers.length; p++){
          let player = rosterPlayers[p];
          let su = new sleeperUserPlayer();
          su.SleeperUserId = roster.owner_id;
          su.SleeperPlayerId = player;
          
           this.allSleeperUserPlayers.push(su);          
        }
      }
      this.updateSelectedUserRoster();
      this.loadTeamTotalSalaries();
    });

  }

  getRostersAndUploadToDatabase(){
    this.http.get(this.sleeperApiStringRosters, {responseType: 'json'}).subscribe(data =>{
      console.log(data);
      let dataObj = data as any;
      let keys = Object.keys(data);
      for(let i =0; i < keys.length; i++){
        let roster = dataObj[keys[i]];
        let rosterPlayers = roster.players;
        
        for(let p =0; p < rosterPlayers.length; p++){
          let player = rosterPlayers[p];
          let su = new sleeperUserPlayer();
          su.SleeperUserId = roster.owner_id;
          su.SleeperPlayerId = player;
          this.http.post('https://localhost:5001/api/SleeperUserPlayer',su).subscribe((data) =>{});
          // let existsInDatabase = this.allDatabasePlayers.find(f => f.SleeperID == parseInt(player));
          // if(!existsInDatabase){
          //   console.log(player + ' could not be found in database');
          // }
        }
      }
    });
  }

  getAllPlayers(){
    this.http.get(this.sleeperApiStringAllPlayers, {responseType: 'json'}).subscribe(data => {
      let dataObj = data as any;
      let keys = Object.keys(data);
      console.log(keys);
      let allPlayers: any[]=[];
      let playerIdsImported: string[]=[];
      try{
        // 0 - 3001 [DONE]
        // 3000 -> 6001 [DONE]
        // 6000 -> 9001
        // 9000 -> keys.length
        for(let i = 9000; i < keys.length; i++){
          let sleeperPlayer = dataObj[keys[i]];
          if(sleeperPlayer.active){
            (new Promise(r => setTimeout(r, 1000))).then(() =>{
              let p = new player();
              p.FirstName = sleeperPlayer.first_name;
              p.LastName = sleeperPlayer.last_name;
              p.Position = sleeperPlayer.position;
              p.ESPNID = sleeperPlayer.espn_id;
              p.YahooId = sleeperPlayer.yahoo_id;
              p.SleeperID = parseInt(keys[i]);
              p.IsActive = sleeperPlayer.active;
              //allPlayers.push(p);
               this.http.post('https://localhost:5001/api/Player',p).subscribe((data) =>{});
            });                         
          }
          
        }
        console.log('done saving data...');
      } catch (Error){
        console.log('error saving data : ' + Error);
      } 
    
      
    });
   
  }

  updatePlayerDatabase(){
    this.http.get(this.sleeperApiStringAllPlayers, {responseType: 'json'}).subscribe(data => {
      let dataObj = data as any;
      let keys = Object.keys(data);

      for(let i =0; i < keys.length; i++){
        let sleeperPlayer = dataObj[keys[i]];
        // is active
        if(sleeperPlayer.active){
          let sleeperId = parseInt(keys[i]);
          let existsInDatabaseCurrently = this.allDatabasePlayers.find(f => f.SleeperID == sleeperId);
          // Add to database if doesn't exist...
          if(!existsInDatabaseCurrently){
              let p = new player();
              p.FirstName = sleeperPlayer.first_name;
              p.LastName = sleeperPlayer.last_name;
              p.Position = sleeperPlayer.position;
              p.ESPNID = sleeperPlayer.espn_id;
              p.YahooId = sleeperPlayer.yahoo_id;
              p.SleeperID = parseInt(keys[i]);
              p.IsActive = sleeperPlayer.active;
              //allPlayers.push(p);
               this.http.post('https://localhost:5001/api/Player',p).subscribe((data) =>{});
          }
        }
      }


    });
  }
  
  updateSelectedUserRoster(){
    this.userPlayers = [];
    this.totalSalary = 0;
    this.tempPlayers = [];
    this.selectedTempPlayer = new player();
    this.tempRosterSalary = 0;
  
    if(this.selectedUser && this.allSleeperUserPlayers && this.allDatabasePlayers){
      let sup = this.allSleeperUserPlayers.filter(f => f.SleeperUserId == this.selectedUser.SleeperUserId);
      if(sup){
        for(let i =0; i < sup.length; i ++){
          let player = this.allDatabasePlayers.find(f => f.SleeperID == sup[i].SleeperPlayerId);
          if(player){
            this.userPlayers.push(player);
            this.totalSalary += player.Salary;
          }
        }
      }
    }
  }
  
  // Ui Methods
  selectedUserChanged(event: any){   
    //this.http.post('https://localhost:5001/api/PlayersByUserId', { SleeperUserId: this.selectedUser.SleeperUserId }).subscribe(this.userTeamLoaded.bind(this));
    this.updateSelectedUserRoster();
  }

  addTempPlayerToTempTeam(p: player){
    this.tempPlayers.push(p);
    this.calculateFreeAgencySalary();
    // if(this.selectedTempPlayer){
      
    // }
  }

  removeFromTempList(p: player){
    let spliceIndex = this.tempPlayers.findIndex(f => f.SleeperID == p.SleeperID);
    if(spliceIndex != -1){
      this.tempPlayers.splice(spliceIndex,1);
      this.calculateFreeAgencySalary();
    }
    
  }

  calculateFreeAgencySalary(){
    this.tempRosterSalary =0;
      this.tempPlayers.forEach((p) =>{
        this.tempRosterSalary += p.Salary;
      });
  }

  excludeFromSalary(p: player){
    p.ExcludeFromSalary = !p.ExcludeFromSalary;
    this.totalSalary =0;
    this.userPlayers.forEach((p) => {
      if(!p.ExcludeFromSalary){
        this.totalSalary += p.Salary;
      }
    })
  }

  loadTeamTotalSalaries(){
    for(let i =0; i < this.allSleeperUsers.length; i++){
      let user = this.allSleeperUsers[i];
      user.TotalSalaryCap = 0;
      let roster = this.allSleeperUserPlayers.filter(f => f.SleeperUserId == this.allSleeperUsers[i].SleeperUserId);
      if(roster){
        roster.forEach((a) =>{
          let player = this.allDatabasePlayers.find(x => x.SleeperID == a.SleeperPlayerId);
          if(player){
            user.TotalSalaryCap += player.Salary;
          }          
        });
      }

    }
  }
  navChanged(){
    if(this.active == 1){
      setTimeout(() =>{
        this.cdkVirtualScrollViewport?.checkViewportSize();  
      }, 1000);      
    } else if (this.active == 2){
      console.log('setting view port for dropdown..');      
      setTimeout(() =>{
        this.selectViewPort?.checkViewportSize();
      }, 1000);      
    }
  }

}
export class player {
  FirstName: string='';
  LastName: string='';
  Position: string='';
  ESPNID: number=0;
  YahooId: number=0;
  SleeperID: number = 0;
  IsActive: boolean = false;
  IsRookie: boolean = false;
  Salary: number = 0;
  ExcludeFromSalary: boolean = false;
}
export class user {
  DisplayName: string = '';
  TeamName: string = '';
  AvatarUrl: string = '';
  SleeperUserId: string = '';
  TotalSalaryCap: number = 0;
}
export class sleeperUserPlayer{
  SleeperPlayerId: number = 0;
  SleeperUserId: string = '';
}
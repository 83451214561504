<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  
</style>

<div class="container-fluid" style="height: 100vh;background-color: black;overflow-y:hidden"> 
  <div class="row" style="width: 100vw;height:50px;background-color:#1f2730;margin-bottom: 25px;box-shadow:  0px 1px 3px #888888;">
    <div class="col-md-2" style="color:white;padding-top:15px">
      <span style="padding:10px">Dynasty Salary Tool</span>
    </div>
  </div>
  <ul ngbNav #nav="ngbNav" (activeIdChange)="navChanged()" [(activeId)]="active" class="nav-tabs" data-bs-theme="dark">
    <li [ngbNavItem]="1">
      <button ngbNavLink>Overview</button>
      <ng-template ngbNavContent>        
          <div class="col-md-12">                           
            <div class="row centeredPillRow" style="overflow-y:auto;">
              <table class="col-md-12 table table-striped" data-bs-theme="dark">
                <thead>
                  <tr>
                    <th scope="col">Team</th>
                    <th scope="col">Total Cap</th>
                    <th scope="col">Salary Cap</th>
                    <th scope="col">Current Cap Space</th>
                  </tr>
                  
                </thead>
                <tbody>
                  
                  <tr *ngFor="let p of allSleeperUsers">                    
                    <td>{{p.DisplayName}}</td>
                    <td [style.color]="p.TotalSalaryCap > 60000000 ? 'red' : 'green'" >{{p.TotalSalaryCap | currency: 'USD'}}</td>
                    <td>{{60000000 | currency: 'USD'}}</td>
                    <td [style.color]="(60000000 - p.TotalSalaryCap) < 0 ? 'red' : 'green'">{{(60000000 - p.TotalSalaryCap) | currency: 'USD'}}</td>
                  </tr>                        
                </tbody>                
              </table>
            </div>                  
          </div>                  
      </ng-template>
    </li>    
    <li [ngbNavItem]="2">
      <button ngbNavLink>Team Editor</button>
      <ng-template ngbNavContent>
        <div class="row centeredPillRow">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-1">
                <img [hidden]="!selectedUser.AvatarUrl || selectedUser.AvatarUrl == ''" height="100" width="100" [src]="selectedUser.AvatarUrl"/>
               </div> 
               <div class="col-md-7" text-align="center">
                <div class="row">
                  <div class="col-md-2">
                    <select name="selectUser" class="custom-select" [(ngModel)]="selectedUser" (ngModelChange)="selectedUserChanged($event)">                  
                      <option *ngFor="let u of allSleeperUsers" [ngValue]="u" >
                        {{u.DisplayName}}
                      </option>
                    </select>  
                  </div>                                
                </div>                                
               </div>               
            </div>   
            <div class="row">
              <div class="col-md-8">
                <div class="row" style="overflow-y:auto;max-height:63vh">
                  <table class="col-md-12 table table-striped" data-bs-theme="dark">
                    <tbody>
                      <tr *ngFor="let p of userPlayers">
                        <td [style.color]="p.ExcludeFromSalary ? 'red': ''">{{p.FirstName}} {{p.LastName}}</td>
                        <td [style.color]="p.ExcludeFromSalary ? 'red': ''">{{p.Position}}</td>
                        <td [style.color]="p.ExcludeFromSalary ? 'red': ''">{{p.Salary | currency: 'USD'}}</td>
                        <td [hidden]="p.ExcludeFromSalary"><span style="text-decoration: underline;cursor:pointer" (click)="excludeFromSalary(p)">Exclude from Salary</span></td>
                        <td [hidden]="!p.ExcludeFromSalary"><span style="text-decoration: underline;cursor:pointer" (click)="excludeFromSalary(p)">Include in Salary</span></td>
                      </tr>     
                      <tr *ngFor="let p of tempPlayers">
                        <td style="color:yellow">{{p.FirstName}} {{p.LastName}}</td>
                        <td style="color:yellow">{{p.Position}}</td>
                        <td style="color:yellow">{{p.Salary | currency: 'USD'}}</td>
                        <td style="color:yellow"><button (click)="removeFromTempList(p)">Remove</button></td>
                      </tr>                        
                    </tbody>                
                  </table>
                </div>
                <div class="row">
                  <table class="col-md-12 table table-striped" data-bs-theme="dark" style="margin-bottom: 0px;">
                    <tbody>
                      <tr>
                        <td [style.color]="(totalSalary) > 60000000 ? 'red' : 'green'">Total Salary : {{totalSalary | currency: 'USD'}}</td>                        
                        <td style="color:yellow">Free agent(s) Cost : {{tempRosterSalary | currency: 'USD'}}</td>                       
                        <td [style.color]="(totalSalary + tempRosterSalary) > 60000000 ? 'red' : 'green'">Total (Hypothetical Cost) : {{(totalSalary + tempRosterSalary)| currency: 'USD'}}</td>
                        <td></td>
                      </tr>
                    </tbody> 
                  </table>
                </div>
              </div>
              <div class="col-md-4" style="color:white">
                <div class="row">
                  <h5 style="color:white">Player Database</h5>                
                </div>
                <div class="row">
                  <span style="color:yellow">Add players below to see how they affect your salary cap</span>
                </div>
                <cdk-virtual-scroll-viewport height="500px" style="height: 550px" itemSize="40" >
                  <div class="col-md-12" *cdkVirtualFor="let p of playersForFreeAgency" >
                    <div class="row" style="height:40px">
                      <div class="col-md-4">{{p.FirstName}} {{p.LastName}}</div>
                      <div class="col-md-2">{{p.Position}}</div>
                      <div class="col-md-3">{{p.Salary | currency: 'USD'}}</div>
                      <div class="col-md-3"><button (click)="addTempPlayerToTempTeam(p)">Add</button></div>
                    </div>              
                  </div>
              </cdk-virtual-scroll-viewport>
              </div>                            
            </div>             
          </div>
          
          <!-- <div class="col-md-6">
            <h4>Free Agency Targets</h4>
            <h6>Adding players here does NOT add them to your actual team.</h6>
            <div class="row">
              
                <label class="col-md-2" for="selectPlayer">Player :</label>
                <select class="col-md-4" name="selectPlayer" [(ngModel)]="selectedTempPlayer">
                  <cdk-virtual-scroll-viewport #selectViewPort style="height: 200px" itemSize="40" >            
                    <option *cdkVirtualFor="let p of playersForFreeAgency" [ngValue]="p" >
                      {{p.FirstName}} {{p.LastName}} | {{p.Salary | currency: 'USD'}}
                    </option>
                </cdk-virtual-scroll-viewport>         
                 
                </select>          
                <div class="col-md-4">
                  <button (click)="addTempPlayerToTempTeam()">Add Player</button>
                </div>
            </div> -->
            <!-- <div class="row">
              <table>
                <tr *ngFor="let p of tempPlayers">
                  <td>{{p.FirstName}} {{p.LastName}}</td>
                  <td>{{p.Position}}</td>
                  <td>{{p.Salary | currency: 'USD'}}</td>
                  <td><button (click)="removeFromTempList(p)">Remove</button></td>
                </tr>
                <tr></tr>
                <tr>
                  <div style="border-bottom: 1px solid black;margin:10px"></div>
                </tr>
                <tr style="padding-top:15px">
                  <td>Free Agent(s) Cost : </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Current Salary : </td>
                  <td></td>
                  <td>{{totalSalary | currency: 'USD'}}</td>
                </tr>
                <tr>
                  <td>Total (hypothetical) Salary : </td>
                  <td></td>
                  <td>{{(totalSalary + tempRosterSalary)| currency: 'USD'}}</td>
                </tr>
              </table>
            </div>
          </div> -->
        </div>
        
      </ng-template>
    </li>
    
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>    
    
</div>

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder  * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


<router-outlet />
